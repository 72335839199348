<div class="flex">
  <div id="ad"></div>

  <div id="background">
    <mat-card>
      <form [formGroup]="loginForm" (ngSubmit)="onLoginSubmit()">
        <h1>
          <img
            id="application-logo"
            width="480"
            class="sap-logo"
            title="SAP - Sistema de Avaliação Prática"
            src="/assets/img/logos/logo-sap-login.png"
          />
        </h1>
        <h2 *ngIf="nomeAmbiente">Ambiente: {{ nomeAmbiente }}</h2>

        <label for="cpf">
          <p class="input-name">Digite seu CPF</p>
        </label>
        <div class="form-fields">
          <input
            id="cpf"
            mask="000.000.000-00"
            [dropSpecialCharacters]="false"
            class="background-input"
            name="cpf"
            formControlName="cpf"
            placeholder=""
          />

          <br /><br />

          <label for="password">
            <p class="input-name">Senha</p>
          </label>
          <input
            id="password"
            class="background-input"
            formControlName="password"
            name="password"
            type="password"
            placeholder=""
          />

          <div>
            <button
              mat-raised-button
              class="login-btn center"
              color="primary"
              [disabled]="blockLoginBtn"
            >
              <i *ngIf="!blockLoginBtn">Entrar</i>
              <mat-spinner
                class="login-spinner"
                *ngIf="blockLoginBtn"
                diameter="20"
              ></mat-spinner>
            </button>

            <div class="">
              <br />

              <span class="action-button-forgot" (click)="onForgotPassword()"
                >Esqueci minha senha</span
              >
              <button
                type="button"
                id="self-registration-button"
                mat-raised-button
                class="register-btn center"
                color="primary"
                (click)="onRegister()"
              >
                Cadastre-se aqui
              </button>

              <br />
              <!-- <button type="button" id="google-registration-button" mat-raised-button class="register-btn center" color="primary" [disabled]="blockGoogleLoginBtn" (click)="socialSignIn('google')">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 48 48" class="abcRioButtonSvg"><g><path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path><path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path><path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path><path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path><path fill="none" d="M0 0h48v48H0z"></path></g></svg>
                <i *ngIf="!blockGoogleLoginBtn">Login com o Google</i>
                <mat-spinner class="google-login-spinner" *ngIf="blockGoogleLoginBtn" diameter="20"></mat-spinner>
              </button> -->
              <!-- <div class="login-options" (click)="socialSignIn('google')">
                <img class="login-options-item" src="/assets/img/btns/google-login-btn.png">
              </div> -->
            </div>
          </div>
        </div>
      </form>
      <br />
    </mat-card>
  </div>
  <app-support-card></app-support-card>
</div>
