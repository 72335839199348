<app-modal-header [hasClose]='true' [title]="title" (handleClose)="close()"></app-modal-header>

<mat-dialog-content class="content-dialog" *ngIf="data.action != 3">

  <mat-list>
    <div mat-subheader>Aluno(s) selecionado(s):</div>
    <mat-list-item *ngFor="let item of data.itens">
      <mat-icon mat-list-icon>person</mat-icon>
      <div mat-line>{{ item.name }}</div>
      <div mat-line>CPF: {{ item.cpf_number }} </div>
    </mat-list-item>
  </mat-list>

  <mat-expansion-panel class="m-b20" *ngIf="data.action == 2">
    <mat-expansion-panel-header>
      Histórico de Agendamento
    </mat-expansion-panel-header>

    <table style="width: 100%;">
      <thead>
        <tr class="t-head-canceled">
          <th width="25">Data</th>
          <th width="15">Hora</th>
          <th width="60">Motivo</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of canceled">
          <td>{{item.appointment_timestamp | date: 'dd/MM/yyyy'}}</td>
          <td>{{item.appointment_timestamp | date: 'hh:mm:ss'}}</td>
          <td>{{item.reason}}</td>
        </tr>
      </tbody>
    </table>

  </mat-expansion-panel>

  <mat-card class="m-b20">
    <form [formGroup]="appointmentForm" class="pd0">

      <mat-form-field *ngIf="data.action == 2">
        <textarea matInput placeholder="Justificativa" rows="3" formControlName="reason" required></textarea>
        <mat-error *ngIf="appointmentForm.get('reason').errors && appointmentForm.get('reason').touched">
          {{appointmentForm.get('reason').errors['message']}}</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-select matSelect formControlName="place_id" placeholder="Local de prova" required>
          <mat-option *ngFor="let item of places" [value]="item.id">
            {{item.description}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="appointmentForm.get('place_id').errors && appointmentForm.get('place_id').touched">
          {{appointmentForm.get('place_id').errors['message']}}</mat-error>
      </mat-form-field>

      <div class="flex">

        <mat-form-field class="half-input">
          <mat-datepicker-toggle matSuffix [for]="appointment_timestamp"></mat-datepicker-toggle>
          <input (click)="appointment_timestamp.open()" [matDatepicker]="appointment_timestamp" [min]="minDate" [max]="maxDate" matInput type="text"
            formControlName="appointment_timestamp" placeholder="Data Agendamento" readonly="true" required>
          <mat-datepicker #appointment_timestamp></mat-datepicker>
          <mat-error *ngIf="appointmentForm.get('appointment_timestamp').errors && appointmentForm.get('appointment_timestamp').touched">
            {{appointmentForm.get('appointment_timestamp').errors['message']}}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <input matInput placeholder="Hora" #time formControlName="time" type="time" required>
          <mat-error *ngIf="appointmentForm.get('time').errors && appointmentForm.get('time').touched">
            {{appointmentForm.get('time').errors['message']}}</mat-error>
        </mat-form-field>

      </div>

      <mat-form-field>
        <mat-select matSelect formControlName="evaluator_user_id" placeholder="Avaliador" required>
          <mat-option *ngFor="let item of avaliators" [value]="item.id">
            {{item.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="appointmentForm.get('evaluator_user_id').errors && appointmentForm.get('evaluator_user_id').touched">
          {{appointmentForm.get('evaluator_user_id').errors['message']}}</mat-error>
      </mat-form-field>

    </form>
  </mat-card>

</mat-dialog-content>

<mat-dialog-content class="content-dialog" *ngIf="data.action == 3">
  <form [formGroup]="cancelForm">
    <div *ngIf="data.info" class="info">
      <p class="text-danger">Ao cancelar o exame, o estudante será automaticamente desomologado.</p>
      <p>Caso o estudante tenha faltado no dia da prova, mas irá reagendar a aplicação, você deve entrar em contato com o Coordenador de Avaliação</p>
    </div>
    <mat-form-field>
      <mat-select matSelect formControlName="appointment_cancel_type_id" placeholder="Tipo de Cancelamento" required>
        <mat-option *ngFor="let item of cancelType" [value]="item.id">
          {{item.description}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="cancelForm.get('appointment_cancel_type_id').errors && cancelForm.get('appointment_cancel_type_id').touched">
        {{cancelForm.get('appointment_cancel_type_id').errors['message']}}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <textarea matInput placeholder="Justificativa" rows="7" formControlName="reason"></textarea>
      <mat-error *ngIf="cancelForm.get('reason').errors && cancelForm.get('reason').touched">
        {{cancelForm.get('reason').errors['message']}}</mat-error>
    </mat-form-field>

  </form>
</mat-dialog-content>

<div class="modal-footer">
  <button mat-raised-button mat-dialog-close type="button" id="cancel">Cancelar</button>
  <button id="modalApplyBtn" class="primary-action-btn" mat-raised-button type="submit" (click)="appointment()" [disabled]="appointmentForm.invalid"
    *ngIf="data.action == 1">{{btn}}</button>
  <button class="primary-action-btn" mat-raised-button type="submit" (click)="cancelAppointment()" [disabled]="valid()"
    *ngIf="data.action != 1">{{btn}}</button>
</div>
