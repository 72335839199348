<app-modal-header [hasClose]='true' [title]="'Símbolos / Caracteres Especiais'" (handleClose)="close()"></app-modal-header>

<mat-dialog-content class="content-dialog">

  <div id="contents" style="visibility: visible; text-align: center;">
    <span class="char" data-decimal="2801" data-entity="&amp;#2801;" data-id="43137" title="Gujarati Rupee Sign">૱</span>
    <span class="char" data-decimal="43064" data-entity="&amp;#43064;" data-id="43142" title="North Indic Rupee Mark">꠸</span>
    <span class="char" data-decimal="9519" data-entity="&amp;#9519;" data-id="43453" title="Box Drawings Down Light And Horizontal Heavy">┯</span>
    <span class="char" data-decimal="9520" data-entity="&amp;#9520;" data-id="43454" title="Box Drawings Down Heavy And Horizontal Light">┰</span>
    <span class="char" data-decimal="9521" data-entity="&amp;#9521;" data-id="43455" title="Box Drawings Right Light And Left Down Heavy">┱</span>
    <span class="char" data-decimal="9522" data-entity="&amp;#9522;" data-id="43456" title="Box Drawings Left Light And Right Down Heavy">┲</span>
    <span class="char" data-decimal="10071" data-entity="&amp;#10071;" data-id="43665" title="Heavy Exclamation Mark Symbol">❗</span>
    <span class="char" data-decimal="9658" data-entity="&amp;#9658;" data-id="43949" title="Black Right Pointing Pointer">►</span>
    <span class="char" data-decimal="9668" data-entity="&amp;#9668;" data-id="43959" title="Black Left Pointing Pointer">◄</span>
    <span class="char" data-decimal="258" data-entity="&amp;#258;" data-id="44643" title="Latin Capital Letter A With Breve">Ă</span>
    <span class="char" data-decimal="259" data-entity="&amp;#259;" data-id="44644" title="Latin Small Letter A With Breve">ă</span>
    <span class="char" data-decimal="32" data-entity="&amp;#32;" data-id="44769" title="Space"> </span>
    <span class="char" data-decimal="48" data-entity="&amp;#48;" data-id="44784" title="Digit Zero">0</span>
    <span class="char" data-decimal="49" data-entity="&amp;#49;" data-id="44785" title="Digit One">1</span>
    <span class="char" data-decimal="50" data-entity="&amp;#50;" data-id="44786" title="Digit Two">2</span>
    <span class="char" data-decimal="51" data-entity="&amp;#51;" data-id="44787" title="Digit Three">3</span>
    <span class="char" data-decimal="52" data-entity="&amp;#52;" data-id="44788" title="Digit Four">4</span>
    <span class="char" data-decimal="53" data-entity="&amp;#53;" data-id="44789" title="Digit Five">5</span>
    <span class="char" data-decimal="54" data-entity="&amp;#54;" data-id="44790" title="Digit Six">6</span>
    <span class="char" data-decimal="55" data-entity="&amp;#55;" data-id="44791" title="Digit Seven">7</span>
    <span class="char" data-decimal="56" data-entity="&amp;#56;" data-id="44792" title="Digit Eight">8</span>
    <span class="char" data-decimal="57" data-entity="&amp;#57;" data-id="44793" title="Digit Nine">9</span>
    <span class="char" data-decimal="469" data-entity="&amp;#469;" data-id="44948" title="Latin Capital Letter U With Diaeresis And Macron">Ǖ</span>
    <span class="char" data-decimal="470" data-entity="&amp;#470;" data-id="44949" title="Latin Small Letter U With Diaeresis And Macron">ǖ</span>
    <span class="char" data-decimal="42880" data-entity="&amp;#42880;" data-id="45131" title="Latin Capital Letter Turned L">Ꞁ</span>
    <span class="char" data-decimal="164" data-entity="&amp;curren;" data-id="45147" title="Currency Sign">¤</span>
    <span class="char" data-decimal="173" data-entity="&amp;shy;" data-id="45156" title="Soft Hyphen">&shy;</span>
    <span class="char" data-decimal="208" data-entity="&amp;ETH;" data-id="45191" title="Latin Capital Letter Eth">Ð</span>
    <span class="char" data-decimal="162" data-entity="&amp;cent;" data-id="45145" title="Cent Sign">¢</span>
    <span class="char" data-decimal="8485" data-entity="&amp;#8485;" data-id="45276" title="Ounce Sign">℥</span>
    <span class="char" data-decimal="8486" data-entity="&amp;#8486;" data-id="45277" title="Ohm Sign">Ω</span>
    <span class="char" data-decimal="8487" data-entity="&amp;#8487;" data-id="45278" title="Inverted Ohm Sign">℧</span>
    <span class="char" data-decimal="8490" data-entity="&amp;#8490;" data-id="45281" title="Kelvin Sign">K</span>
    <span class="char" data-decimal="8502" data-entity="&amp;#8502;" data-id="45293" title="Bet Symbol">ℶ</span>
    <span class="char" data-decimal="8503" data-entity="&amp;#8503;" data-id="45294" title="Gimel Symbol">ℷ</span>
    <span class="char" data-decimal="8504" data-entity="&amp;#8504;" data-id="45295" title="Dalet Symbol">ℸ</span>
    <span class="char" data-decimal="8599" data-entity="&amp;#8599;" data-id="45309" title="Double Struck Italic Small E">ⅇ</span>
    <span class="char" data-decimal="8522" data-entity="&amp;#8522;" data-id="45312" title="Property Line">⅊</span>
    <span class="char" data-decimal="9868" data-entity="&amp;#9868;" data-id="45875" title="Digram For Greater Yang">⚌</span>
    <span class="char" data-decimal="9869" data-entity="&amp;#9869;" data-id="45876" title="Digram For Lesser Yin">⚍</span>
    <span class="char" data-decimal="9870" data-entity="&amp;#9870;" data-id="45877" title="Digram For Lesser Yang">⚎</span>
    <span class="char" data-decimal="9871" data-entity="&amp;#9871;" data-id="45878" title="Digram For Greater Yin">⚏</span>
    <span class="char" data-decimal="9901" data-entity="&amp;#9901;" data-id="45905" title="Marriage Symbol">⚭</span>
    <span class="char" data-decimal="9902" data-entity="&amp;#9902;" data-id="45906" title="Divorce Symbol">⚮</span>
    <span class="char" data-decimal="8960" data-entity="&amp;#8960;" data-id="45942" title="Diameter Sign">⌀</span>
    <span class="char" data-decimal="9169" data-entity="&amp;#9169;" data-id="46151" title="Metrical Breve">⏑</span>
    <span class="char" data-decimal="9170" data-entity="&amp;#9170;" data-id="46152" title="Metrical Long Over Short">⏒</span>
    <span class="char" data-decimal="9171" data-entity="&amp;#9171;" data-id="46153" title="Metrical Short Over Long">⏓</span>
    <span class="char" data-decimal="9172" data-entity="&amp;#9172;" data-id="46154" title="Metrical Long Over Two Shorts">⏔</span>
    <span class="char" data-decimal="9173" data-entity="&amp;#9173;" data-id="46155" title="Metrical Two Shorts Over Long">⏕</span>
    <span class="char" data-decimal="9174" data-entity="&amp;#9174;" data-id="46156" title="Metrical Two Shorts Joined">⏖</span>
    <span class="char" data-decimal="9175" data-entity="&amp;#9175;" data-id="46157" title="Metrical Triseme">⏗</span>
    <span class="char" data-decimal="9176" data-entity="&amp;#9176;" data-id="46158" title="Metrical Tetraseme">⏘</span>
    <span class="char" data-decimal="9177" data-entity="&amp;#9177;" data-id="46159" title="Metrical Pentaseme">⏙</span>
    <span class="char" data-decimal="9184" data-entity="&amp;#9184;" data-id="46166" title="Top Tortoise Shell Bracket">⏠</span>
    <span class="char" data-decimal="9185" data-entity="&amp;#9185;" data-id="46167" title="Bottom Tortoise Shell Bracket">⏡</span>
    <span class="char" data-decimal="9190" data-entity="&amp;#9190;" data-id="46172" title="Ac Current">⏦</span>
    <span class="char" data-decimal="7552" data-entity="&amp;#7552;" data-id="46174" title="Latin Small Letter B With Palatal Hook">ᶀ</span>
    <span class="char" data-decimal="7553" data-entity="&amp;#7553;" data-id="46175" title="Latin Small Letter D With Palatal Hook">ᶁ</span>
    <span class="char" data-decimal="7554" data-entity="&amp;#7554;" data-id="46176" title="Latin Small Letter F With Palatal Hook">ᶂ</span>
    <span class="char" data-decimal="7555" data-entity="&amp;#7555;" data-id="46177" title="Latin Small Letter G With Palatal Hook">ᶃ</span>
    <span class="char" data-decimal="7556" data-entity="&amp;#7556;" data-id="46178" title="Latin Small Letter K With Palatal Hook">ᶄ</span>
    <span class="char" data-decimal="7558" data-entity="&amp;#7558;" data-id="46180" title="Latin Small Letter M With Palatal Hook">ᶆ</span>
    <span class="char" data-decimal="7559" data-entity="&amp;#7559;" data-id="46181" title="Latin Small Letter N With Palatal Hook">ᶇ</span>
    <span class="char" data-decimal="7560" data-entity="&amp;#7560;" data-id="46182" title="Latin Small Letter P With Palatal Hook">ᶈ</span>
    <span class="char" data-decimal="7561" data-entity="&amp;#7561;" data-id="46183" title="Latin Small Letter R With Palatal Hook">ᶉ</span>
    <span class="char" data-decimal="7562" data-entity="&amp;#7562;" data-id="46184" title="Latin Small Letter S With Palatal Hook">ᶊ</span>
    <span class="char" data-decimal="7563" data-entity="&amp;#7563;" data-id="46185" title="Latin Small Letter Esh With Palatal Hook">ᶋ</span>
    <span class="char" data-decimal="7564" data-entity="&amp;#7564;" data-id="46186" title="Latin Small Letter V With Palatal Hook">ᶌ</span>
    <span class="char" data-decimal="7565" data-entity="&amp;#7565;" data-id="46187" title="Latin Small Letter X With Palatal Hook">ᶍ</span>
    <span class="char" data-decimal="7566" data-entity="&amp;#7566;" data-id="46188" title="Latin Small Letter Z With Palatal Hook">ᶎ</span>
    <span class="char" data-decimal="7567" data-entity="&amp;#7567;" data-id="46189" title="Latin Small Letter A With Retroflex Hook">ᶏ</span>
    <span class="char" data-decimal="7568" data-entity="&amp;#7568;" data-id="46190" title="Latin Small Letter Alpha With Retroflex Hook">ᶐ</span>
    <span class="char" data-decimal="7569" data-entity="&amp;#7569;" data-id="46191" title="Latin Small Letter D With Hook And Tail">ᶑ</span>
    <span class="char" data-decimal="7570" data-entity="&amp;#7570;" data-id="46192" title="Latin Small Letter E With Retroflex Hook">ᶒ</span>
    <span class="char" data-decimal="7571" data-entity="&amp;#7571;" data-id="46193" title="Latin Small Letter Open E With Retroflex Hook">ᶓ</span>
    <span class="char" data-decimal="7572" data-entity="&amp;#7572;" data-id="46194" title="Latin Small Letter Reversed Open E With Retroflex Hook">ᶔ</span>
    <span class="char" data-decimal="7573" data-entity="&amp;#7573;" data-id="46195" title="Latin Small Letter Schwa With Retroflex Hook">ᶕ</span>
    <span class="char" data-decimal="7574" data-entity="&amp;#7574;" data-id="46196" title="Latin Small Letter I With Retroflex Hook">ᶖ</span>
    <span class="char" data-decimal="7575" data-entity="&amp;#7575;" data-id="46197" title="Latin Small Letter Open O With Retroflex Hook">ᶗ</span>
    <span class="char" data-decimal="7576" data-entity="&amp;#7576;" data-id="46198" title="Latin Small Letter Esh With Retroflex Hook">ᶘ</span>
    <span class="char" data-decimal="7577" data-entity="&amp;#7577;" data-id="46199" title="Latin Small Letter U With Retroflex Hook">ᶙ</span>
    <span class="char" data-decimal="7578" data-entity="&amp;#7578;" data-id="46200" title="Latin Small Letter Ezh With Retroflex Hook">ᶚ</span>
    <span class="char" data-decimal="7608" data-entity="&amp;#7608;" data-id="46230" title="Modifier Letter Small Capital U">ᶸ</span>
    <span class="char" data-decimal="7535" data-entity="&amp;#7535;" data-id="46349" title="Latin Small Letter M With Middle Tilde">ᵯ</span>
    <span class="char" data-decimal="7536" data-entity="&amp;#7536;" data-id="46350" title="Latin Small Letter N With Middle Tilde">ᵰ</span>
    <span class="char" data-decimal="7540" data-entity="&amp;#7540;" data-id="46354" title="Latin Small Letter S With Middle Tilde">ᵴ</span>
    <span class="char" data-decimal="7542" data-entity="&amp;#7542;" data-id="46356" title="Latin Small Letter Z With Middle Tilde">ᵶ</span>
    <span class="char" data-decimal="7545" data-entity="&amp;#7545;" data-id="46359" title="Latin Small Letter Insular G">ᵹ</span>
    <span class="char" data-decimal="7548" data-entity="&amp;#7548;" data-id="46362" title="Latin Small Letter Iota With Stroke">ᵼ</span>
    <span class="char" data-decimal="7549" data-entity="&amp;#7549;" data-id="46363" title="Latin Small Letter P With Stroke">ᵽ</span>
    <span class="char" data-decimal="7550" data-entity="&amp;#7550;" data-id="46364" title="Latin Small Capital Letter U With Stroke">ᵾ</span>
    <span class="char" data-decimal="7551" data-entity="&amp;#7551;" data-id="46365" title="Latin Small Letter Upsilon With Stroke">ᵿ</span>
    <span class="char" data-decimal="8192" data-entity="&amp;#8192;" data-id="46390" title="En Quad"> </span>
    <span class="char" data-decimal="8193" data-entity="&amp;#8193;" data-id="46391" title="Em Quad"> </span>
    <span class="char" data-decimal="8204" data-entity="&amp;#8204;" data-id="46402" title="Zero Width Non Joiner">&zwnj;</span>
    <span class="char" data-decimal="8205" data-entity="&amp;#8205;" data-id="46403" title="Zero Width Joiner">&zwj;</span>
    <span class="char" data-decimal="8206" data-entity="&amp;#8206;" data-id="46404" title="Left To Right Mark">&lrm;</span>
    <span class="char" data-decimal="8207" data-entity="&amp;#8207;" data-id="46405" title="Right To Left Mark">&rlm;</span>
    <span class="char" data-decimal="8239" data-entity="&amp;#8239;" data-id="46430" title="Narrow Non Break Space"> </span>
    <span class="char" data-decimal="8257" data-entity="&amp;#8257;" data-id="46448" title="Caret Insertion Point">⁁</span>
    <span class="char" data-decimal="8266" data-entity="&amp;#8266;" data-id="46457" title="Tironian Sign Et">⁊</span>
    <span class="char" data-decimal="8287" data-entity="&amp;#8287;" data-id="46478" title="Medium Mathematical Space"> </span>
    <span class="char" data-decimal="8298" data-entity="&amp;#8298;" data-id="46479" title="Inhibit Symmetric Swapping">⁪</span>
    <span class="char" data-decimal="8299" data-entity="&amp;#8299;" data-id="46480" title="Activate Symmetric Swapping">⁫</span>
    <span class="char" data-decimal="8300" data-entity="&amp;#8300;" data-id="46481" title="Inhibit Arabic Form Shaping">⁬</span>
    <span class="char" data-decimal="8301" data-entity="&amp;#8301;" data-id="46482" title="Activate Arabic Form Shaping">⁭</span>
    <span class="char" data-decimal="8302" data-entity="&amp;#8302;" data-id="46483" title="National Digit Shapes">⁮</span>
    <span class="char" data-decimal="8303" data-entity="&amp;#8303;" data-id="46484" title="Nominal Digit Shapes">⁯</span>
    <span class="char" data-decimal="11804" data-entity="&amp;#11804;" data-id="46512" title="Left Low Paraphrase Bracket">⸜</span>
    <span class="char" data-decimal="11805" data-entity="&amp;#11805;" data-id="46513" title="Right Low Paraphrase Bracket">⸝</span>
    <span class="char" data-entity="N/A" data-id="46535" title="">¶</span>
    <span class="char" data-decimal="1567" data-entity="&amp;#1567;" data-id="46536" title="Irony Mark"></span>
    <span class="char" data-decimal="165" data-entity="&amp;yen;" data-id="45148" title="Yen Sign">¥</span>
    <span class="char" data-decimal="163" data-entity="&amp;pound;" data-id="45146" title="Pound Sign">£</span>
    <span class="char" data-decimal="8533" data-entity="&amp;#8533;" data-id="46539" title="Vulgar Fraction One Fifth">⅕</span>
    <span class="char" data-decimal="8537" data-entity="&amp;#8537;" data-id="46540" title="Vulgar Fraction One Sixth">⅙</span>
    <span class="char" data-decimal="8539" data-entity="&amp;#8539;" data-id="46541" title="Vulgar Fraction One Eighth">⅛</span>
    <span class="char" data-decimal="8532" data-entity="&amp;#8532;" data-id="46544" title="Vulgar Fraction Two Thirds">⅔</span>
    <span class="char" data-decimal="8534" data-entity="&amp;#8534;" data-id="46545" title="Vulgar Fraction Two Fifths">⅖</span>
    <span class="char" data-decimal="8535" data-entity="&amp;#8535;" data-id="46546" title="Vulgar Fraction Three Fifths">⅗</span>
    <span class="char" data-decimal="8536" data-entity="&amp;#8536;" data-id="46547" title="Vulgar Fraction Four Fifths">⅘</span>
    <span class="char" data-decimal="8540" data-entity="&amp;#8540;" data-id="46548" title="Vulgar Fraction Three Eighths">⅜</span>
    <span class="char" data-decimal="8538" data-entity="&amp;#8538;" data-id="46549" title="Vulgar Fraction Five Sixths">⅚</span>
    <span class="char" data-decimal="8528" data-entity="&amp;#8528;" data-id="46550" title="Vulgar Fraction One Seventh">⅐</span>
    <span class="char" data-decimal="8541" data-entity="&amp;#8541;" data-id="46551" title="Vulgar Fraction Five Eighths">⅝</span>
    <span class="char" data-decimal="8585" data-entity="&amp;#8585;" data-id="46537" title="Vulgar Fraction Zero Thirds">↉</span>
    <span class="char" data-decimal="8531" data-entity="&amp;#8531;" data-id="46538" title="Vulgar Fraction One Third">⅓</span>
    <span class="char" data-decimal="8529" data-entity="&amp;#8529;" data-id="46542" title="Vulgar Fraction One Ninth">⅑</span>
    <span class="char" data-decimal="8530" data-entity="&amp;#8530;" data-id="46543" title="Vulgar Fraction One Tenth">⅒</span>
    <span class="char" data-decimal="8542" data-entity="&amp;#8542;" data-id="46552" title="Vulgar Fraction Seven Eighths">⅞</span>
    <span class="char" data-decimal="8592" data-entity="&amp;larr;" data-id="43150" title="Leftwards Arrow">←</span>
    <span class="char" data-decimal="8593" data-entity="&amp;uarr;" data-id="43151" title="Upwards Arrow">↑</span>
    <span class="char" data-decimal="8594" data-entity="&amp;rarr;" data-id="43152" title="Rightwards Arrow">→</span>
    <span class="char" data-decimal="8595" data-entity="&amp;darr;" data-id="43153" title="Downwards Arrow">↓</span>
    <span class="char" data-decimal="8596" data-entity="&amp;harr;" data-id="43154" title="Left Right Arrow">↔</span>
    <span class="char" data-decimal="8597" data-entity="&amp;#8597;" data-id="43155" title="Up Down Arrow">↕</span>
    <span class="char" data-decimal="8598" data-entity="&amp;#8598;" data-id="43156" title="North West Arrow">↖</span>
    <span class="char" data-decimal="8599" data-entity="&amp;#8599;" data-id="43157" title="North East Arrow">↗</span>
    <span class="char" data-decimal="8600" data-entity="&amp;#8600;" data-id="43158" title="South East Arrow">↘</span>
    <span class="char" data-decimal="8601" data-entity="&amp;#8601;" data-id="43159" title="South West Arrow">↙</span>
    <span class="char" data-decimal="8602" data-entity="&amp;#8602;" data-id="43160" title="Leftwards Arrow With Stroke">↚</span>
    <span class="char" data-decimal="8603" data-entity="&amp;#8603;" data-id="43161" title="Rightwards Arrow With Stroke">↛</span>
    <span class="char" data-decimal="8604" data-entity="&amp;#8604;" data-id="43162" title="Leftwards Wave Arrow">↜</span>
    <span class="char" data-decimal="8605" data-entity="&amp;#8605;" data-id="43163" title="Rightwards Wave Arrow">↝</span>
    <span class="char" data-decimal="8606" data-entity="&amp;#8606;" data-id="43164" title="Leftwards Two Headed Arrow">↞</span>
    <span class="char" data-decimal="8607" data-entity="&amp;#8607;" data-id="43165" title="Upwards Two Headed Arrow">↟</span>
    <span class="char" data-decimal="8608" data-entity="&amp;#8608;" data-id="43166" title="Rightwards Two Headed Arrow">↠</span>
    <span class="char" data-decimal="8609" data-entity="&amp;#8609;" data-id="43167" title="Downwards Two Headed Arrow">↡</span>
    <span class="char" data-decimal="8610" data-entity="&amp;#8610;" data-id="43168" title="Leftwards Arrow With Tail">↢</span>
    <span class="char" data-decimal="8611" data-entity="&amp;#8611;" data-id="43169" title="Rightwards Arrow With Tail">↣</span>
    <span class="char" data-decimal="8612" data-entity="&amp;#8612;" data-id="43170" title="Leftwards Arrow From Bar">↤</span>
    <span class="char" data-decimal="8613" data-entity="&amp;#8613;" data-id="43171" title="Upwards Arrow From Bar">↥</span>
    <span class="char" data-decimal="8614" data-entity="&amp;#8614;" data-id="43172" title="Rightwards Arrow From Bar">↦</span>
    <span class="char" data-decimal="8615" data-entity="&amp;#8615;" data-id="43173" title="Downwards Arrow From Bar">↧</span>
    <span class="char" data-decimal="8616" data-entity="&amp;#8616;" data-id="43174" title="Up Down Arrow With Base">↨</span>
    <span class="char" data-decimal="8617" data-entity="&amp;#8617;" data-id="43175" title="Leftwards Arrow With Hook">↩</span>
    <span class="char" data-decimal="8618" data-entity="&amp;#8618;" data-id="43176" title="Rightwards Arrow With Hook">↪</span>
    <span class="char" data-decimal="8619" data-entity="&amp;#8619;" data-id="43177" title="Leftwards Arrow With Loop">↫</span>
    <span class="char" data-decimal="8620" data-entity="&amp;#8620;" data-id="43178" title="Rightwards Arrow With Loop">↬</span>
    <span class="char" data-decimal="8621" data-entity="&amp;#8621;" data-id="43179" title="Left Right Wave Arrow">↭</span>
    <span class="char" data-decimal="8622" data-entity="&amp;#8622;" data-id="43180" title="Left Right Arrow With Stroke">↮</span>
    <span class="char" data-decimal="8623" data-entity="&amp;#8623;" data-id="43181" title="Downwards Zigzag Arrow">↯</span>
    <span class="char" data-decimal="8624" data-entity="&amp;#8624;" data-id="43182" title="Upwards Arrow With Tip Leftwards">↰</span>
    <span class="char" data-decimal="8625" data-entity="&amp;#8625;" data-id="43183" title="Upwards Arrow With Tip Rightwards">↱</span>
    <span class="char" data-decimal="8626" data-entity="&amp;#8626;" data-id="43184" title="Downwards Arrow With Tip Leftwards">↲</span>
    <span class="char" data-decimal="8627" data-entity="&amp;#8627;" data-id="43185" title="Downwards Arrow With Tip Rightwards">↳</span>
    <span class="char" data-decimal="8628" data-entity="&amp;#8628;" data-id="43186" title="Rightwards Arrow With Corner Downwards">↴</span>
    <span class="char" data-decimal="8629" data-entity="&amp;crarr;" data-id="43187" title="Downwards Arrow With Corner Leftwards">↵</span>
    <span class="char" data-decimal="8630" data-entity="&amp;#8630;" data-id="43188" title="Anticlockwise Top Semicircle Arrow">↶</span>
    <span class="char" data-decimal="8631" data-entity="&amp;#8631;" data-id="43189" title="Clockwise Top Semicircle Arrow">↷</span>
    <span class="char" data-decimal="8632" data-entity="&amp;#8632;" data-id="43190" title="North West Arrow To Long Bar">↸</span>
    <span class="char" data-decimal="8633" data-entity="&amp;#8633;" data-id="43191" title="Leftwards Arrow To Bar Over Rightwards Arrow To Bar">↹</span>
    <span class="char" data-decimal="8634" data-entity="&amp;#8634;" data-id="43192" title="Anticlockwise Open Circle Arrow">↺</span>
    <span class="char" data-decimal="8635" data-entity="&amp;#8635;" data-id="43193" title="Clockwise Open Circle Arrow">↻</span>
    <span class="char" data-decimal="8636" data-entity="&amp;#8636;" data-id="43194" title="Leftwards Harpoon With Barb Upwards">↼</span>
    <span class="char" data-decimal="8637" data-entity="&amp;#8637;" data-id="43195" title="Leftwards Harpoon With Barb Downwards">↽</span>
    <span class="char" data-decimal="8638" data-entity="&amp;#8638;" data-id="43196" title="Upwards Harpoon With Barb Rightwards">↾</span>
    <span class="char" data-decimal="8639" data-entity="&amp;#8639;" data-id="43197" title="Upwards Harpoon With Barb Leftwards">↿</span>
    <span class="char" data-decimal="8640" data-entity="&amp;#8640;" data-id="43198" title="Rightwards Harpoon With Barb Upwards">⇀</span>
    <span class="char" data-decimal="8641" data-entity="&amp;#8641;" data-id="43199" title="Rightwards Harpoon With Barb Downwards">⇁</span>
    <span class="char" data-decimal="8642" data-entity="&amp;#8642;" data-id="43200" title="Downwards Harpoon With Barb Rightwards">⇂</span>
    <span class="char" data-decimal="8643" data-entity="&amp;#8643;" data-id="43201" title="Downwards Harpoon With Barb Leftwards">⇃</span>
    <span class="char" data-decimal="8644" data-entity="&amp;#8644;" data-id="43202" title="Rightwards Arrow Over Leftwards Arrow">⇄</span>
    <span class="char" data-decimal="8645" data-entity="&amp;#8645;" data-id="43203" title="Upwards Arrow Leftwards Of Downwards Arrow">⇅</span>
    <span class="char" data-decimal="8646" data-entity="&amp;#8646;" data-id="43204" title="Leftwards Arrow Over Rightwards Arrow">⇆</span>
    <span class="char" data-decimal="8647" data-entity="&amp;#8647;" data-id="43205" title="Leftwards Paired Arrows">⇇</span>
    <span class="char" data-decimal="8648" data-entity="&amp;#8648;" data-id="43206" title="Upwards Paired Arrows">⇈</span>
    <span class="char" data-decimal="8649" data-entity="&amp;#8649;" data-id="43207" title="Rightwards Paired Arrows">⇉</span>
    <span class="char" data-decimal="8650" data-entity="&amp;#8650;" data-id="43208" title="Downwards Paired Arrows">⇊</span>
    <span class="char" data-decimal="8651" data-entity="&amp;#8651;" data-id="43209" title="Leftwards Harpoon Over Rightwards Harpoon">⇋</span>
    <span class="char" data-decimal="8652" data-entity="&amp;#8652;" data-id="43210" title="Rightwards Harpoon Over Leftwards Harpoon">⇌</span>
    <span class="char" data-decimal="8653" data-entity="&amp;#8653;" data-id="43211" title="Leftwards Double Arrow With Stroke">⇍</span>
    <span class="char" data-decimal="8654" data-entity="&amp;#8654;" data-id="43212" title="Left Right Double Arrow With Stroke">⇎</span>
    <span class="char" data-decimal="8655" data-entity="&amp;#8655;" data-id="43213" title="Rightwards Double Arrow With Stroke">⇏</span>
    <span class="char" data-decimal="8656" data-entity="&amp;lArr;" data-id="43214" title="Leftwards Double Arrow">⇐</span>
    <span class="char" data-decimal="8657" data-entity="&amp;uArr;" data-id="43215" title="Upwards Double Arrow">⇑</span>
    <span class="char" data-decimal="8658" data-entity="&amp;rArr;" data-id="43216" title="Rightwards Double Arrow">⇒</span>
    <span class="char" data-decimal="8659" data-entity="&amp;dArr;" data-id="43217" title="Downwards Double Arrow">⇓</span>
    <span class="char" data-decimal="8660" data-entity="&amp;hArr;" data-id="43218" title="Left Right Double Arrow">⇔</span>
    <span class="char" data-decimal="8661" data-entity="&amp;#8661;" data-id="43219" title="Up Down Double Arrow">⇕</span>
    <span class="char" data-decimal="8662" data-entity="&amp;#8662;" data-id="43220" title="North West Double Arrow">⇖</span>
    <span class="char" data-decimal="8663" data-entity="&amp;#8663;" data-id="43221" title="North East Double Arrow">⇗</span>
    <span class="char" data-decimal="8664" data-entity="&amp;#8664;" data-id="43222" title="South East Double Arrow">⇘</span>
    <span class="char" data-decimal="8665" data-entity="&amp;#8665;" data-id="43223" title="South West Double Arrow">⇙</span>
    <span class="char" data-decimal="8666" data-entity="&amp;#8666;" data-id="43224" title="Leftwards Triple Arrow">⇚</span>
    <span class="char" data-decimal="8667" data-entity="&amp;#8667;" data-id="43225" title="Rightwards Triple Arrow">⇛</span>
    <span class="char" data-decimal="8668" data-entity="&amp;#8668;" data-id="43226" title="Leftwards Squiggle Arrow">⇜</span>
    <span class="char" data-decimal="8669" data-entity="&amp;#8669;" data-id="43227" title="Rightwards Squiggle Arrow">⇝</span>
    <span class="char" data-decimal="8670" data-entity="&amp;#8670;" data-id="43228" title="Upwards Arrow With Double Stroke">⇞</span>
    <span class="char" data-decimal="8671" data-entity="&amp;#8671;" data-id="43229" title="Downwards Arrow With Double Stroke">⇟</span>
    <span class="char" data-decimal="8672" data-entity="&amp;#8672;" data-id="43230" title="Leftwards Dashed Arrow">⇠</span>
    <span class="char" data-decimal="8673" data-entity="&amp;#8673;" data-id="43231" title="Upwards Dashed Arrow">⇡</span>
    <span class="char" data-decimal="8674" data-entity="&amp;#8674;" data-id="43232" title="Rightwards Dashed Arrow">⇢</span>
    <span class="char" data-decimal="8675" data-entity="&amp;#8675;" data-id="43233" title="Downwards Dashed Arrow">⇣</span>
    <span class="char" data-decimal="8676" data-entity="&amp;#8676;" data-id="43234" title="Leftwards Arrow To Bar">⇤</span>
    <span class="char" data-decimal="8677" data-entity="&amp;#8677;" data-id="43235" title="Rightwards Arrow To Bar">⇥</span>
    <span class="char" data-decimal="8678" data-entity="&amp;#8678;" data-id="43236" title="Leftwards White Arrow">⇦</span>
    <span class="char" data-decimal="8680" data-entity="&amp;#8680;" data-id="43238" title="Rightwards White Arrow">⇨</span>
    <span class="char" data-decimal="8681" data-entity="&amp;#8681;" data-id="43239" title="Downwards White Arrow">⇩</span>
    <span class="char" data-decimal="8682" data-entity="&amp;#8682;" data-id="43240" title="Upwards White Arrow From Bar">⇪</span>
    <span class="char" data-decimal="8679" data-entity="&amp;#8679;" data-id="43237" title="Upwards White Arrow">⇧</span>
    <span class="char" data-decimal="8683" data-entity="&amp;#8683;" data-id="43241" title="Upwards White Arrow On Pedestal">⇫</span>
    <span class="char" data-decimal="8684" data-entity="&amp;#8684;" data-id="43242" title="Upwards White Arrow On Pedestal With Horizontal Bar">⇬</span>
    <span class="char" data-decimal="8685" data-entity="&amp;#8685;" data-id="43243" title="Upwards White Arrow On Pedestal With Vertical Bar">⇭</span>
    <span class="char" data-decimal="8686" data-entity="&amp;#8686;" data-id="43244" title="Upwards White Double Arrow">⇮</span>
    <span class="char" data-decimal="8687" data-entity="&amp;#8687;" data-id="43245" title="Upwards White Double Arrow On Pedestal">⇯</span>
    <span class="char" data-decimal="8688" data-entity="&amp;#8688;" data-id="43246" title="Rightwards White Arrow From Wall">⇰</span>
    <span class="char" data-decimal="8689" data-entity="&amp;#8689;" data-id="43247" title="North West Arrow To Corner">⇱</span>
    <span class="char" data-decimal="8690" data-entity="&amp;#8690;" data-id="43248" title="South East Arrow To Corner">⇲</span>
    <span class="char" data-decimal="8691" data-entity="&amp;#8691;" data-id="43249" title="Up Down White Arrow">⇳</span>
    <span class="char" data-decimal="8692" data-entity="&amp;#8692;" data-id="43250" title="Right Arrow With Small Circle">⇴</span>
    <span class="char" data-decimal="8693" data-entity="&amp;#8693;" data-id="43251" title="Downwards Arrow Leftwards Of Upwards Arrow">⇵</span>
    <span class="char" data-decimal="8694" data-entity="&amp;#8694;" data-id="43252" title="Three Rightwards Arrows">⇶</span>
    <span class="char" data-decimal="8695" data-entity="&amp;#8695;" data-id="43253" title="Leftwards Arrow With Vertical Stroke">⇷</span>
    <span class="char" data-decimal="8696" data-entity="&amp;#8696;" data-id="43254" title="Rightwards Arrow With Vertical Stroke">⇸</span>
    <span class="char" data-decimal="8697" data-entity="&amp;#8697;" data-id="43255" title="Left Right Arrow With Vertical Stroke">⇹</span>
    <span class="char" data-decimal="8698" data-entity="&amp;#8698;" data-id="43256" title="Leftwards Arrow With Double Vertical Stroke">⇺</span>
    <span class="char" data-decimal="8699" data-entity="&amp;#8699;" data-id="43257" title="Rightwards Arrow With Double Vertical Stroke">⇻</span>
    <span class="char" data-decimal="8700" data-entity="&amp;#8700;" data-id="43258" title="Left Right Arrow With Double Vertical Stroke">⇼</span>
    <span class="char" data-decimal="8701" data-entity="&amp;#8701;" data-id="43259" title="Leftwards Open Headed Arrow">⇽</span>
    <span class="char" data-decimal="8702" data-entity="&amp;#8702;" data-id="43260" title="Rightwards Open Headed Arrow">⇾</span>
    <span class="char" data-decimal="8703" data-entity="&amp;#8703;" data-id="43261" title="Left Right Open Headed Arrow">⇿</span>
    <span class="char" data-decimal="10224" data-entity="&amp;#10224;" data-id="43262" title="Upwards Quadruple Arrow">⟰</span>
    <span class="char" data-decimal="10225" data-entity="&amp;#10225;" data-id="43263" title="Downwards Quadruple Arrow">⟱</span>
    <span class="char" data-decimal="10226" data-entity="&amp;#10226;" data-id="43264" title="Anticlockwise Gapped Circle Arrow">⟲</span>
    <span class="char" data-decimal="10227" data-entity="&amp;#10227;" data-id="43265" title="Clockwise Gapped Circle Arrow">⟳</span>
    <span class="char" data-decimal="10228" data-entity="&amp;#10228;" data-id="43266" title="Right Arrow With Circled Plus">⟴</span>
    <span class="char" data-decimal="10229" data-entity="&amp;#10229;" data-id="43267" title="Long Leftwards Arrow">⟵</span>
    <span class="char" data-decimal="10230" data-entity="&amp;#10230;" data-id="43268" title="Long Rightwards Arrow">⟶</span>
    <span class="char" data-decimal="10231" data-entity="&amp;#10231;" data-id="43269" title="Long Left Right Arrow">⟷</span>
    <span class="char" data-decimal="10232" data-entity="&amp;#10232;" data-id="43270" title="Long Leftwards Double Arrow">⟸</span>
    <span class="char" data-decimal="10233" data-entity="&amp;#10233;" data-id="43271" title="Long Rightwards Double Arrow">⟹</span>
    <span class="char" data-decimal="10234" data-entity="&amp;#10234;" data-id="43272" title="Long Left Right Double Arrow">⟺</span>
    <span class="char" data-decimal="10235" data-entity="&amp;#10235;" data-id="43273" title="Long Leftwards Arrow From Bar">⟻</span>
    <span class="char" data-decimal="10236" data-entity="&amp;#10236;" data-id="43274" title="Long Rightwards Arrow From Bar">⟼</span>
    <span class="char" data-decimal="10237" data-entity="&amp;#10237;" data-id="43275" title="Long Leftwards Double Arrow From Bar">⟽</span>
    <span class="char" data-decimal="10238" data-entity="&amp;#10238;" data-id="43276" title="Long Rightwards Double Arrow From Bar">⟾</span>
    <span class="char" data-decimal="10239" data-entity="&amp;#10239;" data-id="43277" title="Long Rightwards Squiggle Arrow">⟿</span>
    <span class="char" data-decimal="10496" data-entity="&amp;#10496;" data-id="43278" title="Rightwards Two Headed Arrow With Vertical Stroke">⤀</span>
    <span class="char" data-decimal="10497" data-entity="&amp;#10497;" data-id="43279" title="Rightwards Two Headed Arrow With Double Vertical Stroke">⤁</span>
    <span class="char" data-decimal="10498" data-entity="&amp;#10498;" data-id="43280" title="Leftwards Double Arrow With Vertical Stroke">⤂</span>
    <span class="char" data-decimal="10499" data-entity="&amp;#10499;" data-id="43281" title="Rightwards Double Arrow With Vertical Stroke">⤃</span>
    <span class="char" data-decimal="10500" data-entity="&amp;#10500;" data-id="43282" title="Left Right Double Arrow With Vertical Stroke">⤄</span>
    <span class="char" data-decimal="10501" data-entity="&amp;#10501;" data-id="43283" title="Rightwards Two Headed Arrow From Bar">⤅</span>
    <span class="char" data-decimal="10502" data-entity="&amp;#10502;" data-id="43284" title="Leftwards Double Arrow From Bar">⤆</span>
    <span class="char" data-decimal="10503" data-entity="&amp;#10503;" data-id="43285" title="Rightwards Double Arrow From Bar">⤇</span>
    <span class="char" data-decimal="10504" data-entity="&amp;#10504;" data-id="43286" title="Downwards Arrow With Horizontal Stroke">⤈</span>
    <span class="char" data-decimal="10505" data-entity="&amp;#10505;" data-id="43287" title="Upwards Arrow With Horizontal Stroke">⤉</span>
    <span class="char" data-decimal="10506" data-entity="&amp;#10506;" data-id="43288" title="Upwards Triple Arrow">⤊</span>
    <span class="char" data-decimal="10507" data-entity="&amp;#10507;" data-id="43289" title="Downwards Triple Arrow">⤋</span>
    <span class="char" data-decimal="10508" data-entity="&amp;#10508;" data-id="43290" title="Leftwards Double Dash Arrow">⤌</span>
    <span class="char" data-decimal="10509" data-entity="&amp;#10509;" data-id="43291" title="Rightwards Double Dash Arrow">⤍</span>
    <span class="char" data-decimal="10510" data-entity="&amp;#10510;" data-id="43292" title="Leftwards Triple Dash Arrow">⤎</span>
    <span class="char" data-decimal="10511" data-entity="&amp;#10511;" data-id="43293" title="Rightwards Triple Dash Arrow">⤏</span>
    <span class="char" data-decimal="10512" data-entity="&amp;#10512;" data-id="43294" title="Rightwards Two Headed Triple Dash Arrow">⤐</span>
    <span class="char" data-decimal="10513" data-entity="&amp;#10513;" data-id="43295" title="Rightwards Arrow With Dotted Stem">⤑</span>
    <span class="char" data-decimal="10514" data-entity="&amp;#10514;" data-id="43296" title="Upwards Arrow To Bar">⤒</span>
    <span class="char" data-decimal="10515" data-entity="&amp;#10515;" data-id="43297" title="Downwards Arrow To Bar">⤓</span>
    <span class="char" data-decimal="10516" data-entity="&amp;#10516;" data-id="43298" title="Rightwards Arrow With Tail With Vertical Stroke">⤔</span>
    <span class="char" data-decimal="10517" data-entity="&amp;#10517;" data-id="43299" title="Rightwards Arrow With Tail With Double Vertical Stroke">⤕</span>
    <span class="char" data-decimal="10518" data-entity="&amp;#10518;" data-id="43300" title="Rightwards Two Headed Arrow With Tail">⤖</span>
    <span class="char" data-decimal="10519" data-entity="&amp;#10519;" data-id="43301" title="Rightwards Two Headed Arrow With Tail With Vertical Stroke">⤗</span>
    <span class="char" data-decimal="10520" data-entity="&amp;#10520;" data-id="43302" title="Rightwards Two Headed Arrow With Tail With Double Vertical Stroke">⤘</span>
    <span class="char" data-decimal="10521" data-entity="&amp;#10521;" data-id="43303" title="Leftwards Arrow Tail">⤙</span>
    <span class="char" data-decimal="10522" data-entity="&amp;#10522;" data-id="43304" title="Rightwards Arrow Tail">⤚</span>
    <span class="char" data-decimal="10523" data-entity="&amp;#10523;" data-id="43305" title="Leftwards Double Arrow Tail">⤛</span>
    <span class="char" data-decimal="10524" data-entity="&amp;#10524;" data-id="43306" title="Rightwards Double Arrow Tail">⤜</span>
    <span class="char" data-decimal="10525" data-entity="&amp;#10525;" data-id="43307" title="Leftwards Arrow To Black Diamond">⤝</span>
    <span class="char" data-decimal="10526" data-entity="&amp;#10526;" data-id="43308" title="Rightwards Arrow To Black Diamond">⤞</span>
    <span class="char" data-decimal="10527" data-entity="&amp;#10527;" data-id="43309" title="Leftwards Arrow From Bar To Black Diamond">⤟</span>
    <span class="char" data-decimal="10528" data-entity="&amp;#10528;" data-id="43310" title="Rightwards Arrow From Bar To Black Diamond">⤠</span>
    <span class="char" data-decimal="10529" data-entity="&amp;#10529;" data-id="43311" title="North West And South East Arrow">⤡</span>
    <span class="char" data-decimal="10530" data-entity="&amp;#10530;" data-id="43312" title="North East And South West Arrow">⤢</span>
    <span class="char" data-decimal="10531" data-entity="&amp;#10531;" data-id="43313" title="North West Arrow With Hook">⤣</span>
    <span class="char" data-decimal="10532" data-entity="&amp;#10532;" data-id="43314" title="North East Arrow With Hook">⤤</span>
    <span class="char" data-decimal="10533" data-entity="&amp;#10533;" data-id="43315" title="South East Arrow With Hook">⤥</span>
    <span class="char" data-decimal="10534" data-entity="&amp;#10534;" data-id="43316" title="South West Arrow With Hook">⤦</span>
    <span class="char" data-decimal="10535" data-entity="&amp;#10535;" data-id="43317" title="North West Arrow And North East Arrow">⤧</span>
    <span class="char" data-decimal="10536" data-entity="&amp;#10536;" data-id="43318" title="North East Arrow And South East Arrow">⤨</span>
    <span class="char" data-decimal="10537" data-entity="&amp;#10537;" data-id="43319" title="South East Arrow And South West Arrow">⤩</span>
    <span class="char" data-decimal="10538" data-entity="&amp;#10538;" data-id="43320" title="South West Arrow And North West Arrow">⤪</span>
    <span class="char" data-decimal="10539" data-entity="&amp;#10539;" data-id="43321" title="Rising Diagonal Crossing Falling Diagonal">⤫</span>
    <span class="char" data-decimal="10540" data-entity="&amp;#10540;" data-id="43322" title="Falling Diagonal Crossing Rising Diagonal">⤬</span>
    <span class="char" data-decimal="10541" data-entity="&amp;#10541;" data-id="43323" title="South East Arrow Crossing North East Arrow">⤭</span>
    <span class="char" data-decimal="10542" data-entity="&amp;#10542;" data-id="43324" title="North East Arrow Crossing South East Arrow">⤮</span>
    <span class="char" data-decimal="10543" data-entity="&amp;#10543;" data-id="43325" title="Falling Diagonal Crossing North East Arrow">⤯</span>
    <span class="char" data-decimal="10544" data-entity="&amp;#10544;" data-id="43326" title="Rising Diagonal Crossing South East Arrow">⤰</span>
    <span class="char" data-decimal="10545" data-entity="&amp;#10545;" data-id="43327" title="North East Arrow Crossing North West Arrow">⤱</span>
    <span class="char" data-decimal="10546" data-entity="&amp;#10546;" data-id="43328" title="North West Arrow Crossing North East Arrow">⤲</span>
    <span class="char" data-decimal="10547" data-entity="&amp;#10547;" data-id="43329" title="Wave Arrow Pointing Directly Right">⤳</span>
    <span class="char" data-decimal="10548" data-entity="&amp;#10548;" data-id="43330" title="Arrow Pointing Rightwards Then Curving Upwards">⤴</span>
    <span class="char" data-decimal="10549" data-entity="&amp;#10549;" data-id="43331" title="Arrow Pointing Rightwards Then Curving Downwards">⤵</span>
    <span class="char" data-decimal="10550" data-entity="&amp;#10550;" data-id="43332" title="Arrow Pointing Downwards Then Curving Leftwards">⤶</span>
    <span class="char" data-decimal="10551" data-entity="&amp;#10551;" data-id="43333" title="Arrow Pointing Downwards Then Curving Rightwards">⤷</span>
    <span class="char" data-decimal="10552" data-entity="&amp;#10552;" data-id="43334" title="Right Side Arc Clockwise Arrow">⤸</span>
    <span class="char" data-decimal="10553" data-entity="&amp;#10553;" data-id="43335" title="Left Side Arc Anticlockwise Arrow">⤹</span>
    <span class="char" data-decimal="10554" data-entity="&amp;#10554;" data-id="43336" title="Top Arc Anticlockwise Arrow">⤺</span>
    <span class="char" data-decimal="10555" data-entity="&amp;#10555;" data-id="43337" title="Bottom Arc Anticlockwise Arrow">⤻</span>
    <span class="char" data-decimal="10556" data-entity="&amp;#10556;" data-id="43338" title="Top Arc Clockwise Arrow With Minus">⤼</span>
    <span class="char" data-decimal="10557" data-entity="&amp;#10557;" data-id="43339" title="Top Arc Anticlockwise Arrow With Plus">⤽</span>
    <span class="char" data-decimal="10558" data-entity="&amp;#10558;" data-id="43340" title="Lower Right Semicircular Clockwise Arrow">⤾</span>
    <span class="char" data-decimal="10559" data-entity="&amp;#10559;" data-id="43341" title="Lower Left Semicircular Anticlockwise Arrow">⤿</span>
    <span class="char" data-decimal="10560" data-entity="&amp;#10560;" data-id="43342" title="Anticlockwise Closed Circle Arrow">⥀</span>
    <span class="char" data-decimal="10561" data-entity="&amp;#10561;" data-id="43343" title="Clockwise Closed Circle Arrow">⥁</span>
    <span class="char" data-decimal="10562" data-entity="&amp;#10562;" data-id="43344" title="Rightwards Arrow Above Short Leftwards Arrow">⥂</span>
    <span class="char" data-decimal="10563" data-entity="&amp;#10563;" data-id="43345" title="Leftwards Arrow Above Short Rightwards Arrow">⥃</span>
    <span class="char" data-decimal="10564" data-entity="&amp;#10564;" data-id="43346" title="Short Rightwards Arrow Above Leftwards Arrow">⥄</span>
    <span class="char" data-decimal="10565" data-entity="&amp;#10565;" data-id="43347" title="Rightwards Arrow With Plus Below">⥅</span>
    <span class="char" data-decimal="10566" data-entity="&amp;#10566;" data-id="43348" title="Leftwards Arrow With Plus Below">⥆</span>
    <span class="char" data-decimal="10567" data-entity="&amp;#10567;" data-id="43349" title="Rightwards Arrow Through X">⥇</span>
    <span class="char" data-decimal="10568" data-entity="&amp;#10568;" data-id="43350" title="Left Right Arrow Through Small Circle">⥈</span>
    <span class="char" data-decimal="10569" data-entity="&amp;#10569;" data-id="43351" title="Upwards Two Headed Arrow From Small Circle">⥉</span>
    <span class="char" data-decimal="10570" data-entity="&amp;#10570;" data-id="43352" title="Left Barb Up Right Barb Down Harpoon">⥊</span>
    <span class="char" data-decimal="10571" data-entity="&amp;#10571;" data-id="43353" title="Left Barb Down Right Barb Up Harpoon">⥋</span>
    <span class="char" data-decimal="10572" data-entity="&amp;#10572;" data-id="43354" title="Up Barb Right Down Barb Left Harpoon">⥌</span>
    <span class="char" data-decimal="10573" data-entity="&amp;#10573;" data-id="43355" title="Up Barb Left Down Barb Right Harpoon">⥍</span>
    <span class="char" data-decimal="10574" data-entity="&amp;#10574;" data-id="43356" title="Left Barb Up Right Barb Up Harpoon">⥎</span>
    <span class="char" data-decimal="10575" data-entity="&amp;#10575;" data-id="43357" title="Up Barb Right Down Barb Right Harpoon">⥏</span>
    <span class="char" data-decimal="10576" data-entity="&amp;#10576;" data-id="43358" title="Left Barb Down Right Barb Down Harpoon">⥐</span>
    <span class="char" data-decimal="10577" data-entity="&amp;#10577;" data-id="43359" title="Up Barb Left Down Barb Left Harpoon">⥑</span>
    <span class="char" data-decimal="10578" data-entity="&amp;#10578;" data-id="43360" title="Leftwards Harpoon With Barb Up To Bar">⥒</span>
    <span class="char" data-decimal="10579" data-entity="&amp;#10579;" data-id="43361" title="Rightwards Harpoon With Barb Up To Bar">⥓</span>
    <span class="char" data-decimal="10580" data-entity="&amp;#10580;" data-id="43362" title="Upwards Harpoon With Barb Right To Bar">⥔</span>
    <span class="char" data-decimal="10581" data-entity="&amp;#10581;" data-id="43363" title="Downwards Harpoon With Barb Right To Bar">⥕</span>
    <span class="char" data-decimal="10582" data-entity="&amp;#10582;" data-id="43364" title="Leftwards Harpoon With Barb Down To Bar">⥖</span>
    <span class="char" data-decimal="10583" data-entity="&amp;#10583;" data-id="43365" title="Rightwards Harpoon With Barb Down To Bar">⥗</span>
    <span class="char" data-decimal="10584" data-entity="&amp;#10584;" data-id="43366" title="Upwards Harpoon With Barb Left To Bar">⥘</span>
    <span class="char" data-decimal="10585" data-entity="&amp;#10585;" data-id="43367" title="Downwards Harpoon With Barb Left To Bar">⥙</span>
    <span class="char" data-decimal="10586" data-entity="&amp;#10586;" data-id="43368" title="Leftwards Harpoon With Barb Up From Bar">⥚</span>
    <span class="char" data-decimal="10587" data-entity="&amp;#10587;" data-id="43369" title="Rightwards Harpoon With Barb Up From Bar">⥛</span>
    <span class="char" data-decimal="10588" data-entity="&amp;#10588;" data-id="43370" title="Upwards Harpoon With Barb Right From Bar">⥜</span>
    <span class="char" data-decimal="10589" data-entity="&amp;#10589;" data-id="43371" title="Downwards Harpoon With Barb Right From Bar">⥝</span>
    <span class="char" data-decimal="10590" data-entity="&amp;#10590;" data-id="43372" title="Leftwards Harpoon With Barb Down From Bar">⥞</span>
    <span class="char" data-decimal="10591" data-entity="&amp;#10591;" data-id="43373" title="Rightwards Harpoon With Barb Down From Bar">⥟</span>
    <span class="char" data-decimal="10592" data-entity="&amp;#10592;" data-id="43374" title="Upwards Harpoon With Barb Left From Bar">⥠</span>
    <span class="char" data-decimal="10593" data-entity="&amp;#10593;" data-id="43375" title="Downwards Harpoon With Barb Left From Bar">⥡</span>
    <span class="char" data-decimal="10594" data-entity="&amp;#10594;" data-id="43376" title="Leftwards Harpoon With Barb Up Above Leftwards Harpoon With Barb Down">⥢</span>
    <span class="char" data-decimal="10595" data-entity="&amp;#10595;" data-id="43377" title="Upwards Harpoon With Barb Left Beside Upwards Harpoon With Barb Right">⥣</span>
    <span class="char" data-decimal="10596" data-entity="&amp;#10596;" data-id="43378" title="Rightwards Harpoon With Barb Up Above Rightwards Harpoon With Barb Down">⥤</span>
    <span class="char" data-decimal="10597" data-entity="&amp;#10597;" data-id="43379" title="Downwards Harpoon With Barb Left Beside Downwards Harpoon With Barb Right">⥥</span>
    <span class="char" data-decimal="10598" data-entity="&amp;#10598;" data-id="43380" title="Leftwards Harpoon With Barb Up Above Rightwards Harpoon With Barb Up">⥦</span>
    <span class="char" data-decimal="10599" data-entity="&amp;#10599;" data-id="43381" title="Leftwards Harpoon With Barb Down Above Rightwards Harpoon With Barb Down">⥧</span>
    <span class="char" data-decimal="10600" data-entity="&amp;#10600;" data-id="43382" title="Rightwards Harpoon With Barb Up Above Leftwards Harpoon With Barb Up">⥨</span>
    <span class="char" data-decimal="10601" data-entity="&amp;#10601;" data-id="43383" title="Rightwards Harpoon With Barb Down Above Leftwards Harpoon With Barb Down">⥩</span>
    <span class="char" data-decimal="10602" data-entity="&amp;#10602;" data-id="43384" title="Leftwards Harpoon With Barb Up Above Long Dash">⥪</span>
    <span class="char" data-decimal="10603" data-entity="&amp;#10603;" data-id="43385" title="Leftwards Harpoon With Barb Down Below Long Dash">⥫</span>
    <span class="char" data-decimal="10604" data-entity="&amp;#10604;" data-id="43386" title="Rightwards Harpoon With Barb Up Above Long Dash">⥬</span>
    <span class="char" data-decimal="10605" data-entity="&amp;#10605;" data-id="43387" title="Rightwards Harpoon With Barb Down Below Long Dash">⥭</span>
    <span class="char" data-decimal="10606" data-entity="&amp;#10606;" data-id="43388" title="Upwards Harpoon With Barb Left Beside Downwards Harpoon With Barb Right">⥮</span>
    <span class="char" data-decimal="10607" data-entity="&amp;#10607;" data-id="43389" title="Downwards Harpoon With Barb Left Beside Upwards Harpoon With Barb Right">⥯</span>
    <span class="char" data-decimal="10608" data-entity="&amp;#10608;" data-id="43390" title="Right Double Arrow With Rounded Head">⥰</span>
    <span class="char" data-decimal="10609" data-entity="&amp;#10609;" data-id="43391" title="Equals Sign Above Rightwards Arrow">⥱</span>
    <span class="char" data-decimal="10610" data-entity="&amp;#10610;" data-id="43392" title="Tilde Operator Above Rightwards Arrow">⥲</span>
    <span class="char" data-decimal="10611" data-entity="&amp;#10611;" data-id="43393" title="Leftwards Arrow Above Tilde Operator">⥳</span>
    <span class="char" data-decimal="10612" data-entity="&amp;#10612;" data-id="43394" title="Rightwards Arrow Above Tilde Operator">⥴</span>
    <span class="char" data-decimal="10613" data-entity="&amp;#10613;" data-id="43395" title="Rightwards Arrow Above Almost Equal To">⥵</span>
    <span class="char" data-decimal="10614" data-entity="&amp;#10614;" data-id="43396" title="Less Than Above Leftwards Arrow">⥶</span>
    <span class="char" data-decimal="10615" data-entity="&amp;#10615;" data-id="43397" title="Leftwards Arrow Through Less Than">⥷</span>
    <span class="char" data-decimal="10616" data-entity="&amp;#10616;" data-id="43398" title="Greater Than Above Rightwards Arrow">⥸</span>
    <span class="char" data-decimal="10617" data-entity="&amp;#10617;" data-id="43399" title="Subset Above Rightwards Arrow">⥹</span>
    <span class="char" data-decimal="10618" data-entity="&amp;#10618;" data-id="43400" title="Leftwards Arrow Through Subset">⥺</span>
    <span class="char" data-decimal="10619" data-entity="&amp;#10619;" data-id="43401" title="Superset Above Leftwards Arrow">⥻</span>
    <span class="char" data-decimal="10620" data-entity="&amp;#10620;" data-id="43402" title="Left Fish Tail">⥼</span>
    <span class="char" data-decimal="10621" data-entity="&amp;#10621;" data-id="43403" title="Right Fish Tail">⥽</span>
    <span class="char" data-decimal="10622" data-entity="&amp;#10622;" data-id="43404" title="Up Fish Tail">⥾</span>
    <span class="char" data-decimal="10623" data-entity="&amp;#10623;" data-id="43405" title="Down Fish Tail">⥿</span>
    <span class="char" data-decimal="10132" data-entity="&amp;#10132;" data-id="43724" title="Heavy Wide Headed Rightwards Arrow">➔</span>
    <span class="char" data-decimal="10136" data-entity="&amp;#10136;" data-id="43725" title="Heavy South East Arrow">➘</span>
    <span class="char" data-decimal="10137" data-entity="&amp;#10137;" data-id="43726" title="Heavy Rightwards Arrow">➙</span>
    <span class="char" data-decimal="10138" data-entity="&amp;#10138;" data-id="43727" title="Heavy North East Arrow">➚</span>
    <span class="char" data-decimal="10139" data-entity="&amp;#10139;" data-id="43728" title="Drafting Point Rightwards Arrow">➛</span>
    <span class="char" data-decimal="10140" data-entity="&amp;#10140;" data-id="43729" title="Heavy Round Tipped Rightwards Arrow">➜</span>
    <span class="char" data-decimal="10141" data-entity="&amp;#10141;" data-id="43730" title="Triangle Headed Rightwards Arrow">➝</span>
    <span class="char" data-decimal="10142" data-entity="&amp;#10142;" data-id="43731" title="Heavy Triangle Headed Rightwards Arrow">➞</span>
    <span class="char" data-decimal="10141" data-entity="&amp;#10141;" data-id="43730" title="Triangle Headed Rightwards Arrow">➝</span>
    <span class="char" data-decimal="10142" data-entity="&amp;#10142;" data-id="43731" title="Heavy Triangle Headed Rightwards Arrow">➞</span>
    <span class="char" data-decimal="10143" data-entity="&amp;#10143;" data-id="43732" title="Dashed Triangle Headed Rightwards Arrow">➟</span>
    <span class="char" data-decimal="10144" data-entity="&amp;#10144;" data-id="43733" title="Heavy Dashed Triangle Headed Rightwards Arrow">➠</span>
    <span class="char" data-decimal="10145" data-entity="&amp;#10145;" data-id="43734" title="Black Rightwards Arrow">➡</span>
    <span class="char" data-decimal="10146" data-entity="&amp;#10146;" data-id="43735" title="Three D Top Lighted Rightwards Arrowhead">➢</span>
    <span class="char" data-decimal="10147" data-entity="&amp;#10147;" data-id="43736" title="Three D Bottom Lighted Rightwards Arrowhead">➣</span>
    <span class="char" data-decimal="10148" data-entity="&amp;#10148;" data-id="43737" title="Black Rightwards Arrowhead">➤</span>
    <span class="char" data-decimal="10149" data-entity="&amp;#10149;" data-id="43738" title="Heavy Black Curved Downwards And Rightwards Arrow">➥</span>
    <span class="char" data-decimal="10150" data-entity="&amp;#10150;" data-id="43739" title="Heavy Black Curved Upwards And Rightwards Arrow">➦</span>
    <span class="char" data-decimal="10151" data-entity="&amp;#10151;" data-id="43740" title="Squat Black Rightwards Arrow">➧</span>
    <span class="char" data-decimal="10152" data-entity="&amp;#10152;" data-id="43741" title="Heavy Concave Pointed Black Rightwards Arrow">➨</span>
    <span class="char" data-decimal="10153" data-entity="&amp;#10153;" data-id="43742" title="Right Shaded White Rightwards Arrow">➩</span>
    <span class="char" data-decimal="10153" data-entity="&amp;#10153;" data-id="43742" title="Right Shaded White Rightwards Arrow">➩</span>
    <span class="char" data-decimal="10154" data-entity="&amp;#10154;" data-id="43743" title="Left Shaded White Rightwards Arrow">➪</span>
    <span class="char" data-decimal="10155" data-entity="&amp;#10155;" data-id="43744" title="Back Tilted Shadowed White Rightwards Arrow">➫</span>
    <span class="char" data-decimal="10156" data-entity="&amp;#10156;" data-id="43745" title="Front Tilted Shadowed White Rightwards Arrow">➬</span>
    <span class="char" data-decimal="10157" data-entity="&amp;#10157;" data-id="43746" title="Heavy Lower Right Shadowed White Rightwards Arrow">➭</span>
    <span class="char" data-decimal="10158" data-entity="&amp;#10158;" data-id="43747" title="Heavy Upper Right Shadowed White Rightwards Arrow">➮</span>
    <span class="char" data-decimal="10159" data-entity="&amp;#10159;" data-id="43748" title="Notched Lower Right Shadowed White Rightwards Arrow">➯</span>
    <span class="char" data-decimal="10161" data-entity="&amp;#10161;" data-id="43749" title="Notched Upper Right Shadowed White Rightwards Arrow">➱</span>
    <span class="char" data-decimal="10162" data-entity="&amp;#10162;" data-id="43750" title="Circled Heavy White Rightwards Arrow">➲</span>
    <span class="char" data-decimal="10163" data-entity="&amp;#10163;" data-id="43751" title="White Feathered Rightwards Arrow">➳</span>
    <span class="char" data-decimal="10164" data-entity="&amp;#10164;" data-id="43752" title="Black Feathered South East Arrow">➴</span>
    <span class="char" data-decimal="10165" data-entity="&amp;#10165;" data-id="43753" title="Black Feathered Rightwards Arrow">➵</span>
    <span class="char" data-decimal="10166" data-entity="&amp;#10166;" data-id="43754" title="Black Feathered North East Arrow">➶</span>
    <span class="char" data-decimal="10167" data-entity="&amp;#10167;" data-id="43755" title="Heavy Black Feathered South East Arrow">➷</span>
    <span class="char" data-decimal="10168" data-entity="&amp;#10168;" data-id="43756" title="Heavy Black Feathered Rightwards Arrow">➸</span>
    <span class="char" data-decimal="10169" data-entity="&amp;#10169;" data-id="43757" title="Heavy Black Feathered North East Arrow">➹</span>
    <span class="char" data-decimal="10170" data-entity="&amp;#10170;" data-id="43758" title="Teardrop Barbed Rightwards Arrow">➺</span>
    <span class="char" data-decimal="10171" data-entity="&amp;#10171;" data-id="43759" title="Heavy Teardrop Shanked Rightwards Arrow">➻</span>
    <span class="char" data-decimal="10172" data-entity="&amp;#10172;" data-id="43760" title="Wedge Tailed Rightwards Arrow">➼</span>
    <span class="char" data-decimal="10173" data-entity="&amp;#10173;" data-id="43761" title="Heavy Wedge Tailed Rightwards Arrow">➽</span>
    <span class="char" data-decimal="10174" data-entity="&amp;#10174;" data-id="43762" title="Open Outlined Rightwards Arrow">➾</span>
    <span class="char" data-decimal="11008" data-entity="&amp;#11008;" data-id="45911" title="North East White Arrow">⬀</span>
    <span class="char" data-decimal="11009" data-entity="&amp;#11009;" data-id="45912" title="North West White Arrow">⬁</span>
    <span class="char" data-decimal="11010" data-entity="&amp;#11010;" data-id="45913" title="South East White Arrow">⬂</span>
    <span class="char" data-decimal="11011" data-entity="&amp;#11011;" data-id="45914" title="South West White Arrow">⬃</span>
    <span class="char" data-decimal="11012" data-entity="&amp;#11012;" data-id="45915" title="Left Right White Arrow">⬄</span>
    <span class="char" data-decimal="11013" data-entity="&amp;#11013;" data-id="45916" title="Leftwards Black Arrow">⬅</span>
    <span class="char" data-decimal="11014" data-entity="&amp;#11014;" data-id="45917" title="Upwards Black Arrow">⬆</span>
    <span class="char" data-decimal="11015" data-entity="&amp;#11015;" data-id="45918" title="Downwards Black Arrow">⬇</span>
    <span class="char" data-decimal="11016" data-entity="&amp;#11016;" data-id="45919" title="North East Black Arrow">⬈</span>
    <span class="char" data-decimal="11017" data-entity="&amp;#11017;" data-id="45920" title="North West Black Arrow">⬉</span>
    <span class="char" data-decimal="11018" data-entity="&amp;#11018;" data-id="45921" title="South East Black Arrow">⬊</span>
    <span class="char" data-decimal="11019" data-entity="&amp;#11019;" data-id="45922" title="South West Black Arrow">⬋</span>
    <span class="char" data-decimal="11020" data-entity="&amp;#11020;" data-id="45923" title="Left Right Black Arrow">⬌</span>
    <span class="char" data-decimal="11021" data-entity="&amp;#11021;" data-id="45924" title="Up Down Black Arrow">⬍</span>
    <span class="char" data-decimal="11022" data-entity="&amp;#11022;" data-id="45925" title="Rightwards Arrow With Tip Downwards">⬎</span>
    <span class="char" data-decimal="11023" data-entity="&amp;#11023;" data-id="45926" title="Rightwards Arrow With Tip Upwards">⬏</span>
    <span class="char" data-decimal="11024" data-entity="&amp;#11024;" data-id="45927" title="Leftwards Arrow With Tip Downwards">⬐</span>
    <span class="char" data-decimal="11025" data-entity="&amp;#11025;" data-id="45928" title="Leftwards Arrow With Tip Upwards">⬑</span>
    <span class="char" data-decimal="9735" data-entity="&amp;#9735;" data-id="45743" title="Lightning">☇</span>
    <span class="char" data-decimal="9736" data-entity="&amp;#9736;" data-id="45744" title="Thunderstorm">☈</span>
    <span class="char" data-decimal="9166" data-entity="&amp;#9166;" data-id="46148" title="Return Symbol">⏎</span>
    <span class="char" data-decimal="9027" data-entity="&amp;#9027;" data-id="46009" title="Apl Functional Symbol Quad Less Than">⍃</span>
    <span class="char" data-decimal="9028" data-entity="&amp;#9028;" data-id="46010" title="Apl Functional Symbol Quad Greater Than">⍄</span>
    <span class="char" data-decimal="9029" data-entity="&amp;#9029;" data-id="46011" title="Apl Functional Symbol Leftwards Vane">⍅</span>
    <span class="char" data-decimal="9030" data-entity="&amp;#9030;" data-id="46012" title="Apl Functional Symbol Rightwards Vane">⍆</span>
    <span class="char" data-decimal="9031" data-entity="&amp;#9031;" data-id="46013" title="Apl Functional Symbol Quad Leftwards Arrow">⍇</span>
    <span class="char" data-decimal="9032" data-entity="&amp;#9032;" data-id="46014" title="Apl Functional Symbol Quad Rightwards Arrow">⍈</span>
    <span class="char" data-decimal="9040" data-entity="&amp;#9040;" data-id="46022" title="Apl Functional Symbol Quad Upwards Arrow">⍐</span>
    <span class="char" data-decimal="9047" data-entity="&amp;#9047;" data-id="46029" title="Apl Functional Symbol Quad Downwards Arrow">⍗</span>
    <span class="char" data-decimal="9036" data-entity="&amp;#9036;" data-id="46018" title="Apl Functional Symbol Quad Down Caret">⍌</span>
    <span class="char" data-decimal="9043" data-entity="&amp;#9043;" data-id="46025" title="Apl Functional Symbol Quad Up Caret">⍓</span>
    <span class="char" data-decimal="9037" data-entity="&amp;#9037;" data-id="46019" title="Apl Functional Symbol Quad Delta">⍍</span>
    <span class="char" data-decimal="9044" data-entity="&amp;#9044;" data-id="46026" title="Apl Functional Symbol Quad Del">⍔</span>
    <span class="char" data-decimal="9039" data-entity="&amp;#9039;" data-id="46021" title="Apl Functional Symbol Upwards Vane">⍏</span>
    <span class="char" data-decimal="9046" data-entity="&amp;#9046;" data-id="46028" title="Apl Functional Symbol Downwards Vane">⍖</span>
    <span class="char" data-decimal="9854" data-entity="&amp;#9854;" data-id="45861" title="Permanent Paper Sign">♾</span>
    <span class="char" data-decimal="9100" data-entity="&amp;#9100;" data-id="46082" title="Undo Symbol">⎌</span>
    <span class="char" data-decimal="9738" data-entity="&amp;#9738;" data-id="45746" title="Ascending Node">☊</span>
    <span class="char" data-decimal="9739" data-entity="&amp;#9739;" data-id="45747" title="Descending Node">☋</span>
    <span class="char" data-decimal="9740" data-entity="&amp;#9740;" data-id="45748" title="Conjunction">☌</span>
    <span class="char" data-decimal="9741" data-entity="&amp;#9741;" data-id="45749" title="Opposition">☍</span>
    <span class="char" data-decimal="8963" data-entity="&amp;#8963;" data-id="45945" title="Up Arrowhead">⌃</span>
    <span class="char" data-decimal="8964" data-entity="&amp;#8964;" data-id="45946" title="Down Arrowhead">⌄</span>
    <span class="char" data-decimal="8996" data-entity="&amp;#8996;" data-id="45978" title="Up Arrowhead Between Two Horizontal Bars">⌤</span>
    <span class="char" data-decimal="8965" data-entity="&amp;#8965;" data-id="45947" title="Projective">⌅</span>
    <span class="char" data-decimal="8966" data-entity="&amp;#8966;" data-id="45948" title="Perspective">⌆</span>
    <span class="char" data-decimal="8967" data-entity="&amp;#8967;" data-id="45949" title="Wavy Line">⌇</span>
    <span class="char" data-decimal="9867" data-entity="&amp;#9867;" data-id="45874" title="Monogram For Yin">⚋</span>
    <span class="char" data-decimal="9866" data-entity="&amp;#9866;" data-id="45873" title="Monogram For Yang">⚊</span>
    <span class="char" data-decimal="8972" data-entity="&amp;#8972;" data-id="45954" title="Bottom Right Crop">⌌</span>
    <span class="char" data-decimal="8973" data-entity="&amp;#8973;" data-id="45955" title="Bottom Left Crop">⌍</span>
    <span class="char" data-decimal="8974" data-entity="&amp;#8974;" data-id="45956" title="Top Right Crop">⌎</span>
    <span class="char" data-decimal="8975" data-entity="&amp;#8975;" data-id="45957" title="Top Left Crop">⌏</span>
    <span class="char" data-decimal="8976" data-entity="&amp;#8976;" data-id="45958" title="Reversed Not Sign">⌐</span>
    <span class="char" data-decimal="8977" data-entity="&amp;#8977;" data-id="45959" title="Square Lozenge">⌑</span>
    <span class="char" data-decimal="8980" data-entity="&amp;#8980;" data-id="45962" title="Sector">⌔</span>
    <span class="char" data-decimal="8981" data-entity="&amp;#8981;" data-id="45963" title="Telephone Recorder">⌕</span>
    <span class="char" data-decimal="8983" data-entity="&amp;#8983;" data-id="45965" title="Viewdata Square">⌗</span>
    <span class="char" data-decimal="8985" data-entity="&amp;#8985;" data-id="45967" title="Turned Not Sign">⌙</span>
    <span class="char" data-decimal="8994" data-entity="&amp;#8994;" data-id="45976" title="Frown">⌢</span>
    <span class="char" data-decimal="8995" data-entity="&amp;#8995;" data-id="45977" title="Smile">⌣</span>
    <span class="char" data-decimal="9007" data-entity="&amp;#9007;" data-id="45989" title="Symmetry">⌯</span>
    <span class="char" data-decimal="9004" data-entity="&amp;#9004;" data-id="45986" title="Benzene Ring">⌬</span>
    <span class="char" data-decimal="9005" data-entity="&amp;#9005;" data-id="45987" title="Cylindricity">⌭</span>
    <span class="char" data-decimal="9006" data-entity="&amp;#9006;" data-id="45988" title="All Around Profile">⌮</span>
    <span class="char" data-decimal="8982" data-entity="&amp;#8982;" data-id="45964" title="Position Indicator">⌖</span>
    <span class="char" data-decimal="9008" data-entity="&amp;#9008;" data-id="45990" title="Total Runout">⌰</span>
    <span class="char" data-decimal="9009" data-entity="&amp;#9009;" data-id="45991" title="Dimension Origin">⌱</span>
    <span class="char" data-decimal="9010" data-entity="&amp;#9010;" data-id="45992" title="Conical Taper">⌲</span>
    <span class="char" data-decimal="9011" data-entity="&amp;#9011;" data-id="45993" title="Slope">⌳</span>
    <span class="char" data-decimal="9012" data-entity="&amp;#9012;" data-id="45994" title="Counterbore">⌴</span>
    <span class="char" data-decimal="9013" data-entity="&amp;#9013;" data-id="45995" title="Countersink">⌵</span>
    <span class="char" data-decimal="9014" data-entity="&amp;#9014;" data-id="45996" title="Apl Functional Symbol I Beam">⌶</span>
    <span class="char" data-decimal="9015" data-entity="&amp;#9015;" data-id="45997" title="Apl Functional Symbol Squish Quad">⌷</span>
    <span class="char" data-decimal="9016" data-entity="&amp;#9016;" data-id="45998" title="Apl Functional Symbol Quad Equal">⌸</span>
    <span class="char" data-decimal="9017" data-entity="&amp;#9017;" data-id="45999" title="Apl Functional Symbol Quad Divide">⌹</span>
    <span class="char" data-decimal="9018" data-entity="&amp;#9018;" data-id="46000" title="Apl Functional Symbol Quad Diamond">⌺</span>
    <span class="char" data-decimal="9019" data-entity="&amp;#9019;" data-id="46001" title="Apl Functional Symbol Quad Jot">⌻</span>
    <span class="char" data-decimal="9020" data-entity="&amp;#9020;" data-id="46002" title="Apl Functional Symbol Quad Circle">⌼</span>
    <span class="char" data-decimal="9071" data-entity="&amp;#9071;" data-id="46053" title="Apl Functional Symbol Quad Not Equal">⍯</span>
    <span class="char" data-decimal="9072" data-entity="&amp;#9072;" data-id="46054" title="Apl Functional Symbol Quad Question">⍰</span>
    <span class="char" data-decimal="9021" data-entity="&amp;#9021;" data-id="46003" title="Apl Functional Symbol Circle Stile">⌽</span>
    <span class="char" data-decimal="9022" data-entity="&amp;#9022;" data-id="46004" title="Apl Functional Symbol Circle Jot">⌾</span>
    <span class="char" data-decimal="9023" data-entity="&amp;#9023;" data-id="46005" title="Apl Functional Symbol Slash Bar">⌿</span>
    <span class="char" data-decimal="9024" data-entity="&amp;#9024;" data-id="46006" title="Apl Functional Symbol Backslash Bar">⍀</span>
    <span class="char" data-decimal="9025" data-entity="&amp;#9025;" data-id="46007" title="Apl Functional Symbol Quad Slash">⍁</span>
    <span class="char" data-decimal="9026" data-entity="&amp;#9026;" data-id="46008" title="Apl Functional Symbol Quad Backslash">⍂</span>
    <span class="char" data-decimal="9033" data-entity="&amp;#9033;" data-id="46015" title="Apl Functional Symbol Circle Backslash">⍉</span>
    <span class="char" data-decimal="9034" data-entity="&amp;#9034;" data-id="46016" title="Apl Functional Symbol Down Tack Underbar">⍊</span>
    <span class="char" data-decimal="9035" data-entity="&amp;#9035;" data-id="46017" title="Apl Functional Symbol Delta Stile">⍋</span>
    <span class="char" data-decimal="9038" data-entity="&amp;#9038;" data-id="46020" title="Apl Functional Symbol Down Tack Jot">⍎</span>
    <span class="char" data-decimal="9039" data-entity="&amp;#9039;" data-id="46021" title="Apl Functional Symbol Upwards Vane">⍏</span>
    <span class="char" data-decimal="9041" data-entity="&amp;#9041;" data-id="46023" title="Apl Functional Symbol Up Tack Overbar">⍑</span>
    <span class="char" data-decimal="9042" data-entity="&amp;#9042;" data-id="46024" title="Apl Functional Symbol Del Stile">⍒</span>
    <span class="char" data-decimal="9045" data-entity="&amp;#9045;" data-id="46027" title="Apl Functional Symbol Up Tack Jot">⍕</span>
    <span class="char" data-decimal="9046" data-entity="&amp;#9046;" data-id="46028" title="Apl Functional Symbol Downwards Vane">⍖</span>
    <span class="char" data-decimal="9048" data-entity="&amp;#9048;" data-id="46030" title="Apl Functional Symbol Quote Underbar">⍘</span>
    <span class="char" data-decimal="9049" data-entity="&amp;#9049;" data-id="46031" title="Apl Functional Symbol Delta Underbar">⍙</span>
    <span class="char" data-decimal="9050" data-entity="&amp;#9050;" data-id="46032" title="Apl Functional Symbol Diamond Underbar">⍚</span>
    <span class="char" data-decimal="9051" data-entity="&amp;#9051;" data-id="46033" title="Apl Functional Symbol Jot Underbar">⍛</span>
    <span class="char" data-decimal="9052" data-entity="&amp;#9052;" data-id="46034" title="Apl Functional Symbol Circle Underbar">⍜</span>
    <span class="char" data-decimal="9053" data-entity="&amp;#9053;" data-id="46035" title="Apl Functional Symbol Up Shoe Jot">⍝</span>
    <span class="char" data-decimal="9054" data-entity="&amp;#9054;" data-id="46036" title="Apl Functional Symbol Quote Quad">⍞</span>
    <span class="char" data-decimal="9056" data-entity="&amp;#9056;" data-id="46038" title="Apl Functional Symbol Quad Colon">⍠</span>
    <span class="char" data-decimal="9055" data-entity="&amp;#9055;" data-id="46037" title="Apl Functional Symbol Circle Star">⍟</span>
    <span class="char" data-decimal="9057" data-entity="&amp;#9057;" data-id="46039" title="Apl Functional Symbol Up Tack Diaeresis">⍡</span>
    <span class="char" data-decimal="9058" data-entity="&amp;#9058;" data-id="46040" title="Apl Functional Symbol Del Diaeresis">⍢</span>
    <span class="char" data-decimal="9059" data-entity="&amp;#9059;" data-id="46041" title="Apl Functional Symbol Star Diaeresis">⍣</span>
    <span class="char" data-decimal="9060" data-entity="&amp;#9060;" data-id="46042" title="Apl Functional Symbol Jot Diaeresis">⍤</span>
    <span class="char" data-decimal="9061" data-entity="&amp;#9061;" data-id="46043" title="Apl Functional Symbol Circle Diaeresis">⍥</span>
    <span class="char" data-decimal="9064" data-entity="&amp;#9064;" data-id="46046" title="Apl Functional Symbol Tilde Diaeresis">⍨</span>
    <span class="char" data-decimal="9065" data-entity="&amp;#9065;" data-id="46047" title="Apl Functional Symbol Greater Than Diaeresis">⍩</span>
    <span class="char" data-decimal="9062" data-entity="&amp;#9062;" data-id="46044" title="Apl Functional Symbol Down Shoe Stile">⍦</span>
    <span class="char" data-decimal="9063" data-entity="&amp;#9063;" data-id="46045" title="Apl Functional Symbol Left Shoe Stile">⍧</span>
    <span class="char" data-decimal="9068" data-entity="&amp;#9068;" data-id="46050" title="Apl Functional Symbol Zilde">⍬</span>
    <span class="char" data-decimal="9087" data-entity="&amp;#9087;" data-id="46069" title="Vertical Line Wth Middle Dot">⍿</span>
    <span class="char" data-decimal="9066" data-entity="&amp;#9066;" data-id="46048" title="Apl Functional Symbol Comma Bar">⍪</span>
    <span class="char" data-decimal="9070" data-entity="&amp;#9070;" data-id="46052" title="Apl Functional Symbol Semicolon Underbar">⍮</span>
    <span class="char" data-decimal="9067" data-entity="&amp;#9067;" data-id="46049" title="Apl Functional Symbol Del Tilde">⍫</span>
    <span class="char" data-decimal="9073" data-entity="&amp;#9073;" data-id="46055" title="Apl Functional Symbol Down Caret Tilde">⍱</span>
    <span class="char" data-decimal="9074" data-entity="&amp;#9074;" data-id="46056" title="Apl Functional Symbol Up Caret Tilde">⍲</span>
    <span class="char" data-decimal="9069" data-entity="&amp;#9069;" data-id="46051" title="Apl Functional Symbol Stile Tilde">⍭</span>
    <span class="char" data-decimal="9075" data-entity="&amp;#9075;" data-id="46057" title="Apl Functional Symbol Iota">⍳</span>
    <span class="char" data-decimal="9076" data-entity="&amp;#9076;" data-id="46058" title="Apl Functional Symbol Rho">⍴</span>
    <span class="char" data-decimal="9077" data-entity="&amp;#9077;" data-id="46059" title="Apl Functional Symbol Omega">⍵</span>
    <span class="char" data-decimal="9078" data-entity="&amp;#9078;" data-id="46060" title="Apl Functional Symbol Alpha Underbar">⍶</span>
    <span class="char" data-decimal="9079" data-entity="&amp;#9079;" data-id="46061" title="Apl Functional Symbol Epsilon Underbar">⍷</span>
    <span class="char" data-decimal="9080" data-entity="&amp;#9080;" data-id="46062" title="Apl Functional Symbol Iota Underbar">⍸</span>
    <span class="char" data-decimal="9081" data-entity="&amp;#9081;" data-id="46063" title="Apl Functional Symbol Omega Underbar">⍹</span>
    <span class="char" data-decimal="9082" data-entity="&amp;#9082;" data-id="46064" title="Apl Functional Symbol Alpha">⍺</span>
    <span class="char" data-decimal="9084" data-entity="&amp;#9084;" data-id="46066" title="Right Angle With Downwards Zigzag Arrow">⍼</span>
    <span class="char" data-decimal="9085" data-entity="&amp;#9085;" data-id="46067" title="Shouldered Open Box">⍽</span>
    <span class="char" data-decimal="9086" data-entity="&amp;#9086;" data-id="46068" title="Bell Symbol">⍾</span>
    <span class="char" data-decimal="9088" data-entity="&amp;#9088;" data-id="46070" title="Insertion Symbol">⎀</span>
    <span class="char" data-decimal="9089" data-entity="&amp;#9089;" data-id="46071" title="Continuous Underline Symbol">⎁</span>
    <span class="char" data-decimal="9090" data-entity="&amp;#9090;" data-id="46072" title="Discontinuous Underline Symbol">⎂</span>
    <span class="char" data-decimal="9091" data-entity="&amp;#9091;" data-id="46073" title="Emphasis Symbol">⎃</span>
    <span class="char" data-decimal="9092" data-entity="&amp;#9092;" data-id="46074" title="Composition Symbol">⎄</span>
    <span class="char" data-decimal="9093" data-entity="&amp;#9093;" data-id="46075" title="White Square With Centre Vertical Line">⎅</span>
    <span class="char" data-decimal="9094" data-entity="&amp;#9094;" data-id="46076" title="Enter Symbol">⎆</span>
    <span class="char" data-decimal="9097" data-entity="&amp;#9097;" data-id="46079" title="Circled Horizontal Bar With Notch">⎉</span>
    <span class="char" data-decimal="9098" data-entity="&amp;#9098;" data-id="46080" title="Circled Triangle Down">⎊</span>
    <span class="char" data-decimal="9099" data-entity="&amp;#9099;" data-id="46081" title="Broken Circle With Northwest Arrow">⎋</span>
    <span class="char" data-decimal="9101" data-entity="&amp;#9101;" data-id="46083" title="Monostable Symbol">⎍</span>
    <span class="char" data-decimal="9102" data-entity="&amp;#9102;" data-id="46084" title="Hysteresis Symbol">⎎</span>
    <span class="char" data-decimal="9103" data-entity="&amp;#9103;" data-id="46085" title="Open Circuit Output H Type Symbol">⎏</span>
    <span class="char" data-decimal="9104" data-entity="&amp;#9104;" data-id="46086" title="Open Circuit Output L Type Symbol">⎐</span>
    <span class="char" data-decimal="9105" data-entity="&amp;#9105;" data-id="46087" title="Passive Pull Down Output Symbol">⎑</span>
    <span class="char" data-decimal="9106" data-entity="&amp;#9106;" data-id="46088" title="Passive Pull Up Output Symbol">⎒</span>
    <span class="char" data-decimal="9107" data-entity="&amp;#9107;" data-id="46089" title="Direct Current Symbol Form 2">⎓</span>
    <span class="char" data-decimal="9108" data-entity="&amp;#9108;" data-id="46090" title="Software Function Symbol">⎔</span>
    <span class="char" data-decimal="9109" data-entity="&amp;#9109;" data-id="46091" title="Apl Functional Symbol Quad">⎕</span>
    <span class="char" data-decimal="9187" data-entity="&amp;#9187;" data-id="46169" title="Benzene Ring With Circle">⏣</span>
    <span class="char" data-decimal="8979" data-entity="&amp;#8979;" data-id="45961" title="Segment">⌓</span>
    <span class="char" data-decimal="9189" data-entity="&amp;#9189;" data-id="46171" title="Flatness">⏥</span>
    <span class="char" data-decimal="9186" data-entity="&amp;#9186;" data-id="46168" title="White Trapezium">⏢</span>
    <span class="char" data-decimal="9110" data-entity="&amp;#9110;" data-id="46092" title="Decimal Separator Key Symbol">⎖</span>
    <span class="char" data-decimal="9138" data-entity="&amp;#9138;" data-id="46120" title="Summation Top">⎲</span>
    <span class="char" data-decimal="9139" data-entity="&amp;#9139;" data-id="46121" title="Summation Bottom">⎳</span>
    <span class="char" data-decimal="9140" data-entity="&amp;#9140;" data-id="46122" title="Top Square Bracket">⎴</span>
    <span class="char" data-decimal="9141" data-entity="&amp;#9141;" data-id="46123" title="Bottom Square Bracket">⎵</span>
    <span class="char" data-decimal="9142" data-entity="&amp;#9142;" data-id="46124" title="Bottom Square Bracket Over Top Square Bracket">⎶</span>
    <span class="char" data-decimal="9144" data-entity="&amp;#9144;" data-id="46126" title="Left Vertical Box Line">⎸</span>
    <span class="char" data-decimal="9145" data-entity="&amp;#9145;" data-id="46127" title="Right Vertical Box Line">⎹</span>
    <span class="char" data-decimal="9146" data-entity="&amp;#9146;" data-id="46128" title="Horizontal Scan Line 1">⎺</span>
    <span class="char" data-decimal="9147" data-entity="&amp;#9147;" data-id="46129" title="Horizontal Scan Line 3">⎻</span>
    <span class="char" data-decimal="9148" data-entity="&amp;#9148;" data-id="46130" title="Horizontal Scan Line 7">⎼</span>
    <span class="char" data-decimal="9149" data-entity="&amp;#9149;" data-id="46131" title="Horizontal Scan Line 9">⎽</span>
    <span class="char" data-decimal="9150" data-entity="&amp;#9150;" data-id="46132" title="Dentistry Symbol Light Vertical And Top Right">⎾</span>
    <span class="char" data-decimal="9151" data-entity="&amp;#9151;" data-id="46133" title="Dentistry Symbol Light Vertical And Bottom Right">⎿</span>
    <span class="char" data-decimal="9152" data-entity="&amp;#9152;" data-id="46134" title="Dentistry Symbol Light Vertical With Circle">⏀</span>
    <span class="char" data-decimal="9153" data-entity="&amp;#9153;" data-id="46135" title="Dentistry Symbol Light Down And Horizontal With Circle">⏁</span>
    <span class="char" data-decimal="9154" data-entity="&amp;#9154;" data-id="46136" title="Dentistry Symbol Light Up And Horizontal With Circle">⏂</span>
    <span class="char" data-decimal="9155" data-entity="&amp;#9155;" data-id="46137" title="Dentistry Symbol Light Vertical With Triangle">⏃</span>
    <span class="char" data-decimal="9156" data-entity="&amp;#9156;" data-id="46138" title="Dentistry Symbol Light Down And Horizontal With Triangle">⏄</span>
    <span class="char" data-decimal="9157" data-entity="&amp;#9157;" data-id="46139" title="Dentistry Symbol Light Up And Horizontal With Triangle">⏅</span>
    <span class="char" data-decimal="9158" data-entity="&amp;#9158;" data-id="46140" title="Dentistry Symbol Light Vertical And Wave">⏆</span>
    <span class="char" data-decimal="9159" data-entity="&amp;#9159;" data-id="46141" title="Dentistry Symbol Light Down And Horizontal With Wave">⏇</span>
    <span class="char" data-decimal="9160" data-entity="&amp;#9160;" data-id="46142" title="Dentistry Symbol Light Up And Horizontal With Wave">⏈</span>
    <span class="char" data-decimal="9161" data-entity="&amp;#9161;" data-id="46143" title="Dentistry Symbol Light Down And Horizontal">⏉</span>
    <span class="char" data-decimal="9162" data-entity="&amp;#9162;" data-id="46144" title="Dentistry Symbol Light Up And Horizontal">⏉</span>
    <span class="char" data-decimal="9163" data-entity="&amp;#9163;" data-id="46145" title="Dentistry Symbol Light Vertical And Top Left">⏋</span>
    <span class="char" data-decimal="9164" data-entity="&amp;#9164;" data-id="46146" title="Dentistry Symbol Light Vertical And Bottom Left">⏌</span>
    <span class="char" data-decimal="9165" data-entity="&amp;#9165;" data-id="46147" title="Square Foot">⏍</span>
    <span class="char" data-decimal="9168" data-entity="&amp;#9168;" data-id="46150" title="Vertical Line Extension">⏐</span>
    <span class="char" data-decimal="9188" data-entity="&amp;#9188;" data-id="46170" title="Straightness">⏤</span>
    <span class="char" data-decimal="9178" data-entity="&amp;#9178;" data-id="46160" title="Earth Ground">⏚</span>
    <span class="char" data-decimal="9179" data-entity="&amp;#9179;" data-id="46161" title="Fuse">⏛</span>
    <span class="char" data-decimal="9411" data-entity="&amp;#9411;" data-id="43862" title="Circled Latin Capital Letter N">Ⓝ</span>
    <span class="char" data-decimal="8496" data-entity="&amp;#8496;" data-id="45287" title="Script Capital E">ℰ</span>
    <span class="char" data-decimal="9446" data-entity="&amp;#9446;" data-id="43897" title="Circled Latin Small Letter W">ⓦ</span>
    <span class="char" data-decimal="33" data-entity="&amp;#33;" data-id="44770" title="Exclamation Mark">!</span>
    <span class="char" data-decimal="8195" data-entity="&amp;#8195;" data-id="46393" title="Em Space">&emsp;</span>
    <span class="char" data-decimal="8195" data-entity="&amp;#8195;" data-id="46393" title="Em Space">&emsp;</span>
    <span class="char" data-decimal="8195" data-entity="&amp;#8195;" data-id="46393" title="Em Space">&emsp;</span>
    <span class="char" data-decimal="8984" data-entity="&amp;#8984;" data-id="45966" title="Place Of Interest Sign">⌘</span>
    <span class="char" data-decimal="171" data-entity="&amp;laquo;" data-id="45154" title="Left Pointing Double Angle Quotation Mark">«</span>
    <span class="char" data-decimal="187" data-entity="&amp;raquo;" data-id="45170" title="Right Pointing Double Angle Quotation Mark">»</span>
    <span class="char" data-decimal="8249" data-entity="&amp;#8249;" data-id="46440" title="Single Left Pointing Angle Quotation Mark">‹</span>
    <span class="char" data-decimal="8250" data-entity="&amp;#8250;" data-id="46441" title="Single Right Pointing Angle Quotation Mark">›</span>
    <span class="char" data-decimal="8216" data-entity="&amp;#8216;" data-id="46414" title="Left Single Quotation Mark">‘</span>
    <span class="char" data-decimal="8217" data-entity="&amp;#8217;" data-id="46415" title="Right Single Quotation Mark">’</span>
    <span class="char" data-decimal="8220" data-entity="&amp;#8220;" data-id="46418" title="Left Double Quotation Mark">“</span>
    <span class="char" data-decimal="8221" data-entity="&amp;#8221;" data-id="46419" title="Right Double Quotation Mark">”</span>
    <span class="char" data-decimal="8222" data-entity="&amp;#8222;" data-id="46420" title="Double Low 9 Quotation Mark">„</span>
    <span class="char" data-decimal="8218" data-entity="&amp;#8218;" data-id="46416" title="Single Low 9 Quotation Mark">‚</span>
    <span class="char" data-decimal="10077" data-entity="&amp;#10077;" data-id="43671" title="Heavy Double Turned Comma Quotation Mark Ornament">❝</span>
    <span class="char" data-decimal="10078" data-entity="&amp;#10078;" data-id="43672" title="Heavy Double Comma Quotation Mark Ornament">❞</span>
    <span class="char" data-decimal="163" data-entity="&amp;pound;" data-id="43131" title="Pound Sign">£</span>
    <span class="char" data-decimal="165" data-entity="&amp;yen;" data-id="43133" title="Yen Sign">¥</span>
    <span class="char" data-decimal="8364" data-entity="&amp;euro;" data-id="43578" title="Euro Sign">€</span>
    <span class="char" data-decimal="36" data-entity="&amp;#36;" data-id="43129" title="Dollar Sign">$</span>
    <span class="char" data-decimal="162" data-entity="&amp;cent;" data-id="43130" title="Cent Sign">¢</span>
    <span class="char" data-decimal="172" data-entity="&amp;not;" data-id="45155" title="Not Sign">¬</span>
    <span class="char" data-decimal="182" data-entity="&amp;para;" data-id="45165" title="Pilcrow Sign">¶</span>
    <span class="char" data-decimal="64" data-entity="&amp;#64;" data-id="44800" title="Commercial At">&#64;</span>
    <span class="char" data-decimal="167" data-entity="&amp;sect;" data-id="45150" title="Section Sign">§</span>
    <span class="char" data-decimal="174" data-entity="&amp;reg;" data-id="45157" title="Registered Sign">®</span>
    <span class="char" data-decimal="169" data-entity="&amp;copy;" data-id="45152" title="Copyright Sign">©</span>
    <span class="char" data-decimal="8482" data-entity="&amp;trade;" data-id="45273" title="Trade Mark Sign">™</span>
    <span class="char" data-decimal="176" data-entity="&amp;deg;" data-id="45159" title="Degree Sign">°</span>
    <span class="char" data-decimal="215" data-entity="&amp;times;" data-id="45198" title="Multiplication Sign">×</span>
    <span class="char" data-decimal="960" data-entity="&amp;pi;" data-id="44322" title="Greek Small Letter Pi">π</span>
    <span class="char" data-decimal="177" data-entity="&amp;plusmn;" data-id="45160" title="Plus Minus Sign">±</span>
    <span class="char" data-decimal="8730" data-entity="&amp;radic;" data-id="45506" title="Square Root">√</span>
    <span class="char" data-decimal="8240" data-entity="&amp;#8240;" data-id="46431" title="Per Mille Sign">‰</span>
    <span class="char" data-decimal="937" data-entity="&amp;Omega;" data-id="44299" title="Greek Capital Letter Omega">Ω</span>
    <span class="char" data-decimal="8734" data-entity="&amp;infin;" data-id="45510" title="Infinity">∞</span>
    <span class="char" data-decimal="8776" data-entity="&amp;asymp;" data-id="45552" title="Almost Equal To">≈</span>
    <span class="char" data-decimal="247" data-entity="&amp;divide;" data-id="45230" title="Division Sign">÷</span>
    <span class="char" data-decimal="126" data-entity="&amp;#126;" data-id="44862" title="Tilde">~</span>
    <span class="char" data-decimal="8800" data-entity="&amp;ne;" data-id="45576" title="Not Equal To">≠</span>
    <span class="char" data-decimal="185" data-entity="&amp;sup1;" data-id="45168" title="Superscript One">¹</span>
    <span class="char" data-decimal="178" data-entity="&amp;sup2;" data-id="45161" title="Superscript Two">²</span>
    <span class="char" data-decimal="179" data-entity="&amp;sup3;" data-id="45162" title="Superscript Three">³</span>
    <span class="char" data-decimal="189" data-entity="&amp;frac12;" data-id="45172" title="Vulgar Fraction One Half">½</span>
    <span class="char" data-decimal="188" data-entity="&amp;frac14;" data-id="45171" title="Vulgar Fraction One Quarter">¼</span>
    <span class="char" data-decimal="190" data-entity="&amp;frac34;" data-id="45173" title="Vulgar Fraction Three Quarters">¾</span>
    <span class="char" data-decimal="8208" data-entity="&amp;#8208;" data-id="46406" title="Hyphen">‐</span>
    <span class="char" data-decimal="8211" data-entity="&amp;#8211;" data-id="46409" title="En Dash">–</span>
    <span class="char" data-decimal="8212" data-entity="&amp;#8212;" data-id="46410" title="Em Dash">—</span>
    <span class="char" data-decimal="124" data-entity="&amp;#124;" data-id="44860" title="Vertical Line">|</span>
    <span class="char" data-decimal="8260" data-entity="&amp;#8260;" data-id="46451" title="Fraction Slash">⁄</span>
    <span class="char" data-decimal="92" data-entity="&amp;#92;" data-id="44828" title="Reverse Solidus">\</span>
    <span class="char" data-decimal="8224" data-entity="&amp;#8224;" data-id="46422" title="Dagger">†</span>
    <span class="char" data-decimal="8225" data-entity="&amp;#8225;" data-id="46423" title="Double Dagger">‡</span>
    <span class="char" data-decimal="8230" data-entity="&amp;#8230;" data-id="46428" title="Horizontal Ellipsis">…</span>
    <span class="char" data-decimal="183" data-entity="&amp;middot;" data-id="45166" title="Middle Dot">·</span>
    <span class="char" data-decimal="8226" data-entity="&amp;#8226;" data-id="46424" title="Bullet">•</span>
    <span class="char" data-decimal="9679" data-entity="&amp;#9679;" data-id="43970" title="Black Circle">●</span>
    <span class="char" data-decimal="63743" data-entity="&amp;#63743;" data-id="46533" title=""></span>
    <span class="char" data-decimal="8997" data-entity="&amp;#8997;" data-id="45979" title="Option Key">⌥</span>
    <span class="char" data-decimal="8963" data-entity="&amp;#8963;" data-id="45945" title="Up Arrowhead">⌃</span>
    <span class="char" data-decimal="8679" data-entity="&amp;#8679;" data-id="43237" title="Upwards White Arrow">⇧</span>
    <span class="char" data-decimal="8617" data-entity="&amp;#8617;" data-id="43175" title="Leftwards Arrow With Hook">↩</span>
    <span class="char" data-decimal="161" data-entity="&amp;iexcl;" data-id="45144" title="Inverted Exclamation Mark">¡</span>
    <span class="char" data-decimal="191" data-entity="&amp;iquest;" data-id="45174" title="Inverted Question Mark">¿</span>
    <span class="char" data-decimal="8253" data-entity="&amp;#8253;" data-id="46444" title="Interrobang">‽</span>
    <span class="char" data-decimal="8258" data-entity="&amp;#8258;" data-id="46449" title="Asterism">⁂</span>
    <span class="char" data-decimal="8756" data-entity="&amp;there4;" data-id="45532" title="Therefore">∴</span>
    <span class="char" data-decimal="8757" data-entity="&amp;#8757;" data-id="45533" title="Because">∵</span>
    <span class="char" data-decimal="9674" data-entity="&amp;loz;" data-id="43965" title="Lozenge">◊</span>
    <span class="char" data-decimal="8251" data-entity="&amp;#8251;" data-id="46442" title="Reference Mark">※</span>
    <span class="char" data-decimal="8592" data-entity="&amp;larr;" data-id="43150" title="Leftwards Arrow">←</span>
    <span class="char" data-decimal="8594" data-entity="&amp;rarr;" data-id="43152" title="Rightwards Arrow">→</span>
    <span class="char" data-decimal="8593" data-entity="&amp;uarr;" data-id="43151" title="Upwards Arrow">↑</span>
    <span class="char" data-decimal="8595" data-entity="&amp;darr;" data-id="43153" title="Downwards Arrow">↓</span>
    <span class="char" data-decimal="9756" data-entity="&amp;#9756;" data-id="45763" title="White Left Pointing Index">☜</span>
    <span class="char" data-decimal="9758" data-entity="&amp;#9758;" data-id="45765" title="White Right Pointing Index">☞</span>
    <span class="char" data-decimal="9757" data-entity="&amp;#9757;" data-id="45764" title="White Up Pointing Index">☝</span>
    <span class="char" data-decimal="9759" data-entity="&amp;#9759;" data-id="45766" title="White Down Pointing Index">☟</span>
    <span class="char" data-decimal="10004" data-entity="&amp;#10004;" data-id="43604" title="Heavy Check Mark">✔</span>
    <span class="char" data-decimal="9733" data-entity="&amp;#9733;" data-id="45741" title="Black Star">★</span>
    <span class="char" data-decimal="9734" data-entity="&amp;#9734;" data-id="45742" title="White Star">☆</span>
    <span class="char" data-decimal="9850" data-entity="&amp;#9850;" data-id="45857" title="Recycling Symbol For Generic Materials">♺</span>
    <span class="char" data-decimal="9788" data-entity="&amp;#9788;" data-id="45795" title="White Sun With Rays">☼</span>
    <span class="char" data-decimal="9730" data-entity="&amp;#9730;" data-id="45738" title="Umbrella">☂</span>
    <span class="char" data-decimal="9786" data-entity="&amp;#9786;" data-id="45793" title="White Smiling Face">☺</span>
    <span class="char" data-decimal="9785" data-entity="&amp;#9785;" data-id="45792" title="White Frowning Face">☹</span>
    <span class="char" data-decimal="9731" data-entity="&amp;#9731;" data-id="45739" title="Snowman">☃</span>
    <span class="char" data-decimal="9993" data-entity="&amp;#9993;" data-id="43595" title="Envelope">✉</span>
    <span class="char" data-decimal="10047" data-entity="&amp;#10047;" data-id="43646" title="Black Florette">✿</span>
    <span class="char" data-decimal="9988" data-entity="&amp;#9988;" data-id="43591" title="White Scissors">✄</span>
    <span class="char" data-decimal="9992" data-entity="&amp;#9992;" data-id="43594" title="Airplane">✈</span>
    <span class="char" data-decimal="9996" data-entity="&amp;#9996;" data-id="43596" title="Victory Hand">✌</span>
    <span class="char" data-decimal="9998" data-entity="&amp;#9998;" data-id="43598" title="Lower Right Pencil">✎</span>
    <span class="char" data-decimal="9824" data-entity="&amp;spades;" data-id="45831" title="Black Spade Suit">♠</span>
    <span class="char" data-decimal="9830" data-entity="&amp;diams;" data-id="45837" title="Black Diamond Suit">♦</span>
    <span class="char" data-decimal="9827" data-entity="&amp;clubs;" data-id="45834" title="Black Club Suit">♣</span>
    <span class="char" data-decimal="9829" data-entity="&amp;hearts;" data-id="45836" title="Black Heart Suit">♥</span>
    <span class="char" data-decimal="9834" data-entity="&amp;#9834;" data-id="45841" title="Eighth Note">♪</span>
    <span class="char" data-decimal="9835" data-entity="&amp;#9835;" data-id="45842" title="Beamed Eighth Notes">♫</span>
    <span class="char" data-decimal="9839" data-entity="&amp;#9839;" data-id="45846" title="Music Sharp Sign">♯</span>
    <span class="char" data-decimal="9792" data-entity="&amp;#9792;" data-id="45799" title="Female Sign">♀</span>
    <span class="char" data-decimal="9794" data-entity="&amp;#9794;" data-id="45801" title="Male Sign">♂</span>
    <span class="char" data-decimal="945" data-entity="&amp;alpha;" data-id="44307" title="Greek Small Letter Alpha">α</span>
    <span class="char" data-decimal="223" data-entity="&amp;szlig;" data-id="45206" title="Latin Small Letter Sharp S">ß</span>
    <span class="char" data-decimal="193" data-entity="&amp;Aacute;" data-id="45176" title="Latin Capital Letter A With Acute">Á</span>
    <span class="char" data-decimal="225" data-entity="&amp;aacute;" data-id="45208" title="Latin Small Letter A With Acute">á</span>
    <span class="char" data-decimal="192" data-entity="&amp;Agrave;" data-id="45175" title="Latin Capital Letter A With Grave">À</span>
    <span class="char" data-decimal="224" data-entity="&amp;agrave;" data-id="45207" title="Latin Small Letter A With Grave">à</span>
    <span class="char" data-decimal="197" data-entity="&amp;Aring;" data-id="45180" title="Latin Capital Letter A With Ring Above">Å</span>
    <span class="char" data-decimal="229" data-entity="&amp;aring;" data-id="45212" title="Latin Small Letter A With Ring Above">å</span>
    <span class="char" data-decimal="196" data-entity="&amp;Auml;" data-id="45179" title="Latin Capital Letter A With Diaeresis">Ä</span>
    <span class="char" data-decimal="228" data-entity="&amp;auml;" data-id="45211" title="Latin Small Letter A With Diaeresis">ä</span>
    <span class="char" data-decimal="198" data-entity="&amp;AElig;" data-id="45181" title="Latin Capital Letter Ae">Æ</span>
    <span class="char" data-decimal="230" data-entity="&amp;aelig;" data-id="45213" title="Latin Small Letter Ae">æ</span>
    <span class="char" data-decimal="199" data-entity="&amp;Ccedil;" data-id="45182" title="Latin Capital Letter C With Cedilla">Ç</span>
    <span class="char" data-decimal="231" data-entity="&amp;ccedil;" data-id="45214" title="Latin Small Letter C With Cedilla">ç</span>
    <span class="char" data-decimal="201" data-entity="&amp;Eacute;" data-id="45184" title="Latin Capital Letter E With Acute">É</span>
    <span class="char" data-decimal="233" data-entity="&amp;eacute;" data-id="45216" title="Latin Small Letter E With Acute">é</span>
    <span class="char" data-decimal="200" data-entity="&amp;Egrave;" data-id="45183" title="Latin Capital Letter E With Grave">È</span>
    <span class="char" data-decimal="232" data-entity="&amp;egrave;" data-id="45215" title="Latin Small Letter E With Grave">è</span>
    <span class="char" data-decimal="202" data-entity="&amp;Ecirc;" data-id="45185" title="Latin Capital Letter E With Circumflex">Ê</span>
    <span class="char" data-decimal="234" data-entity="&amp;ecirc;" data-id="45217" title="Latin Small Letter E With Circumflex">ê</span>
    <span class="char" data-decimal="205" data-entity="&amp;Iacute;" data-id="45188" title="Latin Capital Letter I With Acute">Í</span>
    <span class="char" data-decimal="237" data-entity="&amp;iacute;" data-id="45220" title="Latin Small Letter I With Acute">í</span>
    <span class="char" data-decimal="204" data-entity="&amp;Igrave;" data-id="45187" title="Latin Capital Letter I With Grave">Ì</span>
    <span class="char" data-decimal="236" data-entity="&amp;igrave;" data-id="45219" title="Latin Small Letter I With Grave">ì</span>
    <span class="char" data-decimal="206" data-entity="&amp;Icirc;" data-id="45189" title="Latin Capital Letter I With Circumflex">Î</span>
    <span class="char" data-decimal="238" data-entity="&amp;icirc;" data-id="45221" title="Latin Small Letter I With Circumflex">î</span>
    <span class="char" data-decimal="209" data-entity="&amp;Ntilde;" data-id="45192" title="Latin Capital Letter N With Tilde">Ñ</span>
    <span class="char" data-decimal="241" data-entity="&amp;ntilde;" data-id="45224" title="Latin Small Letter N With Tilde">ñ</span>
    <span class="char" data-decimal="211" data-entity="&amp;Oacute;" data-id="45194" title="Latin Capital Letter O With Acute">Ó</span>
    <span class="char" data-decimal="243" data-entity="&amp;oacute;" data-id="45226" title="Latin Small Letter O With Acute">ó</span>
    <span class="char" data-decimal="210" data-entity="&amp;Ograve;" data-id="45193" title="Latin Capital Letter O With Grave">Ò</span>
    <span class="char" data-decimal="242" data-entity="&amp;ograve;" data-id="45225" title="Latin Small Letter O With Grave">ò</span>
    <span class="char" data-decimal="212" data-entity="&amp;Ocirc;" data-id="45195" title="Latin Capital Letter O With Circumflex">Ô</span>
    <span class="char" data-decimal="244" data-entity="&amp;ocirc;" data-id="45227" title="Latin Small Letter O With Circumflex">ô</span>
    <span class="char" data-decimal="214" data-entity="&amp;Ouml;" data-id="45197" title="Latin Capital Letter O With Diaeresis">Ö</span>
    <span class="char" data-decimal="246" data-entity="&amp;ouml;" data-id="45229" title="Latin Small Letter O With Diaeresis">ö</span>
    <span class="char" data-decimal="216" data-entity="&amp;Oslash;" data-id="45199" title="Latin Capital Letter O With Stroke">Ø</span>
    <span class="char" data-decimal="248" data-entity="&amp;oslash;" data-id="45231" title="Latin Small Letter O With Stroke">ø</span>
    <span class="char" data-decimal="218" data-entity="&amp;Uacute;" data-id="45201" title="Latin Capital Letter U With Acute">Ú</span>
    <span class="char" data-decimal="250" data-entity="&amp;uacute;" data-id="45233" title="Latin Small Letter U With Acute">ú</span>
    <span class="char" data-decimal="217" data-entity="&amp;Ugrave;" data-id="45200" title="Latin Capital Letter U With Grave">Ù</span>
    <span class="char" data-decimal="249" data-entity="&amp;ugrave;" data-id="45232" title="Latin Small Letter U With Grave">ù</span>
    <span class="char" data-decimal="220" data-entity="&amp;Uuml;" data-id="45203" title="Latin Capital Letter U With Diaeresis">Ü</span>
    <span class="char" data-decimal="252" data-entity="&amp;uuml;" data-id="45235" title="Latin Small Letter U With Diaeresis">ü</span>
    <span class="char" data-decimal="381" data-entity="&amp;#381;" data-id="44766" title="Latin Capital Letter Z With Caron">Ž</span>
    <span class="char" data-decimal="382" data-entity="&amp;#382;" data-id="44767" title="Latin Small Letter Z With Caron">ž</span>
    <span class="char" data-decimal="8371" data-entity="&amp;#8371;" data-id="43585" title="Austral Sign">₳</span>
    <span class="char" data-decimal="3647" data-entity="&amp;#3647;" data-id="43139" title="Thai Currency Symbol Baht">฿</span>
    <span class="char" data-decimal="65504" data-entity="&amp;#65504;" data-id="43145" title="Fullwidth Cent Sign">￠</span>
    <span class="char" data-decimal="8364" data-entity="&amp;euro;" data-id="43578" title="Euro Sign">€</span>
    <span class="char" data-decimal="8353" data-entity="&amp;#8353;" data-id="43567" title="Colon Sign">₡</span>
    <span class="char" data-decimal="162" data-entity="&amp;cent;" data-id="43130" title="Cent Sign">¢</span>
    <span class="char" data-decimal="8354" data-entity="&amp;#8354;" data-id="43568" title="Cruzeiro Sign">₢</span>
    <span class="char" data-decimal="8373" data-entity="&amp;#8373;" data-id="43587" title="Cedi Sign">₵</span>
    <span class="char" data-decimal="8363" data-entity="&amp;#8363;" data-id="43577" title="Dong Sign">₫</span>
    <span class="char" data-decimal="65505" data-entity="&amp;#65505;" data-id="43146" title="Fullwidth Pound Sign">￡</span>
    <span class="char" data-decimal="163" data-entity="&amp;pound;" data-id="43131" title="Pound Sign">£</span>
    <span class="char" data-decimal="8356" data-entity="&amp;#8356;" data-id="43570" title="Lira Sign">₤</span>
    <span class="char" data-decimal="8355" data-entity="&amp;#8355;" data-id="43569" title="French Franc Sign">₣</span>
    <span class="char" data-decimal="402" data-entity="&amp;fnof;" data-id="43134" title="Latin Small Letter F With Hook">ƒ</span>
    <span class="char" data-decimal="8370" data-entity="&amp;#8370;" data-id="43584" title="Guarani Sign">₲</span>
    <span class="char" data-decimal="8365" data-entity="&amp;#8365;" data-id="43579" title="Kip Sign">₭</span>
    <span class="char" data-decimal="8357" data-entity="&amp;#8357;" data-id="43571" title="Mill Sign">₥</span>
    <span class="char" data-decimal="8358" data-entity="&amp;#8358;" data-id="43572" title="Naira Sign">₦</span>
    <span class="char" data-decimal="8369" data-entity="&amp;#8369;" data-id="43583" title="Peso Sign">₱</span>
    <span class="char" data-decimal="65284" data-entity="&amp;#65284;" data-id="43144" title="Fullwidth Dollar Sign">＄</span>
    <span class="char" data-decimal="36" data-entity="&amp;#36;" data-id="43129" title="Dollar Sign">$</span>
    <span class="char" data-decimal="8366" data-entity="&amp;#8366;" data-id="43580" title="Tugrik Sign">₮</span>
    <span class="char" data-decimal="8361" data-entity="&amp;#8361;" data-id="43575" title="Won Sign">₩</span>
    <span class="char" data-decimal="65510" data-entity="&amp;#65510;" data-id="43148" title="Fullwidth Won Sign">￦</span>
    <span class="char" data-decimal="165" data-entity="&amp;yen;" data-id="43133" title="Yen Sign">¥</span>
    <span class="char" data-decimal="65509" data-entity="&amp;#65509;" data-id="43147" title="Fullwidth Yen Sign">￥</span>
    <span class="char" data-decimal="8372" data-entity="&amp;#8372;" data-id="43586" title="Hryvnia Sign">₴</span>
    <span class="char" data-decimal="8368" data-entity="&amp;#8368;" data-id="43582" title="German Penny Symbol">₰</span>
    <span class="char" data-decimal="164" data-entity="&amp;curren;" data-id="43132" title="Currency Sign">¤</span>
    <span class="char" data-decimal="6107" data-entity="&amp;#6107;" data-id="43140" title="Khmer Currency Symbol Riel">៛</span>
    <span class="char" data-decimal="8362" data-entity="&amp;#8362;" data-id="43576" title="New Sheqel Sign">₪</span>
    <span class="char" data-decimal="8367" data-entity="&amp;#8367;" data-id="43581" title="Drachma Sign">₯</span>
    <span class="char" data-decimal="8352" data-entity="&amp;#8352;" data-id="43566" title="Euro Currency Sign">₠</span>
    <span class="char" data-decimal="8359" data-entity="&amp;#8359;" data-id="43573" title="Peseta Sign">₧</span>
    <span class="char" data-decimal="8360" data-entity="&amp;#8360;" data-id="43574" title="Rupee Sign">₨</span>
    <span class="char" data-decimal="3065" data-entity="&amp;#3065;" data-id="43138" title="Tamil Rupee Sign">௹</span>
    <span class="char" data-decimal="65020" data-entity="&amp;#65020;" data-id="43143" title="Rial Sign">﷼</span>
    <span class="char" data-decimal="13136" data-entity="&amp;#13136;" data-id="43141" title="Square Yuan">㍐</span>
    <span class="char" data-decimal="2546" data-entity="&amp;#2546;" data-id="43135" title="Bengali Rupee Mark">৲</span>
    <span class="char" data-decimal="2547" data-entity="&amp;#2547;" data-id="43136" title="Bengali Rupee Sign">৳</span>
    <span class="char" data-decimal="126" data-entity="&amp;#126;" data-id="44862" title="Tilde">~</span>
    <span class="char" data-decimal="443" data-entity="&amp;#443;" data-id="44922" title="Latin Letter Two With Stroke">ƻ</span>
    <span class="char" data-decimal="444" data-entity="&amp;#444;" data-id="44923" title="Latin Capital Letter Tone Five">Ƽ</span>
    <span class="char" data-decimal="445" data-entity="&amp;#445;" data-id="44924" title="Latin Small Letter Tone Five">ƽ</span>
    <span class="char" data-decimal="185" data-entity="&amp;sup1;" data-id="45168" title="Superscript One">¹</span>
    <span class="char" data-decimal="184" data-entity="&amp;cedil;" data-id="45167" title="Cedilla">¸</span>
    <span class="char" data-decimal="172" data-entity="&amp;not;" data-id="45155" title="Not Sign">¬</span>
    <span class="char" data-decimal="168" data-entity="&amp;uml;" data-id="45151" title="Diaeresis">¨</span>
    <span class="char" data-decimal="578" data-entity="&amp;#578;" data-id="45057" title="Latin Small Letter Glottal Stop">ɂ</span>
    <span class="char" data-decimal="449" data-entity="&amp;#449;" data-id="44928" title="Latin Letter Lateral Click">ǁ</span>
    <span class="char" data-decimal="175" data-entity="&amp;macr;" data-id="45158" title="Macron">¯</span>
    <span class="char" data-decimal="577" data-entity="&amp;#577;" data-id="45056" title="Latin Capital Letter Glottal Stop">Ɂ</span>
    <span class="char" data-decimal="450" data-entity="&amp;#450;" data-id="44929" title="Latin Letter Alveolar Click">ǂ</span>
    <span class="char" data-decimal="161" data-entity="&amp;iexcl;" data-id="45144" title="Inverted Exclamation Mark">¡</span>
    <span class="char" data-decimal="180" data-entity="&amp;acute;" data-id="45163" title="Acute Accent">´</span>
    <span class="char" data-decimal="176" data-entity="&amp;deg;" data-id="45159" title="Degree Sign">°</span>
    <span class="char" data-decimal="166" data-entity="&amp;brvbar;" data-id="45149" title="Broken Bar">¦</span>
    <span class="char" data-decimal="46" data-entity="&amp;#46;" data-id="44782" title="Full Stop">.</span>
    <span class="char" data-decimal="44" data-entity="&amp;#44;" data-id="44780" title="Comma">,</span>
    <span class="char" data-decimal="183" data-entity="&amp;middot;" data-id="45166" title="Middle Dot">·</span>
    <span class="char" data-decimal="47" data-entity="&amp;#47;" data-id="44783" title="Solidus">/</span>
    <span class="char" data-decimal="95" data-entity="&amp;#95;" data-id="44831" title="Low Line">_</span>
    <span class="char" data-decimal="92" data-entity="&amp;#92;" data-id="44828" title="Reverse Solidus">\</span>
    <span class="char" data-decimal="191" data-entity="&amp;iquest;" data-id="45174" title="Inverted Question Mark">¿</span>
    <span class="char" data-decimal="186" data-entity="&amp;ordm;" data-id="45169" title="Masculine Ordinal Indicator">º</span>
    <span class="char" data-decimal="167" data-entity="&amp;sect;" data-id="45150" title="Section Sign">§</span>
    <span class="char" data-decimal="34" data-entity="&amp;#34;" data-id="44771" title="Quotation Mark">"</span>
    <span class="char" data-decimal="42" data-entity="&amp;#42;" data-id="44778" title="Asterisk">*</span>
    <span class="char" data-decimal="45" data-entity="&amp;#45;" data-id="44781" title="Hyphen Minus">-</span>
    <span class="char" data-decimal="43" data-entity="&amp;#43;" data-id="44779" title="Plus Sign">+</span>
    <span class="char" data-decimal="38" data-entity="&amp;#38;" data-id="44774" title="Ampersand">&amp;</span>
    <span class="char" data-decimal="37" data-entity="&amp;#37;" data-id="44773" title="Percent Sign">%</span>
    <span class="char" data-decimal="36" data-entity="&amp;#36;" data-id="44772" title="Dollar Sign">$</span>
    <span class="char" data-decimal="188" data-entity="&amp;frac14;" data-id="45171" title="Vulgar Fraction One Quarter">¼</span>
    <span class="char" data-decimal="190" data-entity="&amp;frac34;" data-id="45173" title="Vulgar Fraction Three Quarters">¾</span>
    <span class="char" data-decimal="189" data-entity="&amp;frac12;" data-id="45172" title="Vulgar Fraction One Half">½</span>
    <span class="char" data-decimal="182" data-entity="&amp;para;" data-id="45165" title="Pilcrow Sign">¶</span>
    <span class="char" data-decimal="169" data-entity="&amp;copy;" data-id="45152" title="Copyright Sign">©</span>
    <span class="char" data-decimal="174" data-entity="&amp;reg;" data-id="45157" title="Registered Sign">®</span>
    <span class="char" data-decimal="64" data-entity="&amp;#64;" data-id="44800" title="Commercial At">&#64;</span>
    <span class="char" data-decimal="7839" data-entity="&amp;#7839;" data-id="44544" title="Latin Small Letter Delta">ẟ</span>
    <span class="char" data-decimal="11391" data-entity="&amp;#11391;" data-id="45102" title="Latin Capital Letter Z With Swash Tail">Ɀ</span>
    <span class="char" data-decimal="96" data-entity="&amp;#96;" data-id="44832" title="Grave Accent">`</span>
    <span class="char" data-decimal="11390" data-entity="&amp;#11390;" data-id="45101" title="Latin Capital Letter S With Swash Tail">Ȿ</span>
    <span class="char" data-decimal="94" data-entity="&amp;#94;" data-id="44830" title="Circumflex Accent">^</span>
    <span class="char" data-decimal="42784" data-entity="&amp;#42784;" data-id="45103" title="Modifier Letter Stress And High Tone">꜠</span>
    <span class="char" data-decimal="42785" data-entity="&amp;#42785;" data-id="45104" title="Modifier Letter Stress And Low Tone">꜡</span>
    <span class="char" data-decimal="7931" data-entity="&amp;#7931;" data-id="44636" title="Latin Small Letter Middle Welsh Ll">ỻ</span>
    <span class="char" data-decimal="39" data-entity="&amp;#39;" data-id="44775" title="Apostrophe">'</span>
    <span class="char" data-decimal="61" data-entity="&amp;#61;" data-id="44797" title="Equals Sign">=</span>
    <span class="char" data-decimal="58" data-entity="&amp;#58;" data-id="44794" title="Colon">:</span>
    <span class="char" data-decimal="59" data-entity="&amp;#59;" data-id="44795" title="Semicolon">;</span>
    <span class="char" data-decimal="60" data-entity="&amp;#60;" data-id="44796" title="Less Than Sign">&lt;</span>
    <span class="char" data-decimal="42892" data-entity="&amp;#42892;" data-id="45137" title="Latin Small Letter Saltillo">ꞌ</span>
    <span class="char" data-decimal="42891" data-entity="&amp;#42891;" data-id="45136" title="Latin Capital Letter Saltillo">Ꞌ</span>
    <span class="char" data-decimal="42890" data-entity="&amp;#42890;" data-id="45135" title="Modifier Letter Short Equals Sign">꞊</span>
    <span class="char" data-decimal="42881" data-entity="&amp;#42881;" data-id="45132" title="Latin Small Letter Turned L">ꞁ</span>
    <span class="char" data-decimal="42888" data-entity="&amp;#42888;" data-id="45133" title="Modifier Letter Low Circumflex Accent">ꞈ</span>
    <span class="char" data-decimal="42889" data-entity="&amp;#42889;" data-id="45134" title="Modifier Letter Colon">꞉</span>
    <span class="char" data-decimal="62" data-entity="&amp;#62;" data-id="44798" title="Greater Than Sign">&gt;</span>
    <span class="char" data-decimal="63" data-entity="&amp;#63;" data-id="44799" title="Question Mark">?</span>
    <span class="char" data-decimal="247" data-entity="&amp;divide;" data-id="45230" title="Division Sign">÷</span>
    <span class="char" data-decimal="8510" data-entity="&amp;#8510;" data-id="45300" title="Double Struck Capital Gamma">ℾ</span>
    <span class="char" data-decimal="8511" data-entity="&amp;#8511;" data-id="45301" title="Double Struck Capital Pi">ℿ</span>
    <span class="char" data-decimal="8468" data-entity="&amp;#8468;" data-id="45259" title="L B Bar Symbol">℔</span>
    <span class="char" data-decimal="8489" data-entity="&amp;#8489;" data-id="45280" title="Turned Greek Small Letter Iota">℩</span>
    <span class="char" data-decimal="8457" data-entity="&amp;#8457;" data-id="45248" title="Degree Fahrenheit">℉</span>
    <span class="char" data-decimal="8512" data-entity="&amp;#8512;" data-id="45302" title="Double Struck N Ary Summation">⅀</span>
    <span class="char" data-decimal="8456" data-entity="&amp;#8456;" data-id="45247" title="Scruple">℈</span>
    <span class="char" data-decimal="254" data-entity="&amp;thorn;" data-id="45237" title="Latin Small Letter Thorn">þ</span>
    <span class="char" data-decimal="240" data-entity="&amp;eth;" data-id="45223" title="Latin Small Letter Eth">ð</span>
    <span class="char" data-decimal="222" data-entity="&amp;THORN;" data-id="45205" title="Latin Capital Letter Thorn">Þ</span>
    <span class="char" data-decimal="181" data-entity="&amp;micro;" data-id="45164" title="Micro Sign">µ</span>
    <span class="char" data-decimal="170" data-entity="&amp;ordf;" data-id="45153" title="Feminine Ordinal Indicator">ª</span>
    <span class="char" data-decimal="42827" data-entity="&amp;#42827;" data-id="45126" title="Latin Small Letter O With Long Stroke Overlay">ꝋ</span>
    <span class="char" data-decimal="42815" data-entity="&amp;#42815;" data-id="45116" title="Latin Small Letter Reversed C With Dot">ꜿ</span>
    <span class="char" data-decimal="42814" data-entity="&amp;#42814;" data-id="45115" title="Latin Capital Letter Reversed C With Dot">Ꜿ</span>
    <span class="char" data-decimal="11389" data-entity="&amp;#11389;" data-id="45100" title="Modifier Letter Capital V">ⱽ</span>
    <span class="char" data-decimal="11386" data-entity="&amp;#11386;" data-id="45097" title="Latin Small Letter O With Low Ring Inside">ⱺ</span>
    <span class="char" data-decimal="11385" data-entity="&amp;#11385;" data-id="45096" title="Latin Small Letter Turned R With Tail">ⱹ</span>
    <span class="char" data-decimal="11383" data-entity="&amp;#11383;" data-id="45094" title="Latin Small Letter Tailless Phi">ⱷ</span>
    <span class="char" data-decimal="11382" data-entity="&amp;#11382;" data-id="45093" title="Latin Small Letter Half H">ⱶ</span>
    <span class="char" data-decimal="11381" data-entity="&amp;#11381;" data-id="45092" title="Latin Capital Letter Half H">Ⱶ</span>
    <span class="char" data-decimal="11380" data-entity="&amp;#11380;" data-id="45091" title="Latin Small Letter V With Curl">ⱴ</span>
    <span class="char" data-decimal="11377" data-entity="&amp;#11377;" data-id="45088" title="Latin Small Letter V With Right Hook">ⱱ</span>
    <span class="char" data-decimal="11376" data-entity="&amp;#11376;" data-id="45087" title="Latin Capital Letter Turned Alpha">Ɒ</span>
    <span class="char" data-decimal="11366" data-entity="&amp;#11366;" data-id="45077" title="Latin Small Letter T With Diagonal Stroke">ⱦ</span>
    <span class="char" data-decimal="566" data-entity="&amp;#566;" data-id="45045" title="Latin Small Letter T With Curl">ȶ</span>
    <span class="char" data-decimal="564" data-entity="&amp;#564;" data-id="45043" title="Latin Small Letter L With Curl">ȴ</span>
    <span class="char" data-decimal="547" data-entity="&amp;#547;" data-id="45026" title="Latin Small Letter Ou">ȣ</span>
    <span class="char" data-decimal="546" data-entity="&amp;#546;" data-id="45025" title="Latin Capital Letter Ou">Ȣ</span>
    <span class="char" data-decimal="545" data-entity="&amp;#545;" data-id="45024" title="Latin Small Letter D With Curl">ȡ</span>
    <span class="char" data-decimal="541" data-entity="&amp;#541;" data-id="45020" title="Latin Small Letter Yogh">ȝ</span>
    <span class="char" data-decimal="540" data-entity="&amp;#540;" data-id="45019" title="Latin Capital Letter Yogh">Ȝ</span>
    <span class="char" data-decimal="539" data-entity="&amp;#539;" data-id="45018" title="Latin Small Letter T With Comma Below">ț</span>
    <span class="char" data-decimal="523" data-entity="&amp;#523;" data-id="45002" title="Latin Small Letter I With Inverted Breve">ȋ</span>
    <span class="char" data-decimal="522" data-entity="&amp;#522;" data-id="45001" title="Latin Capital Letter I With Inverted Breve">Ȋ</span>
    <span class="char" data-decimal="521" data-entity="&amp;#521;" data-id="45000" title="Latin Small Letter I With Double Grave">ȉ</span>
    <span class="char" data-decimal="520" data-entity="&amp;#520;" data-id="44999" title="Latin Capital Letter I With Double Grave">Ȉ</span>
    <span class="char" data-decimal="495" data-entity="&amp;#495;" data-id="44974" title="Latin Small Letter Ezh With Caron">ǯ</span>
    <span class="char" data-decimal="494" data-entity="&amp;#494;" data-id="44973" title="Latin Capital Letter Ezh With Caron">Ǯ</span>
    <span class="char" data-decimal="451" data-entity="&amp;#451;" data-id="44930" title="Latin Letter Retroflex Click">ǃ</span>
    <span class="char" data-decimal="448" data-entity="&amp;#448;" data-id="44927" title="Latin Letter Dental Click">ǀ</span>
    <span class="char" data-decimal="447" data-entity="&amp;#447;" data-id="44926" title="Latin Letter Wynn">ƿ</span>
    <span class="char" data-decimal="446" data-entity="&amp;#446;" data-id="44925" title="Latin Letter Inverted Glottal Stop With Stroke">ƾ</span>
    <span class="char" data-decimal="442" data-entity="&amp;#442;" data-id="44921" title="Latin Small Letter Ezh With Tail">ƺ</span>
    <span class="char" data-decimal="441" data-entity="&amp;#441;" data-id="44920" title="Latin Small Letter Ezh Reversed">ƹ</span>
    <span class="char" data-decimal="440" data-entity="&amp;#440;" data-id="44919" title="Latin Capital Letter Ezh Reversed">Ƹ</span>
    <span class="char" data-decimal="439" data-entity="&amp;#439;" data-id="44918" title="Latin Capital Letter Ezh">Ʒ</span>
    <span class="char" data-decimal="434" data-entity="&amp;#434;" data-id="44913" title="Latin Capital Letter V With Hook">Ʋ</span>
    <span class="char" data-decimal="432" data-entity="&amp;#432;" data-id="44911" title="Latin Small Letter U With Horn">ư</span>
    <span class="char" data-decimal="426" data-entity="&amp;#426;" data-id="44905" title="Latin Letter Reversed Esh Loop">ƪ</span>
    <span class="char" data-decimal="419" data-entity="&amp;#419;" data-id="44898" title="Latin Small Letter Oi">ƣ</span>
    <span class="char" data-decimal="418" data-entity="&amp;#418;" data-id="44897" title="Latin Capital Letter Oi">Ƣ</span>
    <span class="char" data-decimal="415" data-entity="&amp;#415;" data-id="44894" title="Latin Capital Letter O With Middle Tilde">Ɵ</span>
    <span class="char" data-decimal="411" data-entity="&amp;#411;" data-id="44890" title="Latin Small Letter Lambda With Stroke">ƛ</span>
    <span class="char" data-decimal="406" data-entity="&amp;#406;" data-id="44885" title="Latin Capital Letter Iota">Ɩ</span>
    <span class="char" data-decimal="405" data-entity="&amp;#405;" data-id="44884" title="Latin Small Letter Hv">ƕ</span>
    <span class="char" data-decimal="397" data-entity="&amp;#397;" data-id="44876" title="Latin Small Letter Turned Delta">ƍ</span>
    <span class="char" data-decimal="383" data-entity="&amp;#383;" data-id="44768" title="Latin Small Letter Long S">ſ</span>
    <span class="char" data-decimal="7933" data-entity="&amp;#7933;" data-id="44638" title="Latin Small Letter Middle Welsh V">ỽ</span>
    <span class="char" data-decimal="11776" data-entity="&amp;#11776;" data-id="46485" title="Right Angle Substitution Marker">⸀</span>
    <span class="char" data-decimal="11777" data-entity="&amp;#11777;" data-id="46486" title="Right Angle Dotted Substitution Marker">⸁</span>
    <span class="char" data-decimal="11778" data-entity="&amp;#11778;" data-id="46487" title="Left Substitution Bracket">⸂</span>
    <span class="char" data-decimal="11779" data-entity="&amp;#11779;" data-id="46488" title="Right Substitution Bracket">⸃</span>
    <span class="char" data-decimal="11780" data-entity="&amp;#11780;" data-id="46489" title="Left Dotted Substitution Bracket">⸄</span>
    <span class="char" data-decimal="11781" data-entity="&amp;#11781;" data-id="46490" title="Right Dotted Substitution Bracket">⸅</span>
    <span class="char" data-decimal="11782" data-entity="&amp;#11782;" data-id="46491" title="Raised Interpolation Marker">⸆</span>
    <span class="char" data-decimal="11783" data-entity="&amp;#11783;" data-id="46492" title="Raised Dotted Interpolation Marker">⸇</span>
    <span class="char" data-decimal="11784" data-entity="&amp;#11784;" data-id="46493" title="Dotted Transposition Marker">⸈</span>
    <span class="char" data-decimal="11785" data-entity="&amp;#11785;" data-id="46494" title="Left Transposition Bracket">⸉</span>
    <span class="char" data-decimal="11786" data-entity="&amp;#11786;" data-id="46495" title="Right Transposition Bracket">⸊</span>
    <span class="char" data-decimal="11787" data-entity="&amp;#11787;" data-id="46496" title="Raised Square">⸋</span>
    <span class="char" data-decimal="11788" data-entity="&amp;#11788;" data-id="46497" title="Left Raised Omission Bracket">⸌</span>
    <span class="char" data-decimal="11789" data-entity="&amp;#11789;" data-id="46498" title="Right Raised Omission Bracket">⸍</span>
    <span class="char" data-decimal="11790" data-entity="&amp;#11790;" data-id="46499" title="Editorial Coronis">⸎</span>
    <span class="char" data-decimal="11791" data-entity="&amp;#11791;" data-id="46500" title="Paragraphos">⸏</span>
    <span class="char" data-decimal="11792" data-entity="&amp;#11792;" data-id="46501" title="Forked Paragraphos">⸐</span>
    <span class="char" data-decimal="11793" data-entity="&amp;#11793;" data-id="46502" title="Reversed Forked Paragraphos">⸑</span>
    <span class="char" data-decimal="11794" data-entity="&amp;#11794;" data-id="46503" title="Hypodiastole">⸒</span>
    <span class="char" data-decimal="11796" data-entity="&amp;#11796;" data-id="46505" title="Downwards Ancora">⸔</span>
    <span class="char" data-decimal="11797" data-entity="&amp;#11797;" data-id="46506" title="Upwards Ancora">⸕</span>
    <span class="char" data-decimal="9650" data-entity="&amp;#9650;" data-id="43941" title="Black Up Pointing Triangle">▲</span>
    <span class="char" data-decimal="9660" data-entity="&amp;#9660;" data-id="43951" title="Black Down Pointing Triangle">▼</span>
    <span class="char" data-decimal="9664" data-entity="&amp;#9664;" data-id="43955" title="Black Left Pointing Triangle">◀</span>
    <span class="char" data-decimal="9654" data-entity="&amp;#9654;" data-id="43945" title="Black Right Pointing Triangle">▶</span>
    <span class="char" data-decimal="9698" data-entity="&amp;#9698;" data-id="43989" title="Black Lower Right Triangle">◢</span>
    <span class="char" data-decimal="9699" data-entity="&amp;#9699;" data-id="43990" title="Black Lower Left Triangle">◣</span>
    <span class="char" data-decimal="9701" data-entity="&amp;#9701;" data-id="43992" title="Black Upper Right Triangle">◥</span>
    <span class="char" data-decimal="9700" data-entity="&amp;#9700;" data-id="43991" title="Black Upper Left Triangle">◤</span>
    <span class="char" data-decimal="9651" data-entity="&amp;#9651;" data-id="43942" title="White Up Pointing Triangle">△</span>
    <span class="char" data-decimal="9661" data-entity="&amp;#9661;" data-id="43952" title="White Down Pointing Triangle">▽</span>
    <span class="char" data-decimal="9727" data-entity="&amp;#9727;" data-id="44018" title="Lower Right Triangle">◿</span>
    <span class="char" data-decimal="9722" data-entity="&amp;#9722;" data-id="44013" title="Lower Left Triangle">◺</span>
    <span class="char" data-decimal="9721" data-entity="&amp;#9721;" data-id="44012" title="Upper Right Triangle">◹</span>
    <span class="char" data-decimal="9720" data-entity="&amp;#9720;" data-id="44011" title="Upper Left Triangle">◸</span>
    <span class="char" data-decimal="9652" data-entity="&amp;#9652;" data-id="43943" title="Black Up Pointing Small Triangle">▴</span>
    <span class="char" data-decimal="9662" data-entity="&amp;#9662;" data-id="43953" title="Black Down Pointing Small Triangle">▾</span>
    <span class="char" data-decimal="9666" data-entity="&amp;#9666;" data-id="43957" title="Black Left Pointing Small Triangle">◂</span>
    <span class="char" data-decimal="9656" data-entity="&amp;#9656;" data-id="43947" title="Black Right Pointing Small Triangle">▸</span>
    <span class="char" data-decimal="9653" data-entity="&amp;#9653;" data-id="43944" title="White Up Pointing Small Triangle">▵</span>
    <span class="char" data-decimal="9663" data-entity="&amp;#9663;" data-id="43954" title="White Down Pointing Small Triangle">▿</span>
    <span class="char" data-decimal="9667" data-entity="&amp;#9667;" data-id="43958" title="White Left Pointing Small Triangle">◃</span>
    <span class="char" data-decimal="9657" data-entity="&amp;#9657;" data-id="43948" title="White Right Pointing Small Triangle">▹</span>
    <span class="char" data-decimal="9665" data-entity="&amp;#9665;" data-id="43956" title="White Left Pointing Triangle">◁</span>
    <span class="char" data-decimal="9655" data-entity="&amp;#9655;" data-id="43946" title="White Right Pointing Triangle">▷</span>
    <span class="char" data-decimal="9669" data-entity="&amp;#9669;" data-id="43960" title="White Left Pointing Pointer">◅</span>
    <span class="char" data-decimal="9659" data-entity="&amp;#9659;" data-id="43950" title="White Right Pointing Pointer">▻</span>
    <span class="char" data-decimal="9708" data-entity="&amp;#9708;" data-id="43999" title="White Up Pointing Triangle With Dot">◬</span>
    <span class="char" data-decimal="10177" data-entity="&amp;#10177;" data-id="45317" title="White Triangle Containing Small White Triangle">⟁</span>
    <span class="char" data-decimal="10699" data-entity="&amp;#10699;" data-id="45428" title="Triangle With Underbar">⧋</span>
    <span class="char" data-decimal="10698" data-entity="&amp;#10698;" data-id="45427" title="Triangle With Dot Above">⧊</span>
    <span class="char" data-decimal="8895" data-entity="&amp;#8895;" data-id="45671" title="Right Triangle">⊿</span>
    <span class="char" data-decimal="8710" data-entity="&amp;#8710;" data-id="45486" title="Increment">∆</span>
    <span class="char" data-decimal="8711" data-entity="&amp;nabla;" data-id="45487" title="Nabla">∇</span>
    <span class="char" data-decimal="9709" data-entity="&amp;#9709;" data-id="44000" title="Up Pointing Triangle With Left Half Black">◭</span>
    <span class="char" data-decimal="9710" data-entity="&amp;#9710;" data-id="44001" title="Up Pointing Triangle With Right Half Black">◮</span>
    <span class="char" data-decimal="10729" data-entity="&amp;#10729;" data-id="45457" title="Down Pointing Triangle With Right Half Black">⧩</span>
    <span class="char" data-decimal="10728" data-entity="&amp;#10728;" data-id="45456" title="Down Pointing Triangle With Left Half Black">⧨</span>
    <span class="char" data-decimal="8980" data-entity="&amp;#8980;" data-id="45962" title="Sector">⌔</span>
    <span class="char" data-decimal="10192" data-entity="&amp;#10192;" data-id="45325" title="White Diamond With Centred Dot">⟐</span>
    <span class="char" data-decimal="9671" data-entity="&amp;#9671;" data-id="43962" title="White Diamond">◇</span>
    <span class="char" data-decimal="9670" data-entity="&amp;#9670;" data-id="43961" title="Black Diamond">◆</span>
    <span class="char" data-decimal="9672" data-entity="&amp;#9672;" data-id="43963" title="White Diamond Containing Black Small Diamond">◈</span>
    <span class="char" data-decimal="11030" data-entity="&amp;#11030;" data-id="45933" title="Diamond With Left Half Black">⬖</span>
    <span class="char" data-decimal="11031" data-entity="&amp;#11031;" data-id="45934" title="Diamond With Right Half Black">⬗</span>
    <span class="char" data-decimal="11032" data-entity="&amp;#11032;" data-id="45935" title="Diamond With Top Half Black">⬘</span>
    <span class="char" data-decimal="11033" data-entity="&amp;#11033;" data-id="45936" title="Diamond With Bottom Half Black">⬙</span>
    <span class="char" data-decimal="11040" data-entity="&amp;#11040;" data-id="45938" title="White Pentagon">⬠</span>
    <span class="char" data-decimal="11041" data-entity="&amp;#11041;" data-id="45939" title="White Hexagon">⬡</span>
    <span class="char" data-decimal="9108" data-entity="&amp;#9108;" data-id="46090" title="Software Function Symbol">⎔</span>
    <span class="char" data-decimal="8900" data-entity="&amp;#8900;" data-id="45676" title="Diamond Operator">⋄</span>
    <span class="char" data-decimal="9674" data-entity="&amp;loz;" data-id="43965" title="Lozenge">◊</span>
    <span class="char" data-decimal="10731" data-entity="&amp;#10731;" data-id="45459" title="Black Lozenge">⧫</span>
    <span class="char" data-decimal="11042" data-entity="&amp;#11042;" data-id="45940" title="Black Hexagon">⬢</span>
    <span class="char" data-decimal="11043" data-entity="&amp;#11043;" data-id="45941" title="Horizontal Black Hexagon">⬣</span>
    <span class="char" data-decimal="9648" data-entity="&amp;#9648;" data-id="43939" title="Black Parallelogram">▰</span>
    <span class="char" data-decimal="9642" data-entity="&amp;#9642;" data-id="43933" title="Black Small Square">▪</span>
    <span class="char" data-decimal="9724" data-entity="&amp;#9724;" data-id="44015" title="Black Medium Square">◼</span>
    <span class="char" data-decimal="9646" data-entity="&amp;#9646;" data-id="43937" title="Black Vertical Rectangle">▮</span>
    <span class="char" data-decimal="9726" data-entity="&amp;#9726;" data-id="44017" title="Black Medium Small Square">◾</span>
    <span class="char" data-decimal="9623" data-entity="&amp;#9623;" data-id="43557" title="Quadrant Lower Right">▗</span>
    <span class="char" data-decimal="9622" data-entity="&amp;#9622;" data-id="43556" title="Quadrant Lower Left">▖</span>
    <span class="char" data-decimal="9632" data-entity="&amp;#9632;" data-id="43923" title="Black Square">■</span>
    <span class="char" data-decimal="8718" data-entity="&amp;#8718;" data-id="45494" title="End Of Proof">∎</span>
    <span class="char" data-decimal="9603" data-entity="&amp;#9603;" data-id="43537" title="Lower Three Eighths Block">▃</span>
    <span class="char" data-decimal="9604" data-entity="&amp;#9604;" data-id="43538" title="Lower Half Block">▄</span>
    <span class="char" data-decimal="9605" data-entity="&amp;#9605;" data-id="43539" title="Lower Five Eighths Block">▅</span>
    <span class="char" data-decimal="9606" data-entity="&amp;#9606;" data-id="43540" title="Lower Three Quarters Block">▆</span>
    <span class="char" data-decimal="9607" data-entity="&amp;#9607;" data-id="43541" title="Lower Seven Eighths Block">▇</span>
    <span class="char" data-decimal="9608" data-entity="&amp;#9608;" data-id="43542" title="Full Block">█</span>
    <span class="char" data-decimal="9612" data-entity="&amp;#9612;" data-id="43546" title="Left Half Block">▌</span>
    <span class="char" data-decimal="9616" data-entity="&amp;#9616;" data-id="43550" title="Right Half Block">▐</span>
    <span class="char" data-decimal="9613" data-entity="&amp;#9613;" data-id="43547" title="Left Three Eighths Block">▍</span>
    <span class="char" data-decimal="9614" data-entity="&amp;#9614;" data-id="43548" title="Left One Quarter Block">▎</span>
    <span class="char" data-decimal="9609" data-entity="&amp;#9609;" data-id="43543" title="Left Seven Eighths Block">▉</span>
    <span class="char" data-decimal="9610" data-entity="&amp;#9610;" data-id="43544" title="Left Three Quarters Block">▊</span>
    <span class="char" data-decimal="9611" data-entity="&amp;#9611;" data-id="43545" title="Left Five Eighths Block">▋</span>
    <span class="char" data-decimal="10072" data-entity="&amp;#10072;" data-id="43666" title="Light Vertical Bar">❘</span>
    <span class="char" data-decimal="10073" data-entity="&amp;#10073;" data-id="43667" title="Medium Vertical Bar">❙</span>
    <span class="char" data-decimal="10074" data-entity="&amp;#10074;" data-id="43668" title="Heavy Vertical Bar">❚</span>
    <span class="char" data-decimal="9600" data-entity="&amp;#9600;" data-id="43534" title="Upper Half Block">▀</span>
    <span class="char" data-decimal="9624" data-entity="&amp;#9624;" data-id="43558" title="Quadrant Upper Left">▘</span>
    <span class="char" data-decimal="9629" data-entity="&amp;#9629;" data-id="43563" title="Quadrant Upper Right">▝</span>
    <span class="char" data-decimal="9625" data-entity="&amp;#9625;" data-id="43559" title="Quadrant Upper Left And Lower Left And Lower Right">▙</span>
    <span class="char" data-decimal="9626" data-entity="&amp;#9626;" data-id="43560" title="Quadrant Upper Left And Lower Right">▚</span>
    <span class="char" data-decimal="9627" data-entity="&amp;#9627;" data-id="43561" title="Quadrant Upper Left And Upper Right And Lower Left">▛</span>
    <span class="char" data-decimal="9628" data-entity="&amp;#9628;" data-id="43562" title="Quadrant Upper Left And Upper Right And Lower Right">▜</span>
    <span class="char" data-decimal="9631" data-entity="&amp;#9631;" data-id="43565" title="Quadrant Upper Right And Lower Left And Lower Right">▟</span>
    <span class="char" data-decimal="9630" data-entity="&amp;#9630;" data-id="43564" title="Quadrant Upper Right And Lower Left">▞</span>
    <span class="char" data-decimal="9617" data-entity="&amp;#9617;" data-id="43551" title="Light Shade">░</span>
    <span class="char" data-decimal="9618" data-entity="&amp;#9618;" data-id="43552" title="Medium Shade">▒</span>
    <span class="char" data-decimal="9619" data-entity="&amp;#9619;" data-id="43553" title="Dark Shade">▓</span>
    <span class="char" data-decimal="9602" data-entity="&amp;#9602;" data-id="43536" title="Lower One Quarter Block">▂</span>
    <span class="char" data-decimal="9601" data-entity="&amp;#9601;" data-id="43535" title="Lower One Eighth Block">▁</span>
    <span class="char" data-decimal="9644" data-entity="&amp;#9644;" data-id="43935" title="Black Rectangle">▬</span>
    <span class="char" data-decimal="9620" data-entity="&amp;#9620;" data-id="43554" title="Upper One Eighth Block">▔</span>
    <span class="char" data-decimal="9643" data-entity="&amp;#9643;" data-id="43934" title="White Small Square">▫</span>
    <span class="char" data-decimal="9647" data-entity="&amp;#9647;" data-id="43938" title="White Vertical Rectangle">▯</span>
    <span class="char" data-decimal="9645" data-entity="&amp;#9645;" data-id="43936" title="White Rectangle">▭</span>
    <span class="char" data-decimal="9649" data-entity="&amp;#9649;" data-id="43940" title="White Parallelogram">▱</span>
    <span class="char" data-decimal="9725" data-entity="&amp;#9725;" data-id="44016" title="White Medium Small Square">◽</span>
    <span class="char" data-decimal="9633" data-entity="&amp;#9633;" data-id="43924" title="White Square">□</span>
    <span class="char" data-decimal="9723" data-entity="&amp;#9723;" data-id="44014" title="White Medium Square">◻</span>
    <span class="char" data-decimal="9634" data-entity="&amp;#9634;" data-id="43925" title="White Square With Rounded Corners">▢</span>
    <span class="char" data-decimal="8862" data-entity="&amp;#8862;" data-id="45638" title="Squared Plus">⊞</span>
    <span class="char" data-decimal="8865" data-entity="&amp;#8865;" data-id="45641" title="Squared Dot Operator">⊡</span>
    <span class="char" data-decimal="8863" data-entity="&amp;#8863;" data-id="45639" title="Squared Minus">⊟</span>
    <span class="char" data-decimal="8864" data-entity="&amp;#8864;" data-id="45640" title="Squared Times">⊠</span>
    <span class="char" data-decimal="9635" data-entity="&amp;#9635;" data-id="43926" title="White Square Containing Black Small Square">▣</span>
    <span class="char" data-decimal="9636" data-entity="&amp;#9636;" data-id="43927" title="Square With Horizontal Fill">▤</span>
    <span class="char" data-decimal="9637" data-entity="&amp;#9637;" data-id="43928" title="Square With Vertical Fill">▥</span>
    <span class="char" data-decimal="9638" data-entity="&amp;#9638;" data-id="43929" title="Square With Orthogonal Crosshatch Fill">▦</span>
    <span class="char" data-decimal="11034" data-entity="&amp;#11034;" data-id="45937" title="Dotted Square">⬚</span>
    <span class="char" data-decimal="9639" data-entity="&amp;#9639;" data-id="43930" title="Square With Upper Left To Lower Right Fill">▧</span>
    <span class="char" data-decimal="9640" data-entity="&amp;#9640;" data-id="43931" title="Square With Upper Right To Lower Left Fill">▨</span>
    <span class="char" data-decimal="9641" data-entity="&amp;#9641;" data-id="43932" title="Square With Diagonal Crosshatch Fill">▩</span>
    <span class="char" data-decimal="11027" data-entity="&amp;#11027;" data-id="45930" title="Square With Bottom Half Black">⬓</span>
    <span class="char" data-decimal="9703" data-entity="&amp;#9703;" data-id="43994" title="Square With Left Half Black">◧</span>
    <span class="char" data-decimal="11026" data-entity="&amp;#11026;" data-id="45929" title="Square With Top Half Black">⬒</span>
    <span class="char" data-decimal="9704" data-entity="&amp;#9704;" data-id="43995" title="Square With Right Half Black">◨</span>
    <span class="char" data-decimal="9705" data-entity="&amp;#9705;" data-id="43996" title="Square With Upper Left Diagonal Half Black">◩</span>
    <span class="char" data-decimal="9706" data-entity="&amp;#9706;" data-id="43997" title="Square With Lower Right Diagonal Half Black">◪</span>
    <span class="char" data-decimal="11028" data-entity="&amp;#11028;" data-id="45931" title="Square With Upper Right Diagonal Half Black">⬔</span>
    <span class="char" data-decimal="11029" data-entity="&amp;#11029;" data-id="45932" title="Square With Lower Left Diagonal Half Black">⬕</span>
    <span class="char" data-decimal="10063" data-entity="&amp;#10063;" data-id="43660" title="Lower Right Drop Shadowed White Square">❏</span>
    <span class="char" data-decimal="10064" data-entity="&amp;#10064;" data-id="43661" title="Upper Right Drop Shadowed White Square">❐</span>
    <span class="char" data-decimal="10065" data-entity="&amp;#10065;" data-id="43662" title="Lower Right Shadowed White Square">❑</span>
    <span class="char" data-decimal="10066" data-entity="&amp;#10066;" data-id="43663" title="Upper Right Shadowed White Square">❒</span>
    <span class="char" data-decimal="10696" data-entity="&amp;#10696;" data-id="45425" title="Squared Square">⧈</span>
    <span class="char" data-decimal="9712" data-entity="&amp;#9712;" data-id="44003" title="White Square With Upper Left Quadrant">◰</span>
    <span class="char" data-decimal="9713" data-entity="&amp;#9713;" data-id="44004" title="White Square With Lower Left Quadrant">◱</span>
    <span class="char" data-decimal="9715" data-entity="&amp;#9715;" data-id="44006" title="White Square With Upper Right Quadrant">◳</span>
    <span class="char" data-decimal="9714" data-entity="&amp;#9714;" data-id="44005" title="White Square With Lower Right Quadrant">◲</span>
    <span class="char" data-decimal="9707" data-entity="&amp;#9707;" data-id="43998" title="White Square With Vertical Bisecting Line">◫</span>
    <span class="char" data-decimal="10695" data-entity="&amp;#10695;" data-id="45424" title="Squared Small Circle">⧇</span>
    <span class="char" data-decimal="10693" data-entity="&amp;#10693;" data-id="45422" title="Squared Falling Diagonal Slash">⧅</span>
    <span class="char" data-decimal="10692" data-entity="&amp;#10692;" data-id="45421" title="Squared Rising Diagonal Slash">⧄</span>
    <span class="char" data-decimal="9025" data-entity="&amp;#9025;" data-id="46007" title="Apl Functional Symbol Quad Slash">⍁</span>
    <span class="char" data-decimal="9026" data-entity="&amp;#9026;" data-id="46008" title="Apl Functional Symbol Quad Backslash">⍂</span>
    <span class="char" data-decimal="10209" data-entity="&amp;#10209;" data-id="45342" title="White Concave Sided Diamond">⟡</span>
    <span class="char" data-decimal="10697" data-entity="&amp;#10697;" data-id="45426" title="Two Joined Squares">⧉</span>
    <span class="char" data-decimal="9900" data-entity="&amp;#9900;" data-id="45904" title="Medium Small White Circle">⚬</span>
    <span class="char" data-decimal="9675" data-entity="&amp;#9675;" data-id="43966" title="White Circle">○</span>
    <span class="char" data-decimal="9898" data-entity="&amp;#9898;" data-id="45902" title="Medium White Circle">⚪</span>
    <span class="char" data-decimal="9676" data-entity="&amp;#9676;" data-id="43967" title="Dotted Circle">◌</span>
    <span class="char" data-decimal="9677" data-entity="&amp;#9677;" data-id="43968" title="Circle With Vertical Fill">◍</span>
    <span class="char" data-decimal="9678" data-entity="&amp;#9678;" data-id="43969" title="Bullseye">◎</span>
    <span class="char" data-decimal="9711" data-entity="&amp;#9711;" data-id="44002" title="Large Circle">◯</span>
    <span class="char" data-decimal="10061" data-entity="&amp;#10061;" data-id="43659" title="Shadowed White Circle">❍</span>
    <span class="char" data-decimal="9673" data-entity="&amp;#9673;" data-id="43964" title="Fisheye">◉</span>
    <span class="char" data-decimal="10686" data-entity="&amp;#10686;" data-id="45415" title="Circled White Bullet">⦾</span>
    <span class="char" data-decimal="8857" data-entity="&amp;#8857;" data-id="45633" title="Circled Dot Operator">⊙</span>
    <span class="char" data-decimal="10687" data-entity="&amp;#10687;" data-id="45416" title="Circled Bullet">⦿</span>
    <span class="char" data-decimal="8860" data-entity="&amp;#8860;" data-id="45636" title="Circled Equals">⊜</span>
    <span class="char" data-decimal="8854" data-entity="&amp;#8854;" data-id="45630" title="Circled Minus">⊖</span>
    <span class="char" data-decimal="8856" data-entity="&amp;#8856;" data-id="45632" title="Circled Division Slash">⊘</span>
    <span class="char" data-decimal="8858" data-entity="&amp;#8858;" data-id="45634" title="Circled Ring Operator">⊚</span>
    <span class="char" data-decimal="8859" data-entity="&amp;#8859;" data-id="45635" title="Circled Asterisk Operator">⊛</span>
    <span class="char" data-decimal="8861" data-entity="&amp;#8861;" data-id="45637" title="Circled Dash">⊝</span>
    <span class="char" data-decimal="9679" data-entity="&amp;#9679;" data-id="43970" title="Black Circle">●</span>
    <span class="char" data-decimal="9899" data-entity="&amp;#9899;" data-id="45903" title="Medium Black Circle">⚫</span>
    <span class="char" data-decimal="10625" data-entity="&amp;#10625;" data-id="45354" title="Z Notation Spot">⦁</span>
    <span class="char" data-decimal="9680" data-entity="&amp;#9680;" data-id="43971" title="Circle With Left Half Black">◐</span>
    <span class="char" data-decimal="9681" data-entity="&amp;#9681;" data-id="43972" title="Circle With Right Half Black">◑</span>
    <span class="char" data-decimal="9682" data-entity="&amp;#9682;" data-id="43973" title="Circle With Lower Half Black">◒</span>
    <span class="char" data-decimal="9683" data-entity="&amp;#9683;" data-id="43974" title="Circle With Upper Half Black">◓</span>
    <span class="char" data-decimal="9684" data-entity="&amp;#9684;" data-id="43975" title="Circle With Upper Right Quadrant Black">◔</span>
    <span class="char" data-decimal="9685" data-entity="&amp;#9685;" data-id="43976" title="Circle With All But Upper Left Quadrant Black">◕</span>
    <span class="char" data-decimal="10678" data-entity="&amp;#10678;" data-id="45407" title="Circled Vertical Bar">⦶</span>
    <span class="char" data-decimal="10680" data-entity="&amp;#10680;" data-id="45409" title="Circled Reverse Solidus">⦸</span>
    <span class="char" data-decimal="9717" data-entity="&amp;#9717;" data-id="44008" title="White Circle With Lower Left Quadrant">◵</span>
    <span class="char" data-decimal="9716" data-entity="&amp;#9716;" data-id="44007" title="White Circle With Upper Left Quadrant">◴</span>
    <span class="char" data-decimal="9718" data-entity="&amp;#9718;" data-id="44009" title="White Circle With Lower Right Quadrant">◶</span>
    <span class="char" data-decimal="9719" data-entity="&amp;#9719;" data-id="44010" title="White Circle With Upper Right Quadrant">◷</span>
    <span class="char" data-decimal="8853" data-entity="&amp;oplus;" data-id="45629" title="Circled Plus">⊕</span>
    <span class="char" data-decimal="8855" data-entity="&amp;otimes;" data-id="45631" title="Circled Times">⊗</span>
    <span class="char" data-decimal="10631" data-entity="&amp;#10631;" data-id="45360" title="Z Notation Left Image Bracket">⦇</span>
    <span class="char" data-decimal="10632" data-entity="&amp;#10632;" data-id="45361" title="Z Notation Right Image Bracket">⦈</span>
    <span class="char" data-decimal="10633" data-entity="&amp;#10633;" data-id="45362" title="Z Notation Left Binding Bracket">⦉</span>
    <span class="char" data-decimal="10634" data-entity="&amp;#10634;" data-id="45363" title="Z Notation Right Binding Bracket">⦊</span>
    <span class="char" data-decimal="10088" data-entity="&amp;#10088;" data-id="43680" title="Medium Left Parenthesis Ornament">❨</span>
    <span class="char" data-decimal="10089" data-entity="&amp;#10089;" data-id="43681" title="Medium Right Parenthesis Ornament">❩</span>
    <span class="char" data-decimal="11816" data-entity="&amp;#11816;" data-id="46524" title="Left Double Parenthesis">⸨</span>
    <span class="char" data-decimal="11817" data-entity="&amp;#11817;" data-id="46525" title="Right Double Parenthesis">⸩</span>
    <span class="char" data-decimal="9686" data-entity="&amp;#9686;" data-id="43977" title="Left Half Black Circle">◖</span>
    <span class="char" data-decimal="9687" data-entity="&amp;#9687;" data-id="43978" title="Right Half Black Circle">◗</span>
    <span class="char" data-decimal="10090" data-entity="&amp;#10090;" data-id="43682" title="Medium Flattened Left Parenthesis Ornament">❪</span>
    <span class="char" data-decimal="10091" data-entity="&amp;#10091;" data-id="43683" title="Medium Flattened Right Parenthesis Ornament">❫</span>
    <span class="char" data-decimal="10094" data-entity="&amp;#10094;" data-id="43686" title="Heavy Left Pointing Angle Quotation Mark Ornament">❮</span>
    <span class="char" data-decimal="10095" data-entity="&amp;#10095;" data-id="43687" title="Heavy Right Pointing Angle Quotation Mark Ornament">❯</span>
    <span class="char" data-decimal="10092" data-entity="&amp;#10092;" data-id="43684" title="Medium Left Pointing Angle Bracket Ornament">❬</span>
    <span class="char" data-decimal="10093" data-entity="&amp;#10093;" data-id="43685" title="Medium Right Pointing Angle Bracket Ornament">❭</span>
    <span class="char" data-decimal="10096" data-entity="&amp;#10096;" data-id="43688" title="Heavy Left Pointing Angle Bracket Ornament">❰</span>
    <span class="char" data-decimal="10097" data-entity="&amp;#10097;" data-id="43689" title="Heavy Right Pointing Angle Bracket Ornament">❱</span>
    <span class="char" data-decimal="8847" data-entity="&amp;#8847;" data-id="45623" title="Square Image Of">⊏</span>
    <span class="char" data-decimal="8848" data-entity="&amp;#8848;" data-id="45624" title="Square Original Of">⊐</span>
    <span class="char" data-decimal="8849" data-entity="&amp;#8849;" data-id="45625" title="Square Image Of Or Equal To">⊑</span>
    <span class="char" data-decimal="8850" data-entity="&amp;#8850;" data-id="45626" title="Square Original Of Or Equal To">⊒</span>
    <span class="char" data-decimal="9688" data-entity="&amp;#9688;" data-id="43979" title="Inverse Bullet">◘</span>
    <span class="char" data-decimal="9689" data-entity="&amp;#9689;" data-id="43980" title="Inverse White Circle">◙</span>
    <span class="char" data-decimal="9690" data-entity="&amp;#9690;" data-id="43981" title="Upper Half Inverse White Circle">◚</span>
    <span class="char" data-decimal="9691" data-entity="&amp;#9691;" data-id="43982" title="Lower Half Inverse White Circle">◛</span>
    <span class="char" data-decimal="9692" data-entity="&amp;#9692;" data-id="43983" title="Upper Left Quadrant Circular Arc">◜</span>
    <span class="char" data-decimal="9693" data-entity="&amp;#9693;" data-id="43984" title="Upper Right Quadrant Circular Arc">◝</span>
    <span class="char" data-decimal="9694" data-entity="&amp;#9694;" data-id="43985" title="Lower Right Quadrant Circular Arc">◞</span>
    <span class="char" data-decimal="9695" data-entity="&amp;#9695;" data-id="43986" title="Lower Left Quadrant Circular Arc">◟</span>
    <span class="char" data-decimal="9696" data-entity="&amp;#9696;" data-id="43987" title="Upper Half Circle">◠</span>
    <span class="char" data-decimal="9697" data-entity="&amp;#9697;" data-id="43988" title="Lower Half Circle">◡</span>
    <span class="char" data-decimal="8914" data-entity="&amp;#8914;" data-id="45690" title="Double Intersection">⋒</span>
    <span class="char" data-decimal="8915" data-entity="&amp;#8915;" data-id="45691" title="Double Union">⋓</span>
    <span class="char" data-decimal="8912" data-entity="&amp;#8912;" data-id="45688" title="Double Subset">⋐</span>
    <span class="char" data-decimal="8913" data-entity="&amp;#8913;" data-id="45689" title="Double Superset">⋑</span>
    <span class="char" data-decimal="9584" data-entity="&amp;#9584;" data-id="43518" title="Box Drawings Light Arc Up And Right">╰</span>
    <span class="char" data-decimal="9582" data-entity="&amp;#9582;" data-id="43516" title="Box Drawings Light Arc Down And Left">╮</span>
    <span class="char" data-decimal="9581" data-entity="&amp;#9581;" data-id="43515" title="Box Drawings Light Arc Down And Right">╭</span>
    <span class="char" data-decimal="9583" data-entity="&amp;#9583;" data-id="43517" title="Box Drawings Light Arc Up And Left">╯</span>
    <span class="char" data-decimal="8978" data-entity="&amp;#8978;" data-id="45960" title="Arc">⌒</span>
    <span class="char" data-decimal="9587" data-entity="&amp;#9587;" data-id="43521" title="Box Drawings Light Diagonal Cross">╳</span>
    <span class="char" data-decimal="10005" data-entity="&amp;#10005;" data-id="43605" title="Multiplication X">✕</span>
    <span class="char" data-decimal="9585" data-entity="&amp;#9585;" data-id="43519" title="Box Drawings Light Diagonal Upper Right To Lower Left">╱</span>
    <span class="char" data-decimal="9586" data-entity="&amp;#9586;" data-id="43520" title="Box Drawings Light Diagonal Upper Left To Lower Right">╲</span>
    <span class="char" data-decimal="10744" data-entity="&amp;#10744;" data-id="45472" title="Big Solidus">⧸</span>
    <span class="char" data-decimal="10745" data-entity="&amp;#10745;" data-id="45473" title="Big Reverse Solidus">⧹</span>
    <span class="char" data-decimal="8979" data-entity="&amp;#8979;" data-id="45961" title="Segment">⌓</span>
    <span class="char" data-decimal="9702" data-entity="&amp;#9702;" data-id="43993" title="White Bullet">◦</span>
    <span class="char" data-decimal="10070" data-entity="&amp;#10070;" data-id="43664" title="Black Diamond Minus White X">❖</span>
    <span class="char" data-decimal="10006" data-entity="&amp;#10006;" data-id="43606" title="Heavy Multiplication X">✖</span>
    <span class="char" data-decimal="10010" data-entity="&amp;#10010;" data-id="43610" title="Heavy Greek Cross">✚</span>
    <span class="char" data-decimal="10012" data-entity="&amp;#10012;" data-id="43612" title="Heavy Open Centre Cross">✜</span>
    <span class="char" data-decimal="10707" data-entity="&amp;#10707;" data-id="45436" title="Black Bowtie">⧓</span>
    <span class="char" data-decimal="10711" data-entity="&amp;#10711;" data-id="45440" title="Black Hourglass">⧗</span>
    <span class="char" data-decimal="10705" data-entity="&amp;#10705;" data-id="45434" title="Bowtie With Left Half Black">⧑</span>
    <span class="char" data-decimal="10706" data-entity="&amp;#10706;" data-id="45435" title="Bowtie With Right Half Black">⧒</span>
    <span class="char" data-decimal="10710" data-entity="&amp;#10710;" data-id="45439" title="White Hourglass">⧖</span>
    <span class="char" data-decimal="95" data-entity="&amp;#95;" data-id="44831" title="Low Line">_</span>
    <span class="char" data-decimal="9866" data-entity="&amp;#9866;" data-id="45873" title="Monogram For Yang">⚊</span>
    <span class="char" data-decimal="9588" data-entity="&amp;#9588;" data-id="43522" title="Box Drawings Light Left">╴</span>
    <span class="char" data-decimal="9596" data-entity="&amp;#9596;" data-id="43530" title="Box Drawings Light Left And Heavy Right">╼</span>
    <span class="char" data-decimal="9598" data-entity="&amp;#9598;" data-id="43532" title="Box Drawings Heavy Left And Light Right">╾</span>
    <span class="char" data-decimal="8208" data-entity="&amp;#8208;" data-id="46406" title="Hyphen">‐</span>
    <span class="char" data-decimal="8259" data-entity="&amp;#8259;" data-id="46450" title="Hyphen Bullet">⁃</span>
    <span class="char" data-decimal="8209" data-entity="&amp;#8209;" data-id="46407" title="Non Breaking Hyphen">‑</span>
    <span class="char" data-decimal="8210" data-entity="&amp;#8210;" data-id="46408" title="Figure Dash">‒</span>
    <span class="char" data-decimal="45" data-entity="&amp;#45;" data-id="44781" title="Hyphen Minus">-</span>
    <span class="char" data-decimal="8211" data-entity="&amp;#8211;" data-id="46409" title="En Dash">–</span>
    <span class="char" data-decimal="9135" data-entity="&amp;#9135;" data-id="46117" title="Horizontal Line Extension">⎯</span>
    <span class="char" data-decimal="8212" data-entity="&amp;#8212;" data-id="46410" title="Em Dash">—</span>
    <span class="char" data-decimal="8213" data-entity="&amp;#8213;" data-id="46411" title="Horizontal Bar">―</span>
    <span class="char" data-decimal="9590" data-entity="&amp;#9590;" data-id="43524" title="Box Drawings Light Right">╶</span>
    <span class="char" data-decimal="9594" data-entity="&amp;#9594;" data-id="43528" title="Box Drawings Heavy Right">╺</span>
    <span class="char" data-decimal="9592" data-entity="&amp;#9592;" data-id="43526" title="Box Drawings Heavy Left">╸</span>
    <span class="char" data-decimal="9472" data-entity="&amp;#9472;" data-id="43406" title="Box Drawings Light Horizontal">─</span>
    <span class="char" data-decimal="9473" data-entity="&amp;#9473;" data-id="43407" title="Box Drawings Heavy Horizontal">━</span>
    <span class="char" data-decimal="9476" data-entity="&amp;#9476;" data-id="43410" title="Box Drawings Light Triple Dash Horizontal">┄</span>
    <span class="char" data-decimal="9477" data-entity="&amp;#9477;" data-id="43411" title="Box Drawings Heavy Triple Dash Horizontal">┅</span>
    <span class="char" data-decimal="9480" data-entity="&amp;#9480;" data-id="43414" title="Box Drawings Light Quadruple Dash Horizontal">┈</span>
    <span class="char" data-decimal="9481" data-entity="&amp;#9481;" data-id="43415" title="Box Drawings Heavy Quadruple Dash Horizontal">┉</span>
    <span class="char" data-decimal="9548" data-entity="&amp;#9548;" data-id="43482" title="Box Drawings Light Double Dash Horizontal">╌</span>
    <span class="char" data-decimal="9549" data-entity="&amp;#9549;" data-id="43483" title="Box Drawings Heavy Double Dash Horizontal">╍</span>
    <span class="char" data-decimal="9552" data-entity="&amp;#9552;" data-id="43486" title="Box Drawings Double Horizontal">═</span>
    <span class="char" data-decimal="8803" data-entity="&amp;#8803;" data-id="45579" title="Strictly Equivalent To">≣</span>
    <span class="char" data-decimal="8801" data-entity="&amp;equiv;" data-id="45577" title="Identical To">≡</span>
    <span class="char" data-decimal="9776" data-entity="&amp;#9776;" data-id="45783" title="Trigram For Heaven">☰</span>
    <span class="char" data-decimal="9777" data-entity="&amp;#9777;" data-id="45784" title="Trigram For Lake">☱</span>
    <span class="char" data-decimal="9778" data-entity="&amp;#9778;" data-id="45785" title="Trigram For Fire">☲</span>
    <span class="char" data-decimal="9779" data-entity="&amp;#9779;" data-id="45786" title="Trigram For Thunder">☳</span>
    <span class="char" data-decimal="9780" data-entity="&amp;#9780;" data-id="45787" title="Trigram For Wind">☴</span>
    <span class="char" data-decimal="9781" data-entity="&amp;#9781;" data-id="45788" title="Trigram For Water">☵</span>
    <span class="char" data-decimal="9782" data-entity="&amp;#9782;" data-id="45789" title="Trigram For Mountain">☶</span>
    <span class="char" data-decimal="9783" data-entity="&amp;#9783;" data-id="45790" title="Trigram For Earth">☷</span>
    <span class="char" data-decimal="9589" data-entity="&amp;#9589;" data-id="43523" title="Box Drawings Light Up">╵</span>
    <span class="char" data-decimal="9591" data-entity="&amp;#9591;" data-id="43525" title="Box Drawings Light Down">╷</span>
    <span class="char" data-decimal="9593" data-entity="&amp;#9593;" data-id="43527" title="Box Drawings Heavy Up">╹</span>
    <span class="char" data-decimal="9595" data-entity="&amp;#9595;" data-id="43529" title="Box Drawings Heavy Down">╻</span>
    <span class="char" data-decimal="9474" data-entity="&amp;#9474;" data-id="43408" title="Box Drawings Light Vertical">│</span>
    <span class="char" data-decimal="9621" data-entity="&amp;#9621;" data-id="43555" title="Right One Eighth Block">▕</span>
    <span class="char" data-decimal="9615" data-entity="&amp;#9615;" data-id="43549" title="Left One Eighth Block">▏</span>
    <span class="char" data-decimal="9475" data-entity="&amp;#9475;" data-id="43409" title="Box Drawings Heavy Vertical">┃</span>
    <span class="char" data-decimal="9478" data-entity="&amp;#9478;" data-id="43412" title="Box Drawings Light Triple Dash Vertical">┆</span>
    <span class="char" data-decimal="9479" data-entity="&amp;#9479;" data-id="43413" title="Box Drawings Heavy Triple Dash Vertical">┇</span>
    <span class="char" data-decimal="9482" data-entity="&amp;#9482;" data-id="43416" title="Box Drawings Light Quadruple Dash Vertical">┊</span>
    <span class="char" data-decimal="9550" data-entity="&amp;#9550;" data-id="43484" title="Box Drawings Light Double Dash Vertical">╎</span>
    <span class="char" data-decimal="9483" data-entity="&amp;#9483;" data-id="43417" title="Box Drawings Heavy Quadruple Dash Vertical">┋</span>
    <span class="char" data-decimal="9599" data-entity="&amp;#9599;" data-id="43533" title="Box Drawings Heavy Up And Light Down">╿</span>
    <span class="char" data-decimal="9597" data-entity="&amp;#9597;" data-id="43531" title="Box Drawings Light Up And Heavy Down">╽</span>
    <span class="char" data-decimal="9484" data-entity="&amp;#9484;" data-id="43418" title="Box Drawings Light Down And Right">┌</span>
    <span class="char" data-decimal="9485" data-entity="&amp;#9485;" data-id="43419" title="Box Drawings Down Light And Right Heavy">┍</span>
    <span class="char" data-decimal="9486" data-entity="&amp;#9486;" data-id="43420" title="Box Drawings Down Heavy And Right Light">┎</span>
    <span class="char" data-decimal="9487" data-entity="&amp;#9487;" data-id="43421" title="Box Drawings Heavy Down And Right">┏</span>
    <span class="char" data-decimal="9488" data-entity="&amp;#9488;" data-id="43422" title="Box Drawings Light Down And Left">┐</span>
    <span class="char" data-decimal="9489" data-entity="&amp;#9489;" data-id="43423" title="Box Drawings Down Light And Left Heavy">┑</span>
    <span class="char" data-decimal="9490" data-entity="&amp;#9490;" data-id="43424" title="Box Drawings Down Heavy And Left Light">┒</span>
    <span class="char" data-decimal="9491" data-entity="&amp;#9491;" data-id="43425" title="Box Drawings Heavy Down And Left">┓</span>
    <span class="char" data-decimal="9492" data-entity="&amp;#9492;" data-id="43426" title="Box Drawings Light Up And Right">└</span>
    <span class="char" data-decimal="9493" data-entity="&amp;#9493;" data-id="43427" title="Box Drawings Up Light And Right Heavy">┕</span>
    <span class="char" data-decimal="9494" data-entity="&amp;#9494;" data-id="43428" title="Box Drawings Up Heavy And Right Light">┖</span>
    <span class="char" data-decimal="9495" data-entity="&amp;#9495;" data-id="43429" title="Box Drawings Heavy Up And Right">┗</span>
    <span class="char" data-decimal="9496" data-entity="&amp;#9496;" data-id="43430" title="Box Drawings Light Up And Left">┘</span>
    <span class="char" data-decimal="9497" data-entity="&amp;#9497;" data-id="43431" title="Box Drawings Up Light And Left Heavy">┙</span>
    <span class="char" data-decimal="9498" data-entity="&amp;#9498;" data-id="43432" title="Box Drawings Up Heavy And Left Light">┚</span>
    <span class="char" data-decimal="9499" data-entity="&amp;#9499;" data-id="43433" title="Box Drawings Heavy Up And Left">┛</span>
    <span class="char" data-decimal="9500" data-entity="&amp;#9500;" data-id="43434" title="Box Drawings Light Vertical And Right">├</span>
    <span class="char" data-decimal="9501" data-entity="&amp;#9501;" data-id="43435" title="Box Drawings Vertical Light And Right Heavy">┝</span>
    <span class="char" data-decimal="9502" data-entity="&amp;#9502;" data-id="43436" title="Box Drawings Up Heavy And Right Down Light">┞</span>
    <span class="char" data-decimal="9503" data-entity="&amp;#9503;" data-id="43437" title="Box Drawings Down Heavy And Right Up Light">┟</span>
    <span class="char" data-decimal="9504" data-entity="&amp;#9504;" data-id="43438" title="Box Drawings Vertical Heavy And Right Light">┠</span>
    <span class="char" data-decimal="9505" data-entity="&amp;#9505;" data-id="43439" title="Box Drawings Down Light And Right Up Heavy">┡</span>
    <span class="char" data-decimal="9506" data-entity="&amp;#9506;" data-id="43440" title="Box Drawings Up Light And Right Down Heavy">┢</span>
    <span class="char" data-decimal="9507" data-entity="&amp;#9507;" data-id="43441" title="Box Drawings Heavy Vertical And Right">┣</span>
    <span class="char" data-decimal="9508" data-entity="&amp;#9508;" data-id="43442" title="Box Drawings Light Vertical And Left">┤</span>
    <span class="char" data-decimal="9509" data-entity="&amp;#9509;" data-id="43443" title="Box Drawings Vertical Light And Left Heavy">┥</span>
    <span class="char" data-decimal="9510" data-entity="&amp;#9510;" data-id="43444" title="Box Drawings Up Heavy And Left Down Light">┦</span>
    <span class="char" data-decimal="9511" data-entity="&amp;#9511;" data-id="43445" title="Box Drawings Down Heavy And Left Up Light">┧</span>
    <span class="char" data-decimal="9512" data-entity="&amp;#9512;" data-id="43446" title="Box Drawings Vertical Heavy And Left Light">┨</span>
    <span class="char" data-decimal="9513" data-entity="&amp;#9513;" data-id="43447" title="Box Drawings Down Light And Left Up Heavy">┩</span>
    <span class="char" data-decimal="9514" data-entity="&amp;#9514;" data-id="43448" title="Box Drawings Up Light And Left Down Heavy">┪</span>
    <span class="char" data-decimal="9515" data-entity="&amp;#9515;" data-id="43449" title="Box Drawings Heavy Vertical And Left">┫</span>
    <span class="char" data-decimal="9516" data-entity="&amp;#9516;" data-id="43450" title="Box Drawings Light Down And Horizontal">┬</span>
    <span class="char" data-decimal="9517" data-entity="&amp;#9517;" data-id="43451" title="Box Drawings Left Heavy And Right Down Light">┭</span>
    <span class="char" data-decimal="9518" data-entity="&amp;#9518;" data-id="43452" title="Box Drawings Right Heavy And Left Down Light">┮</span>
    <span class="char" data-decimal="9523" data-entity="&amp;#9523;" data-id="43457" title="Box Drawings Heavy Down And Horizontal">┳</span>
    <span class="char" data-decimal="9524" data-entity="&amp;#9524;" data-id="43458" title="Box Drawings Light Up And Horizontal">┴</span>
    <span class="char" data-decimal="9525" data-entity="&amp;#9525;" data-id="43459" title="Box Drawings Left Heavy And Right Up Light">┵</span>
    <span class="char" data-decimal="9526" data-entity="&amp;#9526;" data-id="43460" title="Box Drawings Right Heavy And Left Up Light">┶</span>
    <span class="char" data-decimal="9527" data-entity="&amp;#9527;" data-id="43461" title="Box Drawings Up Light And Horizontal Heavy">┷</span>
    <span class="char" data-decimal="9528" data-entity="&amp;#9528;" data-id="43462" title="Box Drawings Up Heavy And Horizontal Light">┸</span>
    <span class="char" data-decimal="9529" data-entity="&amp;#9529;" data-id="43463" title="Box Drawings Right Light And Left Up Heavy">┹</span>
    <span class="char" data-decimal="9530" data-entity="&amp;#9530;" data-id="43464" title="Box Drawings Left Light And Right Up Heavy">┺</span>
    <span class="char" data-decimal="9531" data-entity="&amp;#9531;" data-id="43465" title="Box Drawings Heavy Up And Horizontal">┻</span>
    <span class="char" data-decimal="9532" data-entity="&amp;#9532;" data-id="43466" title="Box Drawings Light Vertical And Horizontal">┼</span>
    <span class="char" data-decimal="9533" data-entity="&amp;#9533;" data-id="43467" title="Box Drawings Left Heavy And Right Vertical Light">┽</span>
    <span class="char" data-decimal="9534" data-entity="&amp;#9534;" data-id="43468" title="Box Drawings Right Heavy And Left Vertical Light">┾</span>
    <span class="char" data-decimal="9535" data-entity="&amp;#9535;" data-id="43469" title="Box Drawings Vertical Light And Horizontal Heavy">┿</span>
    <span class="char" data-decimal="9536" data-entity="&amp;#9536;" data-id="43470" title="Box Drawings Up Heavy And Down Horizontal Light">╀</span>
    <span class="char" data-decimal="9537" data-entity="&amp;#9537;" data-id="43471" title="Box Drawings Down Heavy And Up Horizontal Light">╁</span>
    <span class="char" data-decimal="9538" data-entity="&amp;#9538;" data-id="43472" title="Box Drawings Vertical Heavy And Horizontal Light">╂</span>
    <span class="char" data-decimal="9539" data-entity="&amp;#9539;" data-id="43473" title="Box Drawings Left Up Heavy And Right Down Light">╃</span>
    <span class="char" data-decimal="9540" data-entity="&amp;#9540;" data-id="43474" title="Box Drawings Right Up Heavy And Left Down Light">╄</span>
    <span class="char" data-decimal="9541" data-entity="&amp;#9541;" data-id="43475" title="Box Drawings Left Down Heavy And Right Up Light">╅</span>
    <span class="char" data-decimal="9542" data-entity="&amp;#9542;" data-id="43476" title="Box Drawings Right Down Heavy And Left Up Light">╆</span>
    <span class="char" data-decimal="9543" data-entity="&amp;#9543;" data-id="43477" title="Box Drawings Down Light And Up Horizontal Heavy">╇</span>
    <span class="char" data-decimal="9544" data-entity="&amp;#9544;" data-id="43478" title="Box Drawings Up Light And Down Horizontal Heavy">╈</span>
    <span class="char" data-decimal="9545" data-entity="&amp;#9545;" data-id="43479" title="Box Drawings Right Light And Left Vertical Heavy">╉</span>
    <span class="char" data-decimal="9546" data-entity="&amp;#9546;" data-id="43480" title="Box Drawings Left Light And Right Vertical Heavy">╊</span>
    <span class="char" data-decimal="9547" data-entity="&amp;#9547;" data-id="43481" title="Box Drawings Heavy Vertical And Horizontal">╋</span>
    <span class="char" data-decimal="9551" data-entity="&amp;#9551;" data-id="43485" title="Box Drawings Heavy Double Dash Vertical">╏</span>
    <span class="char" data-decimal="9553" data-entity="&amp;#9553;" data-id="43487" title="Box Drawings Double Vertical">║</span>
    <span class="char" data-decimal="9556" data-entity="&amp;#9556;" data-id="43490" title="Box Drawings Double Down And Right">╔</span>
    <span class="char" data-decimal="9554" data-entity="&amp;#9554;" data-id="43488" title="Box Drawings Down Single And Right Double">╒</span>
    <span class="char" data-decimal="9555" data-entity="&amp;#9555;" data-id="43489" title="Box Drawings Down Double And Right Single">╓</span>
    <span class="char" data-decimal="9557" data-entity="&amp;#9557;" data-id="43491" title="Box Drawings Down Single And Left Double">╕</span>
    <span class="char" data-decimal="9558" data-entity="&amp;#9558;" data-id="43492" title="Box Drawings Down Double And Left Single">╖</span>
    <span class="char" data-decimal="9559" data-entity="&amp;#9559;" data-id="43493" title="Box Drawings Double Down And Left">╗</span>
    <span class="char" data-decimal="9562" data-entity="&amp;#9562;" data-id="43496" title="Box Drawings Double Up And Right">╚</span>
    <span class="char" data-decimal="9560" data-entity="&amp;#9560;" data-id="43494" title="Box Drawings Up Single And Right Double">╘</span>
    <span class="char" data-decimal="9561" data-entity="&amp;#9561;" data-id="43495" title="Box Drawings Up Double And Right Single">╙</span>
    <span class="char" data-decimal="9563" data-entity="&amp;#9563;" data-id="43497" title="Box Drawings Up Single And Left Double">╛</span>
    <span class="char" data-decimal="9564" data-entity="&amp;#9564;" data-id="43498" title="Box Drawings Up Double And Left Single">╜</span>
    <span class="char" data-decimal="9565" data-entity="&amp;#9565;" data-id="43499" title="Box Drawings Double Up And Left">╝</span>
    <span class="char" data-decimal="9566" data-entity="&amp;#9566;" data-id="43500" title="Box Drawings Vertical Single And Right Double">╞</span>
    <span class="char" data-decimal="9567" data-entity="&amp;#9567;" data-id="43501" title="Box Drawings Vertical Double And Right Single">╟</span>
    <span class="char" data-decimal="9568" data-entity="&amp;#9568;" data-id="43502" title="Box Drawings Double Vertical And Right">╠</span>
    <span class="char" data-decimal="9569" data-entity="&amp;#9569;" data-id="43503" title="Box Drawings Vertical Single And Left Double">╡</span>
    <span class="char" data-decimal="9570" data-entity="&amp;#9570;" data-id="43504" title="Box Drawings Vertical Double And Left Single">╢</span>
    <span class="char" data-decimal="9571" data-entity="&amp;#9571;" data-id="43505" title="Box Drawings Double Vertical And Left">╣</span>
    <span class="char" data-decimal="9572" data-entity="&amp;#9572;" data-id="43506" title="Box Drawings Down Single And Horizontal Double">╤</span>
    <span class="char" data-decimal="9573" data-entity="&amp;#9573;" data-id="43507" title="Box Drawings Down Double And Horizontal Single">╥</span>
    <span class="char" data-decimal="9574" data-entity="&amp;#9574;" data-id="43508" title="Box Drawings Double Down And Horizontal">╦</span>
    <span class="char" data-decimal="9575" data-entity="&amp;#9575;" data-id="43509" title="Box Drawings Up Single And Horizontal Double">╧</span>
    <span class="char" data-decimal="9576" data-entity="&amp;#9576;" data-id="43510" title="Box Drawings Up Double And Horizontal Single">╨</span>
    <span class="char" data-decimal="9577" data-entity="&amp;#9577;" data-id="43511" title="Box Drawings Double Up And Horizontal">╩</span>
    <span class="char" data-decimal="9578" data-entity="&amp;#9578;" data-id="43512" title="Box Drawings Vertical Single And Horizontal Double">╪</span>
    <span class="char" data-decimal="9579" data-entity="&amp;#9579;" data-id="43513" title="Box Drawings Vertical Double And Horizontal Single">╫</span>
    <span class="char" data-decimal="9580" data-entity="&amp;#9580;" data-id="43514" title="Box Drawings Double Vertical And Horizontal">╬</span>
    <span class="char" data-decimal="8990" data-entity="&amp;#8990;" data-id="45972" title="Bottom Left Corner">⌞</span>
    <span class="char" data-decimal="8991" data-entity="&amp;#8991;" data-id="45973" title="Bottom Right Corner">⌟</span>
    <span class="char" data-decimal="8988" data-entity="&amp;#8988;" data-id="45970" title="Top Left Corner">⌜</span>
    <span class="char" data-decimal="8989" data-entity="&amp;#8989;" data-id="45971" title="Top Right Corner">⌝</span>
    <span class="char" data-decimal="8970" data-entity="&amp;lfloor;" data-id="45952" title="Left Floor">⌊</span>
    <span class="char" data-decimal="8971" data-entity="&amp;rfloor;" data-id="45953" title="Right Floor">⌋</span>
    <span class="char" data-decimal="8969" data-entity="&amp;rceil;" data-id="45951" title="Right Ceiling">⌉</span>
    <span class="char" data-decimal="8968" data-entity="&amp;lceil;" data-id="45950" title="Left Ceiling">⌈</span>
    <span class="char" data-decimal="8971" data-entity="&amp;rfloor;" data-id="45953" title="Right Floor">⌋</span>
    <span class="char" data-decimal="8367" data-entity="&amp;#8367;" data-id="43149" title="Drachma Sign">₯</span>
    <span class="char" data-decimal="7936" data-entity="&amp;#7936;" data-id="44019" title="Greek Small Letter Alpha With Psili">ἀ</span>
    <span class="char" data-decimal="7937" data-entity="&amp;#7937;" data-id="44020" title="Greek Small Letter Alpha With Dasia">ἁ</span>
    <span class="char" data-decimal="7938" data-entity="&amp;#7938;" data-id="44021" title="Greek Small Letter Alpha With Psili And Varia">ἂ</span>
    <span class="char" data-decimal="7939" data-entity="&amp;#7939;" data-id="44022" title="Greek Small Letter Alpha With Dasia And Varia">ἃ</span>
    <span class="char" data-decimal="7940" data-entity="&amp;#7940;" data-id="44023" title="Greek Small Letter Alpha With Psili And Oxia">ἄ</span>
    <span class="char" data-decimal="7941" data-entity="&amp;#7941;" data-id="44024" title="Greek Small Letter Alpha With Dasia And Oxia">ἅ</span>
    <span class="char" data-decimal="7942" data-entity="&amp;#7942;" data-id="44025" title="Greek Small Letter Alpha With Psili And Perispomeni">ἆ</span>
    <span class="char" data-decimal="7943" data-entity="&amp;#7943;" data-id="44026" title="Greek Small Letter Alpha With Dasia And Perispomeni">ἇ</span>
    <span class="char" data-decimal="7944" data-entity="&amp;#7944;" data-id="44027" title="Greek Capital Letter Alpha With Psili">Ἀ</span>
    <span class="char" data-decimal="7945" data-entity="&amp;#7945;" data-id="44028" title="Greek Capital Letter Alpha With Dasia">Ἁ</span>
    <span class="char" data-decimal="7946" data-entity="&amp;#7946;" data-id="44029" title="Greek Capital Letter Alpha With Psili And Varia">Ἂ</span>
    <span class="char" data-decimal="7947" data-entity="&amp;#7947;" data-id="44030" title="Greek Capital Letter Alpha With Dasia And Varia">Ἃ</span>
    <span class="char" data-decimal="7948" data-entity="&amp;#7948;" data-id="44031" title="Greek Capital Letter Alpha With Psili And Oxia">Ἄ</span>
    <span class="char" data-decimal="7949" data-entity="&amp;#7949;" data-id="44032" title="Greek Capital Letter Alpha With Dasia And Oxia">Ἅ</span>
    <span class="char" data-decimal="7950" data-entity="&amp;#7950;" data-id="44033" title="Greek Capital Letter Alpha With Psili And Perispomeni">Ἆ</span>
    <span class="char" data-decimal="7951" data-entity="&amp;#7951;" data-id="44034" title="Greek Capital Letter Alpha With Dasia And Perispomeni">Ἇ</span>
    <span class="char" data-decimal="7952" data-entity="&amp;#7952;" data-id="44035" title="Greek Small Letter Epsilon With Psili">ἐ</span>
    <span class="char" data-decimal="7953" data-entity="&amp;#7953;" data-id="44036" title="Greek Small Letter Epsilon With Dasia">ἑ</span>
    <span class="char" data-decimal="7954" data-entity="&amp;#7954;" data-id="44037" title="Greek Small Letter Epsilon With Psili And Varia">ἒ</span>
    <span class="char" data-decimal="7955" data-entity="&amp;#7955;" data-id="44038" title="Greek Small Letter Epsilon With Dasia And Varia">ἓ</span>
    <span class="char" data-decimal="7956" data-entity="&amp;#7956;" data-id="44039" title="Greek Small Letter Epsilon With Psili And Oxia">ἔ</span>
    <span class="char" data-decimal="7957" data-entity="&amp;#7957;" data-id="44040" title="Greek Small Letter Epsilon With Dasia And Oxia">ἕ</span>
    <span class="char" data-decimal="7960" data-entity="&amp;#7960;" data-id="44041" title="Greek Capital Letter Epsilon With Psili">Ἐ</span>
    <span class="char" data-decimal="7961" data-entity="&amp;#7961;" data-id="44042" title="Greek Capital Letter Epsilon With Dasia">Ἑ</span>
    <span class="char" data-decimal="7962" data-entity="&amp;#7962;" data-id="44043" title="Greek Capital Letter Epsilon With Psili And Varia">Ἒ</span>
    <span class="char" data-decimal="7963" data-entity="&amp;#7963;" data-id="44044" title="Greek Capital Letter Epsilon With Dasia And Varia">Ἓ</span>
    <span class="char" data-decimal="7964" data-entity="&amp;#7964;" data-id="44045" title="Greek Capital Letter Epsilon With Psili And Oxia">Ἔ</span>
    <span class="char" data-decimal="7965" data-entity="&amp;#7965;" data-id="44046" title="Greek Capital Letter Epsilon With Dasia And Oxia">Ἕ</span>
    <span class="char" data-decimal="7968" data-entity="&amp;#7968;" data-id="44047" title="Greek Small Letter Eta With Psili">ἠ</span>
    <span class="char" data-decimal="7969" data-entity="&amp;#7969;" data-id="44048" title="Greek Small Letter Eta With Dasia">ἡ</span>
    <span class="char" data-decimal="7970" data-entity="&amp;#7970;" data-id="44049" title="Greek Small Letter Eta With Psili And Varia">ἢ</span>
    <span class="char" data-decimal="7971" data-entity="&amp;#7971;" data-id="44050" title="Greek Small Letter Eta With Dasia And Varia">ἣ</span>
    <span class="char" data-decimal="7972" data-entity="&amp;#7972;" data-id="44051" title="Greek Small Letter Eta With Psili And Oxia">ἤ</span>
    <span class="char" data-decimal="7973" data-entity="&amp;#7973;" data-id="44052" title="Greek Small Letter Eta With Dasia And Oxia">ἥ</span>
    <span class="char" data-decimal="7974" data-entity="&amp;#7974;" data-id="44053" title="Greek Small Letter Eta With Psili And Perispomeni">ἦ</span>
    <span class="char" data-decimal="7975" data-entity="&amp;#7975;" data-id="44054" title="Greek Small Letter Eta With Dasia And Perispomeni">ἧ</span>
    <span class="char" data-decimal="7976" data-entity="&amp;#7976;" data-id="44055" title="Greek Capital Letter Eta With Psili">Ἠ</span>
    <span class="char" data-decimal="7977" data-entity="&amp;#7977;" data-id="44056" title="Greek Capital Letter Eta With Dasia">Ἡ</span>
    <span class="char" data-decimal="7978" data-entity="&amp;#7978;" data-id="44057" title="Greek Capital Letter Eta With Psili And Varia">Ἢ</span>
    <span class="char" data-decimal="7979" data-entity="&amp;#7979;" data-id="44058" title="Greek Capital Letter Eta With Dasia And Varia">Ἣ</span>
    <span class="char" data-decimal="7980" data-entity="&amp;#7980;" data-id="44059" title="Greek Capital Letter Eta With Psili And Oxia">Ἤ</span>
    <span class="char" data-decimal="7981" data-entity="&amp;#7981;" data-id="44060" title="Greek Capital Letter Eta With Dasia And Oxia">Ἥ</span>
    <span class="char" data-decimal="7982" data-entity="&amp;#7982;" data-id="44061" title="Greek Capital Letter Eta With Psili And Perispomeni">Ἦ</span>
    <span class="char" data-decimal="7983" data-entity="&amp;#7983;" data-id="44062" title="Greek Capital Letter Eta With Dasia And Perispomeni">Ἧ</span>
    <span class="char" data-decimal="7984" data-entity="&amp;#7984;" data-id="44063" title="Greek Small Letter Iota With Psili">ἰ</span>
    <span class="char" data-decimal="7985" data-entity="&amp;#7985;" data-id="44064" title="Greek Small Letter Iota With Dasia">ἱ</span>
    <span class="char" data-decimal="7986" data-entity="&amp;#7986;" data-id="44065" title="Greek Small Letter Iota With Psili And Varia">ἲ</span>
    <span class="char" data-decimal="7987" data-entity="&amp;#7987;" data-id="44066" title="Greek Small Letter Iota With Dasia And Varia">ἳ</span>
    <span class="char" data-decimal="7988" data-entity="&amp;#7988;" data-id="44067" title="Greek Small Letter Iota With Psili And Oxia">ἴ</span>
    <span class="char" data-decimal="7989" data-entity="&amp;#7989;" data-id="44068" title="Greek Small Letter Iota With Dasia And Oxia">ἵ</span>
    <span class="char" data-decimal="7990" data-entity="&amp;#7990;" data-id="44069" title="Greek Small Letter Iota With Psili And Perispomeni">ἶ</span>
    <span class="char" data-decimal="7991" data-entity="&amp;#7991;" data-id="44070" title="Greek Small Letter Iota With Dasia And Perispomeni">ἷ</span>
    <span class="char" data-decimal="7992" data-entity="&amp;#7992;" data-id="44071" title="Greek Capital Letter Iota With Psili">Ἰ</span>
    <span class="char" data-decimal="7993" data-entity="&amp;#7993;" data-id="44072" title="Greek Capital Letter Iota With Dasia">Ἱ</span>
    <span class="char" data-decimal="7994" data-entity="&amp;#7994;" data-id="44073" title="Greek Capital Letter Iota With Psili And Varia">Ἲ</span>
    <span class="char" data-decimal="7995" data-entity="&amp;#7995;" data-id="44074" title="Greek Capital Letter Iota With Dasia And Varia">Ἳ</span>
    <span class="char" data-decimal="7996" data-entity="&amp;#7996;" data-id="44075" title="Greek Capital Letter Iota With Psili And Oxia">Ἴ</span>
    <span class="char" data-decimal="7997" data-entity="&amp;#7997;" data-id="44076" title="Greek Capital Letter Iota With Dasia And Oxia">Ἵ</span>
    <span class="char" data-decimal="7998" data-entity="&amp;#7998;" data-id="44077" title="Greek Capital Letter Iota With Psili And Perispomeni">Ἶ</span>
    <span class="char" data-decimal="7999" data-entity="&amp;#7999;" data-id="44078" title="Greek Capital Letter Iota With Dasia And Perispomeni">Ἷ</span>
    <span class="char" data-decimal="8000" data-entity="&amp;#8000;" data-id="44079" title="Greek Small Letter Omicron With Psili">ὀ</span>
    <span class="char" data-decimal="8001" data-entity="&amp;#8001;" data-id="44080" title="Greek Small Letter Omicron With Dasia">ὁ</span>
    <span class="char" data-decimal="8002" data-entity="&amp;#8002;" data-id="44081" title="Greek Small Letter Omicron With Psili And Varia">ὂ</span>
    <span class="char" data-decimal="8003" data-entity="&amp;#8003;" data-id="44082" title="Greek Small Letter Omicron With Dasia And Varia">ὃ</span>
    <span class="char" data-decimal="8004" data-entity="&amp;#8004;" data-id="44083" title="Greek Small Letter Omicron With Psili And Oxia">ὄ</span>
    <span class="char" data-decimal="8005" data-entity="&amp;#8005;" data-id="44084" title="Greek Small Letter Omicron With Dasia And Oxia">ὅ</span>
    <span class="char" data-decimal="8008" data-entity="&amp;#8008;" data-id="44085" title="Greek Capital Letter Omicron With Psili">Ὀ</span>
    <span class="char" data-decimal="8009" data-entity="&amp;#8009;" data-id="44086" title="Greek Capital Letter Omicron With Dasia">Ὁ</span>
    <span class="char" data-decimal="8010" data-entity="&amp;#8010;" data-id="44087" title="Greek Capital Letter Omicron With Psili And Varia">Ὂ</span>
    <span class="char" data-decimal="8011" data-entity="&amp;#8011;" data-id="44088" title="Greek Capital Letter Omicron With Dasia And Varia">Ὃ</span>
    <span class="char" data-decimal="8012" data-entity="&amp;#8012;" data-id="44089" title="Greek Capital Letter Omicron With Psili And Oxia">Ὄ</span>
    <span class="char" data-decimal="8013" data-entity="&amp;#8013;" data-id="44090" title="Greek Capital Letter Omicron With Dasia And Oxia">Ὅ</span>
    <span class="char" data-decimal="8016" data-entity="&amp;#8016;" data-id="44091" title="Greek Small Letter Upsilon With Psili">ὐ</span>
    <span class="char" data-decimal="8017" data-entity="&amp;#8017;" data-id="44092" title="Greek Small Letter Upsilon With Dasia">ὑ</span>
    <span class="char" data-decimal="8018" data-entity="&amp;#8018;" data-id="44093" title="Greek Small Letter Upsilon With Psili And Varia">ὒ</span>
    <span class="char" data-decimal="8019" data-entity="&amp;#8019;" data-id="44094" title="Greek Small Letter Upsilon With Dasia And Varia">ὓ</span>
    <span class="char" data-decimal="8020" data-entity="&amp;#8020;" data-id="44095" title="Greek Small Letter Upsilon With Psili And Oxia">ὔ</span>
    <span class="char" data-decimal="8021" data-entity="&amp;#8021;" data-id="44096" title="Greek Small Letter Upsilon With Dasia And Oxia">ὕ</span>
    <span class="char" data-decimal="8022" data-entity="&amp;#8022;" data-id="44097" title="Greek Small Letter Upsilon With Psili And Perispomeni">ὖ</span>
    <span class="char" data-decimal="8023" data-entity="&amp;#8023;" data-id="44098" title="Greek Small Letter Upsilon With Dasia And Perispomeni">ὗ</span>
    <span class="char" data-decimal="8025" data-entity="&amp;#8025;" data-id="44099" title="Greek Capital Letter Upsilon With Dasia">Ὑ</span>
    <span class="char" data-decimal="8027" data-entity="&amp;#8027;" data-id="44100" title="Greek Capital Letter Upsilon With Dasia And Varia">Ὓ</span>
    <span class="char" data-decimal="8029" data-entity="&amp;#8029;" data-id="44101" title="Greek Capital Letter Upsilon With Dasia And Oxia">Ὕ</span>
    <span class="char" data-decimal="8031" data-entity="&amp;#8031;" data-id="44102" title="Greek Capital Letter Upsilon With Dasia And Perispomeni">Ὗ</span>
    <span class="char" data-decimal="8032" data-entity="&amp;#8032;" data-id="44103" title="Greek Small Letter Omega With Psili">ὠ</span>
    <span class="char" data-decimal="8033" data-entity="&amp;#8033;" data-id="44104" title="Greek Small Letter Omega With Dasia">ὡ</span>
    <span class="char" data-decimal="8034" data-entity="&amp;#8034;" data-id="44105" title="Greek Small Letter Omega With Psili And Varia">ὢ</span>
    <span class="char" data-decimal="8035" data-entity="&amp;#8035;" data-id="44106" title="Greek Small Letter Omega With Dasia And Varia">ὣ</span>
    <span class="char" data-decimal="8036" data-entity="&amp;#8036;" data-id="44107" title="Greek Small Letter Omega With Psili And Oxia">ὤ</span>
    <span class="char" data-decimal="8037" data-entity="&amp;#8037;" data-id="44108" title="Greek Small Letter Omega With Dasia And Oxia">ὥ</span>
    <span class="char" data-decimal="8038" data-entity="&amp;#8038;" data-id="44109" title="Greek Small Letter Omega With Psili And Perispomeni">ὦ</span>
    <span class="char" data-decimal="8039" data-entity="&amp;#8039;" data-id="44110" title="Greek Small Letter Omega With Dasia And Perispomeni">ὧ</span>
    <span class="char" data-decimal="8040" data-entity="&amp;#8040;" data-id="44111" title="Greek Capital Letter Omega With Psili">Ὠ</span>
    <span class="char" data-decimal="8041" data-entity="&amp;#8041;" data-id="44112" title="Greek Capital Letter Omega With Dasia">Ὡ</span>
    <span class="char" data-decimal="8042" data-entity="&amp;#8042;" data-id="44113" title="Greek Capital Letter Omega With Psili And Varia">Ὢ</span>
    <span class="char" data-decimal="8043" data-entity="&amp;#8043;" data-id="44114" title="Greek Capital Letter Omega With Dasia And Varia">Ὣ</span>
    <span class="char" data-decimal="8044" data-entity="&amp;#8044;" data-id="44115" title="Greek Capital Letter Omega With Psili And Oxia">Ὤ</span>
    <span class="char" data-decimal="8045" data-entity="&amp;#8045;" data-id="44116" title="Greek Capital Letter Omega With Dasia And Oxia">Ὥ</span>
    <span class="char" data-decimal="8046" data-entity="&amp;#8046;" data-id="44117" title="Greek Capital Letter Omega With Psili And Perispomeni">Ὦ</span>
    <span class="char" data-decimal="8047" data-entity="&amp;#8047;" data-id="44118" title="Greek Capital Letter Omega With Dasia And Perispomeni">Ὧ</span>
    <span class="char" data-decimal="8048" data-entity="&amp;#8048;" data-id="44119" title="Greek Small Letter Alpha With Varia">ὰ</span>
    <span class="char" data-decimal="8049" data-entity="&amp;#8049;" data-id="44120" title="Greek Small Letter Alpha With Oxia">ά</span>
    <span class="char" data-decimal="8050" data-entity="&amp;#8050;" data-id="44121" title="Greek Small Letter Epsilon With Varia">ὲ</span>
    <span class="char" data-decimal="8051" data-entity="&amp;#8051;" data-id="44122" title="Greek Small Letter Epsilon With Oxia">έ</span>
    <span class="char" data-decimal="8052" data-entity="&amp;#8052;" data-id="44123" title="Greek Small Letter Eta With Varia">ὴ</span>
    <span class="char" data-decimal="8053" data-entity="&amp;#8053;" data-id="44124" title="Greek Small Letter Eta With Oxia">ή</span>
    <span class="char" data-decimal="8054" data-entity="&amp;#8054;" data-id="44125" title="Greek Small Letter Iota With Varia">ὶ</span>
    <span class="char" data-decimal="8055" data-entity="&amp;#8055;" data-id="44126" title="Greek Small Letter Iota With Oxia">ί</span>
    <span class="char" data-decimal="8056" data-entity="&amp;#8056;" data-id="44127" title="Greek Small Letter Omicron With Varia">ὸ</span>
    <span class="char" data-decimal="8057" data-entity="&amp;#8057;" data-id="44128" title="Greek Small Letter Omicron With Oxia">ό</span>
    <span class="char" data-decimal="8058" data-entity="&amp;#8058;" data-id="44129" title="Greek Small Letter Upsilon With Varia">ὺ</span>
    <span class="char" data-decimal="8059" data-entity="&amp;#8059;" data-id="44130" title="Greek Small Letter Upsilon With Oxia">ύ</span>
    <span class="char" data-decimal="8060" data-entity="&amp;#8060;" data-id="44131" title="Greek Small Letter Omega With Varia">ὼ</span>
    <span class="char" data-decimal="8061" data-entity="&amp;#8061;" data-id="44132" title="Greek Small Letter Omega With Oxia">ώ</span>
    <span class="char" data-decimal="8064" data-entity="&amp;#8064;" data-id="44133" title="Greek Small Letter Alpha With Psili And Ypogegrammeni">ᾀ</span>
    <span class="char" data-decimal="8065" data-entity="&amp;#8065;" data-id="44134" title="Greek Small Letter Alpha With Dasia And Ypogegrammeni">ᾁ</span>
    <span class="char" data-decimal="8066" data-entity="&amp;#8066;" data-id="44135" title="Greek Small Letter Alpha With Psili And Varia And Ypogegrammeni">ᾂ</span>
    <span class="char" data-decimal="8067" data-entity="&amp;#8067;" data-id="44136" title="Greek Small Letter Alpha With Dasia And Varia And Ypogegrammeni">ᾃ</span>
    <span class="char" data-decimal="8068" data-entity="&amp;#8068;" data-id="44137" title="Greek Small Letter Alpha With Psili And Oxia And Ypogegrammeni">ᾄ</span>
    <span class="char" data-decimal="8069" data-entity="&amp;#8069;" data-id="44138" title="Greek Small Letter Alpha With Dasia And Oxia And Ypogegrammeni">ᾅ</span>
    <span class="char" data-decimal="8070" data-entity="&amp;#8070;" data-id="44139" title="Greek Small Letter Alpha With Psili And Perispomeni And Ypogegrammeni">ᾆ</span>
    <span class="char" data-decimal="8071" data-entity="&amp;#8071;" data-id="44140" title="Greek Small Letter Alpha With Dasia And Perispomeni And Ypogegrammeni">ᾇ</span>
    <span class="char" data-decimal="8072" data-entity="&amp;#8072;" data-id="44141" title="Greek Capital Letter Alpha With Psili And Prosgegrammeni">ᾈ</span>
    <span class="char" data-decimal="8073" data-entity="&amp;#8073;" data-id="44142" title="Greek Capital Letter Alpha With Dasia And Prosgegrammeni">ᾉ</span>
    <span class="char" data-decimal="8074" data-entity="&amp;#8074;" data-id="44143" title="Greek Capital Letter Alpha With Psili And Varia And Prosgegrammeni">ᾊ</span>
    <span class="char" data-decimal="8075" data-entity="&amp;#8075;" data-id="44144" title="Greek Capital Letter Alpha With Dasia And Varia And Prosgegrammeni">ᾋ</span>
    <span class="char" data-decimal="8076" data-entity="&amp;#8076;" data-id="44145" title="Greek Capital Letter Alpha With Psili And Oxia And Prosgegrammeni">ᾌ</span>
    <span class="char" data-decimal="8077" data-entity="&amp;#8077;" data-id="44146" title="Greek Capital Letter Alpha With Dasia And Oxia And Prosgegrammeni">ᾍ</span>
    <span class="char" data-decimal="8078" data-entity="&amp;#8078;" data-id="44147" title="Greek Capital Letter Alpha With Psili And Perispomeni And Prosgegrammeni">ᾎ</span>
    <span class="char" data-decimal="8079" data-entity="&amp;#8079;" data-id="44148" title="Greek Capital Letter Alpha With Dasia And Perispomeni And Prosgegrammeni">ᾏ</span>
    <span class="char" data-decimal="8080" data-entity="&amp;#8080;" data-id="44149" title="Greek Small Letter Eta With Psili And Ypogegrammeni">ᾐ</span>
    <span class="char" data-decimal="8081" data-entity="&amp;#8081;" data-id="44150" title="Greek Small Letter Eta With Dasia And Ypogegrammeni">ᾑ</span>
    <span class="char" data-decimal="8082" data-entity="&amp;#8082;" data-id="44151" title="Greek Small Letter Eta With Psili And Varia And Ypogegrammeni">ᾒ</span>
    <span class="char" data-decimal="8083" data-entity="&amp;#8083;" data-id="44152" title="Greek Small Letter Eta With Dasia And Varia And Ypogegrammeni">ᾓ</span>
    <span class="char" data-decimal="8084" data-entity="&amp;#8084;" data-id="44153" title="Greek Small Letter Eta With Psili And Oxia And Ypogegrammeni">ᾔ</span>
    <span class="char" data-decimal="8085" data-entity="&amp;#8085;" data-id="44154" title="Greek Small Letter Eta With Dasia And Oxia And Ypogegrammeni">ᾕ</span>
    <span class="char" data-decimal="8086" data-entity="&amp;#8086;" data-id="44155" title="Greek Small Letter Eta With Psili And Perispomeni And Ypogegrammeni">ᾖ</span>
    <span class="char" data-decimal="8087" data-entity="&amp;#8087;" data-id="44156" title="Greek Small Letter Eta With Dasia And Perispomeni And Ypogegrammeni">ᾗ</span>
    <span class="char" data-decimal="8088" data-entity="&amp;#8088;" data-id="44157" title="Greek Capital Letter Eta With Psili And Prosgegrammeni">ᾘ</span>
    <span class="char" data-decimal="8089" data-entity="&amp;#8089;" data-id="44158" title="Greek Capital Letter Eta With Dasia And Prosgegrammeni">ᾙ</span>
    <span class="char" data-decimal="8090" data-entity="&amp;#8090;" data-id="44159" title="Greek Capital Letter Eta With Psili And Varia And Prosgegrammeni">ᾚ</span>
    <span class="char" data-decimal="8091" data-entity="&amp;#8091;" data-id="44160" title="Greek Capital Letter Eta With Dasia And Varia And Prosgegrammeni">ᾛ</span>
    <span class="char" data-decimal="8092" data-entity="&amp;#8092;" data-id="44161" title="Greek Capital Letter Eta With Psili And Oxia And Prosgegrammeni">ᾜ</span>
    <span class="char" data-decimal="8093" data-entity="&amp;#8093;" data-id="44162" title="Greek Capital Letter Eta With Dasia And Oxia And Prosgegrammeni">ᾝ</span>
    <span class="char" data-decimal="8094" data-entity="&amp;#8094;" data-id="44163" title="Greek Capital Letter Eta With Psili And Perispomeni And Prosgegrammeni">ᾞ</span>
    <span class="char" data-decimal="8095" data-entity="&amp;#8095;" data-id="44164" title="Greek Capital Letter Eta With Dasia And Perispomeni And Prosgegrammeni">ᾟ</span>
    <span class="char" data-decimal="8096" data-entity="&amp;#8096;" data-id="44165" title="Greek Small Letter Omega With Psili And Ypogegrammeni">ᾠ</span>
    <span class="char" data-decimal="8097" data-entity="&amp;#8097;" data-id="44166" title="Greek Small Letter Omega With Dasia And Ypogegrammeni">ᾡ</span>
    <span class="char" data-decimal="8098" data-entity="&amp;#8098;" data-id="44167" title="Greek Small Letter Omega With Psili And Varia And Ypogegrammeni">ᾢ</span>
    <span class="char" data-decimal="8099" data-entity="&amp;#8099;" data-id="44168" title="Greek Small Letter Omega With Dasia And Varia And Ypogegrammeni">ᾣ</span>
    <span class="char" data-decimal="8100" data-entity="&amp;#8100;" data-id="44169" title="Greek Small Letter Omega With Psili And Oxia And Ypogegrammeni">ᾤ</span>
    <span class="char" data-decimal="8101" data-entity="&amp;#8101;" data-id="44170" title="Greek Small Letter Omega With Dasia And Oxia And Ypogegrammeni">ᾥ</span>
    <span class="char" data-decimal="8102" data-entity="&amp;#8102;" data-id="44171" title="Greek Small Letter Omega With Psili And Perispomeni And Ypogegrammeni">ᾦ</span>
    <span class="char" data-decimal="8103" data-entity="&amp;#8103;" data-id="44172" title="Greek Small Letter Omega With Dasia And Perispomeni And Ypogegrammeni">ᾧ</span>
    <span class="char" data-decimal="8104" data-entity="&amp;#8104;" data-id="44173" title="Greek Capital Letter Omega With Psili And Prosgegrammeni">ᾨ</span>
    <span class="char" data-decimal="8105" data-entity="&amp;#8105;" data-id="44174" title="Greek Capital Letter Omega With Dasia And Prosgegrammeni">ᾩ</span>
    <span class="char" data-decimal="8106" data-entity="&amp;#8106;" data-id="44175" title="Greek Capital Letter Omega With Psili And Varia And Prosgegrammeni">ᾪ</span>
    <span class="char" data-decimal="8107" data-entity="&amp;#8107;" data-id="44176" title="Greek Capital Letter Omega With Dasia And Varia And Prosgegrammeni">ᾫ</span>
    <span class="char" data-decimal="8108" data-entity="&amp;#8108;" data-id="44177" title="Greek Capital Letter Omega With Psili And Oxia And Prosgegrammeni">ᾬ</span>
    <span class="char" data-decimal="8109" data-entity="&amp;#8109;" data-id="44178" title="Greek Capital Letter Omega With Dasia And Oxia And Prosgegrammeni">ᾭ</span>
    <span class="char" data-decimal="8110" data-entity="&amp;#8110;" data-id="44179" title="Greek Capital Letter Omega With Psili And Perispomeni And Prosgegrammeni">ᾮ</span>
    <span class="char" data-decimal="8111" data-entity="&amp;#8111;" data-id="44180" title="Greek Capital Letter Omega With Dasia And Perispomeni And Prosgegrammeni">ᾯ</span>
    <span class="char" data-decimal="8112" data-entity="&amp;#8112;" data-id="44181" title="Greek Small Letter Alpha With Vrachy">ᾰ</span>
    <span class="char" data-decimal="8113" data-entity="&amp;#8113;" data-id="44182" title="Greek Small Letter Alpha With Macron">ᾱ</span>
    <span class="char" data-decimal="8114" data-entity="&amp;#8114;" data-id="44183" title="Greek Small Letter Alpha With Varia And Ypogegrammeni">ᾲ</span>
    <span class="char" data-decimal="8115" data-entity="&amp;#8115;" data-id="44184" title="Greek Small Letter Alpha With Ypogegrammeni">ᾳ</span>
    <span class="char" data-decimal="8116" data-entity="&amp;#8116;" data-id="44185" title="Greek Small Letter Alpha With Oxia And Ypogegrammeni">ᾴ</span>
    <span class="char" data-decimal="8118" data-entity="&amp;#8118;" data-id="44186" title="Greek Small Letter Alpha With Perispomeni">ᾶ</span>
    <span class="char" data-decimal="8119" data-entity="&amp;#8119;" data-id="44187" title="Greek Small Letter Alpha With Perispomeni And Ypogegrammeni">ᾷ</span>
    <span class="char" data-decimal="8120" data-entity="&amp;#8120;" data-id="44188" title="Greek Capital Letter Alpha With Vrachy">Ᾰ</span>
    <span class="char" data-decimal="8121" data-entity="&amp;#8121;" data-id="44189" title="Greek Capital Letter Alpha With Macron">Ᾱ</span>
    <span class="char" data-decimal="8122" data-entity="&amp;#8122;" data-id="44190" title="Greek Capital Letter Alpha With Varia">Ὰ</span>
    <span class="char" data-decimal="8123" data-entity="&amp;#8123;" data-id="44191" title="Greek Capital Letter Alpha With Oxia">Ά</span>
    <span class="char" data-decimal="8124" data-entity="&amp;#8124;" data-id="44192" title="Greek Capital Letter Alpha With Prosgegrammeni">ᾼ</span>
    <span class="char" data-decimal="8125" data-entity="&amp;#8125;" data-id="44193" title="Greek Koronis">᾽</span>
    <span class="char" data-decimal="8126" data-entity="&amp;#8126;" data-id="44194" title="Greek Prosgegrammeni">ι</span>
    <span class="char" data-decimal="8127" data-entity="&amp;#8127;" data-id="44195" title="Greek Psili">᾿</span>
    <span class="char" data-decimal="8128" data-entity="&amp;#8128;" data-id="44196" title="Greek Perispomeni">῀</span>
    <span class="char" data-decimal="8129" data-entity="&amp;#8129;" data-id="44197" title="Greek Dialytika And Perispomeni">῁</span>
    <span class="char" data-decimal="8130" data-entity="&amp;#8130;" data-id="44198" title="Greek Small Letter Eta With Varia And Ypogegrammeni">ῂ</span>
    <span class="char" data-decimal="8131" data-entity="&amp;#8131;" data-id="44199" title="Greek Small Letter Eta With Ypogegrammeni">ῃ</span>
    <span class="char" data-decimal="8132" data-entity="&amp;#8132;" data-id="44200" title="Greek Small Letter Eta With Oxia And Ypogegrammeni">ῄ</span>
    <span class="char" data-decimal="8134" data-entity="&amp;#8134;" data-id="44201" title="Greek Small Letter Eta With Perispomeni">ῆ</span>
    <span class="char" data-decimal="8135" data-entity="&amp;#8135;" data-id="44202" title="Greek Small Letter Eta With Perispomeni And Ypogegrammeni">ῇ</span>
    <span class="char" data-decimal="8136" data-entity="&amp;#8136;" data-id="44203" title="Greek Capital Letter Epsilon With Varia">Ὲ</span>
    <span class="char" data-decimal="8137" data-entity="&amp;#8137;" data-id="44204" title="Greek Capital Letter Epsilon With Oxia">Έ</span>
    <span class="char" data-decimal="8138" data-entity="&amp;#8138;" data-id="44205" title="Greek Capital Letter Eta With Varia">Ὴ</span>
    <span class="char" data-decimal="8139" data-entity="&amp;#8139;" data-id="44206" title="Greek Capital Letter Eta With Oxia">Ή</span>
    <span class="char" data-decimal="8140" data-entity="&amp;#8140;" data-id="44207" title="Greek Capital Letter Eta With Prosgegrammeni">ῌ</span>
    <span class="char" data-decimal="8141" data-entity="&amp;#8141;" data-id="44208" title="Greek Psili And Varia">῍</span>
    <span class="char" data-decimal="8142" data-entity="&amp;#8142;" data-id="44209" title="Greek Psili And Oxia">῎</span>
    <span class="char" data-decimal="8143" data-entity="&amp;#8143;" data-id="44210" title="Greek Psili And Perispomeni">῏</span>
    <span class="char" data-decimal="8144" data-entity="&amp;#8144;" data-id="44211" title="Greek Small Letter Iota With Vrachy">ῐ</span>
    <span class="char" data-decimal="8145" data-entity="&amp;#8145;" data-id="44212" title="Greek Small Letter Iota With Macron">ῑ</span>
    <span class="char" data-decimal="8146" data-entity="&amp;#8146;" data-id="44213" title="Greek Small Letter Iota With Dialytika And Varia">ῒ</span>
    <span class="char" data-decimal="8147" data-entity="&amp;#8147;" data-id="44214" title="Greek Small Letter Iota With Dialytika And Oxia">ΐ</span>
    <span class="char" data-decimal="8150" data-entity="&amp;#8150;" data-id="44215" title="Greek Small Letter Iota With Perispomeni">ῖ</span>
    <span class="char" data-decimal="8151" data-entity="&amp;#8151;" data-id="44216" title="Greek Small Letter Iota With Dialytika And Perispomeni">ῗ</span>
    <span class="char" data-decimal="8152" data-entity="&amp;#8152;" data-id="44217" title="Greek Capital Letter Iota With Vrachy">Ῐ</span>
    <span class="char" data-decimal="8153" data-entity="&amp;#8153;" data-id="44218" title="Greek Capital Letter Iota With Macron">Ῑ</span>
    <span class="char" data-decimal="8154" data-entity="&amp;#8154;" data-id="44219" title="Greek Capital Letter Iota With Varia">Ὶ</span>
    <span class="char" data-decimal="8155" data-entity="&amp;#8155;" data-id="44220" title="Greek Capital Letter Iota With Oxia">Ί</span>
    <span class="char" data-decimal="8157" data-entity="&amp;#8157;" data-id="44221" title="Greek Dasia And Varia">῝</span>
    <span class="char" data-decimal="8158" data-entity="&amp;#8158;" data-id="44222" title="Greek Dasia And Oxia">῞</span>
    <span class="char" data-decimal="8159" data-entity="&amp;#8159;" data-id="44223" title="Greek Dasia And Perispomeni">῟</span>
    <span class="char" data-decimal="8160" data-entity="&amp;#8160;" data-id="44224" title="Greek Small Letter Upsilon With Vrachy">ῠ</span>
    <span class="char" data-decimal="8161" data-entity="&amp;#8161;" data-id="44225" title="Greek Small Letter Upsilon With Macron">ῡ</span>
    <span class="char" data-decimal="8162" data-entity="&amp;#8162;" data-id="44226" title="Greek Small Letter Upsilon With Dialytika And Varia">ῢ</span>
    <span class="char" data-decimal="8163" data-entity="&amp;#8163;" data-id="44227" title="Greek Small Letter Upsilon With Dialytika And Oxia">ΰ</span>
    <span class="char" data-decimal="8164" data-entity="&amp;#8164;" data-id="44228" title="Greek Small Letter Rho With Psili">ῤ</span>
    <span class="char" data-decimal="8165" data-entity="&amp;#8165;" data-id="44229" title="Greek Small Letter Rho With Dasia">ῥ</span>
    <span class="char" data-decimal="8166" data-entity="&amp;#8166;" data-id="44230" title="Greek Small Letter Upsilon With Perispomeni">ῦ</span>
    <span class="char" data-decimal="8167" data-entity="&amp;#8167;" data-id="44231" title="Greek Small Letter Upsilon With Dialytika And Perispomeni">ῧ</span>
    <span class="char" data-decimal="8168" data-entity="&amp;#8168;" data-id="44232" title="Greek Capital Letter Upsilon With Vrachy">Ῠ</span>
    <span class="char" data-decimal="8169" data-entity="&amp;#8169;" data-id="44233" title="Greek Capital Letter Upsilon With Macron">Ῡ</span>
    <span class="char" data-decimal="8170" data-entity="&amp;#8170;" data-id="44234" title="Greek Capital Letter Upsilon With Varia">Ὺ</span>
    <span class="char" data-decimal="8171" data-entity="&amp;#8171;" data-id="44235" title="Greek Capital Letter Upsilon With Oxia">Ύ</span>
    <span class="char" data-decimal="8172" data-entity="&amp;#8172;" data-id="44236" title="Greek Capital Letter Rho With Dasia">Ῥ</span>
    <span class="char" data-decimal="8173" data-entity="&amp;#8173;" data-id="44237" title="Greek Dialytika And Varia">῭</span>
    <span class="char" data-decimal="8174" data-entity="&amp;#8174;" data-id="44238" title="Greek Dialytika And Oxia">΅</span>
    <span class="char" data-decimal="8175" data-entity="&amp;#8175;" data-id="44239" title="Greek Varia">`</span>
    <span class="char" data-decimal="8178" data-entity="&amp;#8178;" data-id="44240" title="Greek Small Letter Omega With Varia And Ypogegrammeni">ῲ</span>
    <span class="char" data-decimal="8179" data-entity="&amp;#8179;" data-id="44241" title="Greek Small Letter Omega With Ypogegrammeni">ῳ</span>
    <span class="char" data-decimal="8180" data-entity="&amp;#8180;" data-id="44242" title="Greek Small Letter Omega With Oxia And Ypogegrammeni">ῴ</span>
    <span class="char" data-decimal="8182" data-entity="&amp;#8182;" data-id="44243" title="Greek Small Letter Omega With Perispomeni">ῶ</span>
    <span class="char" data-decimal="8183" data-entity="&amp;#8183;" data-id="44244" title="Greek Small Letter Omega With Perispomeni And Ypogegrammeni">ῷ</span>
    <span class="char" data-decimal="8184" data-entity="&amp;#8184;" data-id="44245" title="Greek Capital Letter Omicron With Varia">Ὸ</span>
    <span class="char" data-decimal="8185" data-entity="&amp;#8185;" data-id="44246" title="Greek Capital Letter Omicron With Oxia">Ό</span>
    <span class="char" data-decimal="8186" data-entity="&amp;#8186;" data-id="44247" title="Greek Capital Letter Omega With Varia">Ὼ</span>
    <span class="char" data-decimal="8187" data-entity="&amp;#8187;" data-id="44248" title="Greek Capital Letter Omega With Oxia">Ώ</span>
    <span class="char" data-decimal="8188" data-entity="&amp;#8188;" data-id="44249" title="Greek Capital Letter Omega With Prosgegrammeni">ῼ</span>
    <span class="char" data-decimal="8189" data-entity="&amp;#8189;" data-id="44250" title="Greek Oxia">´</span>
    <span class="char" data-decimal="8190" data-entity="&amp;#8190;" data-id="44251" title="Greek Dasia">῾</span>
    <span class="char" data-decimal="880" data-entity="&amp;#880;" data-id="44252" title="Greek Capital Letter Heta">Ͱ</span>
    <span class="char" data-decimal="881" data-entity="&amp;#881;" data-id="44253" title="Greek Small Letter Heta">ͱ</span>
    <span class="char" data-decimal="882" data-entity="&amp;#882;" data-id="44254" title="Greek Capital Letter Archaic Sampi">Ͳ</span>
    <span class="char" data-decimal="883" data-entity="&amp;#883;" data-id="44255" title="Greek Small Letter Archaic Sampi">ͳ</span>
    <span class="char" data-decimal="884" data-entity="&amp;#884;" data-id="44256" title="Greek Numeral Sign">ʹ</span>
    <span class="char" data-decimal="885" data-entity="&amp;#885;" data-id="44257" title="Greek Lower Numeral Sign">͵</span>
    <span class="char" data-decimal="886" data-entity="&amp;#886;" data-id="44258" title="Greek Capital Letter Pamphylian Digamma">Ͷ</span>
    <span class="char" data-decimal="887" data-entity="&amp;#887;" data-id="44259" title="Greek Small Letter Pamphylian Digamma">ͷ</span>
    <span class="char" data-decimal="890" data-entity="&amp;#890;" data-id="44260" title="Greek Ypogegrammeni">ͺ</span>
    <span class="char" data-decimal="891" data-entity="&amp;#891;" data-id="44261" title="Greek Small Reversed Lunate Sigma Symbol">ͻ</span>
    <span class="char" data-decimal="892" data-entity="&amp;#892;" data-id="44262" title="Greek Small Dotted Lunate Sigma Symbol">ͼ</span>
    <span class="char" data-decimal="893" data-entity="&amp;#893;" data-id="44263" title="Greek Small Reversed Dotted Lunate Sigma Symbol">ͽ</span>
    <span class="char" data-decimal="894" data-entity="&amp;#894;" data-id="44264" title="Greek Question Mark">;</span>
    <span class="char" data-decimal="900" data-entity="&amp;#900;" data-id="44265" title="Greek Tonos">΄</span>
    <span class="char" data-decimal="901" data-entity="&amp;#901;" data-id="44266" title="Greek Dialytika Tonos">΅</span>
    <span class="char" data-decimal="902" data-entity="&amp;#902;" data-id="44267" title="Greek Capital Letter Alpha With Tonos">Ά</span>
    <span class="char" data-decimal="903" data-entity="&amp;#903;" data-id="44268" title="Greek Ano Teleia">·</span>
    <span class="char" data-decimal="904" data-entity="&amp;#904;" data-id="44269" title="Greek Capital Letter Epsilon With Tonos">Έ</span>
    <span class="char" data-decimal="905" data-entity="&amp;#905;" data-id="44270" title="Greek Capital Letter Eta With Tonos">Ή</span>
    <span class="char" data-decimal="906" data-entity="&amp;#906;" data-id="44271" title="Greek Capital Letter Iota With Tonos">Ί</span>
    <span class="char" data-decimal="908" data-entity="&amp;#908;" data-id="44272" title="Greek Capital Letter Omicron With Tonos">Ό</span>
    <span class="char" data-decimal="910" data-entity="&amp;#910;" data-id="44273" title="Greek Capital Letter Upsilon With Tonos">Ύ</span>
    <span class="char" data-decimal="911" data-entity="&amp;#911;" data-id="44274" title="Greek Capital Letter Omega With Tonos">Ώ</span>
    <span class="char" data-decimal="912" data-entity="&amp;#912;" data-id="44275" title="Greek Small Letter Iota With Dialytika And Tonos">ΐ</span>
    <span class="char" data-decimal="913" data-entity="&amp;Alpha;" data-id="44276" title="Greek Capital Letter Alpha">Α</span>
    <span class="char" data-decimal="914" data-entity="&amp;Beta;" data-id="44277" title="Greek Capital Letter Beta">Β</span>
    <span class="char" data-decimal="915" data-entity="&amp;Gamma;" data-id="44278" title="Greek Capital Letter Gamma">Γ</span>
    <span class="char" data-decimal="916" data-entity="&amp;Delta;" data-id="44279" title="Greek Capital Letter Delta">Δ</span>
    <span class="char" data-decimal="917" data-entity="&amp;Epsilon;" data-id="44280" title="Greek Capital Letter Epsilon">Ε</span>
    <span class="char" data-decimal="918" data-entity="&amp;Zeta;" data-id="44281" title="Greek Capital Letter Zeta">Ζ</span>
    <span class="char" data-decimal="919" data-entity="&amp;Eta;" data-id="44282" title="Greek Capital Letter Eta">Η</span>
    <span class="char" data-decimal="920" data-entity="&amp;Theta;" data-id="44283" title="Greek Capital Letter Theta">Θ</span>
    <span class="char" data-decimal="921" data-entity="&amp;Iota;" data-id="44284" title="Greek Capital Letter Iota">Ι</span>
    <span class="char" data-decimal="922" data-entity="&amp;Kappa;" data-id="44285" title="Greek Capital Letter Kappa">Κ</span>
    <span class="char" data-decimal="923" data-entity="&amp;Lambda;" data-id="44286" title="Greek Capital Letter Lamda">Λ</span>
    <span class="char" data-decimal="924" data-entity="&amp;Mu;" data-id="44287" title="Greek Capital Letter Mu">Μ</span>
    <span class="char" data-decimal="925" data-entity="&amp;Nu;" data-id="44288" title="Greek Capital Letter Nu">Ν</span>
    <span class="char" data-decimal="926" data-entity="&amp;Xi;" data-id="44289" title="Greek Capital Letter Xi">Ξ</span>
    <span class="char" data-decimal="927" data-entity="&amp;Omicron;" data-id="44290" title="Greek Capital Letter Omicron">Ο</span>
    <span class="char" data-decimal="928" data-entity="&amp;Pi;" data-id="44291" title="Greek Capital Letter Pi">Π</span>
    <span class="char" data-decimal="929" data-entity="&amp;Rho;" data-id="44292" title="Greek Capital Letter Rho">Ρ</span>
    <span class="char" data-decimal="931" data-entity="&amp;Sigma;" data-id="44293" title="Greek Capital Letter Sigma">Σ</span>
    <span class="char" data-decimal="932" data-entity="&amp;Tau;" data-id="44294" title="Greek Capital Letter Tau">Τ</span>
    <span class="char" data-decimal="933" data-entity="&amp;Upsilon;" data-id="44295" title="Greek Capital Letter Upsilon">Υ</span>
    <span class="char" data-decimal="934" data-entity="&amp;Phi;" data-id="44296" title="Greek Capital Letter Phi">Φ</span>
    <span class="char" data-decimal="935" data-entity="&amp;Chi;" data-id="44297" title="Greek Capital Letter Chi">Χ</span>
    <span class="char" data-decimal="936" data-entity="&amp;Psi;" data-id="44298" title="Greek Capital Letter Psi">Ψ</span>
    <span class="char" data-decimal="937" data-entity="&amp;Omega;" data-id="44299" title="Greek Capital Letter Omega">Ω</span>
    <span class="char" data-decimal="938" data-entity="&amp;#938;" data-id="44300" title="Greek Capital Letter Iota With Dialytika">Ϊ</span>
    <span class="char" data-decimal="939" data-entity="&amp;#939;" data-id="44301" title="Greek Capital Letter Upsilon With Dialytika">Ϋ</span>
    <span class="char" data-decimal="940" data-entity="&amp;#940;" data-id="44302" title="Greek Small Letter Alpha With Tonos">ά</span>
    <span class="char" data-decimal="941" data-entity="&amp;#941;" data-id="44303" title="Greek Small Letter Epsilon With Tonos">έ</span>
    <span class="char" data-decimal="942" data-entity="&amp;#942;" data-id="44304" title="Greek Small Letter Eta With Tonos">ή</span>
    <span class="char" data-decimal="943" data-entity="&amp;#943;" data-id="44305" title="Greek Small Letter Iota With Tonos">ί</span>
    <span class="char" data-decimal="944" data-entity="&amp;#944;" data-id="44306" title="Greek Small Letter Upsilon With Dialytika And Tonos">ΰ</span>
    <span class="char" data-decimal="945" data-entity="&amp;alpha;" data-id="44307" title="Greek Small Letter Alpha">α</span>
    <span class="char" data-decimal="946" data-entity="&amp;beta;" data-id="44308" title="Greek Small Letter Beta">β</span>
    <span class="char" data-decimal="947" data-entity="&amp;gamma;" data-id="44309" title="Greek Small Letter Gamma">γ</span>
    <span class="char" data-decimal="948" data-entity="&amp;delta;" data-id="44310" title="Greek Small Letter Delta">δ</span>
    <span class="char" data-decimal="949" data-entity="&amp;epsilon;" data-id="44311" title="Greek Small Letter Epsilon">ε</span>
    <span class="char" data-decimal="950" data-entity="&amp;zeta;" data-id="44312" title="Greek Small Letter Zeta">ζ</span>
    <span class="char" data-decimal="951" data-entity="&amp;eta;" data-id="44313" title="Greek Small Letter Eta">η</span>
    <span class="char" data-decimal="952" data-entity="&amp;theta;" data-id="44314" title="Greek Small Letter Theta">θ</span>
    <span class="char" data-decimal="953" data-entity="&amp;iota;" data-id="44315" title="Greek Small Letter Iota">ι</span>
    <span class="char" data-decimal="954" data-entity="&amp;kappa;" data-id="44316" title="Greek Small Letter Kappa">κ</span>
    <span class="char" data-decimal="955" data-entity="&amp;lambda;" data-id="44317" title="Greek Small Letter Lamda">λ</span>
    <span class="char" data-decimal="956" data-entity="&amp;mu;" data-id="44318" title="Greek Small Letter Mu">μ</span>
    <span class="char" data-decimal="957" data-entity="&amp;nu;" data-id="44319" title="Greek Small Letter Nu">ν</span>
    <span class="char" data-decimal="958" data-entity="&amp;xi;" data-id="44320" title="Greek Small Letter Xi">ξ</span>
    <span class="char" data-decimal="959" data-entity="&amp;omicron;" data-id="44321" title="Greek Small Letter Omicron">ο</span>
    <span class="char" data-decimal="960" data-entity="&amp;pi;" data-id="44322" title="Greek Small Letter Pi">π</span>
    <span class="char" data-decimal="961" data-entity="&amp;rho;" data-id="44323" title="Greek Small Letter Rho">ρ</span>
    <span class="char" data-decimal="962" data-entity="&amp;sigmaf;" data-id="44324" title="Greek Small Letter Final Sigma">ς</span>
    <span class="char" data-decimal="963" data-entity="&amp;sigma;" data-id="44325" title="Greek Small Letter Sigma">σ</span>
    <span class="char" data-decimal="964" data-entity="&amp;tau;" data-id="44326" title="Greek Small Letter Tau">τ</span>
    <span class="char" data-decimal="965" data-entity="&amp;upsilon;" data-id="44327" title="Greek Small Letter Upsilon">υ</span>
    <span class="char" data-decimal="966" data-entity="&amp;phi;" data-id="44328" title="Greek Small Letter Phi">φ</span>
    <span class="char" data-decimal="967" data-entity="&amp;chi;" data-id="44329" title="Greek Small Letter Chi">χ</span>
    <span class="char" data-decimal="968" data-entity="&amp;psi;" data-id="44330" title="Greek Small Letter Psi">ψ</span>
    <span class="char" data-decimal="969" data-entity="&amp;omega;" data-id="44331" title="Greek Small Letter Omega">ω</span>
    <span class="char" data-decimal="970" data-entity="&amp;#970;" data-id="44332" title="Greek Small Letter Iota With Dialytika">ϊ</span>
    <span class="char" data-decimal="971" data-entity="&amp;#971;" data-id="44333" title="Greek Small Letter Upsilon With Dialytika">ϋ</span>
    <span class="char" data-decimal="972" data-entity="&amp;#972;" data-id="44334" title="Greek Small Letter Omicron With Tonos">ό</span>
    <span class="char" data-decimal="973" data-entity="&amp;#973;" data-id="44335" title="Greek Small Letter Upsilon With Tonos">ύ</span>
    <span class="char" data-decimal="974" data-entity="&amp;#974;" data-id="44336" title="Greek Small Letter Omega With Tonos">ώ</span>
    <span class="char" data-decimal="976" data-entity="&amp;#976;" data-id="44337" title="Greek Beta Symbol">ϐ</span>
    <span class="char" data-decimal="977" data-entity="&amp;thetasym;" data-id="44338" title="Greek Theta Symbol">ϑ</span>
    <span class="char" data-decimal="978" data-entity="&amp;upsih;" data-id="44339" title="Greek Upsilon With Hook Symbol">ϒ</span>
    <span class="char" data-decimal="979" data-entity="&amp;#979;" data-id="44340" title="Greek Upsilon With Acute And Hook Symbol">ϓ</span>
    <span class="char" data-decimal="980" data-entity="&amp;#980;" data-id="44341" title="Greek Upsilon With Diaeresis And Hook Symbol">ϔ</span>
    <span class="char" data-decimal="981" data-entity="&amp;#981;" data-id="44342" title="Greek Phi Symbol">ϕ</span>
    <span class="char" data-decimal="982" data-entity="&amp;piv;" data-id="44343" title="Greek Pi Symbol">ϖ</span>
    <span class="char" data-decimal="983" data-entity="&amp;#983;" data-id="44344" title="Greek Kai Symbol">ϗ</span>
    <span class="char" data-decimal="984" data-entity="&amp;#984;" data-id="44345" title="Greek Letter Archaic Koppa">Ϙ</span>
    <span class="char" data-decimal="985" data-entity="&amp;#985;" data-id="44346" title="Greek Small Letter Archaic Koppa">ϙ</span>
    <span class="char" data-decimal="986" data-entity="&amp;#986;" data-id="44347" title="Greek Letter Stigma">Ϛ</span>
    <span class="char" data-decimal="987" data-entity="&amp;#987;" data-id="44348" title="Greek Small Letter Stigma">ϛ</span>
    <span class="char" data-decimal="988" data-entity="&amp;#988;" data-id="44349" title="Greek Letter Digamma">Ϝ</span>
    <span class="char" data-decimal="989" data-entity="&amp;#989;" data-id="44350" title="Greek Small Letter Digamma">ϝ</span>
    <span class="char" data-decimal="990" data-entity="&amp;#990;" data-id="44351" title="Greek Letter Koppa">Ϟ</span>
    <span class="char" data-decimal="991" data-entity="&amp;#991;" data-id="44352" title="Greek Small Letter Koppa">ϟ</span>
    <span class="char" data-decimal="992" data-entity="&amp;#992;" data-id="44353" title="Greek Letter Sampi">Ϡ</span>
    <span class="char" data-decimal="993" data-entity="&amp;#993;" data-id="44354" title="Greek Small Letter Sampi">ϡ</span>
    <span class="char" data-decimal="994" data-entity="&amp;#994;" data-id="44355" title="Coptic Capital Letter Shei">Ϣ</span>
    <span class="char" data-decimal="995" data-entity="&amp;#995;" data-id="44356" title="Coptic Small Letter Shei">ϣ</span>
    <span class="char" data-decimal="996" data-entity="&amp;#996;" data-id="44357" title="Coptic Capital Letter Fei">Ϥ</span>
    <span class="char" data-decimal="997" data-entity="&amp;#997;" data-id="44358" title="Coptic Small Letter Fei">ϥ</span>
    <span class="char" data-decimal="998" data-entity="&amp;#998;" data-id="44359" title="Coptic Capital Letter Khei">Ϧ</span>
    <span class="char" data-decimal="999" data-entity="&amp;#999;" data-id="44360" title="Coptic Small Letter Khei">ϧ</span>
    <span class="char" data-decimal="1000" data-entity="&amp;#1000;" data-id="44361" title="Coptic Capital Letter Hori">Ϩ</span>
    <span class="char" data-decimal="1001" data-entity="&amp;#1001;" data-id="44362" title="Coptic Small Letter Hori">ϩ</span>
    <span class="char" data-decimal="1002" data-entity="&amp;#1002;" data-id="44363" title="Coptic Capital Letter Gangia">Ϫ</span>
    <span class="char" data-decimal="1003" data-entity="&amp;#1003;" data-id="44364" title="Coptic Small Letter Gangia">ϫ</span>
    <span class="char" data-decimal="1004" data-entity="&amp;#1004;" data-id="44365" title="Coptic Capital Letter Shima">Ϭ</span>
    <span class="char" data-decimal="1005" data-entity="&amp;#1005;" data-id="44366" title="Coptic Small Letter Shima">ϭ</span>
    <span class="char" data-decimal="1006" data-entity="&amp;#1006;" data-id="44367" title="Coptic Capital Letter Dei">Ϯ</span>
    <span class="char" data-decimal="1007" data-entity="&amp;#1007;" data-id="44368" title="Coptic Small Letter Dei">ϯ</span>
    <span class="char" data-decimal="1008" data-entity="&amp;#1008;" data-id="44369" title="Greek Kappa Symbol">ϰ</span>
    <span class="char" data-decimal="1009" data-entity="&amp;#1009;" data-id="44370" title="Greek Rho Symbol">ϱ</span>
    <span class="char" data-decimal="1010" data-entity="&amp;#1010;" data-id="44371" title="Greek Lunate Sigma Symbol">ϲ</span>
    <span class="char" data-decimal="1011" data-entity="&amp;#1011;" data-id="44372" title="Greek Letter Yot">ϳ</span>
    <span class="char" data-decimal="1012" data-entity="&amp;#1012;" data-id="44373" title="Greek Capital Theta Symbol">ϴ</span>
    <span class="char" data-decimal="1013" data-entity="&amp;#1013;" data-id="44374" title="Greek Lunate Epsilon Symbol">ϵ</span>
    <span class="char" data-decimal="1014" data-entity="&amp;#1014;" data-id="44375" title="Greek Reversed Lunate Epsilon Symbol">϶</span>
    <span class="char" data-decimal="1015" data-entity="&amp;#1015;" data-id="44376" title="Greek Capital Letter Sho">Ϸ</span>
    <span class="char" data-decimal="1016" data-entity="&amp;#1016;" data-id="44377" title="Greek Small Letter Sho">ϸ</span>
    <span class="char" data-decimal="1017" data-entity="&amp;#1017;" data-id="44378" title="Greek Capital Lunate Sigma Symbol">Ϲ</span>
    <span class="char" data-decimal="1018" data-entity="&amp;#1018;" data-id="44379" title="Greek Capital Letter San">Ϻ</span>
    <span class="char" data-decimal="1019" data-entity="&amp;#1019;" data-id="44380" title="Greek Small Letter San">ϻ</span>
    <span class="char" data-decimal="1020" data-entity="&amp;#1020;" data-id="44381" title="Greek Rho With Stroke Symbol">ϼ</span>
    <span class="char" data-decimal="1021" data-entity="&amp;#1021;" data-id="44382" title="Greek Capital Reversed Lunate Sigma Symbol">Ͻ</span>
    <span class="char" data-decimal="1022" data-entity="&amp;#1022;" data-id="44383" title="Greek Capital Dotted Lunate Sigma Symbol">Ͼ</span>
    <span class="char" data-decimal="1023" data-entity="&amp;#1023;" data-id="44384" title="Greek Capital Reversed Dotted Lunate Sigma Symbol">Ͽ</span>
    <span class="char" data-decimal="9398" data-entity="&amp;#9398;" data-id="43849" title="Circled Latin Capital Letter A">Ⓐ</span>
    <span class="char" data-decimal="9424" data-entity="&amp;#9424;" data-id="43875" title="Circled Latin Small Letter A">ⓐ</span>
    <span class="char" data-decimal="9372" data-entity="&amp;#9372;" data-id="43823" title="Parenthesized Latin Small Letter A">⒜</span>
    <span class="char" data-decimal="65" data-entity="&amp;#65;" data-id="44801" title="Latin Capital Letter A">A</span>
    <span class="char" data-decimal="97" data-entity="&amp;#97;" data-id="44833" title="Latin Small Letter A">a</span>
    <span class="char" data-decimal="7840" data-entity="&amp;#7840;" data-id="44545" title="Latin Capital Letter A With Dot Below">Ạ</span>
    <span class="char" data-decimal="7841" data-entity="&amp;#7841;" data-id="44546" title="Latin Small Letter A With Dot Below">ạ</span>
    <span class="char" data-decimal="7842" data-entity="&amp;#7842;" data-id="44547" title="Latin Capital Letter A With Hook Above">Ả</span>
    <span class="char" data-decimal="7843" data-entity="&amp;#7843;" data-id="44548" title="Latin Small Letter A With Hook Above">ả</span>
    <span class="char" data-decimal="7680" data-entity="&amp;#7680;" data-id="44385" title="Latin Capital Letter A With Ring Below">Ḁ</span>
    <span class="char" data-decimal="7681" data-entity="&amp;#7681;" data-id="44386" title="Latin Small Letter A With Ring Below">ḁ</span>
    <span class="char" data-decimal="194" data-entity="&amp;Acirc;" data-id="45177" title="Latin Capital Letter A With Circumflex">Â</span>
    <span class="char" data-decimal="195" data-entity="&amp;Atilde;" data-id="45178" title="Latin Capital Letter A With Tilde">Ã</span>
    <span class="char" data-decimal="461" data-entity="&amp;#461;" data-id="44940" title="Latin Capital Letter A With Caron">Ǎ</span>
    <span class="char" data-decimal="462" data-entity="&amp;#462;" data-id="44941" title="Latin Small Letter A With Caron">ǎ</span>
    <span class="char" data-decimal="7844" data-entity="&amp;#7844;" data-id="44549" title="Latin Capital Letter A With Circumflex And Acute">Ấ</span>
    <span class="char" data-decimal="7845" data-entity="&amp;#7845;" data-id="44550" title="Latin Small Letter A With Circumflex And Acute">ấ</span>
    <span class="char" data-decimal="7846" data-entity="&amp;#7846;" data-id="44551" title="Latin Capital Letter A With Circumflex And Grave">Ầ</span>
    <span class="char" data-decimal="7847" data-entity="&amp;#7847;" data-id="44552" title="Latin Small Letter A With Circumflex And Grave">ầ</span>
    <span class="char" data-decimal="7848" data-entity="&amp;#7848;" data-id="44553" title="Latin Capital Letter A With Circumflex And Hook Above">Ẩ</span>
    <span class="char" data-decimal="7849" data-entity="&amp;#7849;" data-id="44554" title="Latin Small Letter A With Circumflex And Hook Above">ẩ</span>
    <span class="char" data-decimal="514" data-entity="&amp;#514;" data-id="44993" title="Latin Capital Letter A With Inverted Breve">Ȃ</span>
    <span class="char" data-decimal="515" data-entity="&amp;#515;" data-id="44994" title="Latin Small Letter A With Inverted Breve">ȃ</span>
    <span class="char" data-decimal="7850" data-entity="&amp;#7850;" data-id="44555" title="Latin Capital Letter A With Circumflex And Tilde">Ẫ</span>
    <span class="char" data-decimal="7851" data-entity="&amp;#7851;" data-id="44556" title="Latin Small Letter A With Circumflex And Tilde">ẫ</span>
    <span class="char" data-decimal="7852" data-entity="&amp;#7852;" data-id="44557" title="Latin Capital Letter A With Circumflex And Dot Below">Ậ</span>
    <span class="char" data-decimal="7853" data-entity="&amp;#7853;" data-id="44558" title="Latin Small Letter A With Circumflex And Dot Below">ậ</span>
    <span class="char" data-decimal="192" data-entity="&amp;Agrave;" data-id="45175" title="Latin Capital Letter A With Grave">À</span>
    <span class="char" data-decimal="193" data-entity="&amp;Aacute;" data-id="45176" title="Latin Capital Letter A With Acute">Á</span>
    <span class="char" data-decimal="7854" data-entity="&amp;#7854;" data-id="44559" title="Latin Capital Letter A With Breve And Acute">Ắ</span>
    <span class="char" data-decimal="7855" data-entity="&amp;#7855;" data-id="44560" title="Latin Small Letter A With Breve And Acute">ắ</span>
    <span class="char" data-decimal="7856" data-entity="&amp;#7856;" data-id="44561" title="Latin Capital Letter A With Breve And Grave">Ằ</span>
    <span class="char" data-decimal="7857" data-entity="&amp;#7857;" data-id="44562" title="Latin Small Letter A With Breve And Grave">ằ</span>
    <span class="char" data-decimal="7858" data-entity="&amp;#7858;" data-id="44563" title="Latin Capital Letter A With Breve And Hook Above">Ẳ</span>
    <span class="char" data-decimal="7859" data-entity="&amp;#7859;" data-id="44564" title="Latin Small Letter A With Breve And Hook Above">ẳ</span>
    <span class="char" data-decimal="7860" data-entity="&amp;#7860;" data-id="44565" title="Latin Capital Letter A With Breve And Tilde">Ẵ</span>
    <span class="char" data-decimal="7861" data-entity="&amp;#7861;" data-id="44566" title="Latin Small Letter A With Breve And Tilde">ẵ</span>
    <span class="char" data-decimal="7862" data-entity="&amp;#7862;" data-id="44567" title="Latin Capital Letter A With Breve And Dot Below">Ặ</span>
    <span class="char" data-decimal="7863" data-entity="&amp;#7863;" data-id="44568" title="Latin Small Letter A With Breve And Dot Below">ặ</span>
    <span class="char" data-decimal="256" data-entity="&amp;#256;" data-id="44641" title="Latin Capital Letter A With Macron">Ā</span>
    <span class="char" data-decimal="257" data-entity="&amp;#257;" data-id="44642" title="Latin Small Letter A With Macron">ā</span>
    <span class="char" data-decimal="260" data-entity="&amp;#260;" data-id="44645" title="Latin Capital Letter A With Ogonek">Ą</span>
    <span class="char" data-decimal="261" data-entity="&amp;#261;" data-id="44646" title="Latin Small Letter A With Ogonek">ą</span>
    <span class="char" data-decimal="478" data-entity="&amp;#478;" data-id="44957" title="Latin Capital Letter A With Diaeresis And Macron">Ǟ</span>
    <span class="char" data-decimal="512" data-entity="&amp;#512;" data-id="44991" title="Latin Capital Letter A With Double Grave">Ȁ</span>
    <span class="char" data-decimal="513" data-entity="&amp;#513;" data-id="44992" title="Latin Small Letter A With Double Grave">ȁ</span>
    <span class="char" data-decimal="197" data-entity="&amp;Aring;" data-id="45180" title="Latin Capital Letter A With Ring Above">Å</span>
    <span class="char" data-decimal="506" data-entity="&amp;#506;" data-id="44985" title="Latin Capital Letter A With Ring Above And Acute">Ǻ</span>
    <span class="char" data-decimal="507" data-entity="&amp;#507;" data-id="44986" title="Latin Small Letter A With Ring Above And Acute">ǻ</span>
    <span class="char" data-decimal="196" data-entity="&amp;Auml;" data-id="45179" title="Latin Capital Letter A With Diaeresis">Ä</span>
    <span class="char" data-decimal="228" data-entity="&amp;auml;" data-id="45211" title="Latin Small Letter A With Diaeresis">ä</span>
    <span class="char" data-decimal="479" data-entity="&amp;#479;" data-id="44958" title="Latin Small Letter A With Diaeresis And Macron">ǟ</span>
    <span class="char" data-decimal="480" data-entity="&amp;#480;" data-id="44959" title="Latin Capital Letter A With Dot Above And Macron">Ǡ</span>
    <span class="char" data-decimal="481" data-entity="&amp;#481;" data-id="44960" title="Latin Small Letter A With Dot Above And Macron">ǡ</span>
    <span class="char" data-decimal="226" data-entity="&amp;acirc;" data-id="45209" title="Latin Small Letter A With Circumflex">â</span>
    <span class="char" data-decimal="225" data-entity="&amp;aacute;" data-id="45208" title="Latin Small Letter A With Acute">á</span>
    <span class="char" data-decimal="229" data-entity="&amp;aring;" data-id="45212" title="Latin Small Letter A With Ring Above">å</span>
    <span class="char" data-decimal="227" data-entity="&amp;atilde;" data-id="45210" title="Latin Small Letter A With Tilde">ã</span>
    <span class="char" data-decimal="224" data-entity="&amp;agrave;" data-id="45207" title="Latin Small Letter A With Grave">à</span>
    <span class="char" data-decimal="7834" data-entity="&amp;#7834;" data-id="44539" title="Latin Small Letter A With Right Half Ring">ẚ</span>
    <span class="char" data-decimal="550" data-entity="&amp;#550;" data-id="45029" title="Latin Capital Letter A With Dot Above">Ȧ</span>
    <span class="char" data-decimal="551" data-entity="&amp;#551;" data-id="45030" title="Latin Small Letter A With Dot Above">ȧ</span>
    <span class="char" data-decimal="570" data-entity="&amp;#570;" data-id="45049" title="Latin Capital Letter A With Stroke">Ⱥ</span>
    <span class="char" data-decimal="8491" data-entity="&amp;#8491;" data-id="45282" title="Angstrom Sign">Å</span>
    <span class="char" data-decimal="11365" data-entity="&amp;#11365;" data-id="45076" title="Latin Small Letter A With Stroke">ⱥ</span>
    <span class="char" data-decimal="198" data-entity="&amp;AElig;" data-id="45181" title="Latin Capital Letter Ae">Æ</span>
    <span class="char" data-decimal="230" data-entity="&amp;aelig;" data-id="45213" title="Latin Small Letter Ae">æ</span>
    <span class="char" data-decimal="508" data-entity="&amp;#508;" data-id="44987" title="Latin Capital Letter Ae With Acute">Ǽ</span>
    <span class="char" data-decimal="482" data-entity="&amp;#482;" data-id="44961" title="Latin Capital Letter Ae With Macron">Ǣ</span>
    <span class="char" data-decimal="483" data-entity="&amp;#483;" data-id="44962" title="Latin Small Letter Ae With Macron">ǣ</span>
    <span class="char" data-decimal="11375" data-entity="&amp;#11375;" data-id="45086" title="Latin Capital Letter Turned A">Ɐ</span>
    <span class="char" data-decimal="42802" data-entity="&amp;#42802;" data-id="45109" title="Latin Capital Letter Aa">Ꜳ</span>
    <span class="char" data-decimal="42803" data-entity="&amp;#42803;" data-id="45110" title="Latin Small Letter Aa">ꜳ</span>
    <span class="char" data-decimal="42808" data-entity="&amp;#42808;" data-id="45111" title="Latin Capital Letter Av">Ꜹ</span>
    <span class="char" data-decimal="42810" data-entity="&amp;#42810;" data-id="45113" title="Latin Capital Letter Av With Horizontal Bar">Ꜻ</span>
    <span class="char" data-decimal="11373" data-entity="&amp;#11373;" data-id="45084" title="Latin Capital Letter Alpha">Ɑ</span>
    <span class="char" data-decimal="42809" data-entity="&amp;#42809;" data-id="45112" title="Latin Small Letter Av">ꜹ</span>
    <span class="char" data-decimal="42811" data-entity="&amp;#42811;" data-id="45114" title="Latin Small Letter Av With Horizontal Bar">ꜻ</span>
    <span class="char" data-decimal="170" data-entity="&amp;ordf;" data-id="45153" title="Feminine Ordinal Indicator">ª</span>
    <span class="char" data-decimal="8448" data-entity="&amp;#8448;" data-id="45239" title="Account Of">℀</span>
    <span class="char" data-decimal="8525" data-entity="&amp;#8525;" data-id="45314" title="Aktieselskab">⅍</span>
    <span class="char" data-decimal="8449" data-entity="&amp;#8449;" data-id="45240" title="Addressed To The Subject">℁</span>
    <span class="char" data-decimal="9399" data-entity="&amp;#9399;" data-id="43850" title="Circled Latin Capital Letter B">Ⓑ</span>
    <span class="char" data-decimal="9425" data-entity="&amp;#9425;" data-id="43876" title="Circled Latin Small Letter B">ⓑ</span>
    <span class="char" data-decimal="9373" data-entity="&amp;#9373;" data-id="43824" title="Parenthesized Latin Small Letter B">⒝</span>
    <span class="char" data-decimal="66" data-entity="&amp;#66;" data-id="44802" title="Latin Capital Letter B">B</span>
    <span class="char" data-decimal="98" data-entity="&amp;#98;" data-id="44834" title="Latin Small Letter B">b</span>
    <span class="char" data-decimal="7682" data-entity="&amp;#7682;" data-id="44387" title="Latin Capital Letter B With Dot Above">Ḃ</span>
    <span class="char" data-decimal="7683" data-entity="&amp;#7683;" data-id="44388" title="Latin Small Letter B With Dot Above">ḃ</span>
    <span class="char" data-decimal="7684" data-entity="&amp;#7684;" data-id="44389" title="Latin Capital Letter B With Dot Below">Ḅ</span>
    <span class="char" data-decimal="7685" data-entity="&amp;#7685;" data-id="44390" title="Latin Small Letter B With Dot Below">ḅ</span>
    <span class="char" data-decimal="7686" data-entity="&amp;#7686;" data-id="44391" title="Latin Capital Letter B With Line Below">Ḇ</span>
    <span class="char" data-decimal="7687" data-entity="&amp;#7687;" data-id="44392" title="Latin Small Letter B With Line Below">ḇ</span>
    <span class="char" data-decimal="385" data-entity="&amp;#385;" data-id="44864" title="Latin Capital Letter B With Hook">Ɓ</span>
    <span class="char" data-decimal="579" data-entity="&amp;#579;" data-id="45058" title="Latin Capital Letter B With Stroke">Ƀ</span>
    <span class="char" data-decimal="384" data-entity="&amp;#384;" data-id="44863" title="Latin Small Letter B With Stroke">ƀ</span>
    <span class="char" data-decimal="387" data-entity="&amp;#387;" data-id="44866" title="Latin Small Letter B With Topbar">ƃ</span>
    <span class="char" data-decimal="386" data-entity="&amp;#386;" data-id="44865" title="Latin Capital Letter B With Topbar">Ƃ</span>
    <span class="char" data-decimal="388" data-entity="&amp;#388;" data-id="44867" title="Latin Capital Letter Tone Six">Ƅ</span>
    <span class="char" data-decimal="389" data-entity="&amp;#389;" data-id="44868" title="Latin Small Letter Tone Six">ƅ</span>
    <span class="char" data-decimal="8492" data-entity="&amp;#8492;" data-id="45283" title="Script Capital B">ℬ</span>
    <span class="char" data-decimal="9400" data-entity="&amp;#9400;" data-id="43851" title="Circled Latin Capital Letter C">Ⓒ</span>
    <span class="char" data-decimal="9426" data-entity="&amp;#9426;" data-id="43877" title="Circled Latin Small Letter C">ⓒ</span>
    <span class="char" data-decimal="9374" data-entity="&amp;#9374;" data-id="43825" title="Parenthesized Latin Small Letter C">⒞</span>
    <span class="char" data-decimal="67" data-entity="&amp;#67;" data-id="44803" title="Latin Capital Letter C">C</span>
    <span class="char" data-decimal="99" data-entity="&amp;#99;" data-id="44835" title="Latin Small Letter C">c</span>
    <span class="char" data-decimal="7688" data-entity="&amp;#7688;" data-id="44393" title="Latin Capital Letter C With Cedilla And Acute">Ḉ</span>
    <span class="char" data-decimal="7689" data-entity="&amp;#7689;" data-id="44394" title="Latin Small Letter C With Cedilla And Acute">ḉ</span>
    <span class="char" data-decimal="262" data-entity="&amp;#262;" data-id="44647" title="Latin Capital Letter C With Acute">Ć</span>
    <span class="char" data-decimal="263" data-entity="&amp;#263;" data-id="44648" title="Latin Small Letter C With Acute">ć</span>
    <span class="char" data-decimal="264" data-entity="&amp;#264;" data-id="44649" title="Latin Capital Letter C With Circumflex">Ĉ</span>
    <span class="char" data-decimal="265" data-entity="&amp;#265;" data-id="44650" title="Latin Small Letter C With Circumflex">ĉ</span>
    <span class="char" data-decimal="266" data-entity="&amp;#266;" data-id="44651" title="Latin Capital Letter C With Dot Above">Ċ</span>
    <span class="char" data-decimal="267" data-entity="&amp;#267;" data-id="44652" title="Latin Small Letter C With Dot Above">ċ</span>
    <span class="char" data-decimal="268" data-entity="&amp;#268;" data-id="44653" title="Latin Capital Letter C With Caron">Č</span>
    <span class="char" data-decimal="269" data-entity="&amp;#269;" data-id="44654" title="Latin Small Letter C With Caron">č</span>
    <span class="char" data-decimal="199" data-entity="&amp;Ccedil;" data-id="45182" title="Latin Capital Letter C With Cedilla">Ç</span>
    <span class="char" data-decimal="231" data-entity="&amp;ccedil;" data-id="45214" title="Latin Small Letter C With Cedilla">ç</span>
    <span class="char" data-decimal="391" data-entity="&amp;#391;" data-id="44870" title="Latin Capital Letter C With Hook">Ƈ</span>
    <span class="char" data-decimal="392" data-entity="&amp;#392;" data-id="44871" title="Latin Small Letter C With Hook">ƈ</span>
    <span class="char" data-decimal="571" data-entity="&amp;#571;" data-id="45050" title="Latin Capital Letter C With Stroke">Ȼ</span>
    <span class="char" data-decimal="572" data-entity="&amp;#572;" data-id="45051" title="Latin Small Letter C With Stroke">ȼ</span>
    <span class="char" data-decimal="8450" data-entity="&amp;#8450;" data-id="45241" title="Double Struck Capital C">ℂ</span>
    <span class="char" data-decimal="8451" data-entity="&amp;#8451;" data-id="45242" title="Degree Celsius">℃</span>
    <span class="char" data-decimal="8493" data-entity="&amp;#8493;" data-id="45284" title="Black Letter Capital C">ℭ</span>
    <span class="char" data-decimal="390" data-entity="&amp;#390;" data-id="44869" title="Latin Capital Letter Open O">Ɔ</span>
    <span class="char" data-decimal="8453" data-entity="&amp;#8453;" data-id="45244" title="Care Of">℅</span>
    <span class="char" data-decimal="8454" data-entity="&amp;#8454;" data-id="45245" title="Cada Una">℆</span>
    <span class="char" data-decimal="8452" data-entity="&amp;#8452;" data-id="45243" title="Centre Line Symbol">℄</span>
    <span class="char" data-decimal="42814" data-entity="&amp;#42814;" data-id="45115" title="Latin Capital Letter Reversed C With Dot">Ꜿ</span>
    <span class="char" data-decimal="42815" data-entity="&amp;#42815;" data-id="45116" title="Latin Small Letter Reversed C With Dot">ꜿ</span>
    <span class="char" data-decimal="9401" data-entity="&amp;#9401;" data-id="43852" title="Circled Latin Capital Letter D">Ⓓ</span>
    <span class="char" data-decimal="9427" data-entity="&amp;#9427;" data-id="43878" title="Circled Latin Small Letter D">ⓓ</span>
    <span class="char" data-decimal="9375" data-entity="&amp;#9375;" data-id="43826" title="Parenthesized Latin Small Letter D">⒟</span>
    <span class="char" data-decimal="68" data-entity="&amp;#68;" data-id="44804" title="Latin Capital Letter D">D</span>
    <span class="char" data-decimal="100" data-entity="&amp;#100;" data-id="44836" title="Latin Small Letter D">d</span>
    <span class="char" data-decimal="7690" data-entity="&amp;#7690;" data-id="44395" title="Latin Capital Letter D With Dot Above">Ḋ</span>
    <span class="char" data-decimal="7691" data-entity="&amp;#7691;" data-id="44396" title="Latin Small Letter D With Dot Above">ḋ</span>
    <span class="char" data-decimal="7692" data-entity="&amp;#7692;" data-id="44397" title="Latin Capital Letter D With Dot Below">Ḍ</span>
    <span class="char" data-decimal="7693" data-entity="&amp;#7693;" data-id="44398" title="Latin Small Letter D With Dot Below">ḍ</span>
    <span class="char" data-decimal="7694" data-entity="&amp;#7694;" data-id="44399" title="Latin Capital Letter D With Line Below">Ḏ</span>
    <span class="char" data-decimal="7695" data-entity="&amp;#7695;" data-id="44400" title="Latin Small Letter D With Line Below">ḏ</span>
    <span class="char" data-decimal="7696" data-entity="&amp;#7696;" data-id="44401" title="Latin Capital Letter D With Cedilla">Ḑ</span>
    <span class="char" data-decimal="7697" data-entity="&amp;#7697;" data-id="44402" title="Latin Small Letter D With Cedilla">ḑ</span>
    <span class="char" data-decimal="7698" data-entity="&amp;#7698;" data-id="44403" title="Latin Capital Letter D With Circumflex Below">Ḓ</span>
    <span class="char" data-decimal="7699" data-entity="&amp;#7699;" data-id="44404" title="Latin Small Letter D With Circumflex Below">ḓ</span>
    <span class="char" data-decimal="270" data-entity="&amp;#270;" data-id="44655" title="Latin Capital Letter D With Caron">Ď</span>
    <span class="char" data-decimal="271" data-entity="&amp;#271;" data-id="44656" title="Latin Small Letter D With Caron">ď</span>
    <span class="char" data-decimal="394" data-entity="&amp;#394;" data-id="44873" title="Latin Capital Letter D With Hook">Ɗ</span>
    <span class="char" data-decimal="395" data-entity="&amp;#395;" data-id="44874" title="Latin Capital Letter D With Topbar">Ƌ</span>
    <span class="char" data-decimal="396" data-entity="&amp;#396;" data-id="44875" title="Latin Small Letter D With Topbar">ƌ</span>
    <span class="char" data-decimal="393" data-entity="&amp;#393;" data-id="44872" title="Latin Capital Letter African D">Ɖ</span>
    <span class="char" data-decimal="272" data-entity="&amp;#272;" data-id="44657" title="Latin Capital Letter D With Stroke">Đ</span>
    <span class="char" data-decimal="273" data-entity="&amp;#273;" data-id="44658" title="Latin Small Letter D With Stroke">đ</span>
    <span class="char" data-decimal="545" data-entity="&amp;#545;" data-id="45024" title="Latin Small Letter D With Curl">ȡ</span>
    <span class="char" data-decimal="8517" data-entity="&amp;#8517;" data-id="45307" title="Double Struck Italic Capital D">ⅅ</span>
    <span class="char" data-decimal="8518" data-entity="&amp;#8518;" data-id="45308" title="Double Struck Italic Small D">ⅆ</span>
    <span class="char" data-decimal="497" data-entity="&amp;#497;" data-id="44976" title="Latin Capital Letter Dz">Ǳ</span>
    <span class="char" data-decimal="498" data-entity="&amp;#498;" data-id="44977" title="Latin Capital Letter D With Small Letter Z">ǲ</span>
    <span class="char" data-decimal="499" data-entity="&amp;#499;" data-id="44978" title="Latin Small Letter Dz">ǳ</span>
    <span class="char" data-decimal="452" data-entity="&amp;#452;" data-id="44931" title="Latin Capital Letter Dz With Caron">Ǆ</span>
    <span class="char" data-decimal="453" data-entity="&amp;#453;" data-id="44932" title="Latin Capital Letter D With Small Letter Z With Caron">ǅ</span>
    <span class="char" data-decimal="454" data-entity="&amp;#454;" data-id="44933" title="Latin Small Letter Dz With Caron">ǆ</span>
    <span class="char" data-decimal="568" data-entity="&amp;#568;" data-id="45047" title="Latin Small Letter Db Digraph">ȸ</span>
    <span class="char" data-decimal="9402" data-entity="&amp;#9402;" data-id="43853" title="Circled Latin Capital Letter E">Ⓔ</span>
    <span class="char" data-decimal="9428" data-entity="&amp;#9428;" data-id="43879" title="Circled Latin Small Letter E">ⓔ</span>
    <span class="char" data-decimal="9376" data-entity="&amp;#9376;" data-id="43827" title="Parenthesized Latin Small Letter E">⒠</span>
    <span class="char" data-decimal="69" data-entity="&amp;#69;" data-id="44805" title="Latin Capital Letter E">E</span>
    <span class="char" data-decimal="101" data-entity="&amp;#101;" data-id="44837" title="Latin Small Letter E">e</span>
    <span class="char" data-decimal="7700" data-entity="&amp;#7700;" data-id="44405" title="Latin Capital Letter E With Macron And Grave">Ḕ</span>
    <span class="char" data-decimal="7701" data-entity="&amp;#7701;" data-id="44406" title="Latin Small Letter E With Macron And Grave">ḕ</span>
    <span class="char" data-decimal="7702" data-entity="&amp;#7702;" data-id="44407" title="Latin Capital Letter E With Macron And Acute">Ḗ</span>
    <span class="char" data-decimal="7703" data-entity="&amp;#7703;" data-id="44408" title="Latin Small Letter E With Macron And Acute">ḗ</span>
    <span class="char" data-decimal="7704" data-entity="&amp;#7704;" data-id="44409" title="Latin Capital Letter E With Circumflex Below">Ḙ</span>
    <span class="char" data-decimal="7705" data-entity="&amp;#7705;" data-id="44410" title="Latin Small Letter E With Circumflex Below">ḙ</span>
    <span class="char" data-decimal="7706" data-entity="&amp;#7706;" data-id="44411" title="Latin Capital Letter E With Tilde Below">Ḛ</span>
    <span class="char" data-decimal="7707" data-entity="&amp;#7707;" data-id="44412" title="Latin Small Letter E With Tilde Below">ḛ</span>
    <span class="char" data-decimal="7708" data-entity="&amp;#7708;" data-id="44413" title="Latin Capital Letter E With Cedilla And Breve">Ḝ</span>
    <span class="char" data-decimal="7709" data-entity="&amp;#7709;" data-id="44414" title="Latin Small Letter E With Cedilla And Breve">ḝ</span>
    <span class="char" data-decimal="7864" data-entity="&amp;#7864;" data-id="44569" title="Latin Capital Letter E With Dot Below">Ẹ</span>
    <span class="char" data-decimal="7865" data-entity="&amp;#7865;" data-id="44570" title="Latin Small Letter E With Dot Below">ẹ</span>
    <span class="char" data-decimal="7866" data-entity="&amp;#7866;" data-id="44571" title="Latin Capital Letter E With Hook Above">Ẻ</span>
    <span class="char" data-decimal="7867" data-entity="&amp;#7867;" data-id="44572" title="Latin Small Letter E With Hook Above">ẻ</span>
    <span class="char" data-decimal="7870" data-entity="&amp;#7870;" data-id="44575" title="Latin Capital Letter E With Circumflex And Acute">Ế</span>
    <span class="char" data-decimal="7871" data-entity="&amp;#7871;" data-id="44576" title="Latin Small Letter E With Circumflex And Acute">ế</span>
    <span class="char" data-decimal="7868" data-entity="&amp;#7868;" data-id="44573" title="Latin Capital Letter E With Tilde">Ẽ</span>
    <span class="char" data-decimal="7869" data-entity="&amp;#7869;" data-id="44574" title="Latin Small Letter E With Tilde">ẽ</span>
    <span class="char" data-decimal="7872" data-entity="&amp;#7872;" data-id="44577" title="Latin Capital Letter E With Circumflex And Grave">Ề</span>
    <span class="char" data-decimal="7873" data-entity="&amp;#7873;" data-id="44578" title="Latin Small Letter E With Circumflex And Grave">ề</span>
    <span class="char" data-decimal="7874" data-entity="&amp;#7874;" data-id="44579" title="Latin Capital Letter E With Circumflex And Hook Above">Ể</span>
    <span class="char" data-decimal="7875" data-entity="&amp;#7875;" data-id="44580" title="Latin Small Letter E With Circumflex And Hook Above">ể</span>
    <span class="char" data-decimal="7876" data-entity="&amp;#7876;" data-id="44581" title="Latin Capital Letter E With Circumflex And Tilde">Ễ</span>
    <span class="char" data-decimal="7877" data-entity="&amp;#7877;" data-id="44582" title="Latin Small Letter E With Circumflex And Tilde">ễ</span>
    <span class="char" data-decimal="7878" data-entity="&amp;#7878;" data-id="44583" title="Latin Capital Letter E With Circumflex And Dot Below">Ệ</span>
    <span class="char" data-decimal="7879" data-entity="&amp;#7879;" data-id="44584" title="Latin Small Letter E With Circumflex And Dot Below">ệ</span>
    <span class="char" data-decimal="274" data-entity="&amp;#274;" data-id="44659" title="Latin Capital Letter E With Macron">Ē</span>
    <span class="char" data-decimal="275" data-entity="&amp;#275;" data-id="44660" title="Latin Small Letter E With Macron">ē</span>
    <span class="char" data-decimal="276" data-entity="&amp;#276;" data-id="44661" title="Latin Capital Letter E With Breve">Ĕ</span>
    <span class="char" data-decimal="277" data-entity="&amp;#277;" data-id="44662" title="Latin Small Letter E With Breve">ĕ</span>
    <span class="char" data-decimal="278" data-entity="&amp;#278;" data-id="44663" title="Latin Capital Letter E With Dot Above">Ė</span>
    <span class="char" data-decimal="279" data-entity="&amp;#279;" data-id="44664" title="Latin Small Letter E With Dot Above">ė</span>
    <span class="char" data-decimal="280" data-entity="&amp;#280;" data-id="44665" title="Latin Capital Letter E With Ogonek">Ę</span>
    <span class="char" data-decimal="281" data-entity="&amp;#281;" data-id="44666" title="Latin Small Letter E With Ogonek">ę</span>
    <span class="char" data-decimal="282" data-entity="&amp;#282;" data-id="44667" title="Latin Capital Letter E With Caron">Ě</span>
    <span class="char" data-decimal="283" data-entity="&amp;#283;" data-id="44668" title="Latin Small Letter E With Caron">ě</span>
    <span class="char" data-decimal="200" data-entity="&amp;Egrave;" data-id="45183" title="Latin Capital Letter E With Grave">È</span>
    <span class="char" data-decimal="232" data-entity="&amp;egrave;" data-id="45215" title="Latin Small Letter E With Grave">è</span>
    <span class="char" data-decimal="201" data-entity="&amp;Eacute;" data-id="45184" title="Latin Capital Letter E With Acute">É</span>
    <span class="char" data-decimal="233" data-entity="&amp;eacute;" data-id="45216" title="Latin Small Letter E With Acute">é</span>
    <span class="char" data-decimal="202" data-entity="&amp;Ecirc;" data-id="45185" title="Latin Capital Letter E With Circumflex">Ê</span>
    <span class="char" data-decimal="234" data-entity="&amp;ecirc;" data-id="45217" title="Latin Small Letter E With Circumflex">ê</span>
    <span class="char" data-decimal="203" data-entity="&amp;Euml;" data-id="45186" title="Latin Capital Letter E With Diaeresis">Ë</span>
    <span class="char" data-decimal="235" data-entity="&amp;euml;" data-id="45218" title="Latin Small Letter E With Diaeresis">ë</span>
    <span class="char" data-decimal="516" data-entity="&amp;#516;" data-id="44995" title="Latin Capital Letter E With Double Grave">Ȅ</span>
    <span class="char" data-decimal="517" data-entity="&amp;#517;" data-id="44996" title="Latin Small Letter E With Double Grave">ȅ</span>
    <span class="char" data-decimal="552" data-entity="&amp;#552;" data-id="45031" title="Latin Capital Letter E With Cedilla">Ȩ</span>
    <span class="char" data-decimal="553" data-entity="&amp;#553;" data-id="45032" title="Latin Small Letter E With Cedilla">ȩ</span>
    <span class="char" data-decimal="518" data-entity="&amp;#518;" data-id="44997" title="Latin Capital Letter E With Inverted Breve">Ȇ</span>
    <span class="char" data-decimal="519" data-entity="&amp;#519;" data-id="44998" title="Latin Small Letter E With Inverted Breve">ȇ</span>
    <span class="char" data-decimal="398" data-entity="&amp;#398;" data-id="44877" title="Latin Capital Letter Reversed E">Ǝ</span>
    <span class="char" data-decimal="11384" data-entity="&amp;#11384;" data-id="45095" title="Latin Small Letter E With Notch">ⱸ</span>
    <span class="char" data-decimal="582" data-entity="&amp;#582;" data-id="45061" title="Latin Capital Letter E With Stroke">Ɇ</span>
    <span class="char" data-decimal="8455" data-entity="&amp;#8455;" data-id="45246" title="Euler Constant">ℇ</span>
    <span class="char" data-decimal="8495" data-entity="&amp;#8495;" data-id="45286" title="Script Small E">ℯ</span>
    <span class="char" data-decimal="8494" data-entity="&amp;#8494;" data-id="45285" title="Estimated Symbol">℮</span>
    <span class="char" data-decimal="400" data-entity="&amp;#400;" data-id="44879" title="Latin Capital Letter Open E">Ɛ</span>
    <span class="char" data-decimal="8496" data-entity="&amp;#8496;" data-id="45287" title="Script Capital E">ℰ</span>
    <span class="char" data-decimal="399" data-entity="&amp;#399;" data-id="44878" title="Latin Capital Letter Schwa">Ə</span>
    <span class="char" data-decimal="477" data-entity="&amp;#477;" data-id="44956" title="Latin Small Letter Turned E">ǝ</span>
    <span class="char" data-decimal="11387" data-entity="&amp;#11387;" data-id="45098" title="Latin Letter Small Capital Turned E">ⱻ</span>
    <span class="char" data-decimal="583" data-entity="&amp;#583;" data-id="45062" title="Latin Small Letter E With Stroke">ɇ</span>
    <span class="char" data-decimal="9403" data-entity="&amp;#9403;" data-id="43854" title="Circled Latin Capital Letter F">Ⓕ</span>
    <span class="char" data-decimal="9429" data-entity="&amp;#9429;" data-id="43880" title="Circled Latin Small Letter F">ⓕ</span>
    <span class="char" data-decimal="9377" data-entity="&amp;#9377;" data-id="43828" title="Parenthesized Latin Small Letter F">⒡</span>
    <span class="char" data-decimal="70" data-entity="&amp;#70;" data-id="44806" title="Latin Capital Letter F">F</span>
    <span class="char" data-decimal="102" data-entity="&amp;#102;" data-id="44838" title="Latin Small Letter F">f</span>
    <span class="char" data-decimal="7710" data-entity="&amp;#7710;" data-id="44415" title="Latin Capital Letter F With Dot Above">Ḟ</span>
    <span class="char" data-decimal="7711" data-entity="&amp;#7711;" data-id="44416" title="Latin Small Letter F With Dot Above">ḟ</span>
    <span class="char" data-decimal="401" data-entity="&amp;#401;" data-id="44880" title="Latin Capital Letter F With Hook">Ƒ</span>
    <span class="char" data-decimal="402" data-entity="&amp;#402;" data-id="44881" title="Latin Small Letter F With Hook">ƒ</span>
    <span class="char" data-decimal="42800" data-entity="&amp;#42800;" data-id="45107" title="Latin Letter Small Capital F">ꜰ</span>
    <span class="char" data-decimal="8498" data-entity="&amp;#8498;" data-id="45289" title="Turned Capital F">Ⅎ</span>
    <span class="char" data-decimal="8526" data-entity="&amp;#8526;" data-id="45315" title="Turned Small F">ⅎ</span>
    <span class="char" data-decimal="43003" data-entity="&amp;#43003;" data-id="45138" title="Latin Epigraphic Letter Reversed F">ꟻ</span>
    <span class="char" data-decimal="8497" data-entity="&amp;#8497;" data-id="45288" title="Script Capital F">ℱ</span>
    <span class="char" data-decimal="8507" data-entity="&amp;#8507;" data-id="45298" title="Facsimile Sign">℻</span>
    <span class="char" data-decimal="9404" data-entity="&amp;#9404;" data-id="43855" title="Circled Latin Capital Letter G">Ⓖ</span>
    <span class="char" data-decimal="9430" data-entity="&amp;#9430;" data-id="43881" title="Circled Latin Small Letter G">ⓖ</span>
    <span class="char" data-decimal="9378" data-entity="&amp;#9378;" data-id="43829" title="Parenthesized Latin Small Letter G">⒢</span>
    <span class="char" data-decimal="71" data-entity="&amp;#71;" data-id="44807" title="Latin Capital Letter G">G</span>
    <span class="char" data-decimal="103" data-entity="&amp;#103;" data-id="44839" title="Latin Small Letter G">g</span>
    <span class="char" data-decimal="403" data-entity="&amp;#403;" data-id="44882" title="Latin Capital Letter G With Hook">Ɠ</span>
    <span class="char" data-decimal="7712" data-entity="&amp;#7712;" data-id="44417" title="Latin Capital Letter G With Macron">Ḡ</span>
    <span class="char" data-decimal="7713" data-entity="&amp;#7713;" data-id="44418" title="Latin Small Letter G With Macron">ḡ</span>
    <span class="char" data-decimal="284" data-entity="&amp;#284;" data-id="44669" title="Latin Capital Letter G With Circumflex">Ĝ</span>
    <span class="char" data-decimal="285" data-entity="&amp;#285;" data-id="44670" title="Latin Small Letter G With Circumflex">ĝ</span>
    <span class="char" data-decimal="286" data-entity="&amp;#286;" data-id="44671" title="Latin Capital Letter G With Breve">Ğ</span>
    <span class="char" data-decimal="287" data-entity="&amp;#287;" data-id="44672" title="Latin Small Letter G With Breve">ğ</span>
    <span class="char" data-decimal="288" data-entity="&amp;#288;" data-id="44673" title="Latin Capital Letter G With Dot Above">Ġ</span>
    <span class="char" data-decimal="289" data-entity="&amp;#289;" data-id="44674" title="Latin Small Letter G With Dot Above">ġ</span>
    <span class="char" data-decimal="290" data-entity="&amp;#290;" data-id="44675" title="Latin Capital Letter G With Cedilla">Ģ</span>
    <span class="char" data-decimal="291" data-entity="&amp;#291;" data-id="44676" title="Latin Small Letter G With Cedilla">ģ</span>
    <span class="char" data-decimal="484" data-entity="&amp;#484;" data-id="44963" title="Latin Capital Letter G With Stroke">Ǥ</span>
    <span class="char" data-decimal="485" data-entity="&amp;#485;" data-id="44964" title="Latin Small Letter G With Stroke">ǥ</span>
    <span class="char" data-decimal="486" data-entity="&amp;#486;" data-id="44965" title="Latin Capital Letter G With Caron">Ǧ</span>
    <span class="char" data-decimal="487" data-entity="&amp;#487;" data-id="44966" title="Latin Small Letter G With Caron">ǧ</span>
    <span class="char" data-decimal="500" data-entity="&amp;#500;" data-id="44979" title="Latin Capital Letter G With Acute">Ǵ</span>
    <span class="char" data-decimal="8458" data-entity="&amp;#8458;" data-id="45249" title="Script Small G">ℊ</span>
    <span class="char" data-decimal="8513" data-entity="&amp;#8513;" data-id="45303" title="Turned Sans Serif Capital G">⅁</span>
    <span class="char" data-decimal="501" data-entity="&amp;#501;" data-id="44980" title="Latin Small Letter G With Acute">ǵ</span>
    <span class="char" data-decimal="9405" data-entity="&amp;#9405;" data-id="43856" title="Circled Latin Capital Letter H">Ⓗ</span>
    <span class="char" data-decimal="9431" data-entity="&amp;#9431;" data-id="43882" title="Circled Latin Small Letter H">ⓗ</span>
    <span class="char" data-decimal="9379" data-entity="&amp;#9379;" data-id="43830" title="Parenthesized Latin Small Letter H">⒣</span>
    <span class="char" data-decimal="72" data-entity="&amp;#72;" data-id="44808" title="Latin Capital Letter H">H</span>
    <span class="char" data-decimal="104" data-entity="&amp;#104;" data-id="44840" title="Latin Small Letter H">h</span>
    <span class="char" data-decimal="7714" data-entity="&amp;#7714;" data-id="44419" title="Latin Capital Letter H With Dot Above">Ḣ</span>
    <span class="char" data-decimal="7715" data-entity="&amp;#7715;" data-id="44420" title="Latin Small Letter H With Dot Above">ḣ</span>
    <span class="char" data-decimal="7716" data-entity="&amp;#7716;" data-id="44421" title="Latin Capital Letter H With Dot Below">Ḥ</span>
    <span class="char" data-decimal="7717" data-entity="&amp;#7717;" data-id="44422" title="Latin Small Letter H With Dot Below">ḥ</span>
    <span class="char" data-decimal="7718" data-entity="&amp;#7718;" data-id="44423" title="Latin Capital Letter H With Diaeresis">Ḧ</span>
    <span class="char" data-decimal="7719" data-entity="&amp;#7719;" data-id="44424" title="Latin Small Letter H With Diaeresis">ḧ</span>
    <span class="char" data-decimal="7720" data-entity="&amp;#7720;" data-id="44425" title="Latin Capital Letter H With Cedilla">Ḩ</span>
    <span class="char" data-decimal="7721" data-entity="&amp;#7721;" data-id="44426" title="Latin Small Letter H With Cedilla">ḩ</span>
    <span class="char" data-decimal="7722" data-entity="&amp;#7722;" data-id="44427" title="Latin Capital Letter H With Breve Below">Ḫ</span>
    <span class="char" data-decimal="7723" data-entity="&amp;#7723;" data-id="44428" title="Latin Small Letter H With Breve Below">ḫ</span>
    <span class="char" data-decimal="7830" data-entity="&amp;#7830;" data-id="44535" title="Latin Small Letter H With Line Below">ẖ</span>
    <span class="char" data-decimal="292" data-entity="&amp;#292;" data-id="44677" title="Latin Capital Letter H With Circumflex">Ĥ</span>
    <span class="char" data-decimal="293" data-entity="&amp;#293;" data-id="44678" title="Latin Small Letter H With Circumflex">ĥ</span>
    <span class="char" data-decimal="542" data-entity="&amp;#542;" data-id="45021" title="Latin Capital Letter H With Caron">Ȟ</span>
    <span class="char" data-decimal="543" data-entity="&amp;#543;" data-id="45022" title="Latin Small Letter H With Caron">ȟ</span>
    <span class="char" data-decimal="294" data-entity="&amp;#294;" data-id="44679" title="Latin Capital Letter H With Stroke">Ħ</span>
    <span class="char" data-decimal="295" data-entity="&amp;#295;" data-id="44680" title="Latin Small Letter H With Stroke">ħ</span>
    <span class="char" data-decimal="11367" data-entity="&amp;#11367;" data-id="45078" title="Latin Capital Letter H With Descender">Ⱨ</span>
    <span class="char" data-decimal="11368" data-entity="&amp;#11368;" data-id="45079" title="Latin Small Letter H With Descender">ⱨ</span>
    <span class="char" data-decimal="42790" data-entity="&amp;#42790;" data-id="45105" title="Latin Capital Letter Heng">Ꜧ</span>
    <span class="char" data-decimal="8461" data-entity="&amp;#8461;" data-id="45252" title="Double Struck Capital H">ℍ</span>
    <span class="char" data-decimal="502" data-entity="&amp;#502;" data-id="44981" title="Latin Capital Letter Hwair">Ƕ</span>
    <span class="char" data-decimal="8463" data-entity="&amp;#8463;" data-id="45254" title="Planck Constant Over Two Pi">ℏ</span>
    <span class="char" data-decimal="8462" data-entity="&amp;#8462;" data-id="45253" title="Planck Constant">ℎ</span>
    <span class="char" data-decimal="8459" data-entity="&amp;#8459;" data-id="45250" title="Script Capital H">ℋ</span>
    <span class="char" data-decimal="8460" data-entity="&amp;#8460;" data-id="45251" title="Black Letter Capital H">ℌ</span>
    <span class="char" data-decimal="42791" data-entity="&amp;#42791;" data-id="45106" title="Latin Small Letter Heng">ꜧ</span>
    <span class="char" data-decimal="9406" data-entity="&amp;#9406;" data-id="43857" title="Circled Latin Capital Letter I">Ⓘ</span>
    <span class="char" data-decimal="9432" data-entity="&amp;#9432;" data-id="43883" title="Circled Latin Small Letter I">ⓘ</span>
    <span class="char" data-decimal="9380" data-entity="&amp;#9380;" data-id="43831" title="Parenthesized Latin Small Letter I">⒤</span>
    <span class="char" data-decimal="73" data-entity="&amp;#73;" data-id="44809" title="Latin Capital Letter I">I</span>
    <span class="char" data-decimal="105" data-entity="&amp;#105;" data-id="44841" title="Latin Small Letter I">i</span>
    <span class="char" data-decimal="7724" data-entity="&amp;#7724;" data-id="44429" title="Latin Capital Letter I With Tilde Below">Ḭ</span>
    <span class="char" data-decimal="7725" data-entity="&amp;#7725;" data-id="44430" title="Latin Small Letter I With Tilde Below">ḭ</span>
    <span class="char" data-decimal="7726" data-entity="&amp;#7726;" data-id="44431" title="Latin Capital Letter I With Diaeresis And Acute">Ḯ</span>
    <span class="char" data-decimal="7727" data-entity="&amp;#7727;" data-id="44432" title="Latin Small Letter I With Diaeresis And Acute">ḯ</span>
    <span class="char" data-decimal="306" data-entity="&amp;#306;" data-id="44691" title="Latin Capital Ligature Ij">Ĳ</span>
    <span class="char" data-decimal="307" data-entity="&amp;#307;" data-id="44692" title="Latin Small Ligature Ij">ĳ</span>
    <span class="char" data-decimal="236" data-entity="&amp;igrave;" data-id="45219" title="Latin Small Letter I With Grave">ì</span>
    <span class="char" data-decimal="237" data-entity="&amp;iacute;" data-id="45220" title="Latin Small Letter I With Acute">í</span>
    <span class="char" data-decimal="238" data-entity="&amp;icirc;" data-id="45221" title="Latin Small Letter I With Circumflex">î</span>
    <span class="char" data-decimal="239" data-entity="&amp;iuml;" data-id="45222" title="Latin Small Letter I With Diaeresis">ï</span>
    <span class="char" data-decimal="204" data-entity="&amp;Igrave;" data-id="45187" title="Latin Capital Letter I With Grave">Ì</span>
    <span class="char" data-decimal="205" data-entity="&amp;Iacute;" data-id="45188" title="Latin Capital Letter I With Acute">Í</span>
    <span class="char" data-decimal="206" data-entity="&amp;Icirc;" data-id="45189" title="Latin Capital Letter I With Circumflex">Î</span>
    <span class="char" data-decimal="207" data-entity="&amp;Iuml;" data-id="45190" title="Latin Capital Letter I With Diaeresis">Ï</span>
    <span class="char" data-decimal="296" data-entity="&amp;#296;" data-id="44681" title="Latin Capital Letter I With Tilde">Ĩ</span>
    <span class="char" data-decimal="297" data-entity="&amp;#297;" data-id="44682" title="Latin Small Letter I With Tilde">ĩ</span>
    <span class="char" data-decimal="298" data-entity="&amp;#298;" data-id="44683" title="Latin Capital Letter I With Macron">Ī</span>
    <span class="char" data-decimal="299" data-entity="&amp;#299;" data-id="44684" title="Latin Small Letter I With Macron">ī</span>
    <span class="char" data-decimal="300" data-entity="&amp;#300;" data-id="44685" title="Latin Capital Letter I With Breve">Ĭ</span>
    <span class="char" data-decimal="301" data-entity="&amp;#301;" data-id="44686" title="Latin Small Letter I With Breve">ĭ</span>
    <span class="char" data-decimal="302" data-entity="&amp;#302;" data-id="44687" title="Latin Capital Letter I With Ogonek">Į</span>
    <span class="char" data-decimal="303" data-entity="&amp;#303;" data-id="44688" title="Latin Small Letter I With Ogonek">į</span>
    <span class="char" data-decimal="305" data-entity="&amp;#305;" data-id="44690" title="Latin Small Letter Dotless I">ı</span>
    <span class="char" data-decimal="407" data-entity="&amp;#407;" data-id="44886" title="Latin Capital Letter I With Stroke">Ɨ</span>
    <span class="char" data-decimal="410" data-entity="&amp;#410;" data-id="44889" title="Latin Small Letter L With Bar">ƚ</span>
    <span class="char" data-decimal="7930" data-entity="&amp;#7930;" data-id="44635" title="Latin Capital Letter Middle Welsh Ll">Ỻ</span>
    <span class="char" data-decimal="463" data-entity="&amp;#463;" data-id="44942" title="Latin Capital Letter I With Caron">Ǐ</span>
    <span class="char" data-decimal="464" data-entity="&amp;#464;" data-id="44943" title="Latin Small Letter I With Caron">ǐ</span>
    <span class="char" data-decimal="8520" data-entity="&amp;#8520;" data-id="45310" title="Double Struck Italic Small I">ⅈ</span>
    <span class="char" data-decimal="8521" data-entity="&amp;#8521;" data-id="45311" title="Double Struck Italic Small J">ⅉ</span>
    <span class="char" data-decimal="8505" data-entity="&amp;#8505;" data-id="45296" title="Information Source">ℹ</span>
    <span class="char" data-decimal="8465" data-entity="&amp;image;" data-id="45256" title="Black Letter Capital I">ℑ</span>
    <span class="char" data-decimal="8464" data-entity="&amp;#8464;" data-id="45255" title="Script Capital I">ℐ</span>
    <span class="char" data-decimal="9407" data-entity="&amp;#9407;" data-id="43858" title="Circled Latin Capital Letter J">Ⓙ</span>
    <span class="char" data-decimal="9433" data-entity="&amp;#9433;" data-id="43884" title="Circled Latin Small Letter J">ⓙ</span>
    <span class="char" data-decimal="9381" data-entity="&amp;#9381;" data-id="43832" title="Parenthesized Latin Small Letter J">⒥</span>
    <span class="char" data-decimal="74" data-entity="&amp;#74;" data-id="44810" title="Latin Capital Letter J">J</span>
    <span class="char" data-decimal="106" data-entity="&amp;#106;" data-id="44842" title="Latin Small Letter J">j</span>
    <span class="char" data-decimal="308" data-entity="&amp;#308;" data-id="44693" title="Latin Capital Letter J With Circumflex">Ĵ</span>
    <span class="char" data-decimal="309" data-entity="&amp;#309;" data-id="44694" title="Latin Small Letter J With Circumflex">ĵ</span>
    <span class="char" data-decimal="567" data-entity="&amp;#567;" data-id="45046" title="Latin Small Letter Dotless J">ȷ</span>
    <span class="char" data-decimal="11388" data-entity="&amp;#11388;" data-id="45099" title="Latin Subscript Small Letter J">ⱼ</span>
    <span class="char" data-decimal="584" data-entity="&amp;#584;" data-id="45063" title="Latin Capital Letter J With Stroke">Ɉ</span>
    <span class="char" data-decimal="585" data-entity="&amp;#585;" data-id="45064" title="Latin Small Letter J With Stroke">ɉ</span>
    <span class="char" data-decimal="496" data-entity="&amp;#496;" data-id="44975" title="Latin Small Letter J With Caron">ǰ</span>
    <span class="char" data-decimal="9408" data-entity="&amp;#9408;" data-id="43859" title="Circled Latin Capital Letter K">Ⓚ</span>
    <span class="char" data-decimal="9434" data-entity="&amp;#9434;" data-id="43885" title="Circled Latin Small Letter K">ⓚ</span>
    <span class="char" data-decimal="9382" data-entity="&amp;#9382;" data-id="43833" title="Parenthesized Latin Small Letter K">⒦</span>
    <span class="char" data-decimal="75" data-entity="&amp;#75;" data-id="44811" title="Latin Capital Letter K">K</span>
    <span class="char" data-decimal="107" data-entity="&amp;#107;" data-id="44843" title="Latin Small Letter K">k</span>
    <span class="char" data-decimal="7728" data-entity="&amp;#7728;" data-id="44433" title="Latin Capital Letter K With Acute">Ḱ</span>
    <span class="char" data-decimal="7729" data-entity="&amp;#7729;" data-id="44434" title="Latin Small Letter K With Acute">ḱ</span>
    <span class="char" data-decimal="7730" data-entity="&amp;#7730;" data-id="44435" title="Latin Capital Letter K With Dot Below">Ḳ</span>
    <span class="char" data-decimal="7731" data-entity="&amp;#7731;" data-id="44436" title="Latin Small Letter K With Dot Below">ḳ</span>
    <span class="char" data-decimal="7732" data-entity="&amp;#7732;" data-id="44437" title="Latin Capital Letter K With Line Below">Ḵ</span>
    <span class="char" data-decimal="7733" data-entity="&amp;#7733;" data-id="44438" title="Latin Small Letter K With Line Below">ḵ</span>
    <span class="char" data-decimal="310" data-entity="&amp;#310;" data-id="44695" title="Latin Capital Letter K With Cedilla">Ķ</span>
    <span class="char" data-decimal="311" data-entity="&amp;#311;" data-id="44696" title="Latin Small Letter K With Cedilla">ķ</span>
    <span class="char" data-decimal="408" data-entity="&amp;#408;" data-id="44887" title="Latin Capital Letter K With Hook">Ƙ</span>
    <span class="char" data-decimal="409" data-entity="&amp;#409;" data-id="44888" title="Latin Small Letter K With Hook">ƙ</span>
    <span class="char" data-decimal="42816" data-entity="&amp;#42816;" data-id="45117" title="Latin Capital Letter K With Stroke">Ꝁ</span>
    <span class="char" data-decimal="42817" data-entity="&amp;#42817;" data-id="45118" title="Latin Small Letter K With Stroke">ꝁ</span>
    <span class="char" data-decimal="42818" data-entity="&amp;#42818;" data-id="45119" title="Latin Capital Letter K With Diagonal Stroke">Ꝃ</span>
    <span class="char" data-decimal="42819" data-entity="&amp;#42819;" data-id="45120" title="Latin Small Letter K With Diagonal Stroke">ꝃ</span>
    <span class="char" data-decimal="42820" data-entity="&amp;#42820;" data-id="45121" title="Latin Capital Letter K With Stroke And Diagonal Stroke">Ꝅ</span>
    <span class="char" data-decimal="42821" data-entity="&amp;#42821;" data-id="45122" title="Latin Small Letter K With Stroke And Diagonal Stroke">ꝅ</span>
    <span class="char" data-decimal="488" data-entity="&amp;#488;" data-id="44967" title="Latin Capital Letter K With Caron">Ǩ</span>
    <span class="char" data-decimal="489" data-entity="&amp;#489;" data-id="44968" title="Latin Small Letter K With Caron">ǩ</span>
    <span class="char" data-decimal="11369" data-entity="&amp;#11369;" data-id="45080" title="Latin Capital Letter K With Descender">Ⱪ</span>
    <span class="char" data-decimal="11370" data-entity="&amp;#11370;" data-id="45081" title="Latin Small Letter K With Descender">ⱪ</span>
    <span class="char" data-decimal="312" data-entity="&amp;#312;" data-id="44697" title="Latin Small Letter Kra">ĸ</span>
    <span class="char" data-decimal="9409" data-entity="&amp;#9409;" data-id="43860" title="Circled Latin Capital Letter L">Ⓛ</span>
    <span class="char" data-decimal="9435" data-entity="&amp;#9435;" data-id="43886" title="Circled Latin Small Letter L">ⓛ</span>
    <span class="char" data-decimal="9383" data-entity="&amp;#9383;" data-id="43834" title="Parenthesized Latin Small Letter L">⒧</span>
    <span class="char" data-decimal="76" data-entity="&amp;#76;" data-id="44812" title="Latin Capital Letter L">L</span>
    <span class="char" data-decimal="108" data-entity="&amp;#108;" data-id="44844" title="Latin Small Letter L">l</span>
    <span class="char" data-decimal="7734" data-entity="&amp;#7734;" data-id="44439" title="Latin Capital Letter L With Dot Below">Ḷ</span>
    <span class="char" data-decimal="7735" data-entity="&amp;#7735;" data-id="44440" title="Latin Small Letter L With Dot Below">ḷ</span>
    <span class="char" data-decimal="7736" data-entity="&amp;#7736;" data-id="44441" title="Latin Capital Letter L With Dot Below And Macron">Ḹ</span>
    <span class="char" data-decimal="7737" data-entity="&amp;#7737;" data-id="44442" title="Latin Small Letter L With Dot Below And Macron">ḹ</span>
    <span class="char" data-decimal="7738" data-entity="&amp;#7738;" data-id="44443" title="Latin Capital Letter L With Line Below">Ḻ</span>
    <span class="char" data-decimal="7739" data-entity="&amp;#7739;" data-id="44444" title="Latin Small Letter L With Line Below">ḻ</span>
    <span class="char" data-decimal="7740" data-entity="&amp;#7740;" data-id="44445" title="Latin Capital Letter L With Circumflex Below">Ḽ</span>
    <span class="char" data-decimal="7741" data-entity="&amp;#7741;" data-id="44446" title="Latin Small Letter L With Circumflex Below">ḽ</span>
    <span class="char" data-decimal="313" data-entity="&amp;#313;" data-id="44698" title="Latin Capital Letter L With Acute">Ĺ</span>
    <span class="char" data-decimal="314" data-entity="&amp;#314;" data-id="44699" title="Latin Small Letter L With Acute">ĺ</span>
    <span class="char" data-decimal="315" data-entity="&amp;#315;" data-id="44700" title="Latin Capital Letter L With Cedilla">Ļ</span>
    <span class="char" data-decimal="316" data-entity="&amp;#316;" data-id="44701" title="Latin Small Letter L With Cedilla">ļ</span>
    <span class="char" data-decimal="317" data-entity="&amp;#317;" data-id="44702" title="Latin Capital Letter L With Caron">Ľ</span>
    <span class="char" data-decimal="304" data-entity="&amp;#304;" data-id="44689" title="Latin Capital Letter I With Dot Above">İ</span>
    <span class="char" data-decimal="318" data-entity="&amp;#318;" data-id="44703" title="Latin Small Letter L With Caron">ľ</span>
    <span class="char" data-decimal="319" data-entity="&amp;#319;" data-id="44704" title="Latin Capital Letter L With Middle Dot">Ŀ</span>
    <span class="char" data-decimal="320" data-entity="&amp;#320;" data-id="44705" title="Latin Small Letter L With Middle Dot">ŀ</span>
    <span class="char" data-decimal="321" data-entity="&amp;#321;" data-id="44706" title="Latin Capital Letter L With Stroke">Ł</span>
    <span class="char" data-decimal="322" data-entity="&amp;#322;" data-id="44707" title="Latin Small Letter L With Stroke">ł</span>
    <span class="char" data-decimal="7880" data-entity="&amp;#7880;" data-id="44585" title="Latin Capital Letter I With Hook Above">Ỉ</span>
    <span class="char" data-decimal="7881" data-entity="&amp;#7881;" data-id="44586" title="Latin Small Letter I With Hook Above">ỉ</span>
    <span class="char" data-decimal="7882" data-entity="&amp;#7882;" data-id="44587" title="Latin Capital Letter I With Dot Below">Ị</span>
    <span class="char" data-decimal="7883" data-entity="&amp;#7883;" data-id="44588" title="Latin Small Letter I With Dot Below">ị</span>
    <span class="char" data-decimal="573" data-entity="&amp;#573;" data-id="45052" title="Latin Capital Letter L With Bar">Ƚ</span>
    <span class="char" data-decimal="11360" data-entity="&amp;#11360;" data-id="45071" title="Latin Capital Letter L With Double Bar">Ⱡ</span>
    <span class="char" data-decimal="42824" data-entity="&amp;#42824;" data-id="45123" title="Latin Capital Letter L With High Stroke">Ꝉ</span>
    <span class="char" data-decimal="42825" data-entity="&amp;#42825;" data-id="45124" title="Latin Small Letter L With High Stroke">ꝉ</span>
    <span class="char" data-decimal="11361" data-entity="&amp;#11361;" data-id="45072" title="Latin Small Letter L With Double Bar">ⱡ</span>
    <span class="char" data-decimal="11362" data-entity="&amp;#11362;" data-id="45073" title="Latin Capital Letter L With Middle Tilde">Ɫ</span>
    <span class="char" data-decimal="42881" data-entity="&amp;#42881;" data-id="45132" title="Latin Small Letter Turned L">ꞁ</span>
    <span class="char" data-decimal="8466" data-entity="&amp;#8466;" data-id="45257" title="Script Capital L">ℒ</span>
    <span class="char" data-decimal="455" data-entity="&amp;#455;" data-id="44934" title="Latin Capital Letter Lj">Ǉ</span>
    <span class="char" data-decimal="456" data-entity="&amp;#456;" data-id="44935" title="Latin Capital Letter L With Small Letter J">ǈ</span>
    <span class="char" data-decimal="457" data-entity="&amp;#457;" data-id="44936" title="Latin Small Letter Lj">ǉ</span>
    <span class="char" data-decimal="8515" data-entity="&amp;#8515;" data-id="45305" title="Reversed Sans Serif Capital L">⅃</span>
    <span class="char" data-decimal="8514" data-entity="&amp;#8514;" data-id="45304" title="Turned Sans Serif Capital L">⅂</span>
    <span class="char" data-decimal="8467" data-entity="&amp;#8467;" data-id="45258" title="Script Small L">ℓ</span>
    <span class="char" data-decimal="521" data-entity="&amp;#521;" data-id="45000" title="Latin Small Letter I With Double Grave">ȉ</span>
    <span class="char" data-decimal="520" data-entity="&amp;#520;" data-id="44999" title="Latin Capital Letter I With Double Grave">Ȉ</span>
    <span class="char" data-decimal="522" data-entity="&amp;#522;" data-id="45001" title="Latin Capital Letter I With Inverted Breve">Ȋ</span>
    <span class="char" data-decimal="523" data-entity="&amp;#523;" data-id="45002" title="Latin Small Letter I With Inverted Breve">ȋ</span>
    <span class="char" data-decimal="9410" data-entity="&amp;#9410;" data-id="43861" title="Circled Latin Capital Letter M">Ⓜ</span>
    <span class="char" data-decimal="9436" data-entity="&amp;#9436;" data-id="43887" title="Circled Latin Small Letter M">ⓜ</span>
    <span class="char" data-decimal="9384" data-entity="&amp;#9384;" data-id="43835" title="Parenthesized Latin Small Letter M">⒨</span>
    <span class="char" data-decimal="77" data-entity="&amp;#77;" data-id="44813" title="Latin Capital Letter M">M</span>
    <span class="char" data-decimal="109" data-entity="&amp;#109;" data-id="44845" title="Latin Small Letter M">m</span>
    <span class="char" data-decimal="7742" data-entity="&amp;#7742;" data-id="44447" title="Latin Capital Letter M With Acute">Ḿ</span>
    <span class="char" data-decimal="7743" data-entity="&amp;#7743;" data-id="44448" title="Latin Small Letter M With Acute">ḿ</span>
    <span class="char" data-decimal="7744" data-entity="&amp;#7744;" data-id="44449" title="Latin Capital Letter M With Dot Above">Ṁ</span>
    <span class="char" data-decimal="7745" data-entity="&amp;#7745;" data-id="44450" title="Latin Small Letter M With Dot Above">ṁ</span>
    <span class="char" data-decimal="7746" data-entity="&amp;#7746;" data-id="44451" title="Latin Capital Letter M With Dot Below">Ṃ</span>
    <span class="char" data-decimal="7747" data-entity="&amp;#7747;" data-id="44452" title="Latin Small Letter M With Dot Below">ṃ</span>
    <span class="char" data-decimal="43007" data-entity="&amp;#43007;" data-id="45142" title="Latin Epigraphic Letter Archaic M">ꟿ</span>
    <span class="char" data-decimal="43005" data-entity="&amp;#43005;" data-id="45140" title="Latin Epigraphic Letter Inverted M">ꟽ</span>
    <span class="char" data-decimal="11374" data-entity="&amp;#11374;" data-id="45085" title="Latin Capital Letter M With Hook">Ɱ</span>
    <span class="char" data-decimal="425" data-entity="&amp;#425;" data-id="44904" title="Latin Capital Letter Esh">Ʃ</span>
    <span class="char" data-decimal="412" data-entity="&amp;#412;" data-id="44891" title="Latin Capital Letter Turned M">Ɯ</span>
    <span class="char" data-decimal="8499" data-entity="&amp;#8499;" data-id="45290" title="Script Capital M">ℳ</span>
    <span class="char" data-decimal="9411" data-entity="&amp;#9411;" data-id="43862" title="Circled Latin Capital Letter N">Ⓝ</span>
    <span class="char" data-decimal="9437" data-entity="&amp;#9437;" data-id="43888" title="Circled Latin Small Letter N">ⓝ</span>
    <span class="char" data-decimal="9385" data-entity="&amp;#9385;" data-id="43836" title="Parenthesized Latin Small Letter N">⒩</span>
    <span class="char" data-decimal="78" data-entity="&amp;#78;" data-id="44814" title="Latin Capital Letter N">N</span>
    <span class="char" data-decimal="110" data-entity="&amp;#110;" data-id="44846" title="Latin Small Letter N">n</span>
    <span class="char" data-decimal="7748" data-entity="&amp;#7748;" data-id="44453" title="Latin Capital Letter N With Dot Above">Ṅ</span>
    <span class="char" data-decimal="7749" data-entity="&amp;#7749;" data-id="44454" title="Latin Small Letter N With Dot Above">ṅ</span>
    <span class="char" data-decimal="7750" data-entity="&amp;#7750;" data-id="44455" title="Latin Capital Letter N With Dot Below">Ṇ</span>
    <span class="char" data-decimal="7751" data-entity="&amp;#7751;" data-id="44456" title="Latin Small Letter N With Dot Below">ṇ</span>
    <span class="char" data-decimal="7752" data-entity="&amp;#7752;" data-id="44457" title="Latin Capital Letter N With Line Below">Ṉ</span>
    <span class="char" data-decimal="7753" data-entity="&amp;#7753;" data-id="44458" title="Latin Small Letter N With Line Below">ṉ</span>
    <span class="char" data-decimal="7754" data-entity="&amp;#7754;" data-id="44459" title="Latin Capital Letter N With Circumflex Below">Ṋ</span>
    <span class="char" data-decimal="7755" data-entity="&amp;#7755;" data-id="44460" title="Latin Small Letter N With Circumflex Below">ṋ</span>
    <span class="char" data-decimal="323" data-entity="&amp;#323;" data-id="44708" title="Latin Capital Letter N With Acute">Ń</span>
    <span class="char" data-decimal="324" data-entity="&amp;#324;" data-id="44709" title="Latin Small Letter N With Acute">ń</span>
    <span class="char" data-decimal="325" data-entity="&amp;#325;" data-id="44710" title="Latin Capital Letter N With Cedilla">Ņ</span>
    <span class="char" data-decimal="326" data-entity="&amp;#326;" data-id="44711" title="Latin Small Letter N With Cedilla">ņ</span>
    <span class="char" data-decimal="327" data-entity="&amp;#327;" data-id="44712" title="Latin Capital Letter N With Caron">Ň</span>
    <span class="char" data-decimal="328" data-entity="&amp;#328;" data-id="44713" title="Latin Small Letter N With Caron">ň</span>
    <span class="char" data-decimal="504" data-entity="&amp;#504;" data-id="44983" title="Latin Capital Letter N With Grave">Ǹ</span>
    <span class="char" data-decimal="505" data-entity="&amp;#505;" data-id="44984" title="Latin Small Letter N With Grave">ǹ</span>
    <span class="char" data-decimal="330" data-entity="&amp;#330;" data-id="44715" title="Latin Capital Letter Eng">Ŋ</span>
    <span class="char" data-decimal="413" data-entity="&amp;#413;" data-id="44892" title="Latin Capital Letter N With Left Hook">Ɲ</span>
    <span class="char" data-decimal="241" data-entity="&amp;ntilde;" data-id="45224" title="Latin Small Letter N With Tilde">ñ</span>
    <span class="char" data-decimal="329" data-entity="&amp;#329;" data-id="44714" title="Latin Small Letter N Preceded By Apostrophe">ŉ</span>
    <span class="char" data-decimal="209" data-entity="&amp;Ntilde;" data-id="45192" title="Latin Capital Letter N With Tilde">Ñ</span>
    <span class="char" data-decimal="544" data-entity="&amp;#544;" data-id="45023" title="Latin Capital Letter N With Long Right Leg">Ƞ</span>
    <span class="char" data-decimal="414" data-entity="&amp;#414;" data-id="44893" title="Latin Small Letter N With Long Right Leg">ƞ</span>
    <span class="char" data-decimal="331" data-entity="&amp;#331;" data-id="44716" title="Latin Small Letter Eng">ŋ</span>
    <span class="char" data-decimal="458" data-entity="&amp;#458;" data-id="44937" title="Latin Capital Letter Nj">Ǌ</span>
    <span class="char" data-decimal="459" data-entity="&amp;#459;" data-id="44938" title="Latin Capital Letter N With Small Letter J">ǋ</span>
    <span class="char" data-decimal="460" data-entity="&amp;#460;" data-id="44939" title="Latin Small Letter Nj">ǌ</span>
    <span class="char" data-decimal="565" data-entity="&amp;#565;" data-id="45044" title="Latin Small Letter N With Curl">ȵ</span>
    <span class="char" data-decimal="8469" data-entity="&amp;#8469;" data-id="45260" title="Double Struck Capital N">ℕ</span>
    <span class="char" data-decimal="8470" data-entity="&amp;#8470;" data-id="45261" title="Numero Sign">№</span>
    <span class="char" data-decimal="160" data-entity="&amp;nbsp;" data-id="45143" title="No Break Space">&nbsp;</span>
    <span class="char" data-decimal="79" data-entity="&amp;#79;" data-id="44815" title="Latin Capital Letter O">O</span>
    <span class="char" data-decimal="111" data-entity="&amp;#111;" data-id="44847" title="Latin Small Letter O">o</span>
    <span class="char" data-decimal="7756" data-entity="&amp;#7756;" data-id="44461" title="Latin Capital Letter O With Tilde And Acute">Ṍ</span>
    <span class="char" data-decimal="7757" data-entity="&amp;#7757;" data-id="44462" title="Latin Small Letter O With Tilde And Acute">ṍ</span>
    <span class="char" data-decimal="7758" data-entity="&amp;#7758;" data-id="44463" title="Latin Capital Letter O With Tilde And Diaeresis">Ṏ</span>
    <span class="char" data-decimal="7759" data-entity="&amp;#7759;" data-id="44464" title="Latin Small Letter O With Tilde And Diaeresis">ṏ</span>
    <span class="char" data-decimal="7760" data-entity="&amp;#7760;" data-id="44465" title="Latin Capital Letter O With Macron And Grave">Ṑ</span>
    <span class="char" data-decimal="7761" data-entity="&amp;#7761;" data-id="44466" title="Latin Small Letter O With Macron And Grave">ṑ</span>
    <span class="char" data-decimal="7762" data-entity="&amp;#7762;" data-id="44467" title="Latin Capital Letter O With Macron And Acute">Ṓ</span>
    <span class="char" data-decimal="7763" data-entity="&amp;#7763;" data-id="44468" title="Latin Small Letter O With Macron And Acute">ṓ</span>
    <span class="char" data-decimal="554" data-entity="&amp;#554;" data-id="45033" title="Latin Capital Letter O With Diaeresis And Macron">Ȫ</span>
    <span class="char" data-decimal="555" data-entity="&amp;#555;" data-id="45034" title="Latin Small Letter O With Diaeresis And Macron">ȫ</span>
    <span class="char" data-decimal="556" data-entity="&amp;#556;" data-id="45035" title="Latin Capital Letter O With Tilde And Macron">Ȭ</span>
    <span class="char" data-decimal="557" data-entity="&amp;#557;" data-id="45036" title="Latin Small Letter O With Tilde And Macron">ȭ</span>
    <span class="char" data-decimal="558" data-entity="&amp;#558;" data-id="45037" title="Latin Capital Letter O With Dot Above">Ȯ</span>
    <span class="char" data-decimal="559" data-entity="&amp;#559;" data-id="45038" title="Latin Small Letter O With Dot Above">ȯ</span>
    <span class="char" data-decimal="560" data-entity="&amp;#560;" data-id="45039" title="Latin Capital Letter O With Dot Above And Macron">Ȱ</span>
    <span class="char" data-decimal="561" data-entity="&amp;#561;" data-id="45040" title="Latin Small Letter O With Dot Above And Macron">ȱ</span>
    <span class="char" data-decimal="490" data-entity="&amp;#490;" data-id="44969" title="Latin Capital Letter O With Ogonek">Ǫ</span>
    <span class="char" data-decimal="491" data-entity="&amp;#491;" data-id="44970" title="Latin Small Letter O With Ogonek">ǫ</span>
    <span class="char" data-decimal="492" data-entity="&amp;#492;" data-id="44971" title="Latin Capital Letter O With Ogonek And Macron">Ǭ</span>
    <span class="char" data-decimal="493" data-entity="&amp;#493;" data-id="44972" title="Latin Small Letter O With Ogonek And Macron">ǭ</span>
    <span class="char" data-decimal="7884" data-entity="&amp;#7884;" data-id="44589" title="Latin Capital Letter O With Dot Below">Ọ</span>
    <span class="char" data-decimal="7885" data-entity="&amp;#7885;" data-id="44590" title="Latin Small Letter O With Dot Below">ọ</span>
    <span class="char" data-decimal="7886" data-entity="&amp;#7886;" data-id="44591" title="Latin Capital Letter O With Hook Above">Ỏ</span>
    <span class="char" data-decimal="7887" data-entity="&amp;#7887;" data-id="44592" title="Latin Small Letter O With Hook Above">ỏ</span>
    <span class="char" data-decimal="7888" data-entity="&amp;#7888;" data-id="44593" title="Latin Capital Letter O With Circumflex And Acute">Ố</span>
    <span class="char" data-decimal="7889" data-entity="&amp;#7889;" data-id="44594" title="Latin Small Letter O With Circumflex And Acute">ố</span>
    <span class="char" data-decimal="7890" data-entity="&amp;#7890;" data-id="44595" title="Latin Capital Letter O With Circumflex And Grave">Ồ</span>
    <span class="char" data-decimal="7891" data-entity="&amp;#7891;" data-id="44596" title="Latin Small Letter O With Circumflex And Grave">ồ</span>
    <span class="char" data-decimal="7892" data-entity="&amp;#7892;" data-id="44597" title="Latin Capital Letter O With Circumflex And Hook Above">Ổ</span>
    <span class="char" data-decimal="7893" data-entity="&amp;#7893;" data-id="44598" title="Latin Small Letter O With Circumflex And Hook Above">ổ</span>
    <span class="char" data-decimal="7894" data-entity="&amp;#7894;" data-id="44599" title="Latin Capital Letter O With Circumflex And Tilde">Ỗ</span>
    <span class="char" data-decimal="7895" data-entity="&amp;#7895;" data-id="44600" title="Latin Small Letter O With Circumflex And Tilde">ỗ</span>
    <span class="char" data-decimal="7896" data-entity="&amp;#7896;" data-id="44601" title="Latin Capital Letter O With Circumflex And Dot Below">Ộ</span>
    <span class="char" data-decimal="7897" data-entity="&amp;#7897;" data-id="44602" title="Latin Small Letter O With Circumflex And Dot Below">ộ</span>
    <span class="char" data-decimal="7898" data-entity="&amp;#7898;" data-id="44603" title="Latin Capital Letter O With Horn And Acute">Ớ</span>
    <span class="char" data-decimal="7899" data-entity="&amp;#7899;" data-id="44604" title="Latin Small Letter O With Horn And Acute">ớ</span>
    <span class="char" data-decimal="7900" data-entity="&amp;#7900;" data-id="44605" title="Latin Capital Letter O With Horn And Grave">Ờ</span>
    <span class="char" data-decimal="7901" data-entity="&amp;#7901;" data-id="44606" title="Latin Small Letter O With Horn And Grave">ờ</span>
    <span class="char" data-decimal="7902" data-entity="&amp;#7902;" data-id="44607" title="Latin Capital Letter O With Horn And Hook Above">Ở</span>
    <span class="char" data-decimal="7903" data-entity="&amp;#7903;" data-id="44608" title="Latin Small Letter O With Horn And Hook Above">ở</span>
    <span class="char" data-decimal="7904" data-entity="&amp;#7904;" data-id="44609" title="Latin Capital Letter O With Horn And Tilde">Ỡ</span>
    <span class="char" data-decimal="7905" data-entity="&amp;#7905;" data-id="44610" title="Latin Small Letter O With Horn And Tilde">ỡ</span>
    <span class="char" data-decimal="7906" data-entity="&amp;#7906;" data-id="44611" title="Latin Capital Letter O With Horn And Dot Below">Ợ</span>
    <span class="char" data-decimal="7907" data-entity="&amp;#7907;" data-id="44612" title="Latin Small Letter O With Horn And Dot Below">ợ</span>
    <span class="char" data-decimal="416" data-entity="&amp;#416;" data-id="44895" title="Latin Capital Letter O With Horn">Ơ</span>
    <span class="char" data-decimal="417" data-entity="&amp;#417;" data-id="44896" title="Latin Small Letter O With Horn">ơ</span>
    <span class="char" data-decimal="332" data-entity="&amp;#332;" data-id="44717" title="Latin Capital Letter O With Macron">Ō</span>
    <span class="char" data-decimal="333" data-entity="&amp;#333;" data-id="44718" title="Latin Small Letter O With Macron">ō</span>
    <span class="char" data-decimal="334" data-entity="&amp;#334;" data-id="44719" title="Latin Capital Letter O With Breve">Ŏ</span>
    <span class="char" data-decimal="335" data-entity="&amp;#335;" data-id="44720" title="Latin Small Letter O With Breve">ŏ</span>
    <span class="char" data-decimal="336" data-entity="&amp;#336;" data-id="44721" title="Latin Capital Letter O With Double Acute">Ő</span>
    <span class="char" data-decimal="337" data-entity="&amp;#337;" data-id="44722" title="Latin Small Letter O With Double Acute">ő</span>
    <span class="char" data-decimal="210" data-entity="&amp;Ograve;" data-id="45193" title="Latin Capital Letter O With Grave">Ò</span>
    <span class="char" data-decimal="211" data-entity="&amp;Oacute;" data-id="45194" title="Latin Capital Letter O With Acute">Ó</span>
    <span class="char" data-decimal="212" data-entity="&amp;Ocirc;" data-id="45195" title="Latin Capital Letter O With Circumflex">Ô</span>
    <span class="char" data-decimal="213" data-entity="&amp;Otilde;" data-id="45196" title="Latin Capital Letter O With Tilde">Õ</span>
    <span class="char" data-decimal="214" data-entity="&amp;Ouml;" data-id="45197" title="Latin Capital Letter O With Diaeresis">Ö</span>
    <span class="char" data-decimal="465" data-entity="&amp;#465;" data-id="44944" title="Latin Capital Letter O With Caron">Ǒ</span>
    <span class="char" data-decimal="524" data-entity="&amp;#524;" data-id="45003" title="Latin Capital Letter O With Double Grave">Ȍ</span>
    <span class="char" data-decimal="525" data-entity="&amp;#525;" data-id="45004" title="Latin Small Letter O With Double Grave">ȍ</span>
    <span class="char" data-decimal="526" data-entity="&amp;#526;" data-id="45005" title="Latin Capital Letter O With Inverted Breve">Ȏ</span>
    <span class="char" data-decimal="527" data-entity="&amp;#527;" data-id="45006" title="Latin Small Letter O With Inverted Breve">ȏ</span>
    <span class="char" data-decimal="338" data-entity="&amp;#338;" data-id="44723" title="Latin Capital Ligature Oe">Œ</span>
    <span class="char" data-decimal="339" data-entity="&amp;#339;" data-id="44724" title="Latin Small Ligature Oe">œ</span>
    <span class="char" data-decimal="216" data-entity="&amp;Oslash;" data-id="45199" title="Latin Capital Letter O With Stroke">Ø</span>
    <span class="char" data-decimal="510" data-entity="&amp;#510;" data-id="44989" title="Latin Capital Letter O With Stroke And Acute">Ǿ</span>
    <span class="char" data-decimal="42826" data-entity="&amp;#42826;" data-id="45125" title="Latin Capital Letter O With Long Stroke Overlay">Ꝋ</span>
    <span class="char" data-decimal="509" data-entity="&amp;#509;" data-id="44988" title="Latin Small Letter Ae With Acute">ǽ</span>
    <span class="char" data-decimal="511" data-entity="&amp;#511;" data-id="44990" title="Latin Small Letter O With Stroke And Acute">ǿ</span>
    <span class="char" data-decimal="8500" data-entity="&amp;#8500;" data-id="45291" title="Script Small O">ℴ</span>
    <span class="char" data-decimal="9061" data-entity="&amp;#9061;" data-id="46043" title="Apl Functional Symbol Circle Diaeresis">⍥</span>
    <span class="char" data-decimal="9060" data-entity="&amp;#9060;" data-id="46042" title="Apl Functional Symbol Jot Diaeresis">⍤</span>
    <span class="char" data-decimal="9412" data-entity="&amp;#9412;" data-id="43863" title="Circled Latin Capital Letter O">Ⓞ</span>
    <span class="char" data-decimal="9438" data-entity="&amp;#9438;" data-id="43889" title="Circled Latin Small Letter O">ⓞ</span>
    <span class="char" data-decimal="9386" data-entity="&amp;#9386;" data-id="43837" title="Parenthesized Latin Small Letter O">⒪</span>
    <span class="char" data-decimal="242" data-entity="&amp;ograve;" data-id="45225" title="Latin Small Letter O With Grave">ò</span>
    <span class="char" data-decimal="243" data-entity="&amp;oacute;" data-id="45226" title="Latin Small Letter O With Acute">ó</span>
    <span class="char" data-decimal="244" data-entity="&amp;ocirc;" data-id="45227" title="Latin Small Letter O With Circumflex">ô</span>
    <span class="char" data-decimal="245" data-entity="&amp;otilde;" data-id="45228" title="Latin Small Letter O With Tilde">õ</span>
    <span class="char" data-decimal="246" data-entity="&amp;ouml;" data-id="45229" title="Latin Small Letter O With Diaeresis">ö</span>
    <span class="char" data-decimal="466" data-entity="&amp;#466;" data-id="44945" title="Latin Small Letter O With Caron">ǒ</span>
    <span class="char" data-decimal="248" data-entity="&amp;oslash;" data-id="45231" title="Latin Small Letter O With Stroke">ø</span>
    <span class="char" data-decimal="42830" data-entity="&amp;#42830;" data-id="45127" title="Latin Capital Letter Oo">Ꝏ</span>
    <span class="char" data-decimal="42831" data-entity="&amp;#42831;" data-id="45128" title="Latin Small Letter Oo">ꝏ</span>
    <span class="char" data-decimal="9413" data-entity="&amp;#9413;" data-id="43864" title="Circled Latin Capital Letter P">Ⓟ</span>
    <span class="char" data-decimal="9439" data-entity="&amp;#9439;" data-id="43890" title="Circled Latin Small Letter P">ⓟ</span>
    <span class="char" data-decimal="9387" data-entity="&amp;#9387;" data-id="43838" title="Parenthesized Latin Small Letter P">⒫</span>
    <span class="char" data-decimal="8471" data-entity="&amp;#8471;" data-id="45262" title="Sound Recording Copyright">℗</span>
    <span class="char" data-decimal="80" data-entity="&amp;#80;" data-id="44816" title="Latin Capital Letter P">P</span>
    <span class="char" data-decimal="112" data-entity="&amp;#112;" data-id="44848" title="Latin Small Letter P">p</span>
    <span class="char" data-decimal="7764" data-entity="&amp;#7764;" data-id="44469" title="Latin Capital Letter P With Acute">Ṕ</span>
    <span class="char" data-decimal="7765" data-entity="&amp;#7765;" data-id="44470" title="Latin Small Letter P With Acute">ṕ</span>
    <span class="char" data-decimal="7766" data-entity="&amp;#7766;" data-id="44471" title="Latin Capital Letter P With Dot Above">Ṗ</span>
    <span class="char" data-decimal="7767" data-entity="&amp;#7767;" data-id="44472" title="Latin Small Letter P With Dot Above">ṗ</span>
    <span class="char" data-decimal="420" data-entity="&amp;#420;" data-id="44899" title="Latin Capital Letter P With Hook">Ƥ</span>
    <span class="char" data-decimal="421" data-entity="&amp;#421;" data-id="44900" title="Latin Small Letter P With Hook">ƥ</span>
    <span class="char" data-decimal="11363" data-entity="&amp;#11363;" data-id="45074" title="Latin Capital Letter P With Stroke">Ᵽ</span>
    <span class="char" data-decimal="8473" data-entity="&amp;#8473;" data-id="45264" title="Double Struck Capital P">ℙ</span>
    <span class="char" data-decimal="503" data-entity="&amp;#503;" data-id="44982" title="Latin Capital Letter Wynn">Ƿ</span>
    <span class="char" data-decimal="43004" data-entity="&amp;#43004;" data-id="45139" title="Latin Epigraphic Letter Reversed P">ꟼ</span>
    <span class="char" data-decimal="8472" data-entity="&amp;weierp;" data-id="45263" title="Script Capital P">℘</span>
    <span class="char" data-decimal="9414" data-entity="&amp;#9414;" data-id="43865" title="Circled Latin Capital Letter Q">Ⓠ</span>
    <span class="char" data-decimal="9440" data-entity="&amp;#9440;" data-id="43891" title="Circled Latin Small Letter Q">ⓠ</span>
    <span class="char" data-decimal="9388" data-entity="&amp;#9388;" data-id="43839" title="Parenthesized Latin Small Letter Q">⒬</span>
    <span class="char" data-decimal="81" data-entity="&amp;#81;" data-id="44817" title="Latin Capital Letter Q">Q</span>
    <span class="char" data-decimal="113" data-entity="&amp;#113;" data-id="44849" title="Latin Small Letter Q">q</span>
    <span class="char" data-decimal="586" data-entity="&amp;#586;" data-id="45065" title="Latin Capital Letter Small Q With Hook Tail">Ɋ</span>
    <span class="char" data-decimal="587" data-entity="&amp;#587;" data-id="45066" title="Latin Small Letter Q With Hook Tail">ɋ</span>
    <span class="char" data-decimal="8474" data-entity="&amp;#8474;" data-id="45265" title="Double Struck Capital Q">ℚ</span>
    <span class="char" data-decimal="8506" data-entity="&amp;#8506;" data-id="45297" title="Rotated Capital Q">℺</span>
    <span class="char" data-decimal="569" data-entity="&amp;#569;" data-id="45048" title="Latin Small Letter Qp Digraph">ȹ</span>
    <span class="char" data-decimal="9415" data-entity="&amp;#9415;" data-id="43866" title="Circled Latin Capital Letter R">Ⓡ</span>
    <span class="char" data-decimal="9441" data-entity="&amp;#9441;" data-id="43892" title="Circled Latin Small Letter R">ⓡ</span>
    <span class="char" data-decimal="9389" data-entity="&amp;#9389;" data-id="43840" title="Parenthesized Latin Small Letter R">⒭</span>
    <span class="char" data-decimal="82" data-entity="&amp;#82;" data-id="44818" title="Latin Capital Letter R">R</span>
    <span class="char" data-decimal="114" data-entity="&amp;#114;" data-id="44850" title="Latin Small Letter R">r</span>
    <span class="char" data-decimal="340" data-entity="&amp;#340;" data-id="44725" title="Latin Capital Letter R With Acute">Ŕ</span>
    <span class="char" data-decimal="341" data-entity="&amp;#341;" data-id="44726" title="Latin Small Letter R With Acute">ŕ</span>
    <span class="char" data-decimal="342" data-entity="&amp;#342;" data-id="44727" title="Latin Capital Letter R With Cedilla">Ŗ</span>
    <span class="char" data-decimal="343" data-entity="&amp;#343;" data-id="44728" title="Latin Small Letter R With Cedilla">ŗ</span>
    <span class="char" data-decimal="344" data-entity="&amp;#344;" data-id="44729" title="Latin Capital Letter R With Caron">Ř</span>
    <span class="char" data-decimal="345" data-entity="&amp;#345;" data-id="44730" title="Latin Small Letter R With Caron">ř</span>
    <span class="char" data-decimal="7768" data-entity="&amp;#7768;" data-id="44473" title="Latin Capital Letter R With Dot Above">Ṙ</span>
    <span class="char" data-decimal="7769" data-entity="&amp;#7769;" data-id="44474" title="Latin Small Letter R With Dot Above">ṙ</span>
    <span class="char" data-decimal="7770" data-entity="&amp;#7770;" data-id="44475" title="Latin Capital Letter R With Dot Below">Ṛ</span>
    <span class="char" data-decimal="7771" data-entity="&amp;#7771;" data-id="44476" title="Latin Small Letter R With Dot Below">ṛ</span>
    <span class="char" data-decimal="7772" data-entity="&amp;#7772;" data-id="44477" title="Latin Capital Letter R With Dot Below And Macron">Ṝ</span>
    <span class="char" data-decimal="7773" data-entity="&amp;#7773;" data-id="44478" title="Latin Small Letter R With Dot Below And Macron">ṝ</span>
    <span class="char" data-decimal="7774" data-entity="&amp;#7774;" data-id="44479" title="Latin Capital Letter R With Line Below">Ṟ</span>
    <span class="char" data-decimal="7775" data-entity="&amp;#7775;" data-id="44480" title="Latin Small Letter R With Line Below">ṟ</span>
    <span class="char" data-decimal="528" data-entity="&amp;#528;" data-id="45007" title="Latin Capital Letter R With Double Grave">Ȑ</span>
    <span class="char" data-decimal="529" data-entity="&amp;#529;" data-id="45008" title="Latin Small Letter R With Double Grave">ȑ</span>
    <span class="char" data-decimal="530" data-entity="&amp;#530;" data-id="45009" title="Latin Capital Letter R With Inverted Breve">Ȓ</span>
    <span class="char" data-decimal="531" data-entity="&amp;#531;" data-id="45010" title="Latin Small Letter R With Inverted Breve">ȓ</span>
    <span class="char" data-decimal="589" data-entity="&amp;#589;" data-id="45068" title="Latin Small Letter R With Stroke">ɍ</span>
    <span class="char" data-decimal="588" data-entity="&amp;#588;" data-id="45067" title="Latin Capital Letter R With Stroke">Ɍ</span>
    <span class="char" data-decimal="422" data-entity="&amp;#422;" data-id="44901" title="Latin Letter Yr">Ʀ</span>
    <span class="char" data-decimal="11364" data-entity="&amp;#11364;" data-id="45075" title="Latin Capital Letter R With Tail">Ɽ</span>
    <span class="char" data-decimal="8478" data-entity="&amp;#8478;" data-id="45269" title="Prescription Take">℞</span>
    <span class="char" data-decimal="42842" data-entity="&amp;#42842;" data-id="45129" title="Latin Capital Letter R Rotunda">Ꝛ</span>
    <span class="char" data-decimal="42843" data-entity="&amp;#42843;" data-id="45130" title="Latin Small Letter R Rotunda">ꝛ</span>
    <span class="char" data-decimal="8476" data-entity="&amp;real;" data-id="45267" title="Black Letter Capital R">ℜ</span>
    <span class="char" data-decimal="8475" data-entity="&amp;#8475;" data-id="45266" title="Script Capital R">ℛ</span>
    <span class="char" data-decimal="8479" data-entity="&amp;#8479;" data-id="45270" title="Response">℟</span>
    <span class="char" data-decimal="8477" data-entity="&amp;#8477;" data-id="45268" title="Double Struck Capital R">ℝ</span>
    <span class="char" data-decimal="9416" data-entity="&amp;#9416;" data-id="43867" title="Circled Latin Capital Letter S">Ⓢ</span>
    <span class="char" data-decimal="9442" data-entity="&amp;#9442;" data-id="43893" title="Circled Latin Small Letter S">ⓢ</span>
    <span class="char" data-decimal="9390" data-entity="&amp;#9390;" data-id="43841" title="Parenthesized Latin Small Letter S">⒮</span>
    <span class="char" data-decimal="83" data-entity="&amp;#83;" data-id="44819" title="Latin Capital Letter S">S</span>
    <span class="char" data-decimal="115" data-entity="&amp;#115;" data-id="44851" title="Latin Small Letter S">s</span>
    <span class="char" data-decimal="7776" data-entity="&amp;#7776;" data-id="44481" title="Latin Capital Letter S With Dot Above">Ṡ</span>
    <span class="char" data-decimal="7777" data-entity="&amp;#7777;" data-id="44482" title="Latin Small Letter S With Dot Above">ṡ</span>
    <span class="char" data-decimal="7778" data-entity="&amp;#7778;" data-id="44483" title="Latin Capital Letter S With Dot Below">Ṣ</span>
    <span class="char" data-decimal="7779" data-entity="&amp;#7779;" data-id="44484" title="Latin Small Letter S With Dot Below">ṣ</span>
    <span class="char" data-decimal="7780" data-entity="&amp;#7780;" data-id="44485" title="Latin Capital Letter S With Acute And Dot Above">Ṥ</span>
    <span class="char" data-decimal="7781" data-entity="&amp;#7781;" data-id="44486" title="Latin Small Letter S With Acute And Dot Above">ṥ</span>
    <span class="char" data-decimal="7782" data-entity="&amp;#7782;" data-id="44487" title="Latin Capital Letter S With Caron And Dot Above">Ṧ</span>
    <span class="char" data-decimal="7783" data-entity="&amp;#7783;" data-id="44488" title="Latin Small Letter S With Caron And Dot Above">ṧ</span>
    <span class="char" data-decimal="7784" data-entity="&amp;#7784;" data-id="44489" title="Latin Capital Letter S With Dot Below And Dot Above">Ṩ</span>
    <span class="char" data-decimal="7785" data-entity="&amp;#7785;" data-id="44490" title="Latin Small Letter S With Dot Below And Dot Above">ṩ</span>
    <span class="char" data-decimal="346" data-entity="&amp;#346;" data-id="44731" title="Latin Capital Letter S With Acute">Ś</span>
    <span class="char" data-decimal="347" data-entity="&amp;#347;" data-id="44732" title="Latin Small Letter S With Acute">ś</span>
    <span class="char" data-decimal="348" data-entity="&amp;#348;" data-id="44733" title="Latin Capital Letter S With Circumflex">Ŝ</span>
    <span class="char" data-decimal="349" data-entity="&amp;#349;" data-id="44734" title="Latin Small Letter S With Circumflex">ŝ</span>
    <span class="char" data-decimal="350" data-entity="&amp;#350;" data-id="44735" title="Latin Capital Letter S With Cedilla">Ş</span>
    <span class="char" data-decimal="351" data-entity="&amp;#351;" data-id="44736" title="Latin Small Letter S With Cedilla">ş</span>
    <span class="char" data-decimal="352" data-entity="&amp;#352;" data-id="44737" title="Latin Capital Letter S With Caron">Š</span>
    <span class="char" data-decimal="353" data-entity="&amp;#353;" data-id="44738" title="Latin Small Letter S With Caron">š</span>
    <span class="char" data-decimal="536" data-entity="&amp;#536;" data-id="45015" title="Latin Capital Letter S With Comma Below">Ș</span>
    <span class="char" data-decimal="537" data-entity="&amp;#537;" data-id="45016" title="Latin Small Letter S With Comma Below">ș</span>
    <span class="char" data-decimal="575" data-entity="&amp;#575;" data-id="45054" title="Latin Small Letter S With Swash Tail">ȿ</span>
    <span class="char" data-decimal="42801" data-entity="&amp;#42801;" data-id="45108" title="Latin Letter Small Capital S">ꜱ</span>
    <span class="char" data-decimal="423" data-entity="&amp;#423;" data-id="44902" title="Latin Capital Letter Tone Two">Ƨ</span>
    <span class="char" data-decimal="424" data-entity="&amp;#424;" data-id="44903" title="Latin Small Letter Tone Two">ƨ</span>
    <span class="char" data-decimal="7838" data-entity="&amp;#7838;" data-id="44543" title="Latin Capital Letter Sharp S">ẞ</span>
    <span class="char" data-decimal="223" data-entity="&amp;szlig;" data-id="45206" title="Latin Small Letter Sharp S">ß</span>
    <span class="char" data-decimal="7835" data-entity="&amp;#7835;" data-id="44540" title="Latin Small Letter Long S With Dot Above">ẛ</span>
    <span class="char" data-decimal="7836" data-entity="&amp;#7836;" data-id="44541" title="Latin Small Letter Long S With Diagonal Stroke">ẜ</span>
    <span class="char" data-decimal="7837" data-entity="&amp;#7837;" data-id="44542" title="Latin Small Letter Long S With High Stroke">ẝ</span>
    <span class="char" data-decimal="8480" data-entity="&amp;#8480;" data-id="45271" title="Service Mark">℠</span>
    <span class="char" data-decimal="9417" data-entity="&amp;#9417;" data-id="43868" title="Circled Latin Capital Letter T">Ⓣ</span>
    <span class="char" data-decimal="9443" data-entity="&amp;#9443;" data-id="43894" title="Circled Latin Small Letter T">ⓣ</span>
    <span class="char" data-decimal="9391" data-entity="&amp;#9391;" data-id="43842" title="Parenthesized Latin Small Letter T">⒯</span>
    <span class="char" data-decimal="84" data-entity="&amp;#84;" data-id="44820" title="Latin Capital Letter T">T</span>
    <span class="char" data-decimal="116" data-entity="&amp;#116;" data-id="44852" title="Latin Small Letter T">t</span>
    <span class="char" data-decimal="7786" data-entity="&amp;#7786;" data-id="44491" title="Latin Capital Letter T With Dot Above">Ṫ</span>
    <span class="char" data-decimal="7787" data-entity="&amp;#7787;" data-id="44492" title="Latin Small Letter T With Dot Above">ṫ</span>
    <span class="char" data-decimal="7788" data-entity="&amp;#7788;" data-id="44493" title="Latin Capital Letter T With Dot Below">Ṭ</span>
    <span class="char" data-decimal="7789" data-entity="&amp;#7789;" data-id="44494" title="Latin Small Letter T With Dot Below">ṭ</span>
    <span class="char" data-decimal="7790" data-entity="&amp;#7790;" data-id="44495" title="Latin Capital Letter T With Line Below">Ṯ</span>
    <span class="char" data-decimal="7791" data-entity="&amp;#7791;" data-id="44496" title="Latin Small Letter T With Line Below">ṯ</span>
    <span class="char" data-decimal="7792" data-entity="&amp;#7792;" data-id="44497" title="Latin Capital Letter T With Circumflex Below">Ṱ</span>
    <span class="char" data-decimal="7793" data-entity="&amp;#7793;" data-id="44498" title="Latin Small Letter T With Circumflex Below">ṱ</span>
    <span class="char" data-decimal="354" data-entity="&amp;#354;" data-id="44739" title="Latin Capital Letter T With Cedilla">Ţ</span>
    <span class="char" data-decimal="355" data-entity="&amp;#355;" data-id="44740" title="Latin Small Letter T With Cedilla">ţ</span>
    <span class="char" data-decimal="356" data-entity="&amp;#356;" data-id="44741" title="Latin Capital Letter T With Caron">Ť</span>
    <span class="char" data-decimal="357" data-entity="&amp;#357;" data-id="44742" title="Latin Small Letter T With Caron">ť</span>
    <span class="char" data-decimal="358" data-entity="&amp;#358;" data-id="44743" title="Latin Capital Letter T With Stroke">Ŧ</span>
    <span class="char" data-decimal="359" data-entity="&amp;#359;" data-id="44744" title="Latin Small Letter T With Stroke">ŧ</span>
    <span class="char" data-decimal="428" data-entity="&amp;#428;" data-id="44907" title="Latin Capital Letter T With Hook">Ƭ</span>
    <span class="char" data-decimal="430" data-entity="&amp;#430;" data-id="44909" title="Latin Capital Letter T With Retroflex Hook">Ʈ</span>
    <span class="char" data-decimal="7831" data-entity="&amp;#7831;" data-id="44536" title="Latin Small Letter T With Diaeresis">ẗ</span>
    <span class="char" data-decimal="538" data-entity="&amp;#538;" data-id="45017" title="Latin Capital Letter T With Comma Below">Ț</span>
    <span class="char" data-decimal="574" data-entity="&amp;#574;" data-id="45053" title="Latin Capital Letter T With Diagonal Stroke">Ⱦ</span>
    <span class="char" data-decimal="427" data-entity="&amp;#427;" data-id="44906" title="Latin Small Letter T With Palatal Hook">ƫ</span>
    <span class="char" data-decimal="429" data-entity="&amp;#429;" data-id="44908" title="Latin Small Letter T With Hook">ƭ</span>
    <span class="char" data-decimal="539" data-entity="&amp;#539;" data-id="45018" title="Latin Small Letter T With Comma Below">ț</span>
    <span class="char" data-decimal="11366" data-entity="&amp;#11366;" data-id="45077" title="Latin Small Letter T With Diagonal Stroke">ⱦ</span>
    <span class="char" data-decimal="566" data-entity="&amp;#566;" data-id="45045" title="Latin Small Letter T With Curl">ȶ</span>
    <span class="char" data-decimal="8481" data-entity="&amp;#8481;" data-id="45272" title="Telephone Sign">℡</span>
    <span class="char" data-decimal="8482" data-entity="&amp;trade;" data-id="45273" title="Trade Mark Sign">™</span>
    <span class="char" data-decimal="9418" data-entity="&amp;#9418;" data-id="43869" title="Circled Latin Capital Letter U">Ⓤ</span>
    <span class="char" data-decimal="9444" data-entity="&amp;#9444;" data-id="43895" title="Circled Latin Small Letter U">ⓤ</span>
    <span class="char" data-decimal="9392" data-entity="&amp;#9392;" data-id="43843" title="Parenthesized Latin Small Letter U">⒰</span>
    <span class="char" data-decimal="85" data-entity="&amp;#85;" data-id="44821" title="Latin Capital Letter U">U</span>
    <span class="char" data-decimal="117" data-entity="&amp;#117;" data-id="44853" title="Latin Small Letter U">u</span>
    <span class="char" data-decimal="7794" data-entity="&amp;#7794;" data-id="44499" title="Latin Capital Letter U With Diaeresis Below">Ṳ</span>
    <span class="char" data-decimal="7795" data-entity="&amp;#7795;" data-id="44500" title="Latin Small Letter U With Diaeresis Below">ṳ</span>
    <span class="char" data-decimal="7796" data-entity="&amp;#7796;" data-id="44501" title="Latin Capital Letter U With Tilde Below">Ṵ</span>
    <span class="char" data-decimal="7797" data-entity="&amp;#7797;" data-id="44502" title="Latin Small Letter U With Tilde Below">ṵ</span>
    <span class="char" data-decimal="7798" data-entity="&amp;#7798;" data-id="44503" title="Latin Capital Letter U With Circumflex Below">Ṷ</span>
    <span class="char" data-decimal="7799" data-entity="&amp;#7799;" data-id="44504" title="Latin Small Letter U With Circumflex Below">ṷ</span>
    <span class="char" data-decimal="7800" data-entity="&amp;#7800;" data-id="44505" title="Latin Capital Letter U With Tilde And Acute">Ṹ</span>
    <span class="char" data-decimal="7801" data-entity="&amp;#7801;" data-id="44506" title="Latin Small Letter U With Tilde And Acute">ṹ</span>
    <span class="char" data-decimal="7802" data-entity="&amp;#7802;" data-id="44507" title="Latin Capital Letter U With Macron And Diaeresis">Ṻ</span>
    <span class="char" data-decimal="7803" data-entity="&amp;#7803;" data-id="44508" title="Latin Small Letter U With Macron And Diaeresis">ṻ</span>
    <span class="char" data-decimal="7908" data-entity="&amp;#7908;" data-id="44613" title="Latin Capital Letter U With Dot Below">Ụ</span>
    <span class="char" data-decimal="7910" data-entity="&amp;#7910;" data-id="44615" title="Latin Capital Letter U With Hook Above">Ủ</span>
    <span class="char" data-decimal="7911" data-entity="&amp;#7911;" data-id="44616" title="Latin Small Letter U With Hook Above">ủ</span>
    <span class="char" data-decimal="7912" data-entity="&amp;#7912;" data-id="44617" title="Latin Capital Letter U With Horn And Acute">Ứ</span>
    <span class="char" data-decimal="7914" data-entity="&amp;#7914;" data-id="44619" title="Latin Capital Letter U With Horn And Grave">Ừ</span>
    <span class="char" data-decimal="7909" data-entity="&amp;#7909;" data-id="44614" title="Latin Small Letter U With Dot Below">ụ</span>
    <span class="char" data-decimal="7913" data-entity="&amp;#7913;" data-id="44618" title="Latin Small Letter U With Horn And Acute">ứ</span>
    <span class="char" data-decimal="7916" data-entity="&amp;#7916;" data-id="44621" title="Latin Capital Letter U With Horn And Hook Above">Ử</span>
    <span class="char" data-decimal="7917" data-entity="&amp;#7917;" data-id="44622" title="Latin Small Letter U With Horn And Hook Above">ử</span>
    <span class="char" data-decimal="7915" data-entity="&amp;#7915;" data-id="44620" title="Latin Small Letter U With Horn And Grave">ừ</span>
    <span class="char" data-decimal="7919" data-entity="&amp;#7919;" data-id="44624" title="Latin Small Letter U With Horn And Tilde">ữ</span>
    <span class="char" data-decimal="7918" data-entity="&amp;#7918;" data-id="44623" title="Latin Capital Letter U With Horn And Tilde">Ữ</span>
    <span class="char" data-decimal="7920" data-entity="&amp;#7920;" data-id="44625" title="Latin Capital Letter U With Horn And Dot Below">Ự</span>
    <span class="char" data-decimal="7921" data-entity="&amp;#7921;" data-id="44626" title="Latin Small Letter U With Horn And Dot Below">ự</span>
    <span class="char" data-decimal="360" data-entity="&amp;#360;" data-id="44745" title="Latin Capital Letter U With Tilde">Ũ</span>
    <span class="char" data-decimal="361" data-entity="&amp;#361;" data-id="44746" title="Latin Small Letter U With Tilde">ũ</span>
    <span class="char" data-decimal="362" data-entity="&amp;#362;" data-id="44747" title="Latin Capital Letter U With Macron">Ū</span>
    <span class="char" data-decimal="363" data-entity="&amp;#363;" data-id="44748" title="Latin Small Letter U With Macron">ū</span>
    <span class="char" data-decimal="364" data-entity="&amp;#364;" data-id="44749" title="Latin Capital Letter U With Breve">Ŭ</span>
    <span class="char" data-decimal="365" data-entity="&amp;#365;" data-id="44750" title="Latin Small Letter U With Breve">ŭ</span>
    <span class="char" data-decimal="366" data-entity="&amp;#366;" data-id="44751" title="Latin Capital Letter U With Ring Above">Ů</span>
    <span class="char" data-decimal="367" data-entity="&amp;#367;" data-id="44752" title="Latin Small Letter U With Ring Above">ů</span>
    <span class="char" data-decimal="368" data-entity="&amp;#368;" data-id="44753" title="Latin Capital Letter U With Double Acute">Ű</span>
    <span class="char" data-decimal="369" data-entity="&amp;#369;" data-id="44754" title="Latin Small Letter U With Double Acute">ű</span>
    <span class="char" data-decimal="473" data-entity="&amp;#473;" data-id="44952" title="Latin Capital Letter U With Diaeresis And Caron">Ǚ</span>
    <span class="char" data-decimal="474" data-entity="&amp;#474;" data-id="44953" title="Latin Small Letter U With Diaeresis And Caron">ǚ</span>
    <span class="char" data-decimal="471" data-entity="&amp;#471;" data-id="44950" title="Latin Capital Letter U With Diaeresis And Acute">Ǘ</span>
    <span class="char" data-decimal="472" data-entity="&amp;#472;" data-id="44951" title="Latin Small Letter U With Diaeresis And Acute">ǘ</span>
    <span class="char" data-decimal="475" data-entity="&amp;#475;" data-id="44954" title="Latin Capital Letter U With Diaeresis And Grave">Ǜ</span>
    <span class="char" data-decimal="476" data-entity="&amp;#476;" data-id="44955" title="Latin Small Letter U With Diaeresis And Grave">ǜ</span>
    <span class="char" data-decimal="370" data-entity="&amp;#370;" data-id="44755" title="Latin Capital Letter U With Ogonek">Ų</span>
    <span class="char" data-decimal="371" data-entity="&amp;#371;" data-id="44756" title="Latin Small Letter U With Ogonek">ų</span>
    <span class="char" data-decimal="467" data-entity="&amp;#467;" data-id="44946" title="Latin Capital Letter U With Caron">Ǔ</span>
    <span class="char" data-decimal="468" data-entity="&amp;#468;" data-id="44947" title="Latin Small Letter U With Caron">ǔ</span>
    <span class="char" data-decimal="532" data-entity="&amp;#532;" data-id="45011" title="Latin Capital Letter U With Double Grave">Ȕ</span>
    <span class="char" data-decimal="533" data-entity="&amp;#533;" data-id="45012" title="Latin Small Letter U With Double Grave">ȕ</span>
    <span class="char" data-decimal="219" data-entity="&amp;Ucirc;" data-id="45202" title="Latin Capital Letter U With Circumflex">Û</span>
    <span class="char" data-decimal="251" data-entity="&amp;ucirc;" data-id="45234" title="Latin Small Letter U With Circumflex">û</span>
    <span class="char" data-decimal="534" data-entity="&amp;#534;" data-id="45013" title="Latin Capital Letter U With Inverted Breve">Ȗ</span>
    <span class="char" data-decimal="535" data-entity="&amp;#535;" data-id="45014" title="Latin Small Letter U With Inverted Breve">ȗ</span>
    <span class="char" data-decimal="217" data-entity="&amp;Ugrave;" data-id="45200" title="Latin Capital Letter U With Grave">Ù</span>
    <span class="char" data-decimal="249" data-entity="&amp;ugrave;" data-id="45232" title="Latin Small Letter U With Grave">ù</span>
    <span class="char" data-decimal="220" data-entity="&amp;Uuml;" data-id="45203" title="Latin Capital Letter U With Diaeresis">Ü</span>
    <span class="char" data-decimal="252" data-entity="&amp;uuml;" data-id="45235" title="Latin Small Letter U With Diaeresis">ü</span>
    <span class="char" data-decimal="431" data-entity="&amp;#431;" data-id="44910" title="Latin Capital Letter U With Horn">Ư</span>
    <span class="char" data-decimal="250" data-entity="&amp;uacute;" data-id="45233" title="Latin Small Letter U With Acute">ú</span>
    <span class="char" data-decimal="580" data-entity="&amp;#580;" data-id="45059" title="Latin Capital Letter U Bar">Ʉ</span>
    <span class="char" data-decimal="432" data-entity="&amp;#432;" data-id="44911" title="Latin Small Letter U With Horn">ư</span>
    <span class="char" data-decimal="434" data-entity="&amp;#434;" data-id="44913" title="Latin Capital Letter V With Hook">Ʋ</span>
    <span class="char" data-decimal="433" data-entity="&amp;#433;" data-id="44912" title="Latin Capital Letter Upsilon">Ʊ</span>
    <span class="char" data-decimal="9419" data-entity="&amp;#9419;" data-id="43870" title="Circled Latin Capital Letter V">Ⓥ</span>
    <span class="char" data-decimal="9445" data-entity="&amp;#9445;" data-id="43896" title="Circled Latin Small Letter V">ⓥ</span>
    <span class="char" data-decimal="9393" data-entity="&amp;#9393;" data-id="43844" title="Parenthesized Latin Small Letter V">⒱</span>
    <span class="char" data-decimal="86" data-entity="&amp;#86;" data-id="44822" title="Latin Capital Letter V">V</span>
    <span class="char" data-decimal="118" data-entity="&amp;#118;" data-id="44854" title="Latin Small Letter V">v</span>
    <span class="char" data-decimal="7804" data-entity="&amp;#7804;" data-id="44509" title="Latin Capital Letter V With Tilde">Ṽ</span>
    <span class="char" data-decimal="7805" data-entity="&amp;#7805;" data-id="44510" title="Latin Small Letter V With Tilde">ṽ</span>
    <span class="char" data-decimal="7806" data-entity="&amp;#7806;" data-id="44511" title="Latin Capital Letter V With Dot Below">Ṿ</span>
    <span class="char" data-decimal="7807" data-entity="&amp;#7807;" data-id="44512" title="Latin Small Letter V With Dot Below">ṿ</span>
    <span class="char" data-decimal="7932" data-entity="&amp;#7932;" data-id="44637" title="Latin Capital Letter Middle Welsh V">Ỽ</span>
    <span class="char" data-decimal="581" data-entity="&amp;#581;" data-id="45060" title="Latin Capital Letter Turned V">Ʌ</span>
    <span class="char" data-decimal="8483" data-entity="&amp;#8483;" data-id="45274" title="Versicle">℣</span>
    <span class="char" data-decimal="11377" data-entity="&amp;#11377;" data-id="45088" title="Latin Small Letter V With Right Hook">ⱱ</span>
    <span class="char" data-decimal="11380" data-entity="&amp;#11380;" data-id="45091" title="Latin Small Letter V With Curl">ⱴ</span>
    <span class="char" data-decimal="11389" data-entity="&amp;#11389;" data-id="45100" title="Modifier Letter Capital V">ⱽ</span>
    <span class="char" data-decimal="9420" data-entity="&amp;#9420;" data-id="43871" title="Circled Latin Capital Letter W">Ⓦ</span>
    <span class="char" data-decimal="9446" data-entity="&amp;#9446;" data-id="43897" title="Circled Latin Small Letter W">ⓦ</span>
    <span class="char" data-decimal="9394" data-entity="&amp;#9394;" data-id="43845" title="Parenthesized Latin Small Letter W">⒲</span>
    <span class="char" data-decimal="87" data-entity="&amp;#87;" data-id="44823" title="Latin Capital Letter W">W</span>
    <span class="char" data-decimal="119" data-entity="&amp;#119;" data-id="44855" title="Latin Small Letter W">w</span>
    <span class="char" data-decimal="7808" data-entity="&amp;#7808;" data-id="44513" title="Latin Capital Letter W With Grave">Ẁ</span>
    <span class="char" data-decimal="7809" data-entity="&amp;#7809;" data-id="44514" title="Latin Small Letter W With Grave">ẁ</span>
    <span class="char" data-decimal="7810" data-entity="&amp;#7810;" data-id="44515" title="Latin Capital Letter W With Acute">Ẃ</span>
    <span class="char" data-decimal="7811" data-entity="&amp;#7811;" data-id="44516" title="Latin Small Letter W With Acute">ẃ</span>
    <span class="char" data-decimal="7812" data-entity="&amp;#7812;" data-id="44517" title="Latin Capital Letter W With Diaeresis">Ẅ</span>
    <span class="char" data-decimal="7813" data-entity="&amp;#7813;" data-id="44518" title="Latin Small Letter W With Diaeresis">ẅ</span>
    <span class="char" data-decimal="7814" data-entity="&amp;#7814;" data-id="44519" title="Latin Capital Letter W With Dot Above">Ẇ</span>
    <span class="char" data-decimal="7815" data-entity="&amp;#7815;" data-id="44520" title="Latin Small Letter W With Dot Above">ẇ</span>
    <span class="char" data-decimal="7816" data-entity="&amp;#7816;" data-id="44521" title="Latin Capital Letter W With Dot Below">Ẉ</span>
    <span class="char" data-decimal="7817" data-entity="&amp;#7817;" data-id="44522" title="Latin Small Letter W With Dot Below">ẉ</span>
    <span class="char" data-decimal="372" data-entity="&amp;#372;" data-id="44757" title="Latin Capital Letter W With Circumflex">Ŵ</span>
    <span class="char" data-decimal="373" data-entity="&amp;#373;" data-id="44758" title="Latin Small Letter W With Circumflex">ŵ</span>
    <span class="char" data-decimal="7832" data-entity="&amp;#7832;" data-id="44537" title="Latin Small Letter W With Ring Above">ẘ</span>
    <span class="char" data-decimal="11378" data-entity="&amp;#11378;" data-id="45089" title="Latin Capital Letter W With Hook">Ⱳ</span>
    <span class="char" data-decimal="11379" data-entity="&amp;#11379;" data-id="45090" title="Latin Small Letter W With Hook">ⱳ</span>
    <span class="char" data-decimal="9421" data-entity="&amp;#9421;" data-id="43872" title="Circled Latin Capital Letter X">Ⓧ</span>
    <span class="char" data-decimal="9447" data-entity="&amp;#9447;" data-id="43898" title="Circled Latin Small Letter X">ⓧ</span>
    <span class="char" data-decimal="9395" data-entity="&amp;#9395;" data-id="43846" title="Parenthesized Latin Small Letter X">⒳</span>
    <span class="char" data-decimal="88" data-entity="&amp;#88;" data-id="44824" title="Latin Capital Letter X">X</span>
    <span class="char" data-decimal="120" data-entity="&amp;#120;" data-id="44856" title="Latin Small Letter X">x</span>
    <span class="char" data-decimal="7818" data-entity="&amp;#7818;" data-id="44523" title="Latin Capital Letter X With Dot Above">Ẋ</span>
    <span class="char" data-decimal="7819" data-entity="&amp;#7819;" data-id="44524" title="Latin Small Letter X With Dot Above">ẋ</span>
    <span class="char" data-decimal="7820" data-entity="&amp;#7820;" data-id="44525" title="Latin Capital Letter X With Diaeresis">Ẍ</span>
    <span class="char" data-decimal="7821" data-entity="&amp;#7821;" data-id="44526" title="Latin Small Letter X With Diaeresis">ẍ</span>
    <span class="char" data-decimal="8501" data-entity="&amp;alefsym;" data-id="45292" title="Alef Symbol">ℵ</span>
    <span class="char" data-decimal="215" data-entity="&amp;times;" data-id="45198" title="Multiplication Sign">×</span>
    <span class="char" data-decimal="9422" data-entity="&amp;#9422;" data-id="43873" title="Circled Latin Capital Letter Y">Ⓨ</span>
    <span class="char" data-decimal="9448" data-entity="&amp;#9448;" data-id="43899" title="Circled Latin Small Letter Y">ⓨ</span>
    <span class="char" data-decimal="9396" data-entity="&amp;#9396;" data-id="43847" title="Parenthesized Latin Small Letter Y">⒴</span>
    <span class="char" data-decimal="121" data-entity="&amp;#121;" data-id="44857" title="Latin Small Letter Y">y</span>
    <span class="char" data-decimal="89" data-entity="&amp;#89;" data-id="44825" title="Latin Capital Letter Y">Y</span>
    <span class="char" data-decimal="7822" data-entity="&amp;#7822;" data-id="44527" title="Latin Capital Letter Y With Dot Above">Ẏ</span>
    <span class="char" data-decimal="7823" data-entity="&amp;#7823;" data-id="44528" title="Latin Small Letter Y With Dot Above">ẏ</span>
    <span class="char" data-decimal="7934" data-entity="&amp;#7934;" data-id="44639" title="Latin Capital Letter Y With Loop">Ỿ</span>
    <span class="char" data-decimal="7935" data-entity="&amp;#7935;" data-id="44640" title="Latin Small Letter Y With Loop">ỿ</span>
    <span class="char" data-decimal="7833" data-entity="&amp;#7833;" data-id="44538" title="Latin Small Letter Y With Ring Above">ẙ</span>
    <span class="char" data-decimal="7922" data-entity="&amp;#7922;" data-id="44627" title="Latin Capital Letter Y With Grave">Ỳ</span>
    <span class="char" data-decimal="7923" data-entity="&amp;#7923;" data-id="44628" title="Latin Small Letter Y With Grave">ỳ</span>
    <span class="char" data-decimal="7924" data-entity="&amp;#7924;" data-id="44629" title="Latin Capital Letter Y With Dot Below">Ỵ</span>
    <span class="char" data-decimal="7925" data-entity="&amp;#7925;" data-id="44630" title="Latin Small Letter Y With Dot Below">ỵ</span>
    <span class="char" data-decimal="7926" data-entity="&amp;#7926;" data-id="44631" title="Latin Capital Letter Y With Hook Above">Ỷ</span>
    <span class="char" data-decimal="7927" data-entity="&amp;#7927;" data-id="44632" title="Latin Small Letter Y With Hook Above">ỷ</span>
    <span class="char" data-decimal="7928" data-entity="&amp;#7928;" data-id="44633" title="Latin Capital Letter Y With Tilde">Ỹ</span>
    <span class="char" data-decimal="7929" data-entity="&amp;#7929;" data-id="44634" title="Latin Small Letter Y With Tilde">ỹ</span>
    <span class="char" data-decimal="374" data-entity="&amp;#374;" data-id="44759" title="Latin Capital Letter Y With Circumflex">Ŷ</span>
    <span class="char" data-decimal="375" data-entity="&amp;#375;" data-id="44760" title="Latin Small Letter Y With Circumflex">ŷ</span>
    <span class="char" data-decimal="435" data-entity="&amp;#435;" data-id="44914" title="Latin Capital Letter Y With Hook">Ƴ</span>
    <span class="char" data-decimal="436" data-entity="&amp;#436;" data-id="44915" title="Latin Small Letter Y With Hook">ƴ</span>
    <span class="char" data-decimal="376" data-entity="&amp;#376;" data-id="44761" title="Latin Capital Letter Y With Diaeresis">Ÿ</span>
    <span class="char" data-decimal="255" data-entity="&amp;yuml;" data-id="45238" title="Latin Small Letter Y With Diaeresis">ÿ</span>
    <span class="char" data-decimal="221" data-entity="&amp;Yacute;" data-id="45204" title="Latin Capital Letter Y With Acute">Ý</span>
    <span class="char" data-decimal="253" data-entity="&amp;yacute;" data-id="45236" title="Latin Small Letter Y With Acute">ý</span>
    <span class="char" data-decimal="590" data-entity="&amp;#590;" data-id="45069" title="Latin Capital Letter Y With Stroke">Ɏ</span>
    <span class="char" data-decimal="591" data-entity="&amp;#591;" data-id="45070" title="Latin Small Letter Y With Stroke">ɏ</span>
    <span class="char" data-decimal="562" data-entity="&amp;#562;" data-id="45041" title="Latin Capital Letter Y With Macron">Ȳ</span>
    <span class="char" data-decimal="404" data-entity="&amp;#404;" data-id="44883" title="Latin Capital Letter Gamma">Ɣ</span>
    <span class="char" data-decimal="8516" data-entity="&amp;#8516;" data-id="45306" title="Turned Sans Serif Capital Y">⅄</span>
    <span class="char" data-decimal="563" data-entity="&amp;#563;" data-id="45042" title="Latin Small Letter Y With Macron">ȳ</span>
    <span class="char" data-decimal="8509" data-entity="&amp;#8509;" data-id="45299" title="Double Struck Small Gamma">ℽ</span>
    <span class="char" data-decimal="9423" data-entity="&amp;#9423;" data-id="43874" title="Circled Latin Capital Letter Z">Ⓩ</span>
    <span class="char" data-decimal="9449" data-entity="&amp;#9449;" data-id="43900" title="Circled Latin Small Letter Z">ⓩ</span>
    <span class="char" data-decimal="9397" data-entity="&amp;#9397;" data-id="43848" title="Parenthesized Latin Small Letter Z">⒵</span>
    <span class="char" data-decimal="90" data-entity="&amp;#90;" data-id="44826" title="Latin Capital Letter Z">Z</span>
    <span class="char" data-decimal="122" data-entity="&amp;#122;" data-id="44858" title="Latin Small Letter Z">z</span>
    <span class="char" data-decimal="7824" data-entity="&amp;#7824;" data-id="44529" title="Latin Capital Letter Z With Circumflex">Ẑ</span>
    <span class="char" data-decimal="7825" data-entity="&amp;#7825;" data-id="44530" title="Latin Small Letter Z With Circumflex">ẑ</span>
    <span class="char" data-decimal="7826" data-entity="&amp;#7826;" data-id="44531" title="Latin Capital Letter Z With Dot Below">Ẓ</span>
    <span class="char" data-decimal="7827" data-entity="&amp;#7827;" data-id="44532" title="Latin Small Letter Z With Dot Below">ẓ</span>
    <span class="char" data-decimal="7828" data-entity="&amp;#7828;" data-id="44533" title="Latin Capital Letter Z With Line Below">Ẕ</span>
    <span class="char" data-decimal="7829" data-entity="&amp;#7829;" data-id="44534" title="Latin Small Letter Z With Line Below">ẕ</span>
    <span class="char" data-decimal="377" data-entity="&amp;#377;" data-id="44762" title="Latin Capital Letter Z With Acute">Ź</span>
    <span class="char" data-decimal="378" data-entity="&amp;#378;" data-id="44763" title="Latin Small Letter Z With Acute">ź</span>
    <span class="char" data-decimal="379" data-entity="&amp;#379;" data-id="44764" title="Latin Capital Letter Z With Dot Above">Ż</span>
    <span class="char" data-decimal="380" data-entity="&amp;#380;" data-id="44765" title="Latin Small Letter Z With Dot Above">ż</span>
    <span class="char" data-decimal="381" data-entity="&amp;#381;" data-id="44766" title="Latin Capital Letter Z With Caron">Ž</span>
    <span class="char" data-decimal="382" data-entity="&amp;#382;" data-id="44767" title="Latin Small Letter Z With Caron">ž</span>
    <span class="char" data-decimal="548" data-entity="&amp;#548;" data-id="45027" title="Latin Capital Letter Z With Hook">Ȥ</span>
    <span class="char" data-decimal="549" data-entity="&amp;#549;" data-id="45028" title="Latin Small Letter Z With Hook">ȥ</span>
    <span class="char" data-decimal="11371" data-entity="&amp;#11371;" data-id="45082" title="Latin Capital Letter Z With Descender">Ⱬ</span>
    <span class="char" data-decimal="11372" data-entity="&amp;#11372;" data-id="45083" title="Latin Small Letter Z With Descender">ⱬ</span>
    <span class="char" data-decimal="437" data-entity="&amp;#437;" data-id="44916" title="Latin Capital Letter Z With Stroke">Ƶ</span>
    <span class="char" data-decimal="438" data-entity="&amp;#438;" data-id="44917" title="Latin Small Letter Z With Stroke">ƶ</span>
    <span class="char" data-decimal="576" data-entity="&amp;#576;" data-id="45055" title="Latin Small Letter Z With Swash Tail">ɀ</span>
    <span class="char" data-decimal="8488" data-entity="&amp;#8488;" data-id="45279" title="Black Letter Capital Z">ℨ</span>
    <span class="char" data-decimal="8484" data-entity="&amp;#8484;" data-id="45275" title="Double Struck Capital Z">ℤ</span>
    <span class="char" data-decimal="10176" data-entity="&amp;#10176;" data-id="45316" title="Three Dimensional Angle">⟀</span>
    <span class="char" data-decimal="10177" data-entity="&amp;#10177;" data-id="45317" title="White Triangle Containing Small White Triangle">⟁</span>
    <span class="char" data-decimal="10178" data-entity="&amp;#10178;" data-id="45318" title="Perpendicular">⟂</span>
    <span class="char" data-decimal="10179" data-entity="&amp;#10179;" data-id="45319" title="Open Subset">⟃</span>
    <span class="char" data-decimal="10180" data-entity="&amp;#10180;" data-id="45320" title="Open Superset">⟄</span>
    <span class="char" data-decimal="10183" data-entity="&amp;#10183;" data-id="45321" title="Or With Dot Inside">⟇</span>
    <span class="char" data-decimal="10184" data-entity="&amp;#10184;" data-id="45322" title="Reverse Solidus Preceding Subset">⟈</span>
    <span class="char" data-decimal="10185" data-entity="&amp;#10185;" data-id="45323" title="Superset Preceding Solidus">⟉</span>
    <span class="char" data-decimal="10186" data-entity="&amp;#10186;" data-id="45324" title="Vertical Bar With Horizontal Stroke">⟊</span>
    <span class="char" data-decimal="10192" data-entity="&amp;#10192;" data-id="45325" title="White Diamond With Centred Dot">⟐</span>
    <span class="char" data-decimal="10193" data-entity="&amp;#10193;" data-id="45326" title="And With Dot">⟑</span>
    <span class="char" data-decimal="10194" data-entity="&amp;#10194;" data-id="45327" title="Element Of Opening Upwards">⟒</span>
    <span class="char" data-decimal="10195" data-entity="&amp;#10195;" data-id="45328" title="Lower Right Corner With Dot">⟓</span>
    <span class="char" data-decimal="10196" data-entity="&amp;#10196;" data-id="45329" title="Upper Left Corner With Dot">⟔</span>
    <span class="char" data-decimal="10197" data-entity="&amp;#10197;" data-id="45330" title="Left Outer Join">⟕</span>
    <span class="char" data-decimal="10198" data-entity="&amp;#10198;" data-id="45331" title="Right Outer Join">⟖</span>
    <span class="char" data-decimal="10199" data-entity="&amp;#10199;" data-id="45332" title="Full Outer Join">⟗</span>
    <span class="char" data-decimal="10200" data-entity="&amp;#10200;" data-id="45333" title="Large Up Tack">⟘</span>
    <span class="char" data-decimal="10201" data-entity="&amp;#10201;" data-id="45334" title="Large Down Tack">⟙</span>
    <span class="char" data-decimal="10202" data-entity="&amp;#10202;" data-id="45335" title="Left And Right Double Turnstile">⟚</span>
    <span class="char" data-decimal="10203" data-entity="&amp;#10203;" data-id="45336" title="Left And Right Tack">⟛</span>
    <span class="char" data-decimal="10204" data-entity="&amp;#10204;" data-id="45337" title="Left Multimap">⟜</span>
    <span class="char" data-decimal="10205" data-entity="&amp;#10205;" data-id="45338" title="Long Right Tack">⟝</span>
    <span class="char" data-decimal="10206" data-entity="&amp;#10206;" data-id="45339" title="Long Left Tack">⟞</span>
    <span class="char" data-decimal="10207" data-entity="&amp;#10207;" data-id="45340" title="Up Tack With Circle Above">⟟</span>
    <span class="char" data-decimal="10208" data-entity="&amp;#10208;" data-id="45341" title="Lozenge Divided By Horizontal Rule">⟠</span>
    <span class="char" data-decimal="10209" data-entity="&amp;#10209;" data-id="45342" title="White Concave Sided Diamond">⟡</span>
    <span class="char" data-decimal="10210" data-entity="&amp;#10210;" data-id="45343" title="White Concave Sided Diamond With Leftwards Tick">⟢</span>
    <span class="char" data-decimal="10211" data-entity="&amp;#10211;" data-id="45344" title="White Concave Sided Diamond With Rightwards Tick">⟣</span>
    <span class="char" data-decimal="10212" data-entity="&amp;#10212;" data-id="45345" title="White Square With Leftwards Tick">⟤</span>
    <span class="char" data-decimal="10213" data-entity="&amp;#10213;" data-id="45346" title="White Square With Rightwards Tick">⟥</span>
    <span class="char" data-decimal="10214" data-entity="&amp;#10214;" data-id="45347" title="Mathematical Left White Square Bracket">⟦</span>
    <span class="char" data-decimal="10215" data-entity="&amp;#10215;" data-id="45348" title="Mathematical Right White Square Bracket">⟧</span>
    <span class="char" data-decimal="10216" data-entity="&amp;#10216;" data-id="45349" title="Mathematical Left Angle Bracket">⟨</span>
    <span class="char" data-decimal="10217" data-entity="&amp;#10217;" data-id="45350" title="Mathematical Right Angle Bracket">⟩</span>
    <span class="char" data-decimal="10218" data-entity="&amp;#10218;" data-id="45351" title="Mathematical Left Double Angle Bracket">⟪</span>
    <span class="char" data-decimal="10219" data-entity="&amp;#10219;" data-id="45352" title="Mathematical Right Double Angle Bracket">⟫</span>
    <span class="char" data-decimal="10624" data-entity="&amp;#10624;" data-id="45353" title="Triple Vertical Bar Delimiter">⦀</span>
    <span class="char" data-decimal="10625" data-entity="&amp;#10625;" data-id="45354" title="Z Notation Spot">⦁</span>
    <span class="char" data-decimal="10626" data-entity="&amp;#10626;" data-id="45355" title="Z Notation Type Colon">⦂</span>
    <span class="char" data-decimal="10627" data-entity="&amp;#10627;" data-id="45356" title="Left White Curly Bracket">⦃</span>
    <span class="char" data-decimal="10628" data-entity="&amp;#10628;" data-id="45357" title="Right White Curly Bracket">⦄</span>
    <span class="char" data-decimal="10629" data-entity="&amp;#10629;" data-id="45358" title="Left White Parenthesis">⦅</span>
    <span class="char" data-decimal="10630" data-entity="&amp;#10630;" data-id="45359" title="Right White Parenthesis">⦆</span>
    <span class="char" data-decimal="10631" data-entity="&amp;#10631;" data-id="45360" title="Z Notation Left Image Bracket">⦇</span>
    <span class="char" data-decimal="10632" data-entity="&amp;#10632;" data-id="45361" title="Z Notation Right Image Bracket">⦈</span>
    <span class="char" data-decimal="10633" data-entity="&amp;#10633;" data-id="45362" title="Z Notation Left Binding Bracket">⦉</span>
    <span class="char" data-decimal="10634" data-entity="&amp;#10634;" data-id="45363" title="Z Notation Right Binding Bracket">⦊</span>
    <span class="char" data-decimal="10635" data-entity="&amp;#10635;" data-id="45364" title="Left Square Bracket With Underbar">⦋</span>
    <span class="char" data-decimal="10636" data-entity="&amp;#10636;" data-id="45365" title="Right Square Bracket With Underbar">⦌</span>
    <span class="char" data-decimal="10637" data-entity="&amp;#10637;" data-id="45366" title="Left Square Bracket With Tick In Top Corner">⦍</span>
    <span class="char" data-decimal="10638" data-entity="&amp;#10638;" data-id="45367" title="Right Square Bracket With Tick In Bottom Corner">⦎</span>
    <span class="char" data-decimal="10639" data-entity="&amp;#10639;" data-id="45368" title="Left Square Bracket With Tick In Bottom Corner">⦏</span>
    <span class="char" data-decimal="10640" data-entity="&amp;#10640;" data-id="45369" title="Right Square Bracket With Tick In Top Corner">⦐</span>
    <span class="char" data-decimal="10641" data-entity="&amp;#10641;" data-id="45370" title="Left Angle Bracket With Dot">⦑</span>
    <span class="char" data-decimal="10642" data-entity="&amp;#10642;" data-id="45371" title="Right Angle Bracket With Dot">⦒</span>
    <span class="char" data-decimal="10643" data-entity="&amp;#10643;" data-id="45372" title="Left Arc Less Than Bracket">⦓</span>
    <span class="char" data-decimal="10644" data-entity="&amp;#10644;" data-id="45373" title="Right Arc Greater Than Bracket">⦔</span>
    <span class="char" data-decimal="10645" data-entity="&amp;#10645;" data-id="45374" title="Double Left Arc Greater Than Bracket">⦕</span>
    <span class="char" data-decimal="10646" data-entity="&amp;#10646;" data-id="45375" title="Double Right Arc Less Than Bracket">⦖</span>
    <span class="char" data-decimal="10647" data-entity="&amp;#10647;" data-id="45376" title="Left Black Tortoise Shell Bracket">⦗</span>
    <span class="char" data-decimal="10648" data-entity="&amp;#10648;" data-id="45377" title="Right Black Tortoise Shell Bracket">⦘</span>
    <span class="char" data-decimal="10649" data-entity="&amp;#10649;" data-id="45378" title="Dotted Fence">⦙</span>
    <span class="char" data-decimal="10650" data-entity="&amp;#10650;" data-id="45379" title="Vertical Zigzag Line">⦚</span>
    <span class="char" data-decimal="10651" data-entity="&amp;#10651;" data-id="45380" title="Measured Angle Opening Left">⦛</span>
    <span class="char" data-decimal="10652" data-entity="&amp;#10652;" data-id="45381" title="Right Angle Variant With Square">⦜</span>
    <span class="char" data-decimal="10653" data-entity="&amp;#10653;" data-id="45382" title="Measured Right Angle With Dot">⦝</span>
    <span class="char" data-decimal="10654" data-entity="&amp;#10654;" data-id="45383" title="Angle With S Inside">⦞</span>
    <span class="char" data-decimal="10655" data-entity="&amp;#10655;" data-id="45384" title="Acute Angle">⦟</span>
    <span class="char" data-decimal="10656" data-entity="&amp;#10656;" data-id="45385" title="Spherical Angle Opening Left">⦠</span>
    <span class="char" data-decimal="10657" data-entity="&amp;#10657;" data-id="45386" title="Spherical Angle Opening Up">⦡</span>
    <span class="char" data-decimal="10658" data-entity="&amp;#10658;" data-id="45387" title="Turned Angle">⦢</span>
    <span class="char" data-decimal="10659" data-entity="&amp;#10659;" data-id="45388" title="Reversed Angle">⦣</span>
    <span class="char" data-decimal="10660" data-entity="&amp;#10660;" data-id="45389" title="Angle With Underbar">⦤</span>
    <span class="char" data-decimal="10661" data-entity="&amp;#10661;" data-id="45390" title="Reversed Angle With Underbar">⦥</span>
    <span class="char" data-decimal="10662" data-entity="&amp;#10662;" data-id="45391" title="Oblique Angle Opening Up">⦦</span>
    <span class="char" data-decimal="10663" data-entity="&amp;#10663;" data-id="45392" title="Oblique Angle Opening Down">⦧</span>
    <span class="char" data-decimal="10664" data-entity="&amp;#10664;" data-id="45393" title="Measured Angle With Open Arm Ending In Arrow Pointing Up And Right">⦨</span>
    <span class="char" data-decimal="10665" data-entity="&amp;#10665;" data-id="45394" title="Measured Angle With Open Arm Ending In Arrow Pointing Up And Left">⦩</span>
    <span class="char" data-decimal="10666" data-entity="&amp;#10666;" data-id="45395" title="Measured Angle With Open Arm Ending In Arrow Pointing Down And Right">⦪</span>
    <span class="char" data-decimal="10667" data-entity="&amp;#10667;" data-id="45396" title="Measured Angle With Open Arm Ending In Arrow Pointing Down And Left">⦫</span>
    <span class="char" data-decimal="10668" data-entity="&amp;#10668;" data-id="45397" title="Measured Angle With Open Arm Ending In Arrow Pointing Right And Up">⦬</span>
    <span class="char" data-decimal="10669" data-entity="&amp;#10669;" data-id="45398" title="Measured Angle With Open Arm Ending In Arrow Pointing Left And Up">⦭</span>
    <span class="char" data-decimal="10670" data-entity="&amp;#10670;" data-id="45399" title="Measured Angle With Open Arm Ending In Arrow Pointing Right And Down">⦮</span>
    <span class="char" data-decimal="10671" data-entity="&amp;#10671;" data-id="45400" title="Measured Angle With Open Arm Ending In Arrow Pointing Left And Down">⦯</span>
    <span class="char" data-decimal="10672" data-entity="&amp;#10672;" data-id="45401" title="Reversed Empty Set">⦰</span>
    <span class="char" data-decimal="10673" data-entity="&amp;#10673;" data-id="45402" title="Empty Set With Overbar">⦱</span>
    <span class="char" data-decimal="10674" data-entity="&amp;#10674;" data-id="45403" title="Empty Set With Small Circle Above">⦲</span>
    <span class="char" data-decimal="10675" data-entity="&amp;#10675;" data-id="45404" title="Empty Set With Right Arrow Above">⦳</span>
    <span class="char" data-decimal="10676" data-entity="&amp;#10676;" data-id="45405" title="Empty Set With Left Arrow Above">⦴</span>
    <span class="char" data-decimal="10677" data-entity="&amp;#10677;" data-id="45406" title="Circle With Horizontal Bar">⦵</span>
    <span class="char" data-decimal="10678" data-entity="&amp;#10678;" data-id="45407" title="Circled Vertical Bar">⦶</span>
    <span class="char" data-decimal="10679" data-entity="&amp;#10679;" data-id="45408" title="Circled Parallel">⦷</span>
    <span class="char" data-decimal="10680" data-entity="&amp;#10680;" data-id="45409" title="Circled Reverse Solidus">⦸</span>
    <span class="char" data-decimal="10681" data-entity="&amp;#10681;" data-id="45410" title="Circled Perpendicular">⦹</span>
    <span class="char" data-decimal="10682" data-entity="&amp;#10682;" data-id="45411" title="Circle Divided By Horizontal Bar And Top Half Divided By Vertical Bar">⦺</span>
    <span class="char" data-decimal="10683" data-entity="&amp;#10683;" data-id="45412" title="Circle With Superimposed X">⦻</span>
    <span class="char" data-decimal="10684" data-entity="&amp;#10684;" data-id="45413" title="Circled Anticlockwise Rotated Division Sign">⦼</span>
    <span class="char" data-decimal="10685" data-entity="&amp;#10685;" data-id="45414" title="Up Arrow Through Circle">⦽</span>
    <span class="char" data-decimal="10686" data-entity="&amp;#10686;" data-id="45415" title="Circled White Bullet">⦾</span>
    <span class="char" data-decimal="10687" data-entity="&amp;#10687;" data-id="45416" title="Circled Bullet">⦿</span>
    <span class="char" data-decimal="10688" data-entity="&amp;#10688;" data-id="45417" title="Circled Less Than">⧀</span>
    <span class="char" data-decimal="10689" data-entity="&amp;#10689;" data-id="45418" title="Circled Greater Than">⧁</span>
    <span class="char" data-decimal="10690" data-entity="&amp;#10690;" data-id="45419" title="Circle With Small Circle To The Right">⧂</span>
    <span class="char" data-decimal="10691" data-entity="&amp;#10691;" data-id="45420" title="Circle With Two Horizontal Strokes To The Right">⧃</span>
    <span class="char" data-decimal="10692" data-entity="&amp;#10692;" data-id="45421" title="Squared Rising Diagonal Slash">⧄</span>
    <span class="char" data-decimal="10693" data-entity="&amp;#10693;" data-id="45422" title="Squared Falling Diagonal Slash">⧅</span>
    <span class="char" data-decimal="10694" data-entity="&amp;#10694;" data-id="45423" title="Squared Asterisk">⧆</span>
    <span class="char" data-decimal="10695" data-entity="&amp;#10695;" data-id="45424" title="Squared Small Circle">⧇</span>
    <span class="char" data-decimal="10696" data-entity="&amp;#10696;" data-id="45425" title="Squared Square">⧈</span>
    <span class="char" data-decimal="10697" data-entity="&amp;#10697;" data-id="45426" title="Two Joined Squares">⧉</span>
    <span class="char" data-decimal="10698" data-entity="&amp;#10698;" data-id="45427" title="Triangle With Dot Above">⧊</span>
    <span class="char" data-decimal="10699" data-entity="&amp;#10699;" data-id="45428" title="Triangle With Underbar">⧋</span>
    <span class="char" data-decimal="10700" data-entity="&amp;#10700;" data-id="45429" title="S In Triangle">⧌</span>
    <span class="char" data-decimal="10701" data-entity="&amp;#10701;" data-id="45430" title="Triangle With Serifs At Bottom">⧍</span>
    <span class="char" data-decimal="10702" data-entity="&amp;#10702;" data-id="45431" title="Right Triangle Above Left Triangle">⧎</span>
    <span class="char" data-decimal="10703" data-entity="&amp;#10703;" data-id="45432" title="Left Triangle Beside Vertical Bar">⧏</span>
    <span class="char" data-decimal="10704" data-entity="&amp;#10704;" data-id="45433" title="Vertical Bar Beside Right Triangle">⧐</span>
    <span class="char" data-decimal="10705" data-entity="&amp;#10705;" data-id="45434" title="Bowtie With Left Half Black">⧑</span>
    <span class="char" data-decimal="10706" data-entity="&amp;#10706;" data-id="45435" title="Bowtie With Right Half Black">⧒</span>
    <span class="char" data-decimal="10707" data-entity="&amp;#10707;" data-id="45436" title="Black Bowtie">⧓</span>
    <span class="char" data-decimal="10708" data-entity="&amp;#10708;" data-id="45437" title="Times With Left Half Black">⧔</span>
    <span class="char" data-decimal="10709" data-entity="&amp;#10709;" data-id="45438" title="Times With Right Half Black">⧕</span>
    <span class="char" data-decimal="10710" data-entity="&amp;#10710;" data-id="45439" title="White Hourglass">⧖</span>
    <span class="char" data-decimal="10711" data-entity="&amp;#10711;" data-id="45440" title="Black Hourglass">⧗</span>
    <span class="char" data-decimal="10712" data-entity="&amp;#10712;" data-id="45441" title="Left Wiggly Fence">⧘</span>
    <span class="char" data-decimal="10713" data-entity="&amp;#10713;" data-id="45442" title="Right Wiggly Fence">⧙</span>
    <span class="char" data-decimal="10714" data-entity="&amp;#10714;" data-id="45443" title="Left Double Wiggly Fence">⧚</span>
    <span class="char" data-decimal="10715" data-entity="&amp;#10715;" data-id="45444" title="Right Double Wiggly Fence">⧛</span>
    <span class="char" data-decimal="10716" data-entity="&amp;#10716;" data-id="45445" title="Incomplete Infinity">⧜</span>
    <span class="char" data-decimal="10717" data-entity="&amp;#10717;" data-id="45446" title="Tie Over Infinity">⧝</span>
    <span class="char" data-decimal="10718" data-entity="&amp;#10718;" data-id="45447" title="Infinity Negated With Vertical Bar">⧞</span>
    <span class="char" data-decimal="10719" data-entity="&amp;#10719;" data-id="45448" title="Double Ended Multimap">⧟</span>
    <span class="char" data-decimal="10721" data-entity="&amp;#10721;" data-id="45449" title="Increases As">⧡</span>
    <span class="char" data-decimal="10722" data-entity="&amp;#10722;" data-id="45450" title="Shuffle Product">⧢</span>
    <span class="char" data-decimal="10723" data-entity="&amp;#10723;" data-id="45451" title="Equals Sign And Slanted Parallel">⧣</span>
    <span class="char" data-decimal="10724" data-entity="&amp;#10724;" data-id="45452" title="Equals Sign And Slanted Parallel With Tilde Above">⧤</span>
    <span class="char" data-decimal="10725" data-entity="&amp;#10725;" data-id="45453" title="Identical To And Slanted Parallel">⧥</span>
    <span class="char" data-decimal="10726" data-entity="&amp;#10726;" data-id="45454" title="Gleich Stark">⧦</span>
    <span class="char" data-decimal="10727" data-entity="&amp;#10727;" data-id="45455" title="Thermodynamic">⧧</span>
    <span class="char" data-decimal="10728" data-entity="&amp;#10728;" data-id="45456" title="Down Pointing Triangle With Left Half Black">⧨</span>
    <span class="char" data-decimal="10729" data-entity="&amp;#10729;" data-id="45457" title="Down Pointing Triangle With Right Half Black">⧩</span>
    <span class="char" data-decimal="10730" data-entity="&amp;#10730;" data-id="45458" title="Black Diamond With Down Arrow">⧪</span>
    <span class="char" data-decimal="10731" data-entity="&amp;#10731;" data-id="45459" title="Black Lozenge">⧫</span>
    <span class="char" data-decimal="10732" data-entity="&amp;#10732;" data-id="45460" title="White Circle With Down Arrow">⧬</span>
    <span class="char" data-decimal="10733" data-entity="&amp;#10733;" data-id="45461" title="Black Circle With Down Arrow">⧭</span>
    <span class="char" data-decimal="10734" data-entity="&amp;#10734;" data-id="45462" title="Error Barred White Square">⧮</span>
    <span class="char" data-decimal="10735" data-entity="&amp;#10735;" data-id="45463" title="Error Barred Black Square">⧯</span>
    <span class="char" data-decimal="10736" data-entity="&amp;#10736;" data-id="45464" title="Error Barred White Diamond">⧰</span>
    <span class="char" data-decimal="10737" data-entity="&amp;#10737;" data-id="45465" title="Error Barred Black Diamond">⧱</span>
    <span class="char" data-decimal="10738" data-entity="&amp;#10738;" data-id="45466" title="Error Barred White Circle">⧲</span>
    <span class="char" data-decimal="10739" data-entity="&amp;#10739;" data-id="45467" title="Error Barred Black Circle">⧳</span>
    <span class="char" data-decimal="10740" data-entity="&amp;#10740;" data-id="45468" title="Rule Delayed">⧴</span>
    <span class="char" data-decimal="10741" data-entity="&amp;#10741;" data-id="45469" title="Reverse Solidus Operator">⧵</span>
    <span class="char" data-decimal="10742" data-entity="&amp;#10742;" data-id="45470" title="Solidus With Overbar">⧶</span>
    <span class="char" data-decimal="10743" data-entity="&amp;#10743;" data-id="45471" title="Reverse Solidus With Horizontal Stroke">⧷</span>
    <span class="char" data-decimal="10744" data-entity="&amp;#10744;" data-id="45472" title="Big Solidus">⧸</span>
    <span class="char" data-decimal="10745" data-entity="&amp;#10745;" data-id="45473" title="Big Reverse Solidus">⧹</span>
    <span class="char" data-decimal="10746" data-entity="&amp;#10746;" data-id="45474" title="Double Plus">⧺</span>
    <span class="char" data-decimal="10747" data-entity="&amp;#10747;" data-id="45475" title="Triple Plus">⧻</span>
    <span class="char" data-decimal="10748" data-entity="&amp;#10748;" data-id="45476" title="Left Pointing Curved Angle Bracket">⧼</span>
    <span class="char" data-decimal="10749" data-entity="&amp;#10749;" data-id="45477" title="Right Pointing Curved Angle Bracket">⧽</span>
    <span class="char" data-decimal="10750" data-entity="&amp;#10750;" data-id="45478" title="Tiny">⧾</span>
    <span class="char" data-decimal="10751" data-entity="&amp;#10751;" data-id="45479" title="Miny">⧿</span>
    <span class="char" data-decimal="8704" data-entity="&amp;forall;" data-id="45480" title="For All">∀</span>
    <span class="char" data-decimal="8705" data-entity="&amp;#8705;" data-id="45481" title="Complement">∁</span>
    <span class="char" data-decimal="8706" data-entity="&amp;part;" data-id="45482" title="Partial Differential">∂</span>
    <span class="char" data-decimal="8707" data-entity="&amp;exist;" data-id="45483" title="There Exists">∃</span>
    <span class="char" data-decimal="8708" data-entity="&amp;#8708;" data-id="45484" title="There Does Not Exist">∄</span>
    <span class="char" data-decimal="8709" data-entity="&amp;empty;" data-id="45485" title="Empty Set">∅</span>
    <span class="char" data-decimal="8710" data-entity="&amp;#8710;" data-id="45486" title="Increment">∆</span>
    <span class="char" data-decimal="8711" data-entity="&amp;nabla;" data-id="45487" title="Nabla">∇</span>
    <span class="char" data-decimal="8712" data-entity="&amp;isin;" data-id="45488" title="Element Of">∈</span>
    <span class="char" data-decimal="8713" data-entity="&amp;notin;" data-id="45489" title="Not An Element Of">∉</span>
    <span class="char" data-decimal="8714" data-entity="&amp;#8714;" data-id="45490" title="Small Element Of">∊</span>
    <span class="char" data-decimal="8715" data-entity="&amp;ni;" data-id="45491" title="Contains As Member">∋</span>
    <span class="char" data-decimal="8716" data-entity="&amp;#8716;" data-id="45492" title="Does Not Contain As Member">∌</span>
    <span class="char" data-decimal="8717" data-entity="&amp;#8717;" data-id="45493" title="Small Contains As Member">∍</span>
    <span class="char" data-decimal="8718" data-entity="&amp;#8718;" data-id="45494" title="End Of Proof">∎</span>
    <span class="char" data-decimal="8719" data-entity="&amp;prod;" data-id="45495" title="N Ary Product">∏</span>
    <span class="char" data-decimal="8720" data-entity="&amp;#8720;" data-id="45496" title="N Ary Coproduct">∐</span>
    <span class="char" data-decimal="8721" data-entity="&amp;sum;" data-id="45497" title="N Ary Summation">∑</span>
    <span class="char" data-decimal="8722" data-entity="&amp;minus;" data-id="45498" title="Minus Sign">−</span>
    <span class="char" data-decimal="8723" data-entity="&amp;#8723;" data-id="45499" title="Minus Or Plus Sign">∓</span>
    <span class="char" data-decimal="8724" data-entity="&amp;#8724;" data-id="45500" title="Dot Plus">∔</span>
    <span class="char" data-decimal="8725" data-entity="&amp;#8725;" data-id="45501" title="Division Slash">∕</span>
    <span class="char" data-decimal="8726" data-entity="&amp;#8726;" data-id="45502" title="Set Minus">∖</span>
    <span class="char" data-decimal="8727" data-entity="&amp;lowast;" data-id="45503" title="Asterisk Operator">∗</span>
    <span class="char" data-decimal="8728" data-entity="&amp;#8728;" data-id="45504" title="Ring Operator">∘</span>
    <span class="char" data-decimal="8729" data-entity="&amp;#8729;" data-id="45505" title="Bullet Operator">∙</span>
    <span class="char" data-decimal="8730" data-entity="&amp;radic;" data-id="45506" title="Square Root">√</span>
    <span class="char" data-decimal="8731" data-entity="&amp;#8731;" data-id="45507" title="Cube Root">∛</span>
    <span class="char" data-decimal="8732" data-entity="&amp;#8732;" data-id="45508" title="Fourth Root">∜</span>
    <span class="char" data-decimal="8733" data-entity="&amp;prop;" data-id="45509" title="Proportional To">∝</span>
    <span class="char" data-decimal="8734" data-entity="&amp;infin;" data-id="45510" title="Infinity">∞</span>
    <span class="char" data-decimal="8735" data-entity="&amp;#8735;" data-id="45511" title="Right Angle">∟</span>
    <span class="char" data-decimal="8736" data-entity="&amp;ang;" data-id="45512" title="Angle">∠</span>
    <span class="char" data-decimal="8737" data-entity="&amp;#8737;" data-id="45513" title="Measured Angle">∡</span>
    <span class="char" data-decimal="8738" data-entity="&amp;#8738;" data-id="45514" title="Spherical Angle">∢</span>
    <span class="char" data-decimal="8739" data-entity="&amp;#8739;" data-id="45515" title="Divides">∣</span>
    <span class="char" data-decimal="8740" data-entity="&amp;#8740;" data-id="45516" title="Does Not Divide">∤</span>
    <span class="char" data-decimal="8741" data-entity="&amp;#8741;" data-id="45517" title="Parallel To">∥</span>
    <span class="char" data-decimal="8742" data-entity="&amp;#8742;" data-id="45518" title="Not Parallel To">∦</span>
    <span class="char" data-decimal="8743" data-entity="&amp;and;" data-id="45519" title="Logical And">∧</span>
    <span class="char" data-decimal="8744" data-entity="&amp;or;" data-id="45520" title="Logical Or">∨</span>
    <span class="char" data-decimal="8745" data-entity="&amp;cap;" data-id="45521" title="Intersection">∩</span>
    <span class="char" data-decimal="8746" data-entity="&amp;cup;" data-id="45522" title="Union">∪</span>
    <span class="char" data-decimal="8747" data-entity="&amp;int;" data-id="45523" title="Integral">∫</span>
    <span class="char" data-decimal="8748" data-entity="&amp;#8748;" data-id="45524" title="Double Integral">∬</span>
    <span class="char" data-decimal="8749" data-entity="&amp;#8749;" data-id="45525" title="Triple Integral">∭</span>
    <span class="char" data-decimal="8750" data-entity="&amp;#8750;" data-id="45526" title="Contour Integral">∮</span>
    <span class="char" data-decimal="8751" data-entity="&amp;#8751;" data-id="45527" title="Surface Integral">∯</span>
    <span class="char" data-decimal="8752" data-entity="&amp;#8752;" data-id="45528" title="Volume Integral">∰</span>
    <span class="char" data-decimal="8753" data-entity="&amp;#8753;" data-id="45529" title="Clockwise Integral">∱</span>
    <span class="char" data-decimal="8754" data-entity="&amp;#8754;" data-id="45530" title="Clockwise Contour Integral">∲</span>
    <span class="char" data-decimal="8755" data-entity="&amp;#8755;" data-id="45531" title="Anticlockwise Contour Integral">∳</span>
    <span class="char" data-decimal="8756" data-entity="&amp;there4;" data-id="45532" title="Therefore">∴</span>
    <span class="char" data-decimal="8757" data-entity="&amp;#8757;" data-id="45533" title="Because">∵</span>
    <span class="char" data-decimal="8758" data-entity="&amp;#8758;" data-id="45534" title="Ratio">∶</span>
    <span class="char" data-decimal="8759" data-entity="&amp;#8759;" data-id="45535" title="Proportion">∷</span>
    <span class="char" data-decimal="8760" data-entity="&amp;#8760;" data-id="45536" title="Dot Minus">∸</span>
    <span class="char" data-decimal="8761" data-entity="&amp;#8761;" data-id="45537" title="Excess">∹</span>
    <span class="char" data-decimal="8762" data-entity="&amp;#8762;" data-id="45538" title="Geometric Proportion">∺</span>
    <span class="char" data-decimal="8763" data-entity="&amp;#8763;" data-id="45539" title="Homothetic">∻</span>
    <span class="char" data-decimal="8764" data-entity="&amp;sim;" data-id="45540" title="Tilde Operator">∼</span>
    <span class="char" data-decimal="8765" data-entity="&amp;#8765;" data-id="45541" title="Reversed Tilde">∽</span>
    <span class="char" data-decimal="8766" data-entity="&amp;#8766;" data-id="45542" title="Inverted Lazy S">∾</span>
    <span class="char" data-decimal="8767" data-entity="&amp;#8767;" data-id="45543" title="Sine Wave">∿</span>
    <span class="char" data-decimal="8768" data-entity="&amp;#8768;" data-id="45544" title="Wreath Product">≀</span>
    <span class="char" data-decimal="8769" data-entity="&amp;#8769;" data-id="45545" title="Not Tilde">≁</span>
    <span class="char" data-decimal="8770" data-entity="&amp;#8770;" data-id="45546" title="Minus Tilde">≂</span>
    <span class="char" data-decimal="8771" data-entity="&amp;#8771;" data-id="45547" title="Asymptotically Equal To">≃</span>
    <span class="char" data-decimal="8772" data-entity="&amp;#8772;" data-id="45548" title="Not Asymptotically Equal To">≄</span>
    <span class="char" data-decimal="8773" data-entity="&amp;cong;" data-id="45549" title="Approximately Equal To">≅</span>
    <span class="char" data-decimal="8774" data-entity="&amp;#8774;" data-id="45550" title="Approximately But Not Actually Equal To">≆</span>
    <span class="char" data-decimal="8775" data-entity="&amp;#8775;" data-id="45551" title="Neither Approximately Nor Actually Equal To">≇</span>
    <span class="char" data-decimal="8776" data-entity="&amp;asymp;" data-id="45552" title="Almost Equal To">≈</span>
    <span class="char" data-decimal="8777" data-entity="&amp;#8777;" data-id="45553" title="Not Almost Equal To">≉</span>
    <span class="char" data-decimal="8778" data-entity="&amp;#8778;" data-id="45554" title="Almost Equal Or Equal To">≊</span>
    <span class="char" data-decimal="8779" data-entity="&amp;#8779;" data-id="45555" title="Triple Tilde">≋</span>
    <span class="char" data-decimal="8780" data-entity="&amp;#8780;" data-id="45556" title="All Equal To">≌</span>
    <span class="char" data-decimal="8781" data-entity="&amp;#8781;" data-id="45557" title="Equivalent To">≍</span>
    <span class="char" data-decimal="8782" data-entity="&amp;#8782;" data-id="45558" title="Geometrically Equivalent To">≎</span>
    <span class="char" data-decimal="8783" data-entity="&amp;#8783;" data-id="45559" title="Difference Between">≏</span>
    <span class="char" data-decimal="8784" data-entity="&amp;#8784;" data-id="45560" title="Approaches The Limit">≐</span>
    <span class="char" data-decimal="8785" data-entity="&amp;#8785;" data-id="45561" title="Geometrically Equal To">≑</span>
    <span class="char" data-decimal="8786" data-entity="&amp;#8786;" data-id="45562" title="Approximately Equal To Or The Image Of">≒</span>
    <span class="char" data-decimal="8787" data-entity="&amp;#8787;" data-id="45563" title="Image Of Or Approximately Equal To">≓</span>
    <span class="char" data-decimal="8788" data-entity="&amp;#8788;" data-id="45564" title="Colon Equals">≔</span>
    <span class="char" data-decimal="8789" data-entity="&amp;#8789;" data-id="45565" title="Equals Colon">≕</span>
    <span class="char" data-decimal="8790" data-entity="&amp;#8790;" data-id="45566" title="Ring In Equal To">≖</span>
    <span class="char" data-decimal="8791" data-entity="&amp;#8791;" data-id="45567" title="Ring Equal To">≗</span>
    <span class="char" data-decimal="8792" data-entity="&amp;#8792;" data-id="45568" title="Corresponds To">≘</span>
    <span class="char" data-decimal="8793" data-entity="&amp;#8793;" data-id="45569" title="Estimates">≙</span>
    <span class="char" data-decimal="8794" data-entity="&amp;#8794;" data-id="45570" title="Equiangular To">≚</span>
    <span class="char" data-decimal="8795" data-entity="&amp;#8795;" data-id="45571" title="Star Equals">≛</span>
    <span class="char" data-decimal="8796" data-entity="&amp;#8796;" data-id="45572" title="Delta Equal To">≜</span>
    <span class="char" data-decimal="8797" data-entity="&amp;#8797;" data-id="45573" title="Equal To By Definition">≝</span>
    <span class="char" data-decimal="8798" data-entity="&amp;#8798;" data-id="45574" title="Measured By">≞</span>
    <span class="char" data-decimal="8799" data-entity="&amp;#8799;" data-id="45575" title="Questioned Equal To">≟</span>
    <span class="char" data-decimal="8800" data-entity="&amp;ne;" data-id="45576" title="Not Equal To">≠</span>
    <span class="char" data-decimal="8801" data-entity="&amp;equiv;" data-id="45577" title="Identical To">≡</span>
    <span class="char" data-decimal="8802" data-entity="&amp;#8802;" data-id="45578" title="Not Identical To">≢</span>
    <span class="char" data-decimal="8803" data-entity="&amp;#8803;" data-id="45579" title="Strictly Equivalent To">≣</span>
    <span class="char" data-decimal="8804" data-entity="&amp;le;" data-id="45580" title="Less Than Or Equal To">≤</span>
    <span class="char" data-decimal="8805" data-entity="&amp;ge;" data-id="45581" title="Greater Than Or Equal To">≥</span>
    <span class="char" data-decimal="8806" data-entity="&amp;#8806;" data-id="45582" title="Less Than Over Equal To">≦</span>
    <span class="char" data-decimal="8807" data-entity="&amp;#8807;" data-id="45583" title="Greater Than Over Equal To">≧</span>
    <span class="char" data-decimal="8808" data-entity="&amp;#8808;" data-id="45584" title="Less Than But Not Equal To">≨</span>
    <span class="char" data-decimal="8809" data-entity="&amp;#8809;" data-id="45585" title="Greater Than But Not Equal To">≩</span>
    <span class="char" data-decimal="8810" data-entity="&amp;#8810;" data-id="45586" title="Much Less Than">≪</span>
    <span class="char" data-decimal="8811" data-entity="&amp;#8811;" data-id="45587" title="Much Greater Than">≫</span>
    <span class="char" data-decimal="8812" data-entity="&amp;#8812;" data-id="45588" title="Between">≬</span>
    <span class="char" data-decimal="8813" data-entity="&amp;#8813;" data-id="45589" title="Not Equivalent To">≭</span>
    <span class="char" data-decimal="8814" data-entity="&amp;#8814;" data-id="45590" title="Not Less Than">≮</span>
    <span class="char" data-decimal="8815" data-entity="&amp;#8815;" data-id="45591" title="Not Greater Than">≯</span>
    <span class="char" data-decimal="8816" data-entity="&amp;#8816;" data-id="45592" title="Neither Less Than Nor Equal To">≰</span>
    <span class="char" data-decimal="8817" data-entity="&amp;#8817;" data-id="45593" title="Neither Greater Than Nor Equal To">≱</span>
    <span class="char" data-decimal="8818" data-entity="&amp;#8818;" data-id="45594" title="Less Than Or Equivalent To">≲</span>
    <span class="char" data-decimal="8819" data-entity="&amp;#8819;" data-id="45595" title="Greater Than Or Equivalent To">≳</span>
    <span class="char" data-decimal="8820" data-entity="&amp;#8820;" data-id="45596" title="Neither Less Than Nor Equivalent To">≴</span>
    <span class="char" data-decimal="8821" data-entity="&amp;#8821;" data-id="45597" title="Neither Greater Than Nor Equivalent To">≵</span>
    <span class="char" data-decimal="8822" data-entity="&amp;#8822;" data-id="45598" title="Less Than Or Greater Than">≶</span>
    <span class="char" data-decimal="8823" data-entity="&amp;#8823;" data-id="45599" title="Greater Than Or Less Than">≷</span>
    <span class="char" data-decimal="8824" data-entity="&amp;#8824;" data-id="45600" title="Neither Less Than Nor Greater Than">≸</span>
    <span class="char" data-decimal="8825" data-entity="&amp;#8825;" data-id="45601" title="Neither Greater Than Nor Less Than">≹</span>
    <span class="char" data-decimal="8826" data-entity="&amp;#8826;" data-id="45602" title="Precedes">≺</span>
    <span class="char" data-decimal="8827" data-entity="&amp;#8827;" data-id="45603" title="Succeeds">≻</span>
    <span class="char" data-decimal="8828" data-entity="&amp;#8828;" data-id="45604" title="Precedes Or Equal To">≼</span>
    <span class="char" data-decimal="8829" data-entity="&amp;#8829;" data-id="45605" title="Succeeds Or Equal To">≽</span>
    <span class="char" data-decimal="8830" data-entity="&amp;#8830;" data-id="45606" title="Precedes Or Equivalent To">≾</span>
    <span class="char" data-decimal="8831" data-entity="&amp;#8831;" data-id="45607" title="Succeeds Or Equivalent To">≿</span>
    <span class="char" data-decimal="8832" data-entity="&amp;#8832;" data-id="45608" title="Does Not Precede">⊀</span>
    <span class="char" data-decimal="8833" data-entity="&amp;#8833;" data-id="45609" title="Does Not Succeed">⊁</span>
    <span class="char" data-decimal="8834" data-entity="&amp;sub;" data-id="45610" title="Subset Of">⊂</span>
    <span class="char" data-decimal="8835" data-entity="&amp;sup;" data-id="45611" title="Superset Of">⊃</span>
    <span class="char" data-decimal="8836" data-entity="&amp;nsub;" data-id="45612" title="Not A Subset Of">⊄</span>
    <span class="char" data-decimal="8837" data-entity="&amp;#8837;" data-id="45613" title="Not A Superset Of">⊅</span>
    <span class="char" data-decimal="8838" data-entity="&amp;sube;" data-id="45614" title="Subset Of Or Equal To">⊆</span>
    <span class="char" data-decimal="8839" data-entity="&amp;supe;" data-id="45615" title="Superset Of Or Equal To">⊇</span>
    <span class="char" data-decimal="8840" data-entity="&amp;#8840;" data-id="45616" title="Neither A Subset Of Nor Equal To">⊈</span>
    <span class="char" data-decimal="8841" data-entity="&amp;#8841;" data-id="45617" title="Neither A Superset Of Nor Equal To">⊉</span>
    <span class="char" data-decimal="8842" data-entity="&amp;#8842;" data-id="45618" title="Subset Of With Not Equal To">⊊</span>
    <span class="char" data-decimal="8843" data-entity="&amp;#8843;" data-id="45619" title="Superset Of With Not Equal To">⊋</span>
    <span class="char" data-decimal="8844" data-entity="&amp;#8844;" data-id="45620" title="Multiset">⊌</span>
    <span class="char" data-decimal="8845" data-entity="&amp;#8845;" data-id="45621" title="Multiset Multiplication">⊍</span>
    <span class="char" data-decimal="8846" data-entity="&amp;#8846;" data-id="45622" title="Multiset Union">⊎</span>
    <span class="char" data-decimal="8847" data-entity="&amp;#8847;" data-id="45623" title="Square Image Of">⊏</span>
    <span class="char" data-decimal="8848" data-entity="&amp;#8848;" data-id="45624" title="Square Original Of">⊐</span>
    <span class="char" data-decimal="8849" data-entity="&amp;#8849;" data-id="45625" title="Square Image Of Or Equal To">⊑</span>
    <span class="char" data-decimal="8850" data-entity="&amp;#8850;" data-id="45626" title="Square Original Of Or Equal To">⊒</span>
    <span class="char" data-decimal="8851" data-entity="&amp;#8851;" data-id="45627" title="Square Cap">⊓</span>
    <span class="char" data-decimal="8852" data-entity="&amp;#8852;" data-id="45628" title="Square Cup">⊔</span>
    <span class="char" data-decimal="8853" data-entity="&amp;oplus;" data-id="45629" title="Circled Plus">⊕</span>
    <span class="char" data-decimal="8854" data-entity="&amp;#8854;" data-id="45630" title="Circled Minus">⊖</span>
    <span class="char" data-decimal="8855" data-entity="&amp;otimes;" data-id="45631" title="Circled Times">⊗</span>
    <span class="char" data-decimal="8856" data-entity="&amp;#8856;" data-id="45632" title="Circled Division Slash">⊘</span>
    <span class="char" data-decimal="8857" data-entity="&amp;#8857;" data-id="45633" title="Circled Dot Operator">⊙</span>
    <span class="char" data-decimal="8858" data-entity="&amp;#8858;" data-id="45634" title="Circled Ring Operator">⊚</span>
    <span class="char" data-decimal="8859" data-entity="&amp;#8859;" data-id="45635" title="Circled Asterisk Operator">⊛</span>
    <span class="char" data-decimal="8860" data-entity="&amp;#8860;" data-id="45636" title="Circled Equals">⊜</span>
    <span class="char" data-decimal="8861" data-entity="&amp;#8861;" data-id="45637" title="Circled Dash">⊝</span>
    <span class="char" data-decimal="8862" data-entity="&amp;#8862;" data-id="45638" title="Squared Plus">⊞</span>
    <span class="char" data-decimal="8863" data-entity="&amp;#8863;" data-id="45639" title="Squared Minus">⊟</span>
    <span class="char" data-decimal="8864" data-entity="&amp;#8864;" data-id="45640" title="Squared Times">⊠</span>
    <span class="char" data-decimal="8865" data-entity="&amp;#8865;" data-id="45641" title="Squared Dot Operator">⊡</span>
    <span class="char" data-decimal="8866" data-entity="&amp;#8866;" data-id="45642" title="Right Tack">⊢</span>
    <span class="char" data-decimal="8867" data-entity="&amp;#8867;" data-id="45643" title="Left Tack">⊣</span>
    <span class="char" data-decimal="8868" data-entity="&amp;#8868;" data-id="45644" title="Down Tack">⊤</span>
    <span class="char" data-decimal="8869" data-entity="&amp;perp;" data-id="45645" title="Up Tack">⊥</span>
    <span class="char" data-decimal="8870" data-entity="&amp;#8870;" data-id="45646" title="Assertion">⊦</span>
    <span class="char" data-decimal="8871" data-entity="&amp;#8871;" data-id="45647" title="Models">⊧</span>
    <span class="char" data-decimal="8872" data-entity="&amp;#8872;" data-id="45648" title="True">⊨</span>
    <span class="char" data-decimal="8873" data-entity="&amp;#8873;" data-id="45649" title="Forces">⊩</span>
    <span class="char" data-decimal="8874" data-entity="&amp;#8874;" data-id="45650" title="Triple Vertical Bar Right Turnstile">⊪</span>
    <span class="char" data-decimal="8875" data-entity="&amp;#8875;" data-id="45651" title="Double Vertical Bar Double Right Turnstile">⊫</span>
    <span class="char" data-decimal="8876" data-entity="&amp;#8876;" data-id="45652" title="Does Not Prove">⊬</span>
    <span class="char" data-decimal="8877" data-entity="&amp;#8877;" data-id="45653" title="Not True">⊭</span>
    <span class="char" data-decimal="8878" data-entity="&amp;#8878;" data-id="45654" title="Does Not Force">⊮</span>
    <span class="char" data-decimal="8879" data-entity="&amp;#8879;" data-id="45655" title="Negated Double Vertical Bar Double Right Turnstile">⊯</span>
    <span class="char" data-decimal="8880" data-entity="&amp;#8880;" data-id="45656" title="Precedes Under Relation">⊰</span>
    <span class="char" data-decimal="8881" data-entity="&amp;#8881;" data-id="45657" title="Succeeds Under Relation">⊱</span>
    <span class="char" data-decimal="8882" data-entity="&amp;#8882;" data-id="45658" title="Normal Subgroup Of">⊲</span>
    <span class="char" data-decimal="8883" data-entity="&amp;#8883;" data-id="45659" title="Contains As Normal Subgroup">⊳</span>
    <span class="char" data-decimal="8884" data-entity="&amp;#8884;" data-id="45660" title="Normal Subgroup Of Or Equal To">⊴</span>
    <span class="char" data-decimal="8885" data-entity="&amp;#8885;" data-id="45661" title="Contains As Normal Subgroup Or Equal To">⊵</span>
    <span class="char" data-decimal="8886" data-entity="&amp;#8886;" data-id="45662" title="Original Of">⊶</span>
    <span class="char" data-decimal="8887" data-entity="&amp;#8887;" data-id="45663" title="Image Of">⊷</span>
    <span class="char" data-decimal="8888" data-entity="&amp;#8888;" data-id="45664" title="Multimap">⊸</span>
    <span class="char" data-decimal="8889" data-entity="&amp;#8889;" data-id="45665" title="Hermitian Conjugate Matrix">⊹</span>
    <span class="char" data-decimal="8890" data-entity="&amp;#8890;" data-id="45666" title="Intercalate">⊺</span>
    <span class="char" data-decimal="8891" data-entity="&amp;#8891;" data-id="45667" title="Xor">⊻</span>
    <span class="char" data-decimal="8892" data-entity="&amp;#8892;" data-id="45668" title="Nand">⊼</span>
    <span class="char" data-decimal="8893" data-entity="&amp;#8893;" data-id="45669" title="Nor">⊽</span>
    <span class="char" data-decimal="8894" data-entity="&amp;#8894;" data-id="45670" title="Right Angle With Arc">⊾</span>
    <span class="char" data-decimal="8895" data-entity="&amp;#8895;" data-id="45671" title="Right Triangle">⊿</span>
    <span class="char" data-decimal="8896" data-entity="&amp;#8896;" data-id="45672" title="N Ary Logical And">⋀</span>
    <span class="char" data-decimal="8897" data-entity="&amp;#8897;" data-id="45673" title="N Ary Logical Or">⋁</span>
    <span class="char" data-decimal="8898" data-entity="&amp;#8898;" data-id="45674" title="N Ary Intersection">⋂</span>
    <span class="char" data-decimal="8899" data-entity="&amp;#8899;" data-id="45675" title="N Ary Union">⋃</span>
    <span class="char" data-decimal="8900" data-entity="&amp;#8900;" data-id="45676" title="Diamond Operator">⋄</span>
    <span class="char" data-decimal="8901" data-entity="&amp;sdot;" data-id="45677" title="Dot Operator">⋅</span>
    <span class="char" data-decimal="8902" data-entity="&amp;#8902;" data-id="45678" title="Star Operator">⋆</span>
    <span class="char" data-decimal="8903" data-entity="&amp;#8903;" data-id="45679" title="Division Times">⋇</span>
    <span class="char" data-decimal="8904" data-entity="&amp;#8904;" data-id="45680" title="Bowtie">⋈</span>
    <span class="char" data-decimal="8905" data-entity="&amp;#8905;" data-id="45681" title="Left Normal Factor Semidirect Product">⋉</span>
    <span class="char" data-decimal="8906" data-entity="&amp;#8906;" data-id="45682" title="Right Normal Factor Semidirect Product">⋊</span>
    <span class="char" data-decimal="8907" data-entity="&amp;#8907;" data-id="45683" title="Left Semidirect Product">⋋</span>
    <span class="char" data-decimal="8908" data-entity="&amp;#8908;" data-id="45684" title="Right Semidirect Product">⋌</span>
    <span class="char" data-decimal="8909" data-entity="&amp;#8909;" data-id="45685" title="Reversed Tilde Equals">⋍</span>
    <span class="char" data-decimal="8910" data-entity="&amp;#8910;" data-id="45686" title="Curly Logical Or">⋎</span>
    <span class="char" data-decimal="8911" data-entity="&amp;#8911;" data-id="45687" title="Curly Logical And">⋏</span>
    <span class="char" data-decimal="8912" data-entity="&amp;#8912;" data-id="45688" title="Double Subset">⋐</span>
    <span class="char" data-decimal="8913" data-entity="&amp;#8913;" data-id="45689" title="Double Superset">⋑</span>
    <span class="char" data-decimal="8914" data-entity="&amp;#8914;" data-id="45690" title="Double Intersection">⋒</span>
    <span class="char" data-decimal="8915" data-entity="&amp;#8915;" data-id="45691" title="Double Union">⋓</span>
    <span class="char" data-decimal="8916" data-entity="&amp;#8916;" data-id="45692" title="Pitchfork">⋔</span>
    <span class="char" data-decimal="8917" data-entity="&amp;#8917;" data-id="45693" title="Equal And Parallel To">⋕</span>
    <span class="char" data-decimal="8918" data-entity="&amp;#8918;" data-id="45694" title="Less Than With Dot">⋖</span>
    <span class="char" data-decimal="8919" data-entity="&amp;#8919;" data-id="45695" title="Greater Than With Dot">⋗</span>
    <span class="char" data-decimal="8920" data-entity="&amp;#8920;" data-id="45696" title="Very Much Less Than">⋘</span>
    <span class="char" data-decimal="8921" data-entity="&amp;#8921;" data-id="45697" title="Very Much Greater Than">⋙</span>
    <span class="char" data-decimal="8922" data-entity="&amp;#8922;" data-id="45698" title="Less Than Equal To Or Greater Than">⋚</span>
    <span class="char" data-decimal="8923" data-entity="&amp;#8923;" data-id="45699" title="Greater Than Equal To Or Less Than">⋛</span>
    <span class="char" data-decimal="8924" data-entity="&amp;#8924;" data-id="45700" title="Equal To Or Less Than">⋜</span>
    <span class="char" data-decimal="8925" data-entity="&amp;#8925;" data-id="45701" title="Equal To Or Greater Than">⋝</span>
    <span class="char" data-decimal="8926" data-entity="&amp;#8926;" data-id="45702" title="Equal To Or Precedes">⋞</span>
    <span class="char" data-decimal="8927" data-entity="&amp;#8927;" data-id="45703" title="Equal To Or Succeeds">⋟</span>
    <span class="char" data-decimal="8928" data-entity="&amp;#8928;" data-id="45704" title="Does Not Precede Or Equal">⋠</span>
    <span class="char" data-decimal="8929" data-entity="&amp;#8929;" data-id="45705" title="Does Not Succeed Or Equal">⋡</span>
    <span class="char" data-decimal="8930" data-entity="&amp;#8930;" data-id="45706" title="Not Square Image Of Or Equal To">⋢</span>
    <span class="char" data-decimal="8931" data-entity="&amp;#8931;" data-id="45707" title="Not Square Original Of Or Equal To">⋣</span>
    <span class="char" data-decimal="8932" data-entity="&amp;#8932;" data-id="45708" title="Square Image Of Or Not Equal To">⋤</span>
    <span class="char" data-decimal="8933" data-entity="&amp;#8933;" data-id="45709" title="Square Original Of Or Not Equal To">⋥</span>
    <span class="char" data-decimal="8934" data-entity="&amp;#8934;" data-id="45710" title="Less Than But Not Equivalent To">⋦</span>
    <span class="char" data-decimal="8935" data-entity="&amp;#8935;" data-id="45711" title="Greater Than But Not Equivalent To">⋧</span>
    <span class="char" data-decimal="8936" data-entity="&amp;#8936;" data-id="45712" title="Precedes But Not Equivalent To">⋨</span>
    <span class="char" data-decimal="8937" data-entity="&amp;#8937;" data-id="45713" title="Succeeds But Not Equivalent To">⋩</span>
    <span class="char" data-decimal="8938" data-entity="&amp;#8938;" data-id="45714" title="Not Normal Subgroup Of">⋪</span>
    <span class="char" data-decimal="8939" data-entity="&amp;#8939;" data-id="45715" title="Does Not Contain As Normal Subgroup">⋫</span>
    <span class="char" data-decimal="8940" data-entity="&amp;#8940;" data-id="45716" title="Not Normal Subgroup Of Or Equal To">⋬</span>
    <span class="char" data-decimal="8941" data-entity="&amp;#8941;" data-id="45717" title="Does Not Contain As Normal Subgroup Or Equal">⋭</span>
    <span class="char" data-decimal="8942" data-entity="&amp;#8942;" data-id="45718" title="Vertical Ellipsis">⋮</span>
    <span class="char" data-decimal="8943" data-entity="&amp;#8943;" data-id="45719" title="Midline Horizontal Ellipsis">⋯</span>
    <span class="char" data-decimal="8944" data-entity="&amp;#8944;" data-id="45720" title="Up Right Diagonal Ellipsis">⋰</span>
    <span class="char" data-decimal="8945" data-entity="&amp;#8945;" data-id="45721" title="Down Right Diagonal Ellipsis">⋱</span>
    <span class="char" data-decimal="8946" data-entity="&amp;#8946;" data-id="45722" title="Element Of With Long Horizontal Stroke">⋲</span>
    <span class="char" data-decimal="8947" data-entity="&amp;#8947;" data-id="45723" title="Element Of With Vertical Bar At End Of Horizontal Stroke">⋳</span>
    <span class="char" data-decimal="8948" data-entity="&amp;#8948;" data-id="45724" title="Small Element Of With Vertical Bar At End Of Horizontal Stroke">⋴</span>
    <span class="char" data-decimal="8949" data-entity="&amp;#8949;" data-id="45725" title="Element Of With Dot Above">⋵</span>
    <span class="char" data-decimal="8950" data-entity="&amp;#8950;" data-id="45726" title="Element Of With Overbar">⋶</span>
    <span class="char" data-decimal="8951" data-entity="&amp;#8951;" data-id="45727" title="Small Element Of With Overbar">⋷</span>
    <span class="char" data-decimal="8952" data-entity="&amp;#8952;" data-id="45728" title="Element Of With Underbar">⋸</span>
    <span class="char" data-decimal="8953" data-entity="&amp;#8953;" data-id="45729" title="Element Of With Two Horizontal Strokes">⋹</span>
    <span class="char" data-decimal="8954" data-entity="&amp;#8954;" data-id="45730" title="Contains With Long Horizontal Stroke">⋺</span>
    <span class="char" data-decimal="8955" data-entity="&amp;#8955;" data-id="45731" title="Contains With Vertical Bar At End Of Horizontal Stroke">⋻</span>
    <span class="char" data-decimal="8956" data-entity="&amp;#8956;" data-id="45732" title="Small Contains With Vertical Bar At End Of Horizontal Stroke">⋼</span>
    <span class="char" data-decimal="8957" data-entity="&amp;#8957;" data-id="45733" title="Contains With Overbar">⋽</span>
    <span class="char" data-decimal="8958" data-entity="&amp;#8958;" data-id="45734" title="Small Contains With Overbar">⋾</span>
    <span class="char" data-decimal="8959" data-entity="&amp;#8959;" data-id="45735" title="Z Notation Bag Membership">⋿</span>
    <span class="char" data-decimal="10005" data-entity="&amp;#10005;" data-id="43605" title="Multiplication X">✕</span>
    <span class="char" data-decimal="10006" data-entity="&amp;#10006;" data-id="43606" title="Heavy Multiplication X">✖</span>
    <span class="char" data-decimal="10010" data-entity="&amp;#10010;" data-id="43610" title="Heavy Greek Cross">✚</span>
    <span class="char" data-decimal="9664" data-entity="&amp;#9664;" data-id="43955" title="Black Left Pointing Triangle">◀</span>
    <span class="char" data-decimal="9654" data-entity="&amp;#9654;" data-id="43945" title="Black Right Pointing Triangle">▶</span>
    <span class="char" data-decimal="10077" data-entity="&amp;#10077;" data-id="43671" title="Heavy Double Turned Comma Quotation Mark Ornament">❝</span>
    <span class="char" data-decimal="10078" data-entity="&amp;#10078;" data-id="43672" title="Heavy Double Comma Quotation Mark Ornament">❞</span>
    <span class="char" data-decimal="63743" data-entity="&amp;#63743;" data-id="46533" title=""></span>
    <span class="char" data-decimal="9733" data-entity="&amp;#9733;" data-id="45741" title="Black Star">★</span>
    <span class="char" data-decimal="9734" data-entity="&amp;#9734;" data-id="45742" title="White Star">☆</span>
    <span class="char" data-decimal="9788" data-entity="&amp;#9788;" data-id="45795" title="White Sun With Rays">☼</span>
    <span class="char" data-decimal="9730" data-entity="&amp;#9730;" data-id="45738" title="Umbrella">☂</span>
    <span class="char" data-decimal="9786" data-entity="&amp;#9786;" data-id="45793" title="White Smiling Face">☺</span>
    <span class="char" data-decimal="9785" data-entity="&amp;#9785;" data-id="45792" title="White Frowning Face">☹</span>
    <span class="char" data-decimal="9988" data-entity="&amp;#9988;" data-id="43591" title="White Scissors">✄</span>
    <span class="char" data-decimal="9992" data-entity="&amp;#9992;" data-id="43594" title="Airplane">✈</span>
    <span class="char" data-decimal="9996" data-entity="&amp;#9996;" data-id="43596" title="Victory Hand">✌</span>
    <span class="char" data-decimal="9998" data-entity="&amp;#9998;" data-id="43598" title="Lower Right Pencil">✎</span>
    <span class="char" data-decimal="9834" data-entity="&amp;#9834;" data-id="45841" title="Eighth Note">♪</span>
    <span class="char" data-decimal="9835" data-entity="&amp;#9835;" data-id="45842" title="Beamed Eighth Notes">♫</span>
    <span class="char" data-decimal="9728" data-entity="&amp;#9728;" data-id="45736" title="Black Sun With Rays">☀</span>
    <span class="char" data-decimal="9729" data-entity="&amp;#9729;" data-id="45737" title="Cloud">☁</span>
    <span class="char" data-decimal="9748" data-entity="&amp;#9748;" data-id="45755" title="Umbrella With Rain Drops">☔</span>
    <span class="char" data-decimal="9889" data-entity="&amp;#9889;" data-id="45893" title="High Voltage Sign">⚡</span>
    <span class="char" data-decimal="10054" data-entity="&amp;#10054;" data-id="43653" title="Heavy Chevron Snowflake">❆</span>
    <span class="char" data-decimal="9789" data-entity="&amp;#9789;" data-id="45796" title="First Quarter Moon">☽</span>
    <span class="char" data-decimal="9790" data-entity="&amp;#9790;" data-id="45797" title="Last Quarter Moon">☾</span>
    <span class="char" data-decimal="9990" data-entity="&amp;#9990;" data-id="43592" title="Telephone Location Sign">✆</span>
    <span class="char" data-decimal="10004" data-entity="&amp;#10004;" data-id="43604" title="Heavy Check Mark">✔</span>
    <span class="char" data-decimal="9775" data-entity="&amp;#9775;" data-id="45782" title="Yin Yang">☯</span>
    <span class="char" data-decimal="9774" data-entity="&amp;#9774;" data-id="45781" title="Peace Symbol">☮</span>
    <span class="char" data-decimal="9760" data-entity="&amp;#9760;" data-id="45767" title="Skull And Crossbones">☠</span>
    <span class="char" data-decimal="9873" data-entity="&amp;#9873;" data-id="45880" title="Black Flag">⚑</span>
    <span class="char" data-decimal="9772" data-entity="&amp;#9772;" data-id="45779" title="Adi Shakti">☬</span>
    <span class="char" data-decimal="9988" data-entity="&amp;#9988;" data-id="43591" title="White Scissors">✄</span>
    <span class="char" data-decimal="9999" data-entity="&amp;#9999;" data-id="43599" title="Pencil">✏</span>
    <span class="char" data-decimal="9840" data-entity="&amp;#9840;" data-id="45847" title="West Syriac Cross">♰</span>
    <span class="char" data-decimal="10017" data-entity="&amp;#10017;" data-id="43617" title="Star Of David">✡</span>
    <span class="char" data-decimal="10032" data-entity="&amp;#10032;" data-id="43631" title="Shadowed White Star">✰</span>
    <span class="char" data-decimal="10042" data-entity="&amp;#10042;" data-id="43641" title="Sixteen Pointed Asterisk">✺</span>
    <span class="char" data-decimal="9890" data-entity="&amp;#9890;" data-id="45894" title="Doubled Female Sign">⚢</span>
    <span class="char" data-decimal="9891" data-entity="&amp;#9891;" data-id="45895" title="Doubled Male Sign">⚣</span>
    <span class="char" data-decimal="9813" data-entity="&amp;#9813;" data-id="45820" title="White Chess Queen">♕</span>
    <span class="char" data-decimal="9819" data-entity="&amp;#9819;" data-id="45826" title="Black Chess Queen">♛</span>
    <span class="char" data-decimal="9818" data-entity="&amp;#9818;" data-id="45825" title="Black Chess King">♚</span>
    <span class="char" data-decimal="9836" data-entity="&amp;#9836;" data-id="45843" title="Beamed Sixteenth Notes">♬</span>
    <span class="char" data-decimal="9424" data-entity="&amp;#9424;" data-id="43875" title="Circled Latin Small Letter A">ⓐ</span>
    <span class="char" data-decimal="9425" data-entity="&amp;#9425;" data-id="43876" title="Circled Latin Small Letter B">ⓑ</span>
    <span class="char" data-decimal="9426" data-entity="&amp;#9426;" data-id="43877" title="Circled Latin Small Letter C">ⓒ</span>
    <span class="char" data-decimal="9427" data-entity="&amp;#9427;" data-id="43878" title="Circled Latin Small Letter D">ⓓ</span>
    <span class="char" data-decimal="8634" data-entity="&amp;#8634;" data-id="43192" title="Anticlockwise Open Circle Arrow">↺</span>
    <span class="char" data-decimal="8635" data-entity="&amp;#8635;" data-id="43193" title="Clockwise Open Circle Arrow">↻</span>
    <span class="char" data-decimal="8662" data-entity="&amp;#8662;" data-id="43220" title="North West Double Arrow">⇖</span>
    <span class="char" data-decimal="8663" data-entity="&amp;#8663;" data-id="43221" title="North East Double Arrow">⇗</span>
    <span class="char" data-decimal="8664" data-entity="&amp;#8664;" data-id="43222" title="South East Double Arrow">⇘</span>
    <span class="char" data-decimal="8665" data-entity="&amp;#8665;" data-id="43223" title="South West Double Arrow">⇙</span>
    <span class="char" data-decimal="10229" data-entity="&amp;#10229;" data-id="43267" title="Long Leftwards Arrow">⟵</span>
    <span class="char" data-decimal="10231" data-entity="&amp;#10231;" data-id="43269" title="Long Left Right Arrow">⟷</span>
    <span class="char" data-decimal="10230" data-entity="&amp;#10230;" data-id="43268" title="Long Rightwards Arrow">⟶</span>
    <span class="char" data-decimal="10548" data-entity="&amp;#10548;" data-id="43330" title="Arrow Pointing Rightwards Then Curving Upwards">⤴</span>
    <span class="char" data-decimal="10549" data-entity="&amp;#10549;" data-id="43331" title="Arrow Pointing Rightwards Then Curving Downwards">⤵</span>
    <span class="char" data-decimal="10550" data-entity="&amp;#10550;" data-id="43332" title="Arrow Pointing Downwards Then Curving Leftwards">⤶</span>
    <span class="char" data-decimal="10551" data-entity="&amp;#10551;" data-id="43333" title="Arrow Pointing Downwards Then Curving Rightwards">⤷</span>
    <span class="char" data-decimal="10155" data-entity="&amp;#10155;" data-id="43744" title="Back Tilted Shadowed White Rightwards Arrow">➫</span>
    <span class="char" data-decimal="10156" data-entity="&amp;#10156;" data-id="43745" title="Front Tilted Shadowed White Rightwards Arrow">➬</span>
    <span class="char" data-decimal="8364" data-entity="&amp;euro;" data-id="43578" title="Euro Sign">€</span>
    <span class="char" data-decimal="8356" data-entity="&amp;#8356;" data-id="43570" title="Lira Sign">₤</span>
    <span class="char" data-decimal="65284" data-entity="&amp;#65284;" data-id="43144" title="Fullwidth Dollar Sign">＄</span>
    <span class="char" data-decimal="8361" data-entity="&amp;#8361;" data-id="43575" title="Won Sign">₩</span>
    <span class="char" data-decimal="8362" data-entity="&amp;#8362;" data-id="43576" title="New Sheqel Sign">₪</span>
    <span class="char" data-decimal="10177" data-entity="&amp;#10177;" data-id="45317" title="White Triangle Containing Small White Triangle">⟁</span>
    <span class="char" data-decimal="10192" data-entity="&amp;#10192;" data-id="45325" title="White Diamond With Centred Dot">⟐</span>
    <span class="char" data-decimal="9670" data-entity="&amp;#9670;" data-id="43961" title="Black Diamond">◆</span>
    <span class="char" data-decimal="9108" data-entity="&amp;#9108;" data-id="46090" title="Software Function Symbol">⎔</span>
    <span class="char" data-decimal="9617" data-entity="&amp;#9617;" data-id="43551" title="Light Shade">░</span>
    <span class="char" data-decimal="9634" data-entity="&amp;#9634;" data-id="43925" title="White Square With Rounded Corners">▢</span>
    <span class="char" data-decimal="8865" data-entity="&amp;#8865;" data-id="45641" title="Squared Dot Operator">⊡</span>
    <span class="char" data-decimal="9641" data-entity="&amp;#9641;" data-id="43932" title="Square With Diagonal Crosshatch Fill">▩</span>
    <span class="char" data-decimal="10209" data-entity="&amp;#10209;" data-id="45342" title="White Concave Sided Diamond">⟡</span>
    <span class="char" data-decimal="9678" data-entity="&amp;#9678;" data-id="43969" title="Bullseye">◎</span>
    <span class="char" data-decimal="9717" data-entity="&amp;#9717;" data-id="44008" title="White Circle With Lower Left Quadrant">◵</span>
    <span class="char" data-decimal="8855" data-entity="&amp;otimes;" data-id="45631" title="Circled Times">⊗</span>
    <span class="char" data-decimal="10070" data-entity="&amp;#10070;" data-id="43664" title="Black Diamond Minus White X">❖</span>
    <span class="char" data-decimal="937" data-entity="&amp;Omega;" data-id="44299" title="Greek Capital Letter Omega">Ω</span>
    <span class="char" data-decimal="946" data-entity="&amp;beta;" data-id="44308" title="Greek Small Letter Beta">β</span>
    <span class="char" data-decimal="934" data-entity="&amp;Phi;" data-id="44296" title="Greek Capital Letter Phi">Φ</span>
    <span class="char" data-decimal="931" data-entity="&amp;Sigma;" data-id="44293" title="Greek Capital Letter Sigma">Σ</span>
    <span class="char" data-decimal="926" data-entity="&amp;Xi;" data-id="44289" title="Greek Capital Letter Xi">Ξ</span>
    <span class="char" data-decimal="10177" data-entity="&amp;#10177;" data-id="45317" title="White Triangle Containing Small White Triangle">⟁</span>
    <span class="char" data-decimal="10683" data-entity="&amp;#10683;" data-id="45412" title="Circle With Superimposed X">⦻</span>
    <span class="char" data-decimal="10697" data-entity="&amp;#10697;" data-id="45426" title="Two Joined Squares">⧉</span>
    <span class="char" data-decimal="10733" data-entity="&amp;#10733;" data-id="45461" title="Black Circle With Down Arrow">⧭</span>
    <span class="char" data-decimal="10740" data-entity="&amp;#10740;" data-id="45468" title="Rule Delayed">⧴</span>
    <span class="char" data-decimal="8734" data-entity="&amp;infin;" data-id="45510" title="Infinity">∞</span>
    <span class="char" data-decimal="8780" data-entity="&amp;#8780;" data-id="45556" title="All Equal To">≌</span>
    <span class="char" data-decimal="8853" data-entity="&amp;oplus;" data-id="45629" title="Circled Plus">⊕</span>
    <span class="char" data-decimal="8909" data-entity="&amp;#8909;" data-id="45685" title="Reversed Tilde Equals">⋍</span>
    <span class="char" data-decimal="8944" data-entity="&amp;#8944;" data-id="45720" title="Up Right Diagonal Ellipsis">⋰</span>
    <span class="char" data-decimal="8945" data-entity="&amp;#8945;" data-id="45721" title="Down Right Diagonal Ellipsis">⋱</span>
    <span class="char" data-decimal="10006" data-entity="&amp;#10006;" data-id="43606" title="Heavy Multiplication X">✖</span>
    <span class="char" data-decimal="9461" data-entity="&amp;#9461;" data-id="43912" title="Double Circled Digit One">⓵</span>
    <span class="char" data-decimal="9462" data-entity="&amp;#9462;" data-id="43913" title="Double Circled Digit Two">⓶</span>
    <span class="char" data-decimal="9463" data-entity="&amp;#9463;" data-id="43914" title="Double Circled Digit Three">⓷</span>
    <span class="char" data-decimal="9464" data-entity="&amp;#9464;" data-id="43915" title="Double Circled Digit Four">⓸</span>
    <span class="char" data-decimal="9465" data-entity="&amp;#9465;" data-id="43916" title="Double Circled Digit Five">⓹</span>
    <span class="char" data-decimal="9466" data-entity="&amp;#9466;" data-id="43917" title="Double Circled Digit Six">⓺</span>
    <span class="char" data-decimal="9467" data-entity="&amp;#9467;" data-id="43918" title="Double Circled Digit Seven">⓻</span>
    <span class="char" data-decimal="9468" data-entity="&amp;#9468;" data-id="43919" title="Double Circled Digit Eight">⓼</span>
    <span class="char" data-decimal="9469" data-entity="&amp;#9469;" data-id="43920" title="Double Circled Digit Nine">⓽</span>
    <span class="char" data-decimal="9470" data-entity="&amp;#9470;" data-id="43921" title="Double Circled Number Ten">⓾</span>
    <span class="char" data-decimal="7445" data-entity="&amp;#7445;" data-id="46259" title="Latin Letter Small Capital Ou">ᴕ</span>
    <span class="char" data-decimal="11816" data-entity="&amp;#11816;" data-id="46524" title="Left Double Parenthesis">⸨</span>
    <span class="char" data-decimal="11817" data-entity="&amp;#11817;" data-id="46525" title="Right Double Parenthesis">⸩</span>
    <span class="char" data-decimal="10090" data-entity="&amp;#10090;" data-id="43682" title="Medium Flattened Left Parenthesis Ornament">❪</span>
    <span class="char" data-decimal="10091" data-entity="&amp;#10091;" data-id="43683" title="Medium Flattened Right Parenthesis Ornament">❫</span>
    <span class="char" data-decimal="9461" data-entity="&amp;#9461;" data-id="43912" title="Double Circled Digit One">⓵</span>
    <span class="char" data-decimal="9462" data-entity="&amp;#9462;" data-id="43913" title="Double Circled Digit Two">⓶</span>
    <span class="char" data-decimal="9463" data-entity="&amp;#9463;" data-id="43914" title="Double Circled Digit Three">⓷</span>
    <span class="char" data-decimal="9464" data-entity="&amp;#9464;" data-id="43915" title="Double Circled Digit Four">⓸</span>
    <span class="char" data-decimal="9465" data-entity="&amp;#9465;" data-id="43916" title="Double Circled Digit Five">⓹</span>
    <span class="char" data-decimal="9466" data-entity="&amp;#9466;" data-id="43917" title="Double Circled Digit Six">⓺</span>
    <span class="char" data-decimal="9467" data-entity="&amp;#9467;" data-id="43918" title="Double Circled Digit Seven">⓻</span>
    <span class="char" data-decimal="9468" data-entity="&amp;#9468;" data-id="43919" title="Double Circled Digit Eight">⓼</span>
    <span class="char" data-decimal="9469" data-entity="&amp;#9469;" data-id="43920" title="Double Circled Digit Nine">⓽</span>
    <span class="char" data-decimal="9470" data-entity="&amp;#9470;" data-id="43921" title="Double Circled Number Ten">⓾</span>
    <span class="char" data-decimal="9352" data-entity="&amp;#9352;" data-id="43803" title="Digit One Full Stop">⒈</span>
    <span class="char" data-decimal="9353" data-entity="&amp;#9353;" data-id="43804" title="Digit Two Full Stop">⒉</span>
    <span class="char" data-decimal="9354" data-entity="&amp;#9354;" data-id="43805" title="Digit Three Full Stop">⒊</span>
    <span class="char" data-decimal="9355" data-entity="&amp;#9355;" data-id="43806" title="Digit Four Full Stop">⒋</span>
    <span class="char" data-decimal="9356" data-entity="&amp;#9356;" data-id="43807" title="Digit Five Full Stop">⒌</span>
    <span class="char" data-decimal="9357" data-entity="&amp;#9357;" data-id="43808" title="Digit Six Full Stop">⒍</span>
    <span class="char" data-decimal="9358" data-entity="&amp;#9358;" data-id="43809" title="Digit Seven Full Stop">⒎</span>
    <span class="char" data-decimal="9359" data-entity="&amp;#9359;" data-id="43810" title="Digit Eight Full Stop">⒏</span>
    <span class="char" data-decimal="9360" data-entity="&amp;#9360;" data-id="43811" title="Digit Nine Full Stop">⒐</span>
    <span class="char" data-decimal="9361" data-entity="&amp;#9361;" data-id="43812" title="Number Ten Full Stop">⒑</span>
    <span class="char" data-decimal="9362" data-entity="&amp;#9362;" data-id="43813" title="Number Eleven Full Stop">⒒</span>
    <span class="char" data-decimal="9363" data-entity="&amp;#9363;" data-id="43814" title="Number Twelve Full Stop">⒓</span>
    <span class="char" data-decimal="9364" data-entity="&amp;#9364;" data-id="43815" title="Number Thirteen Full Stop">⒔</span>
    <span class="char" data-decimal="9365" data-entity="&amp;#9365;" data-id="43816" title="Number Fourteen Full Stop">⒕</span>
    <span class="char" data-decimal="9366" data-entity="&amp;#9366;" data-id="43817" title="Number Fifteen Full Stop">⒖</span>
    <span class="char" data-decimal="9367" data-entity="&amp;#9367;" data-id="43818" title="Number Sixteen Full Stop">⒗</span>
    <span class="char" data-decimal="9368" data-entity="&amp;#9368;" data-id="43819" title="Number Seventeen Full Stop">⒘</span>
    <span class="char" data-decimal="9369" data-entity="&amp;#9369;" data-id="43820" title="Number Eighteen Full Stop">⒙</span>
    <span class="char" data-decimal="9370" data-entity="&amp;#9370;" data-id="43821" title="Number Nineteen Full Stop">⒚</span>
    <span class="char" data-decimal="9371" data-entity="&amp;#9371;" data-id="43822" title="Number Twenty Full Stop">⒛</span>
    <span class="char" data-decimal="9450" data-entity="&amp;#9450;" data-id="43901" title="Circled Digit Zero">⓪</span>
    <span class="char" data-decimal="9312" data-entity="&amp;#9312;" data-id="43763" title="Circled Digit One">①</span>
    <span class="char" data-decimal="9313" data-entity="&amp;#9313;" data-id="43764" title="Circled Digit Two">②</span>
    <span class="char" data-decimal="9314" data-entity="&amp;#9314;" data-id="43765" title="Circled Digit Three">③</span>
    <span class="char" data-decimal="9315" data-entity="&amp;#9315;" data-id="43766" title="Circled Digit Four">④</span>
    <span class="char" data-decimal="9316" data-entity="&amp;#9316;" data-id="43767" title="Circled Digit Five">⑤</span>
    <span class="char" data-decimal="9317" data-entity="&amp;#9317;" data-id="43768" title="Circled Digit Six">⑥</span>
    <span class="char" data-decimal="9318" data-entity="&amp;#9318;" data-id="43769" title="Circled Digit Seven">⑦</span>
    <span class="char" data-decimal="9319" data-entity="&amp;#9319;" data-id="43770" title="Circled Digit Eight">⑧</span>
    <span class="char" data-decimal="9320" data-entity="&amp;#9320;" data-id="43771" title="Circled Digit Nine">⑨</span>
    <span class="char" data-decimal="9321" data-entity="&amp;#9321;" data-id="43772" title="Circled Number Ten">⑩</span>
    <span class="char" data-decimal="10112" data-entity="&amp;#10112;" data-id="43704" title="Dingbat Circled Sans Serif Digit One">➀</span>
    <span class="char" data-decimal="10113" data-entity="&amp;#10113;" data-id="43705" title="Dingbat Circled Sans Serif Digit Two">➁</span>
    <span class="char" data-decimal="10114" data-entity="&amp;#10114;" data-id="43706" title="Dingbat Circled Sans Serif Digit Three">➂</span>
    <span class="char" data-decimal="10115" data-entity="&amp;#10115;" data-id="43707" title="Dingbat Circled Sans Serif Digit Four">➃</span>
    <span class="char" data-decimal="10116" data-entity="&amp;#10116;" data-id="43708" title="Dingbat Circled Sans Serif Digit Five">➄</span>
    <span class="char" data-decimal="10117" data-entity="&amp;#10117;" data-id="43709" title="Dingbat Circled Sans Serif Digit Six">➅</span>
    <span class="char" data-decimal="10118" data-entity="&amp;#10118;" data-id="43710" title="Dingbat Circled Sans Serif Digit Seven">➆</span>
    <span class="char" data-decimal="10119" data-entity="&amp;#10119;" data-id="43711" title="Dingbat Circled Sans Serif Digit Eight">➇</span>
    <span class="char" data-decimal="10120" data-entity="&amp;#10120;" data-id="43712" title="Dingbat Circled Sans Serif Digit Nine">➈</span>
    <span class="char" data-decimal="10121" data-entity="&amp;#10121;" data-id="43713" title="Dingbat Circled Sans Serif Number Ten">➉</span>
    <span class="char" data-decimal="9322" data-entity="&amp;#9322;" data-id="43773" title="Circled Number Eleven">⑪</span>
    <span class="char" data-decimal="9323" data-entity="&amp;#9323;" data-id="43774" title="Circled Number Twelve">⑫</span>
    <span class="char" data-decimal="9324" data-entity="&amp;#9324;" data-id="43775" title="Circled Number Thirteen">⑬</span>
    <span class="char" data-decimal="9325" data-entity="&amp;#9325;" data-id="43776" title="Circled Number Fourteen">⑭</span>
    <span class="char" data-decimal="9326" data-entity="&amp;#9326;" data-id="43777" title="Circled Number Fifteen">⑮</span>
    <span class="char" data-decimal="9327" data-entity="&amp;#9327;" data-id="43778" title="Circled Number Sixteen">⑯</span>
    <span class="char" data-decimal="9328" data-entity="&amp;#9328;" data-id="43779" title="Circled Number Seventeen">⑰</span>
    <span class="char" data-decimal="9329" data-entity="&amp;#9329;" data-id="43780" title="Circled Number Eighteen">⑱</span>
    <span class="char" data-decimal="9330" data-entity="&amp;#9330;" data-id="43781" title="Circled Number Nineteen">⑲</span>
    <span class="char" data-decimal="9331" data-entity="&amp;#9331;" data-id="43782" title="Circled Number Twenty">⑳</span>
    <span class="char" data-decimal="9471" data-entity="&amp;#9471;" data-id="43922" title="Negative Circled Digit Zero">⓿</span>
    <span class="char" data-decimal="10102" data-entity="&amp;#10102;" data-id="43694" title="Dingbat Negative Circled Digit One">❶</span>
    <span class="char" data-decimal="10103" data-entity="&amp;#10103;" data-id="43695" title="Dingbat Negative Circled Digit Two">❷</span>
    <span class="char" data-decimal="10104" data-entity="&amp;#10104;" data-id="43696" title="Dingbat Negative Circled Digit Three">❸</span>
    <span class="char" data-decimal="10105" data-entity="&amp;#10105;" data-id="43697" title="Dingbat Negative Circled Digit Four">❹</span>
    <span class="char" data-decimal="10106" data-entity="&amp;#10106;" data-id="43698" title="Dingbat Negative Circled Digit Five">❺</span>
    <span class="char" data-decimal="10107" data-entity="&amp;#10107;" data-id="43699" title="Dingbat Negative Circled Digit Six">❻</span>
    <span class="char" data-decimal="10108" data-entity="&amp;#10108;" data-id="43700" title="Dingbat Negative Circled Digit Seven">❼</span>
    <span class="char" data-decimal="10109" data-entity="&amp;#10109;" data-id="43701" title="Dingbat Negative Circled Digit Eight">❽</span>
    <span class="char" data-decimal="10110" data-entity="&amp;#10110;" data-id="43702" title="Dingbat Negative Circled Digit Nine">❾</span>
    <span class="char" data-decimal="10111" data-entity="&amp;#10111;" data-id="43703" title="Dingbat Negative Circled Number Ten">❿</span>
    <span class="char" data-decimal="10122" data-entity="&amp;#10122;" data-id="43714" title="Dingbat Negative Circled Sans Serif Digit One">➊</span>
    <span class="char" data-decimal="10123" data-entity="&amp;#10123;" data-id="43715" title="Dingbat Negative Circled Sans Serif Digit Two">➋</span>
    <span class="char" data-decimal="10124" data-entity="&amp;#10124;" data-id="43716" title="Dingbat Negative Circled Sans Serif Digit Three">➌</span>
    <span class="char" data-decimal="10125" data-entity="&amp;#10125;" data-id="43717" title="Dingbat Negative Circled Sans Serif Digit Four">➍</span>
    <span class="char" data-decimal="10126" data-entity="&amp;#10126;" data-id="43718" title="Dingbat Negative Circled Sans Serif Digit Five">➎</span>
    <span class="char" data-decimal="10127" data-entity="&amp;#10127;" data-id="43719" title="Dingbat Negative Circled Sans Serif Digit Six">➏</span>
    <span class="char" data-decimal="10128" data-entity="&amp;#10128;" data-id="43720" title="Dingbat Negative Circled Sans Serif Digit Seven">➐</span>
    <span class="char" data-decimal="10129" data-entity="&amp;#10129;" data-id="43721" title="Dingbat Negative Circled Sans Serif Digit Eight">➑</span>
    <span class="char" data-decimal="10130" data-entity="&amp;#10130;" data-id="43722" title="Dingbat Negative Circled Sans Serif Digit Nine">➒</span>
    <span class="char" data-decimal="10131" data-entity="&amp;#10131;" data-id="43723" title="Dingbat Negative Circled Sans Serif Number Ten">➓</span>
    <span class="char" data-decimal="9451" data-entity="&amp;#9451;" data-id="43902" title="Negative Circled Number Eleven">⓫</span>
    <span class="char" data-decimal="9452" data-entity="&amp;#9452;" data-id="43903" title="Negative Circled Number Twelve">⓬</span>
    <span class="char" data-decimal="9453" data-entity="&amp;#9453;" data-id="43904" title="Negative Circled Number Thirteen">⓭</span>
    <span class="char" data-decimal="9454" data-entity="&amp;#9454;" data-id="43905" title="Negative Circled Number Fourteen">⓮</span>
    <span class="char" data-decimal="9455" data-entity="&amp;#9455;" data-id="43906" title="Negative Circled Number Fifteen">⓯</span>
    <span class="char" data-decimal="9456" data-entity="&amp;#9456;" data-id="43907" title="Negative Circled Number Sixteen">⓰</span>
    <span class="char" data-decimal="9457" data-entity="&amp;#9457;" data-id="43908" title="Negative Circled Number Seventeen">⓱</span>
    <span class="char" data-decimal="9458" data-entity="&amp;#9458;" data-id="43909" title="Negative Circled Number Eighteen">⓲</span>
    <span class="char" data-decimal="9459" data-entity="&amp;#9459;" data-id="43910" title="Negative Circled Number Nineteen">⓳</span>
    <span class="char" data-decimal="9460" data-entity="&amp;#9460;" data-id="43911" title="Negative Circled Number Twenty">⓴</span>
    <span class="char" data-decimal="9332" data-entity="&amp;#9332;" data-id="43783" title="Parenthesized Digit One">⑴</span>
    <span class="char" data-decimal="9333" data-entity="&amp;#9333;" data-id="43784" title="Parenthesized Digit Two">⑵</span>
    <span class="char" data-decimal="9334" data-entity="&amp;#9334;" data-id="43785" title="Parenthesized Digit Three">⑶</span>
    <span class="char" data-decimal="9335" data-entity="&amp;#9335;" data-id="43786" title="Parenthesized Digit Four">⑷</span>
    <span class="char" data-decimal="9336" data-entity="&amp;#9336;" data-id="43787" title="Parenthesized Digit Five">⑸</span>
    <span class="char" data-decimal="9337" data-entity="&amp;#9337;" data-id="43788" title="Parenthesized Digit Six">⑹</span>
    <span class="char" data-decimal="9338" data-entity="&amp;#9338;" data-id="43789" title="Parenthesized Digit Seven">⑺</span>
    <span class="char" data-decimal="9339" data-entity="&amp;#9339;" data-id="43790" title="Parenthesized Digit Eight">⑻</span>
    <span class="char" data-decimal="9340" data-entity="&amp;#9340;" data-id="43791" title="Parenthesized Digit Nine">⑼</span>
    <span class="char" data-decimal="9341" data-entity="&amp;#9341;" data-id="43792" title="Parenthesized Number Ten">⑽</span>
    <span class="char" data-decimal="9342" data-entity="&amp;#9342;" data-id="43793" title="Parenthesized Number Eleven">⑾</span>
    <span class="char" data-decimal="9343" data-entity="&amp;#9343;" data-id="43794" title="Parenthesized Number Twelve">⑿</span>
    <span class="char" data-decimal="9344" data-entity="&amp;#9344;" data-id="43795" title="Parenthesized Number Thirteen">⒀</span>
    <span class="char" data-decimal="9345" data-entity="&amp;#9345;" data-id="43796" title="Parenthesized Number Fourteen">⒁</span>
    <span class="char" data-decimal="9346" data-entity="&amp;#9346;" data-id="43797" title="Parenthesized Number Fifteen">⒂</span>
    <span class="char" data-decimal="9347" data-entity="&amp;#9347;" data-id="43798" title="Parenthesized Number Sixteen">⒃</span>
    <span class="char" data-decimal="9348" data-entity="&amp;#9348;" data-id="43799" title="Parenthesized Number Seventeen">⒄</span>
    <span class="char" data-decimal="9349" data-entity="&amp;#9349;" data-id="43800" title="Parenthesized Number Eighteen">⒅</span>
    <span class="char" data-decimal="9350" data-entity="&amp;#9350;" data-id="43801" title="Parenthesized Number Nineteen">⒆</span>
    <span class="char" data-decimal="9351" data-entity="&amp;#9351;" data-id="43802" title="Parenthesized Number Twenty">⒇</span>
    <span class="char" data-decimal="7557" data-entity="&amp;#7557;" data-id="46179" title="Latin Small Letter L With Palatal Hook">ᶅ</span>
    <span class="char" data-decimal="7579" data-entity="&amp;#7579;" data-id="46201" title="Modifier Letter Small Turned Alpha">ᶛ</span>
    <span class="char" data-decimal="7580" data-entity="&amp;#7580;" data-id="46202" title="Modifier Letter Small C">ᶜ</span>
    <span class="char" data-decimal="7581" data-entity="&amp;#7581;" data-id="46203" title="Modifier Letter Small C With Curl">ᶝ</span>
    <span class="char" data-decimal="7582" data-entity="&amp;#7582;" data-id="46204" title="Modifier Letter Small Eth">ᶞ</span>
    <span class="char" data-decimal="7583" data-entity="&amp;#7583;" data-id="46205" title="Modifier Letter Small Reversed Open E">ᶟ</span>
    <span class="char" data-decimal="7584" data-entity="&amp;#7584;" data-id="46206" title="Modifier Letter Small F">ᶠ</span>
    <span class="char" data-decimal="7585" data-entity="&amp;#7585;" data-id="46207" title="Modifier Letter Small Dotless J With Stroke">ᶡ</span>
    <span class="char" data-decimal="7586" data-entity="&amp;#7586;" data-id="46208" title="Modifier Letter Small Script G">ᶢ</span>
    <span class="char" data-decimal="7587" data-entity="&amp;#7587;" data-id="46209" title="Modifier Letter Small Turned H">ᶣ</span>
    <span class="char" data-decimal="7588" data-entity="&amp;#7588;" data-id="46210" title="Modifier Letter Small I With Stroke">ᶤ</span>
    <span class="char" data-decimal="7589" data-entity="&amp;#7589;" data-id="46211" title="Modifier Letter Small Iota">ᶥ</span>
    <span class="char" data-decimal="7590" data-entity="&amp;#7590;" data-id="46212" title="Modifier Letter Small Capital I">ᶦ</span>
    <span class="char" data-decimal="7591" data-entity="&amp;#7591;" data-id="46213" title="Modifier Letter Small Capital I With Stroke">ᶧ</span>
    <span class="char" data-decimal="7592" data-entity="&amp;#7592;" data-id="46214" title="Modifier Letter Small J With Crossed Tail">ᶨ</span>
    <span class="char" data-decimal="7593" data-entity="&amp;#7593;" data-id="46215" title="Modifier Letter Small L With Retroflex Hook">ᶩ</span>
    <span class="char" data-decimal="7594" data-entity="&amp;#7594;" data-id="46216" title="Modifier Letter Small L With Palatal Hook">ᶪ</span>
    <span class="char" data-decimal="7595" data-entity="&amp;#7595;" data-id="46217" title="Modifier Letter Small Capital L">ᶫ</span>
    <span class="char" data-decimal="7596" data-entity="&amp;#7596;" data-id="46218" title="Modifier Letter Small M With Hook">ᶬ</span>
    <span class="char" data-decimal="7597" data-entity="&amp;#7597;" data-id="46219" title="Modifier Letter Small Turned M With Long Leg">ᶭ</span>
    <span class="char" data-decimal="7598" data-entity="&amp;#7598;" data-id="46220" title="Modifier Letter Small N With Left Hook">ᶮ</span>
    <span class="char" data-decimal="7599" data-entity="&amp;#7599;" data-id="46221" title="Modifier Letter Small N With Retroflex Hook">ᶯ</span>
    <span class="char" data-decimal="7600" data-entity="&amp;#7600;" data-id="46222" title="Modifier Letter Small Capital N">ᶰ</span>
    <span class="char" data-decimal="7601" data-entity="&amp;#7601;" data-id="46223" title="Modifier Letter Small Barred O">ᶱ</span>
    <span class="char" data-decimal="7602" data-entity="&amp;#7602;" data-id="46224" title="Modifier Letter Small Phi">ᶲ</span>
    <span class="char" data-decimal="7603" data-entity="&amp;#7603;" data-id="46225" title="Modifier Letter Small S With Hook">ᶳ</span>
    <span class="char" data-decimal="7604" data-entity="&amp;#7604;" data-id="46226" title="Modifier Letter Small Esh">ᶴ</span>
    <span class="char" data-decimal="7605" data-entity="&amp;#7605;" data-id="46227" title="Modifier Letter Small T With Palatal Hook">ᶵ</span>
    <span class="char" data-decimal="7606" data-entity="&amp;#7606;" data-id="46228" title="Modifier Letter Small U Bar">ᶶ</span>
    <span class="char" data-decimal="7607" data-entity="&amp;#7607;" data-id="46229" title="Modifier Letter Small Upsilon">ᶷ</span>
    <span class="char" data-decimal="7609" data-entity="&amp;#7609;" data-id="46231" title="Modifier Letter Small V With Hook">ᶹ</span>
    <span class="char" data-decimal="7610" data-entity="&amp;#7610;" data-id="46232" title="Modifier Letter Small Turned V">ᶺ</span>
    <span class="char" data-decimal="7611" data-entity="&amp;#7611;" data-id="46233" title="Modifier Letter Small Z">ᶻ</span>
    <span class="char" data-decimal="7612" data-entity="&amp;#7612;" data-id="46234" title="Modifier Letter Small Z With Retroflex Hook">ᶼ</span>
    <span class="char" data-decimal="7613" data-entity="&amp;#7613;" data-id="46235" title="Modifier Letter Small Z With Curl">ᶽ</span>
    <span class="char" data-decimal="7614" data-entity="&amp;#7614;" data-id="46236" title="Modifier Letter Small Ezh">ᶾ</span>
    <span class="char" data-decimal="7615" data-entity="&amp;#7615;" data-id="46237" title="Modifier Letter Small Theta">ᶿ</span>
    <span class="char" data-decimal="7424" data-entity="&amp;#7424;" data-id="46238" title="Latin Letter Small Capital A">ᴀ</span>
    <span class="char" data-decimal="7425" data-entity="&amp;#7425;" data-id="46239" title="Latin Letter Small Capital Ae">ᴁ</span>
    <span class="char" data-decimal="7426" data-entity="&amp;#7426;" data-id="46240" title="Latin Small Letter Turned Ae">ᴂ</span>
    <span class="char" data-decimal="7427" data-entity="&amp;#7427;" data-id="46241" title="Latin Letter Small Capital Barred B">ᴃ</span>
    <span class="char" data-decimal="7428" data-entity="&amp;#7428;" data-id="46242" title="Latin Letter Small Capital C">ᴄ</span>
    <span class="char" data-decimal="7429" data-entity="&amp;#7429;" data-id="46243" title="Latin Letter Small Capital D">ᴅ</span>
    <span class="char" data-decimal="7430" data-entity="&amp;#7430;" data-id="46244" title="Latin Letter Small Capital Eth">ᴆ</span>
    <span class="char" data-decimal="7431" data-entity="&amp;#7431;" data-id="46245" title="Latin Letter Small Capital E">ᴇ</span>
    <span class="char" data-decimal="7432" data-entity="&amp;#7432;" data-id="46246" title="Latin Small Letter Turned Open E">ᴈ</span>
    <span class="char" data-decimal="7433" data-entity="&amp;#7433;" data-id="46247" title="Latin Small Letter Turned I">ᴉ</span>
    <span class="char" data-decimal="7434" data-entity="&amp;#7434;" data-id="46248" title="Latin Letter Small Capital J">ᴊ</span>
    <span class="char" data-decimal="7435" data-entity="&amp;#7435;" data-id="46249" title="Latin Letter Small Capital K">ᴋ</span>
    <span class="char" data-decimal="7436" data-entity="&amp;#7436;" data-id="46250" title="Latin Letter Small Capital L With Stroke">ᴌ</span>
    <span class="char" data-decimal="7437" data-entity="&amp;#7437;" data-id="46251" title="Latin Letter Small Capital M">ᴍ</span>
    <span class="char" data-decimal="7438" data-entity="&amp;#7438;" data-id="46252" title="Latin Letter Small Capital Reversed N">ᴎ</span>
    <span class="char" data-decimal="7439" data-entity="&amp;#7439;" data-id="46253" title="Latin Letter Small Capital O">ᴏ</span>
    <span class="char" data-decimal="7440" data-entity="&amp;#7440;" data-id="46254" title="Latin Letter Small Capital Open O">ᴐ</span>
    <span class="char" data-decimal="7441" data-entity="&amp;#7441;" data-id="46255" title="Latin Small Letter Sideways O">ᴑ</span>
    <span class="char" data-decimal="7442" data-entity="&amp;#7442;" data-id="46256" title="Latin Small Letter Sideways Open O">ᴒ</span>
    <span class="char" data-decimal="7443" data-entity="&amp;#7443;" data-id="46257" title="Latin Small Letter Sideways O With Stroke">ᴓ</span>
    <span class="char" data-decimal="7444" data-entity="&amp;#7444;" data-id="46258" title="Latin Small Letter Turned Oe">ᴔ</span>
    <span class="char" data-decimal="7445" data-entity="&amp;#7445;" data-id="46259" title="Latin Letter Small Capital Ou">ᴕ</span>
    <span class="char" data-decimal="7446" data-entity="&amp;#7446;" data-id="46260" title="Latin Small Letter Top Half O">ᴖ</span>
    <span class="char" data-decimal="7447" data-entity="&amp;#7447;" data-id="46261" title="Latin Small Letter Bottom Half O">ᴗ</span>
    <span class="char" data-decimal="7448" data-entity="&amp;#7448;" data-id="46262" title="Latin Letter Small Capital P">ᴘ</span>
    <span class="char" data-decimal="7449" data-entity="&amp;#7449;" data-id="46263" title="Latin Letter Small Capital Reversed R">ᴙ</span>
    <span class="char" data-decimal="7450" data-entity="&amp;#7450;" data-id="46264" title="Latin Letter Small Capital Turned R">ᴚ</span>
    <span class="char" data-decimal="7451" data-entity="&amp;#7451;" data-id="46265" title="Latin Letter Small Capital T">ᴛ</span>
    <span class="char" data-decimal="7452" data-entity="&amp;#7452;" data-id="46266" title="Latin Letter Small Capital U">ᴜ</span>
    <span class="char" data-decimal="7453" data-entity="&amp;#7453;" data-id="46267" title="Latin Small Letter Sideways U">ᴝ</span>
    <span class="char" data-decimal="7454" data-entity="&amp;#7454;" data-id="46268" title="Latin Small Letter Sideways Diaeresized U">ᴞ</span>
    <span class="char" data-decimal="7455" data-entity="&amp;#7455;" data-id="46269" title="Latin Small Letter Sideways Turned M">ᴟ</span>
    <span class="char" data-decimal="7456" data-entity="&amp;#7456;" data-id="46270" title="Latin Letter Small Capital V">ᴠ</span>
    <span class="char" data-decimal="7457" data-entity="&amp;#7457;" data-id="46271" title="Latin Letter Small Capital W">ᴡ</span>
    <span class="char" data-decimal="7458" data-entity="&amp;#7458;" data-id="46272" title="Latin Letter Small Capital Z">ᴢ</span>
    <span class="char" data-decimal="7459" data-entity="&amp;#7459;" data-id="46273" title="Latin Letter Small Capital Ezh">ᴣ</span>
    <span class="char" data-decimal="7460" data-entity="&amp;#7460;" data-id="46274" title="Latin Letter Voiced Laryngeal Spirant">ᴤ</span>
    <span class="char" data-decimal="7461" data-entity="&amp;#7461;" data-id="46275" title="Latin Letter Ain">ᴥ</span>
    <span class="char" data-decimal="7462" data-entity="&amp;#7462;" data-id="46276" title="Greek Letter Small Capital Gamma">ᴦ</span>
    <span class="char" data-decimal="7463" data-entity="&amp;#7463;" data-id="46277" title="Greek Letter Small Capital Lamda">ᴧ</span>
    <span class="char" data-decimal="7464" data-entity="&amp;#7464;" data-id="46278" title="Greek Letter Small Capital Pi">ᴨ</span>
    <span class="char" data-decimal="7465" data-entity="&amp;#7465;" data-id="46279" title="Greek Letter Small Capital Rho">ᴩ</span>
    <span class="char" data-decimal="7466" data-entity="&amp;#7466;" data-id="46280" title="Greek Letter Small Capital Psi">ᴪ</span>
    <span class="char" data-decimal="7467" data-entity="&amp;#7467;" data-id="46281" title="Cyrillic Letter Small Capital El">ᴫ</span>
    <span class="char" data-decimal="7468" data-entity="&amp;#7468;" data-id="46282" title="Modifier Letter Capital A">ᴬ</span>
    <span class="char" data-decimal="7469" data-entity="&amp;#7469;" data-id="46283" title="Modifier Letter Capital Ae">ᴭ</span>
    <span class="char" data-decimal="7470" data-entity="&amp;#7470;" data-id="46284" title="Modifier Letter Capital B">ᴮ</span>
    <span class="char" data-decimal="7471" data-entity="&amp;#7471;" data-id="46285" title="Modifier Letter Capital Barred B">ᴯ</span>
    <span class="char" data-decimal="7472" data-entity="&amp;#7472;" data-id="46286" title="Modifier Letter Capital D">ᴰ</span>
    <span class="char" data-decimal="7473" data-entity="&amp;#7473;" data-id="46287" title="Modifier Letter Capital E">ᴱ</span>
    <span class="char" data-decimal="7474" data-entity="&amp;#7474;" data-id="46288" title="Modifier Letter Capital Reversed E">ᴲ</span>
    <span class="char" data-decimal="7475" data-entity="&amp;#7475;" data-id="46289" title="Modifier Letter Capital G">ᴳ</span>
    <span class="char" data-decimal="7476" data-entity="&amp;#7476;" data-id="46290" title="Modifier Letter Capital H">ᴴ</span>
    <span class="char" data-decimal="7477" data-entity="&amp;#7477;" data-id="46291" title="Modifier Letter Capital I">ᴵ</span>
    <span class="char" data-decimal="7478" data-entity="&amp;#7478;" data-id="46292" title="Modifier Letter Capital J">ᴶ</span>
    <span class="char" data-decimal="7479" data-entity="&amp;#7479;" data-id="46293" title="Modifier Letter Capital K">ᴷ</span>
    <span class="char" data-decimal="7480" data-entity="&amp;#7480;" data-id="46294" title="Modifier Letter Capital L">ᴸ</span>
    <span class="char" data-decimal="7481" data-entity="&amp;#7481;" data-id="46295" title="Modifier Letter Capital M">ᴹ</span>
    <span class="char" data-decimal="7482" data-entity="&amp;#7482;" data-id="46296" title="Modifier Letter Capital N">ᴺ</span>
    <span class="char" data-decimal="7483" data-entity="&amp;#7483;" data-id="46297" title="Modifier Letter Capital Reversed N">ᴻ</span>
    <span class="char" data-decimal="7484" data-entity="&amp;#7484;" data-id="46298" title="Modifier Letter Capital O">ᴼ</span>
    <span class="char" data-decimal="7485" data-entity="&amp;#7485;" data-id="46299" title="Modifier Letter Capital Ou">ᴽ</span>
    <span class="char" data-decimal="7486" data-entity="&amp;#7486;" data-id="46300" title="Modifier Letter Capital P">ᴾ</span>
    <span class="char" data-decimal="7487" data-entity="&amp;#7487;" data-id="46301" title="Modifier Letter Capital R">ᴿ</span>
    <span class="char" data-decimal="7488" data-entity="&amp;#7488;" data-id="46302" title="Modifier Letter Capital T">ᵀ</span>
    <span class="char" data-decimal="7489" data-entity="&amp;#7489;" data-id="46303" title="Modifier Letter Capital U">ᵁ</span>
    <span class="char" data-decimal="7490" data-entity="&amp;#7490;" data-id="46304" title="Modifier Letter Capital W">ᵂ</span>
    <span class="char" data-decimal="7491" data-entity="&amp;#7491;" data-id="46305" title="Modifier Letter Small A">ᵃ</span>
    <span class="char" data-decimal="7492" data-entity="&amp;#7492;" data-id="46306" title="Modifier Letter Small Turned A">ᵄ</span>
    <span class="char" data-decimal="7493" data-entity="&amp;#7493;" data-id="46307" title="Modifier Letter Small Alpha">ᵅ</span>
    <span class="char" data-decimal="7494" data-entity="&amp;#7494;" data-id="46308" title="Modifier Letter Small Turned Ae">ᵆ</span>
    <span class="char" data-decimal="7495" data-entity="&amp;#7495;" data-id="46309" title="Modifier Letter Small B">ᵇ</span>
    <span class="char" data-decimal="7496" data-entity="&amp;#7496;" data-id="46310" title="Modifier Letter Small D">ᵈ</span>
    <span class="char" data-decimal="7497" data-entity="&amp;#7497;" data-id="46311" title="Modifier Letter Small E">ᵉ</span>
    <span class="char" data-decimal="7498" data-entity="&amp;#7498;" data-id="46312" title="Modifier Letter Small Schwa">ᵊ</span>
    <span class="char" data-decimal="7499" data-entity="&amp;#7499;" data-id="46313" title="Modifier Letter Small Open E">ᵋ</span>
    <span class="char" data-decimal="7500" data-entity="&amp;#7500;" data-id="46314" title="Modifier Letter Small Turned Open E">ᵌ</span>
    <span class="char" data-decimal="7501" data-entity="&amp;#7501;" data-id="46315" title="Modifier Letter Small G">ᵍ</span>
    <span class="char" data-decimal="7502" data-entity="&amp;#7502;" data-id="46316" title="Modifier Letter Small Turned I">ᵎ</span>
    <span class="char" data-decimal="7503" data-entity="&amp;#7503;" data-id="46317" title="Modifier Letter Small K">ᵏ</span>
    <span class="char" data-decimal="7504" data-entity="&amp;#7504;" data-id="46318" title="Modifier Letter Small M">ᵐ</span>
    <span class="char" data-decimal="7505" data-entity="&amp;#7505;" data-id="46319" title="Modifier Letter Small Eng">ᵑ</span>
    <span class="char" data-decimal="7506" data-entity="&amp;#7506;" data-id="46320" title="Modifier Letter Small O">ᵒ</span>
    <span class="char" data-decimal="7507" data-entity="&amp;#7507;" data-id="46321" title="Modifier Letter Small Open O">ᵓ</span>
    <span class="char" data-decimal="7508" data-entity="&amp;#7508;" data-id="46322" title="Modifier Letter Small Top Half O">ᵔ</span>
    <span class="char" data-decimal="7509" data-entity="&amp;#7509;" data-id="46323" title="Modifier Letter Small Bottom Half O">ᵕ</span>
    <span class="char" data-decimal="7510" data-entity="&amp;#7510;" data-id="46324" title="Modifier Letter Small P">ᵖ</span>
    <span class="char" data-decimal="7511" data-entity="&amp;#7511;" data-id="46325" title="Modifier Letter Small T">ᵗ</span>
    <span class="char" data-decimal="7512" data-entity="&amp;#7512;" data-id="46326" title="Modifier Letter Small U">ᵘ</span>
    <span class="char" data-decimal="7513" data-entity="&amp;#7513;" data-id="46327" title="Modifier Letter Small Sideways U">ᵙ</span>
    <span class="char" data-decimal="7514" data-entity="&amp;#7514;" data-id="46328" title="Modifier Letter Small Turned M">ᵚ</span>
    <span class="char" data-decimal="7515" data-entity="&amp;#7515;" data-id="46329" title="Modifier Letter Small V">ᵛ</span>
    <span class="char" data-decimal="7516" data-entity="&amp;#7516;" data-id="46330" title="Modifier Letter Small Ain">ᵜ</span>
    <span class="char" data-decimal="7517" data-entity="&amp;#7517;" data-id="46331" title="Modifier Letter Small Beta">ᵝ</span>
    <span class="char" data-decimal="7518" data-entity="&amp;#7518;" data-id="46332" title="Modifier Letter Small Greek Gamma">ᵞ</span>
    <span class="char" data-decimal="7519" data-entity="&amp;#7519;" data-id="46333" title="Modifier Letter Small Delta">ᵟ</span>
    <span class="char" data-decimal="7520" data-entity="&amp;#7520;" data-id="46334" title="Modifier Letter Small Greek Phi">ᵠ</span>
    <span class="char" data-decimal="7521" data-entity="&amp;#7521;" data-id="46335" title="Modifier Letter Small Chi">ᵡ</span>
    <span class="char" data-decimal="7522" data-entity="&amp;#7522;" data-id="46336" title="Latin Subscript Small Letter I">ᵢ</span>
    <span class="char" data-decimal="7523" data-entity="&amp;#7523;" data-id="46337" title="Latin Subscript Small Letter R">ᵣ</span>
    <span class="char" data-decimal="7524" data-entity="&amp;#7524;" data-id="46338" title="Latin Subscript Small Letter U">ᵤ</span>
    <span class="char" data-decimal="7525" data-entity="&amp;#7525;" data-id="46339" title="Latin Subscript Small Letter V">ᵥ</span>
    <span class="char" data-decimal="7526" data-entity="&amp;#7526;" data-id="46340" title="Greek Subscript Small Letter Beta">ᵦ</span>
    <span class="char" data-decimal="7527" data-entity="&amp;#7527;" data-id="46341" title="Greek Subscript Small Letter Gamma">ᵧ</span>
    <span class="char" data-decimal="7528" data-entity="&amp;#7528;" data-id="46342" title="Greek Subscript Small Letter Rho">ᵨ</span>
    <span class="char" data-decimal="7529" data-entity="&amp;#7529;" data-id="46343" title="Greek Subscript Small Letter Phi">ᵩ</span>
    <span class="char" data-decimal="7530" data-entity="&amp;#7530;" data-id="46344" title="Greek Subscript Small Letter Chi">ᵪ</span>
    <span class="char" data-decimal="7531" data-entity="&amp;#7531;" data-id="46345" title="Latin Small Letter Ue">ᵫ</span>
    <span class="char" data-decimal="7532" data-entity="&amp;#7532;" data-id="46346" title="Latin Small Letter B With Middle Tilde">ᵬ</span>
    <span class="char" data-decimal="7533" data-entity="&amp;#7533;" data-id="46347" title="Latin Small Letter D With Middle Tilde">ᵭ</span>
    <span class="char" data-decimal="7534" data-entity="&amp;#7534;" data-id="46348" title="Latin Small Letter F With Middle Tilde">ᵮ</span>
    <span class="char" data-decimal="7537" data-entity="&amp;#7537;" data-id="46351" title="Latin Small Letter P With Middle Tilde">ᵱ</span>
    <span class="char" data-decimal="7538" data-entity="&amp;#7538;" data-id="46352" title="Latin Small Letter R With Middle Tilde">ᵲ</span>
    <span class="char" data-decimal="7539" data-entity="&amp;#7539;" data-id="46353" title="Latin Small Letter R With Fishhook And Middle Tilde">ᵳ</span>
    <span class="char" data-decimal="7541" data-entity="&amp;#7541;" data-id="46355" title="Latin Small Letter T With Middle Tilde">ᵵ</span>
    <span class="char" data-decimal="7543" data-entity="&amp;#7543;" data-id="46357" title="Latin Small Letter Turned G">ᵷ</span>
    <span class="char" data-decimal="7544" data-entity="&amp;#7544;" data-id="46358" title="Modifier Letter Cyrillic En">ᵸ</span>
    <span class="char" data-decimal="7546" data-entity="&amp;#7546;" data-id="46360" title="Latin Small Letter Th With Strikethrough">ᵺ</span>
    <span class="char" data-decimal="7547" data-entity="&amp;#7547;" data-id="46361" title="Latin Small Capital Letter I With Stroke">ᵻ</span>
    <span class="char" data-decimal="7627" data-entity="&amp;#7627;" data-id="46366" title="Combining Breve Macron">᷋</span>
    <span class="char" data-decimal="7628" data-entity="&amp;#7628;" data-id="46367" title="Combining Macron Breve">᷌</span>
    <span class="char" data-decimal="7629" data-entity="&amp;#7629;" data-id="46368" title="Combining Double Circumflex Above">᷍</span>
    <span class="char" data-decimal="7630" data-entity="&amp;#7630;" data-id="46369" title="Combining Ogonek Above">᷎</span>
    <span class="char" data-decimal="7631" data-entity="&amp;#7631;" data-id="46370" title="Combining Zigzag Below">᷏</span>
    <span class="char" data-decimal="7635" data-entity="&amp;#7635;" data-id="46371" title="Combining Latin Small Letter Flattened Open A Above">ᷓ</span>
    <span class="char" data-decimal="7636" data-entity="&amp;#7636;" data-id="46372" title="Combining Latin Small Letter Ae">ᷔ</span>
    <span class="char" data-decimal="7637" data-entity="&amp;#7637;" data-id="46373" title="Combining Latin Small Letter Ao">ᷕ</span>
    <span class="char" data-decimal="7638" data-entity="&amp;#7638;" data-id="46374" title="Combining Latin Small Letter Av">ᷖ</span>
    <span class="char" data-decimal="7639" data-entity="&amp;#7639;" data-id="46375" title="Combining Latin Small Letter C Cedilla">ᷗ</span>
    <span class="char" data-decimal="7640" data-entity="&amp;#7640;" data-id="46376" title="Combining Latin Small Letter Insular D">ᷘ</span>
    <span class="char" data-decimal="7641" data-entity="&amp;#7641;" data-id="46377" title="Combining Latin Small Letter Eth">ᷙ</span>
    <span class="char" data-decimal="7643" data-entity="&amp;#7643;" data-id="46378" title="Combining Latin Letter Small Capital G">ᷛ</span>
    <span class="char" data-decimal="7644" data-entity="&amp;#7644;" data-id="46379" title="Combining Latin Small Letter K">ᷜ</span>
    <span class="char" data-decimal="7645" data-entity="&amp;#7645;" data-id="46380" title="Combining Latin Small Letter L">ᷝ</span>
    <span class="char" data-decimal="7646" data-entity="&amp;#7646;" data-id="46381" title="Combining Latin Letter Small Capital L">ᷞ</span>
    <span class="char" data-decimal="7647" data-entity="&amp;#7647;" data-id="46382" title="Combining Latin Letter Small Capital M">ᷟ</span>
    <span class="char" data-decimal="7648" data-entity="&amp;#7648;" data-id="46383" title="Combining Latin Small Letter N">ᷠ</span>
    <span class="char" data-decimal="7649" data-entity="&amp;#7649;" data-id="46384" title="Combining Latin Letter Small Capital N">ᷡ</span>
    <span class="char" data-decimal="7650" data-entity="&amp;#7650;" data-id="46385" title="Combining Latin Letter Small Capital R">ᷢ</span>
    <span class="char" data-decimal="7651" data-entity="&amp;#7651;" data-id="46386" title="Combining Latin Small Letter R Rotunda">ᷣ</span>
    <span class="char" data-decimal="7652" data-entity="&amp;#7652;" data-id="46387" title="Combining Latin Small Letter S">ᷤ</span>
    <span class="char" data-decimal="7653" data-entity="&amp;#7653;" data-id="46388" title="Combining Latin Small Letter Long S">ᷥ</span>
    <span class="char" data-decimal="7654" data-entity="&amp;#7654;" data-id="46389" title="Combining Latin Small Letter Z">ᷦ</span>
    <span class="char" data-decimal="8216" data-entity="&amp;#8216;" data-id="46414" title="Left Single Quotation Mark">‘</span>
    <span class="char" data-decimal="8217" data-entity="&amp;#8217;" data-id="46415" title="Right Single Quotation Mark">’</span>
    <span class="char" data-decimal="8219" data-entity="&amp;#8219;" data-id="46417" title="Single High Reversed 9 Quotation Mark">‛</span>
    <span class="char" data-decimal="8218" data-entity="&amp;#8218;" data-id="46416" title="Single Low 9 Quotation Mark">‚</span>
    <span class="char" data-decimal="8220" data-entity="&amp;#8220;" data-id="46418" title="Left Double Quotation Mark">“</span>
    <span class="char" data-decimal="8221" data-entity="&amp;#8221;" data-id="46419" title="Right Double Quotation Mark">”</span>
    <span class="char" data-decimal="8222" data-entity="&amp;#8222;" data-id="46420" title="Double Low 9 Quotation Mark">„</span>
    <span class="char" data-decimal="8223" data-entity="&amp;#8223;" data-id="46421" title="Double High Reversed 9 Quotation Mark">‟</span>
    <span class="char" data-decimal="171" data-entity="&amp;laquo;" data-id="45154" title="Left Pointing Double Angle Quotation Mark">«</span>
    <span class="char" data-decimal="187" data-entity="&amp;raquo;" data-id="45170" title="Right Pointing Double Angle Quotation Mark">»</span>
    <span class="char" data-decimal="8249" data-entity="&amp;#8249;" data-id="46440" title="Single Left Pointing Angle Quotation Mark">‹</span>
    <span class="char" data-decimal="8250" data-entity="&amp;#8250;" data-id="46441" title="Single Right Pointing Angle Quotation Mark">›</span>
    <span class="char" data-decimal="42891" data-entity="&amp;#42891;" data-id="45136" title="Latin Capital Letter Saltillo">Ꞌ</span>
    <span class="char" data-decimal="34" data-entity="&amp;#34;" data-id="44771" title="Quotation Mark">"</span>
    <span class="char" data-decimal="10075" data-entity="&amp;#10075;" data-id="43669" title="Heavy Single Turned Comma Quotation Mark Ornament">❛</span>
    <span class="char" data-decimal="10076" data-entity="&amp;#10076;" data-id="43670" title="Heavy Single Comma Quotation Mark Ornament">❜</span>
    <span class="char" data-decimal="10077" data-entity="&amp;#10077;" data-id="43671" title="Heavy Double Turned Comma Quotation Mark Ornament">❝</span>
    <span class="char" data-decimal="10078" data-entity="&amp;#10078;" data-id="43672" title="Heavy Double Comma Quotation Mark Ornament">❞</span>
    <span class="char" data-decimal="60" data-entity="&amp;#60;" data-id="44796" title="Less Than Sign">&lt;</span>
    <span class="char" data-decimal="62" data-entity="&amp;#62;" data-id="44798" title="Greater Than Sign">&gt;</span>
    <span class="char" data-decimal="64" data-entity="&amp;#64;" data-id="44800" title="Commercial At">&#64;</span>
    <span class="char" data-decimal="8231" data-entity="&amp;#8231;" data-id="46429" title="Hyphenation Point">‧</span>
    <span class="char" data-decimal="168" data-entity="&amp;uml;" data-id="45151" title="Diaeresis">¨</span>
    <span class="char" data-decimal="8228" data-entity="&amp;#8228;" data-id="46426" title="One Dot Leader">․</span>
    <span class="char" data-decimal="42889" data-entity="&amp;#42889;" data-id="45134" title="Modifier Letter Colon">꞉</span>
    <span class="char" data-decimal="58" data-entity="&amp;#58;" data-id="44794" title="Colon">:</span>
    <span class="char" data-decimal="8282" data-entity="&amp;#8282;" data-id="46473" title="Two Dot Punctuation">⁚</span>
    <span class="char" data-decimal="8285" data-entity="&amp;#8285;" data-id="46476" title="Tricolon">⁝</span>
    <span class="char" data-decimal="8286" data-entity="&amp;#8286;" data-id="46477" title="Vertical Four Dots">⁞</span>
    <span class="char" data-decimal="8229" data-entity="&amp;#8229;" data-id="46427" title="Two Dot Leader">‥</span>
    <span class="char" data-decimal="8230" data-entity="&amp;#8230;" data-id="46428" title="Horizontal Ellipsis">…</span>
    <span class="char" data-decimal="8278" data-entity="&amp;#8278;" data-id="46469" title="Three Dot Punctuation">⁖</span>
    <span class="char" data-decimal="11818" data-entity="&amp;#11818;" data-id="46526" title="Two Dots Over One Dot Punctuation">⸪</span>
    <span class="char" data-decimal="11820" data-entity="&amp;#11820;" data-id="46528" title="Squared Four Dot Punctuation">⸬</span>
    <span class="char" data-decimal="11819" data-entity="&amp;#11819;" data-id="46527" title="One Dot Over Two Dots Punctuation">⸫</span>
    <span class="char" data-decimal="11821" data-entity="&amp;#11821;" data-id="46529" title="Five Dot Mark">⸭</span>
    <span class="char" data-decimal="8283" data-entity="&amp;#8283;" data-id="46474" title="Four Dot Mark">⁛</span>
    <span class="char" data-decimal="8280" data-entity="&amp;#8280;" data-id="46471" title="Four Dot Punctuation">⁘</span>
    <span class="char" data-decimal="8281" data-entity="&amp;#8281;" data-id="46472" title="Five Dot Punctuation">⁙</span>
    <span class="char" data-decimal="8271" data-entity="&amp;#8271;" data-id="46462" title="Reversed Semicolon">⁏</span>
    <span class="char" data-decimal="59" data-entity="&amp;#59;" data-id="44795" title="Semicolon">;</span>
    <span class="char" data-decimal="10626" data-entity="&amp;#10626;" data-id="45355" title="Z Notation Type Colon">⦂</span>
    <span class="char" data-decimal="8259" data-entity="&amp;#8259;" data-id="46450" title="Hyphen Bullet">⁃</span>
    <span class="char" data-decimal="8208" data-entity="&amp;#8208;" data-id="46406" title="Hyphen">‐</span>
    <span class="char" data-decimal="8209" data-entity="&amp;#8209;" data-id="46407" title="Non Breaking Hyphen">‑</span>
    <span class="char" data-decimal="8210" data-entity="&amp;#8210;" data-id="46408" title="Figure Dash">‒</span>
    <span class="char" data-decimal="45" data-entity="&amp;#45;" data-id="44781" title="Hyphen Minus">-</span>
    <span class="char" data-decimal="8211" data-entity="&amp;#8211;" data-id="46409" title="En Dash">–</span>
    <span class="char" data-decimal="9135" data-entity="&amp;#9135;" data-id="46117" title="Horizontal Line Extension">⎯</span>
    <span class="char" data-decimal="8212" data-entity="&amp;#8212;" data-id="46410" title="Em Dash">—</span>
    <span class="char" data-decimal="8213" data-entity="&amp;#8213;" data-id="46411" title="Horizontal Bar">―</span>
    <span class="char" data-decimal="95" data-entity="&amp;#95;" data-id="44831" title="Low Line">_</span>
    <span class="char" data-decimal="8275" data-entity="&amp;#8275;" data-id="46466" title="Swung Dash">⁓</span>
    <span class="char" data-decimal="11803" data-entity="&amp;#11803;" data-id="46511" title="Tilde With Ring Above">⸛</span>
    <span class="char" data-decimal="11806" data-entity="&amp;#11806;" data-id="46514" title="Tilde With Dot Above">⸞</span>
    <span class="char" data-decimal="11807" data-entity="&amp;#11807;" data-id="46515" title="Tilde With Dot Below">⸟</span>
    <span class="char" data-decimal="11823" data-entity="&amp;#11823;" data-id="46531" title="Vertical Tilde">ⸯ</span>
    <span class="char" data-decimal="172" data-entity="&amp;not;" data-id="45155" title="Not Sign">¬</span>
    <span class="char" data-decimal="47" data-entity="&amp;#47;" data-id="44783" title="Solidus">/</span>
    <span class="char" data-decimal="92" data-entity="&amp;#92;" data-id="44828" title="Reverse Solidus">\</span>
    <span class="char" data-decimal="8260" data-entity="&amp;#8260;" data-id="46451" title="Fraction Slash">⁄</span>
    <span class="char" data-decimal="92" data-entity="&amp;#92;" data-id="44828" title="Reverse Solidus">\</span>
    <span class="char" data-decimal="8260" data-entity="&amp;#8260;" data-id="46451" title="Fraction Slash">⁄</span>
    <span class="char" data-decimal="124" data-entity="&amp;#124;" data-id="44860" title="Vertical Line">|</span>
    <span class="char" data-decimal="9116" data-entity="&amp;#9116;" data-id="46098" title="Left Parenthesis Extension">⎜</span>
    <span class="char" data-decimal="166" data-entity="&amp;brvbar;" data-id="45149" title="Broken Bar">¦</span>
    <span class="char" data-decimal="8214" data-entity="&amp;#8214;" data-id="46412" title="Double Vertical Line">‖</span>
    <span class="char" data-decimal="8215" data-entity="&amp;#8215;" data-id="46413" title="Double Low Line">‗</span>
    <span class="char" data-decimal="8224" data-entity="&amp;#8224;" data-id="46422" title="Dagger">†</span>
    <span class="char" data-decimal="8225" data-entity="&amp;#8225;" data-id="46423" title="Double Dagger">‡</span>
    <span class="char" data-decimal="183" data-entity="&amp;middot;" data-id="45166" title="Middle Dot">·</span>
    <span class="char" data-decimal="8226" data-entity="&amp;#8226;" data-id="46424" title="Bullet">•</span>
    <span class="char" data-decimal="11824" data-entity="&amp;#11824;" data-id="46532" title="Ring Point">⸰</span>
    <span class="char" data-decimal="176" data-entity="&amp;deg;" data-id="45159" title="Degree Sign">°</span>
    <span class="char" data-decimal="8227" data-entity="&amp;#8227;" data-id="46425" title="Triangular Bullet">‣</span>
    <span class="char" data-decimal="8274" data-entity="&amp;#8274;" data-id="46465" title="Commercial Minus Sign">⁒</span>
    <span class="char" data-decimal="37" data-entity="&amp;#37;" data-id="44773" title="Percent Sign">%</span>
    <span class="char" data-decimal="8240" data-entity="&amp;#8240;" data-id="46431" title="Per Mille Sign">‰</span>
    <span class="char" data-decimal="8241" data-entity="&amp;#8241;" data-id="46432" title="Per Ten Thousand Sign">‱</span>
    <span class="char" data-decimal="38" data-entity="&amp;#38;" data-id="44774" title="Ampersand">&amp;</span>
    <span class="char" data-decimal="8523" data-entity="&amp;#8523;" data-id="45313" title="Turned Ampersand">⅋</span>
    <span class="char" data-decimal="167" data-entity="&amp;sect;" data-id="45150" title="Section Sign">§</span>
    <span class="char" data-decimal="247" data-entity="&amp;divide;" data-id="45230" title="Division Sign">÷</span>
    <span class="char" data-decimal="43" data-entity="&amp;#43;" data-id="44779" title="Plus Sign">+</span>
    <span class="char" data-decimal="177" data-entity="&amp;plusmn;" data-id="45160" title="Plus Minus Sign">±</span>
    <span class="char" data-decimal="61" data-entity="&amp;#61;" data-id="44797" title="Equals Sign">=</span>
    <span class="char" data-decimal="42890" data-entity="&amp;#42890;" data-id="45135" title="Modifier Letter Short Equals Sign">꞊</span>
    <span class="char" data-decimal="8242" data-entity="&amp;#8242;" data-id="46433" title="Prime">′</span>
    <span class="char" data-decimal="8243" data-entity="&amp;#8243;" data-id="46434" title="Double Prime">″</span>
    <span class="char" data-decimal="8244" data-entity="&amp;#8244;" data-id="46435" title="Triple Prime">‴</span>
    <span class="char" data-decimal="8279" data-entity="&amp;#8279;" data-id="46470" title="Quadruple Prime">⁗</span>
    <span class="char" data-decimal="8245" data-entity="&amp;#8245;" data-id="46436" title="Reversed Prime">‵</span>
    <span class="char" data-decimal="8246" data-entity="&amp;#8246;" data-id="46437" title="Reversed Double Prime">‶</span>
    <span class="char" data-decimal="8247" data-entity="&amp;#8247;" data-id="46438" title="Reversed Triple Prime">‷</span>
    <span class="char" data-decimal="8248" data-entity="&amp;#8248;" data-id="46439" title="Caret">‸</span>
    <span class="char" data-decimal="42" data-entity="&amp;#42;" data-id="44778" title="Asterisk">*</span>
    <span class="char" data-decimal="8273" data-entity="&amp;#8273;" data-id="46464" title="Two Asterisks Aligned Vertically">⁑</span>
    <span class="char" data-decimal="8270" data-entity="&amp;#8270;" data-id="46461" title="Low Asterisk">⁎</span>
    <span class="char" data-decimal="8277" data-entity="&amp;#8277;" data-id="46468" title="Flower Punctuation Mark">⁕</span>
    <span class="char" data-decimal="8251" data-entity="&amp;#8251;" data-id="46442" title="Reference Mark">※</span>
    <span class="char" data-decimal="8284" data-entity="&amp;#8284;" data-id="46475" title="Dotted Cross">⁜</span>
    <span class="char" data-decimal="8258" data-entity="&amp;#8258;" data-id="46449" title="Asterism">⁂</span>
    <span class="char" data-decimal="33" data-entity="&amp;#33;" data-id="44770" title="Exclamation Mark">!</span>
    <span class="char" data-decimal="8252" data-entity="&amp;#8252;" data-id="46443" title="Double Exclamation Mark">‼</span>
    <span class="char" data-decimal="161" data-entity="&amp;iexcl;" data-id="45144" title="Inverted Exclamation Mark">¡</span>
    <span class="char" data-decimal="63" data-entity="&amp;#63;" data-id="44799" title="Question Mark">?</span>
    <span class="char" data-decimal="191" data-entity="&amp;iquest;" data-id="45174" title="Inverted Question Mark">¿</span>
    <span class="char" data-decimal="11822" data-entity="&amp;#11822;" data-id="46530" title="Reversed Question Mark">⸮</span>
    <span class="char" data-decimal="8263" data-entity="&amp;#8263;" data-id="46454" title="Double Question Mark">⁇</span>
    <span class="char" data-decimal="8265" data-entity="&amp;#8265;" data-id="46456" title="Exclamation Question Mark">⁉</span>
    <span class="char" data-decimal="8264" data-entity="&amp;#8264;" data-id="46455" title="Question Exclamation Mark">⁈</span>
    <span class="char" data-decimal="8253" data-entity="&amp;#8253;" data-id="46444" title="Interrobang">‽</span>
    <span class="char" data-decimal="11800" data-entity="&amp;#11800;" data-id="46509" title="Inverted Interrobang">⸘</span>
    <span class="char" data-decimal="188" data-entity="&amp;frac14;" data-id="45171" title="Vulgar Fraction One Quarter">¼</span>
    <span class="char" data-decimal="189" data-entity="&amp;frac12;" data-id="45172" title="Vulgar Fraction One Half">½</span>
    <span class="char" data-decimal="190" data-entity="&amp;frac34;" data-id="45173" title="Vulgar Fraction Three Quarters">¾</span>
    <span class="char" data-decimal="178" data-entity="&amp;sup2;" data-id="45161" title="Superscript Two">²</span>
    <span class="char" data-decimal="179" data-entity="&amp;sup3;" data-id="45162" title="Superscript Three">³</span>
    <span class="char" data-decimal="169" data-entity="&amp;copy;" data-id="45152" title="Copyright Sign">©</span>
    <span class="char" data-decimal="174" data-entity="&amp;reg;" data-id="45157" title="Registered Sign">®</span>
    <span class="char" data-decimal="8482" data-entity="&amp;trade;" data-id="45273" title="Trade Mark Sign">™</span>
    <span class="char" data-decimal="8480" data-entity="&amp;#8480;" data-id="45271" title="Service Mark">℠</span>
    <span class="char" data-decimal="8507" data-entity="&amp;#8507;" data-id="45298" title="Facsimile Sign">℻</span>
    <span class="char" data-decimal="8453" data-entity="&amp;#8453;" data-id="45244" title="Care Of">℅</span>
    <span class="char" data-decimal="8449" data-entity="&amp;#8449;" data-id="45240" title="Addressed To The Subject">℁</span>
    <span class="char" data-decimal="8525" data-entity="&amp;#8525;" data-id="45314" title="Aktieselskab">⅍</span>
    <span class="char" data-decimal="8452" data-entity="&amp;#8452;" data-id="45243" title="Centre Line Symbol">℄</span>
    <span class="char" data-decimal="182" data-entity="&amp;para;" data-id="45165" title="Pilcrow Sign">¶</span>
    <span class="char" data-decimal="8267" data-entity="&amp;#8267;" data-id="46458" title="Reversed Pilcrow Sign">⁋</span>
    <span class="char" data-decimal="10081" data-entity="&amp;#10081;" data-id="43673" title="Curved Stem Paragraph Sign Ornament">❡</span>
    <span class="char" data-decimal="8268" data-entity="&amp;#8268;" data-id="46459" title="Black Leftwards Bullet">⁌</span>
    <span class="char" data-decimal="8269" data-entity="&amp;#8269;" data-id="46460" title="Black Rightwards Bullet">⁍</span>
    <span class="char" data-decimal="11798" data-entity="&amp;#11798;" data-id="46507" title="Dotted Right Pointing Angle">⸖</span>
    <span class="char" data-decimal="11799" data-entity="&amp;#11799;" data-id="46508" title="Double Oblique Hyphen">⸗</span>
    <span class="char" data-decimal="11802" data-entity="&amp;#11802;" data-id="46510" title="Hyphen With Diaeresis">⸚</span>
    <span class="char" data-decimal="11795" data-entity="&amp;#11795;" data-id="46504" title="Dotted Obelos">⸓</span>
    <span class="char" data-decimal="11816" data-entity="&amp;#11816;" data-id="46524" title="Left Double Parenthesis">⸨</span>
    <span class="char" data-decimal="11817" data-entity="&amp;#11817;" data-id="46525" title="Right Double Parenthesis">⸩</span>
    <span class="char" data-decimal="10088" data-entity="&amp;#10088;" data-id="43680" title="Medium Left Parenthesis Ornament">❨</span>
    <span class="char" data-decimal="10089" data-entity="&amp;#10089;" data-id="43681" title="Medium Right Parenthesis Ornament">❩</span>
    <span class="char" data-decimal="10090" data-entity="&amp;#10090;" data-id="43682" title="Medium Flattened Left Parenthesis Ornament">❪</span>
    <span class="char" data-decimal="10091" data-entity="&amp;#10091;" data-id="43683" title="Medium Flattened Right Parenthesis Ornament">❫</span>
    <span class="char" data-decimal="11814" data-entity="&amp;#11814;" data-id="46522" title="Left Sideways U Bracket">⸦</span>
    <span class="char" data-decimal="11815" data-entity="&amp;#11815;" data-id="46523" title="Right Sideways U Bracket">⸧</span>
    <span class="char" data-decimal="10092" data-entity="&amp;#10092;" data-id="43684" title="Medium Left Pointing Angle Bracket Ornament">❬</span>
    <span class="char" data-decimal="10093" data-entity="&amp;#10093;" data-id="43685" title="Medium Right Pointing Angle Bracket Ornament">❭</span>
    <span class="char" data-decimal="10094" data-entity="&amp;#10094;" data-id="43686" title="Heavy Left Pointing Angle Quotation Mark Ornament">❮</span>
    <span class="char" data-decimal="10095" data-entity="&amp;#10095;" data-id="43687" title="Heavy Right Pointing Angle Quotation Mark Ornament">❯</span>
    <span class="char" data-decimal="10096" data-entity="&amp;#10096;" data-id="43688" title="Heavy Left Pointing Angle Bracket Ornament">❰</span>
    <span class="char" data-decimal="10097" data-entity="&amp;#10097;" data-id="43689" title="Heavy Right Pointing Angle Bracket Ornament">❱</span>
    <span class="char" data-decimal="10100" data-entity="&amp;#10100;" data-id="43692" title="Medium Left Curly Bracket Ornament">❴</span>
    <span class="char" data-decimal="10101" data-entity="&amp;#10101;" data-id="43693" title="Medium Right Curly Bracket Ornament">❵</span>
    <span class="char" data-decimal="10098" data-entity="&amp;#10098;" data-id="43690" title="Light Left Tortoise Shell Bracket Ornament">❲</span>
    <span class="char" data-decimal="10099" data-entity="&amp;#10099;" data-id="43691" title="Light Right Tortoise Shell Bracket Ornament">❳</span>
    <span class="char" data-decimal="10647" data-entity="&amp;#10647;" data-id="45376" title="Left Black Tortoise Shell Bracket">⦗</span>
    <span class="char" data-decimal="10648" data-entity="&amp;#10648;" data-id="45377" title="Right Black Tortoise Shell Bracket">⦘</span>
    <span class="char" data-decimal="8261" data-entity="&amp;#8261;" data-id="46452" title="Left Square Bracket With Quill">⁅</span>
    <span class="char" data-decimal="8262" data-entity="&amp;#8262;" data-id="46453" title="Right Square Bracket With Quill">⁆</span>
    <span class="char" data-decimal="9001" data-entity="&amp;lang;" data-id="45983" title="Left Pointing Angle Bracket">〈</span>
    <span class="char" data-decimal="9002" data-entity="&amp;rang;" data-id="45984" title="Right Pointing Angle Bracket">〉</span>
    <span class="char" data-decimal="9180" data-entity="&amp;#9180;" data-id="46162" title="Top Parenthesis">⏜</span>
    <span class="char" data-decimal="9181" data-entity="&amp;#9181;" data-id="46163" title="Bottom Parenthesis">⏝</span>
    <span class="char" data-decimal="9182" data-entity="&amp;#9182;" data-id="46164" title="Top Curly Bracket">⏞</span>
    <span class="char" data-decimal="9183" data-entity="&amp;#9183;" data-id="46165" title="Bottom Curly Bracket">⏟</span>
    <span class="char" data-decimal="11809" data-entity="&amp;#11809;" data-id="46517" title="Right Vertical Bar With Quill">⸡</span>
    <span class="char" data-decimal="11808" data-entity="&amp;#11808;" data-id="46516" title="Left Vertical Bar With Quill">⸠</span>
    <span class="char" data-decimal="11810" data-entity="&amp;#11810;" data-id="46518" title="Top Left Half Bracket">⸢</span>
    <span class="char" data-decimal="11811" data-entity="&amp;#11811;" data-id="46519" title="Top Right Half Bracket">⸣</span>
    <span class="char" data-decimal="11812" data-entity="&amp;#11812;" data-id="46520" title="Bottom Left Half Bracket">⸤</span>
    <span class="char" data-decimal="11813" data-entity="&amp;#11813;" data-id="46521" title="Bottom Right Half Bracket">⸥</span>
    <span class="char" data-decimal="9121" data-entity="&amp;#9121;" data-id="46103" title="Left Square Bracket Upper Corner">⎡</span>
    <span class="char" data-decimal="9124" data-entity="&amp;#9124;" data-id="46106" title="Right Square Bracket Upper Corner">⎤</span>
    <span class="char" data-decimal="9123" data-entity="&amp;#9123;" data-id="46105" title="Left Square Bracket Lower Corner">⎣</span>
    <span class="char" data-decimal="9126" data-entity="&amp;#9126;" data-id="46108" title="Right Square Bracket Lower Corner">⎦</span>
    <span class="char" data-decimal="9128" data-entity="&amp;#9128;" data-id="46110" title="Left Curly Bracket Middle Piece">⎨</span>
    <span class="char" data-decimal="9132" data-entity="&amp;#9132;" data-id="46114" title="Right Curly Bracket Middle Piece">⎬</span>
    <span class="char" data-decimal="8992" data-entity="&amp;#8992;" data-id="45974" title="Top Half Integral">⌠</span>
    <span class="char" data-decimal="8993" data-entity="&amp;#8993;" data-id="45975" title="Bottom Half Integral">⌡</span>
    <span class="char" data-decimal="9115" data-entity="&amp;#9115;" data-id="46097" title="Left Parenthesis Upper Hook">⎛</span>
    <span class="char" data-decimal="9120" data-entity="&amp;#9120;" data-id="46102" title="Right Parenthesis Lower Hook">⎠</span>
    <span class="char" data-decimal="9117" data-entity="&amp;#9117;" data-id="46099" title="Left Parenthesis Lower Hook">⎝</span>
    <span class="char" data-decimal="9118" data-entity="&amp;#9118;" data-id="46100" title="Right Parenthesis Upper Hook">⎞</span>
    <span class="char" data-decimal="8256" data-entity="&amp;#8256;" data-id="46447" title="Character Tie">⁀</span>
    <span class="char" data-decimal="8276" data-entity="&amp;#8276;" data-id="46467" title="Inverted Undertie">⁔</span>
    <span class="char" data-decimal="8255" data-entity="&amp;#8255;" data-id="46446" title="Undertie">‿</span>
    <span class="char" data-decimal="8272" data-entity="&amp;#8272;" data-id="46463" title="Close Up">⁐</span>
    <span class="char" data-decimal="8254" data-entity="&amp;#8254;" data-id="46445" title="Overline">‾</span>
    <span class="char" data-decimal="9119" data-entity="&amp;#9119;" data-id="46101" title="Right Parenthesis Extension">⎟</span>
    <span class="char" data-decimal="9122" data-entity="&amp;#9122;" data-id="46104" title="Left Square Bracket Extension">⎢</span>
    <span class="char" data-decimal="9125" data-entity="&amp;#9125;" data-id="46107" title="Right Square Bracket Extension">⎥</span>
    <span class="char" data-decimal="9130" data-entity="&amp;#9130;" data-id="46112" title="Curly Bracket Extension">⎪</span>
    <span class="char" data-decimal="42881" data-entity="&amp;#42881;" data-id="45132" title="Latin Small Letter Turned L">ꞁ</span>
    <span class="char" data-decimal="9134" data-entity="&amp;#9134;" data-id="46116" title="Integral Extension">⎮</span>
    <span class="char" data-decimal="9127" data-entity="&amp;#9127;" data-id="46109" title="Left Curly Bracket Upper Hook">⎧</span>
    <span class="char" data-decimal="9131" data-entity="&amp;#9131;" data-id="46113" title="Right Curly Bracket Upper Hook">⎫</span>
    <span class="char" data-decimal="9129" data-entity="&amp;#9129;" data-id="46111" title="Left Curly Bracket Lower Hook">⎩</span>
    <span class="char" data-decimal="9133" data-entity="&amp;#9133;" data-id="46115" title="Right Curly Bracket Lower Hook">⎭</span>
    <span class="char" data-decimal="9136" data-entity="&amp;#9136;" data-id="46118" title="Upper Left Or Lower Right Curly Bracket Section">⎰</span>
    <span class="char" data-decimal="9137" data-entity="&amp;#9137;" data-id="46119" title="Upper Right Or Lower Left Curly Bracket Section">⎱</span>
    <span class="char" data-decimal="9992" data-entity="&amp;#9992;" data-id="43594" title="Airplane">✈</span>
    <span class="char" data-decimal="9728" data-entity="&amp;#9728;" data-id="45736" title="Black Sun With Rays">☀</span>
    <span class="char" data-decimal="9788" data-entity="&amp;#9788;" data-id="45795" title="White Sun With Rays">☼</span>
    <span class="char" data-decimal="9729" data-entity="&amp;#9729;" data-id="45737" title="Cloud">☁</span>
    <span class="char" data-decimal="9730" data-entity="&amp;#9730;" data-id="45738" title="Umbrella">☂</span>
    <span class="char" data-decimal="9748" data-entity="&amp;#9748;" data-id="45755" title="Umbrella With Rain Drops">☔</span>
    <span class="char" data-decimal="9889" data-entity="&amp;#9889;" data-id="45893" title="High Voltage Sign">⚡</span>
    <span class="char" data-decimal="10052" data-entity="&amp;#10052;" data-id="43651" title="Snowflake">❄</span>
    <span class="char" data-decimal="10053" data-entity="&amp;#10053;" data-id="43652" title="Tight Trifoliate Snowflake">❅</span>
    <span class="char" data-decimal="10054" data-entity="&amp;#10054;" data-id="43653" title="Heavy Chevron Snowflake">❆</span>
    <span class="char" data-decimal="9731" data-entity="&amp;#9731;" data-id="45739" title="Snowman">☃</span>
    <span class="char" data-decimal="9737" data-entity="&amp;#9737;" data-id="45745" title="Sun">☉</span>
    <span class="char" data-decimal="9732" data-entity="&amp;#9732;" data-id="45740" title="Comet">☄</span>
    <span class="char" data-decimal="9733" data-entity="&amp;#9733;" data-id="45741" title="Black Star">★</span>
    <span class="char" data-decimal="9734" data-entity="&amp;#9734;" data-id="45742" title="White Star">☆</span>
    <span class="char" data-decimal="9789" data-entity="&amp;#9789;" data-id="45796" title="First Quarter Moon">☽</span>
    <span class="char" data-decimal="9790" data-entity="&amp;#9790;" data-id="45797" title="Last Quarter Moon">☾</span>
    <span class="char" data-decimal="8987" data-entity="&amp;#8987;" data-id="45969" title="Hourglass">⌛</span>
    <span class="char" data-decimal="8986" data-entity="&amp;#8986;" data-id="45968" title="Watch">⌚</span>
    <span class="char" data-decimal="9735" data-entity="&amp;#9735;" data-id="45743" title="Lightning">☇</span>
    <span class="char" data-decimal="9736" data-entity="&amp;#9736;" data-id="45744" title="Thunderstorm">☈</span>
    <span class="char" data-decimal="8962" data-entity="&amp;#8962;" data-id="45944" title="House">⌂</span>
    <span class="char" data-decimal="8961" data-entity="&amp;#8961;" data-id="45943" title="Electrical Arrow">⌁</span>
    <span class="char" data-decimal="9191" data-entity="&amp;#9191;" data-id="46173" title="Electrical Intersection">⏧</span>
    <span class="char" data-decimal="9990" data-entity="&amp;#9990;" data-id="43592" title="Telephone Location Sign">✆</span>
    <span class="char" data-decimal="9742" data-entity="&amp;#9742;" data-id="45750" title="Black Telephone">☎</span>
    <span class="char" data-decimal="9743" data-entity="&amp;#9743;" data-id="45751" title="White Telephone">☏</span>
    <span class="char" data-decimal="9745" data-entity="&amp;#9745;" data-id="45752" title="Ballot Box With Check">☑</span>
    <span class="char" data-decimal="10003" data-entity="&amp;#10003;" data-id="43603" title="Check Mark">✓</span>
    <span class="char" data-decimal="10004" data-entity="&amp;#10004;" data-id="43604" title="Heavy Check Mark">✔</span>
    <span class="char" data-decimal="9143" data-entity="&amp;#9143;" data-id="46125" title="Radical Symbol Bottom">⎷</span>
    <span class="char" data-decimal="9083" data-entity="&amp;#9083;" data-id="46065" title="Not Check Mark">⍻</span>
    <span class="char" data-decimal="10006" data-entity="&amp;#10006;" data-id="43606" title="Heavy Multiplication X">✖</span>
    <span class="char" data-decimal="10007" data-entity="&amp;#10007;" data-id="43607" title="Ballot X">✗</span>
    <span class="char" data-decimal="10008" data-entity="&amp;#10008;" data-id="43608" title="Heavy Ballot X">✘</span>
    <span class="char" data-decimal="9746" data-entity="&amp;#9746;" data-id="45753" title="Ballot Box With X">☒</span>
    <span class="char" data-decimal="10005" data-entity="&amp;#10005;" data-id="43605" title="Multiplication X">✕</span>
    <span class="char" data-decimal="9747" data-entity="&amp;#9747;" data-id="45754" title="Saltire">☓</span>
    <span class="char" data-decimal="9749" data-entity="&amp;#9749;" data-id="45756" title="Hot Beverage">☕</span>
    <span class="char" data-decimal="9855" data-entity="&amp;#9855;" data-id="45862" title="Wheelchair Symbol">♿</span>
    <span class="char" data-decimal="9996" data-entity="&amp;#9996;" data-id="43596" title="Victory Hand">✌</span>
    <span class="char" data-decimal="9754" data-entity="&amp;#9754;" data-id="45761" title="Black Left Pointing Index">☚</span>
    <span class="char" data-decimal="9755" data-entity="&amp;#9755;" data-id="45762" title="Black Right Pointing Index">☛</span>
    <span class="char" data-decimal="9756" data-entity="&amp;#9756;" data-id="45763" title="White Left Pointing Index">☜</span>
    <span class="char" data-decimal="9757" data-entity="&amp;#9757;" data-id="45764" title="White Up Pointing Index">☝</span>
    <span class="char" data-decimal="9758" data-entity="&amp;#9758;" data-id="45765" title="White Right Pointing Index">☞</span>
    <span class="char" data-decimal="9759" data-entity="&amp;#9759;" data-id="45766" title="White Down Pointing Index">☟</span>
    <span class="char" data-decimal="9785" data-entity="&amp;#9785;" data-id="45792" title="White Frowning Face">☹</span>
    <span class="char" data-decimal="9786" data-entity="&amp;#9786;" data-id="45793" title="White Smiling Face">☺</span>
    <span class="char" data-decimal="9787" data-entity="&amp;#9787;" data-id="45794" title="Black Smiling Face">☻</span>
    <span class="char" data-decimal="9775" data-entity="&amp;#9775;" data-id="45782" title="Yin Yang">☯</span>
    <span class="char" data-decimal="9880" data-entity="&amp;#9880;" data-id="45887" title="Flower">⚘</span>
    <span class="char" data-decimal="9774" data-entity="&amp;#9774;" data-id="45781" title="Peace Symbol">☮</span>
    <span class="char" data-decimal="10013" data-entity="&amp;#10013;" data-id="43613" title="Latin Cross">✝</span>
    <span class="char" data-decimal="9904" data-entity="&amp;#9904;" data-id="45908" title="Coffin">⚰</span>
    <span class="char" data-decimal="9905" data-entity="&amp;#9905;" data-id="45909" title="Funeral Urn">⚱</span>
    <span class="char" data-decimal="9888" data-entity="&amp;#9888;" data-id="45892" title="Warning Sign">⚠</span>
    <span class="char" data-decimal="9760" data-entity="&amp;#9760;" data-id="45767" title="Skull And Crossbones">☠</span>
    <span class="char" data-decimal="9762" data-entity="&amp;#9762;" data-id="45769" title="Radioactive Sign">☢</span>
    <span class="char" data-decimal="9876" data-entity="&amp;#9876;" data-id="45883" title="Crossed Swords">⚔</span>
    <span class="char" data-decimal="9875" data-entity="&amp;#9875;" data-id="45882" title="Anchor">⚓</span>
    <span class="char" data-decimal="9096" data-entity="&amp;#9096;" data-id="46078" title="Helm Symbol">⎈</span>
    <span class="char" data-decimal="9874" data-entity="&amp;#9874;" data-id="45881" title="Hammer And Pick">⚒</span>
    <span class="char" data-decimal="9873" data-entity="&amp;#9873;" data-id="45880" title="Black Flag">⚑</span>
    <span class="char" data-decimal="9872" data-entity="&amp;#9872;" data-id="45879" title="White Flag">⚐</span>
    <span class="char" data-decimal="9761" data-entity="&amp;#9761;" data-id="45768" title="Caution Sign">☡</span>
    <span class="char" data-decimal="10050" data-entity="&amp;#10050;" data-id="43649" title="Circled Open Centre Eight Pointed Star">❂</span>
    <span class="char" data-decimal="9877" data-entity="&amp;#9877;" data-id="45884" title="Staff Of Aesculapius">⚕</span>
    <span class="char" data-decimal="9878" data-entity="&amp;#9878;" data-id="45885" title="Scales">⚖</span>
    <span class="char" data-decimal="9879" data-entity="&amp;#9879;" data-id="45886" title="Alembic">⚗</span>
    <span class="char" data-decimal="9991" data-entity="&amp;#9991;" data-id="43593" title="Tape Drive">✇</span>
    <span class="char" data-decimal="9763" data-entity="&amp;#9763;" data-id="45770" title="Biohazard Sign">☣</span>
    <span class="char" data-decimal="9881" data-entity="&amp;#9881;" data-id="45888" title="Gear">⚙</span>
    <span class="char" data-decimal="9764" data-entity="&amp;#9764;" data-id="45771" title="Caduceus">☤</span>
    <span class="char" data-decimal="9882" data-entity="&amp;#9882;" data-id="45889" title="Staff Of Hermes">⚚</span>
    <span class="char" data-decimal="9883" data-entity="&amp;#9883;" data-id="45890" title="Atom Symbol">⚛</span>
    <span class="char" data-decimal="9884" data-entity="&amp;#9884;" data-id="45891" title="Fleur De Lis">⚜</span>
    <span class="char" data-decimal="9765" data-entity="&amp;#9765;" data-id="45772" title="Ankh">☥</span>
    <span class="char" data-decimal="9766" data-entity="&amp;#9766;" data-id="45773" title="Orthodox Cross">☦</span>
    <span class="char" data-decimal="9767" data-entity="&amp;#9767;" data-id="45774" title="Chi Rho">☧</span>
    <span class="char" data-decimal="9768" data-entity="&amp;#9768;" data-id="45775" title="Cross Of Lorraine">☨</span>
    <span class="char" data-decimal="9769" data-entity="&amp;#9769;" data-id="45776" title="Cross Of Jerusalem">☩</span>
    <span class="char" data-decimal="8224" data-entity="&amp;#8224;" data-id="46422" title="Dagger">†</span>
    <span class="char" data-decimal="9770" data-entity="&amp;#9770;" data-id="45777" title="Star And Crescent">☪</span>
    <span class="char" data-decimal="9771" data-entity="&amp;#9771;" data-id="45778" title="Farsi Symbol">☫</span>
    <span class="char" data-decimal="9772" data-entity="&amp;#9772;" data-id="45779" title="Adi Shakti">☬</span>
    <span class="char" data-decimal="9773" data-entity="&amp;#9773;" data-id="45780" title="Hammer And Sickle">☭</span>
    <span class="char" data-decimal="9985" data-entity="&amp;#9985;" data-id="43588" title="Upper Blade Scissors">✁</span>
    <span class="char" data-decimal="9986" data-entity="&amp;#9986;" data-id="43589" title="Black Scissors">✂</span>
    <span class="char" data-decimal="9987" data-entity="&amp;#9987;" data-id="43590" title="Lower Blade Scissors">✃</span>
    <span class="char" data-decimal="9988" data-entity="&amp;#9988;" data-id="43591" title="White Scissors">✄</span>
    <span class="char" data-decimal="9997" data-entity="&amp;#9997;" data-id="43597" title="Writing Hand">✍</span>
    <span class="char" data-decimal="9998" data-entity="&amp;#9998;" data-id="43598" title="Lower Right Pencil">✎</span>
    <span class="char" data-decimal="9999" data-entity="&amp;#9999;" data-id="43599" title="Pencil">✏</span>
    <span class="char" data-decimal="10000" data-entity="&amp;#10000;" data-id="43600" title="Upper Right Pencil">✐</span>
    <span class="char" data-decimal="63490" data-entity="&amp;#63490;" data-id="46534" title=""></span>
    <span class="char" data-decimal="10001" data-entity="&amp;#10001;" data-id="43601" title="White Nib">✑</span>
    <span class="char" data-decimal="10002" data-entity="&amp;#10002;" data-id="43602" title="Black Nib">✒</span>
    <span class="char" data-decimal="9993" data-entity="&amp;#9993;" data-id="43595" title="Envelope">✉</span>
    <span class="char" data-decimal="10009" data-entity="&amp;#10009;" data-id="43609" title="Outlined Greek Cross">✙</span>
    <span class="char" data-decimal="10010" data-entity="&amp;#10010;" data-id="43610" title="Heavy Greek Cross">✚</span>
    <span class="char" data-decimal="10012" data-entity="&amp;#10012;" data-id="43612" title="Heavy Open Centre Cross">✜</span>
    <span class="char" data-decimal="10011" data-entity="&amp;#10011;" data-id="43611" title="Open Centre Cross">✛</span>
    <span class="char" data-decimal="9840" data-entity="&amp;#9840;" data-id="45847" title="West Syriac Cross">♰</span>
    <span class="char" data-decimal="9841" data-entity="&amp;#9841;" data-id="45848" title="East Syriac Cross">♱</span>
    <span class="char" data-decimal="10014" data-entity="&amp;#10014;" data-id="43614" title="Shadowed White Latin Cross">✞</span>
    <span class="char" data-decimal="10015" data-entity="&amp;#10015;" data-id="43615" title="Outlined Latin Cross">✟</span>
    <span class="char" data-decimal="10016" data-entity="&amp;#10016;" data-id="43616" title="Maltese Cross">✠</span>
    <span class="char" data-decimal="10017" data-entity="&amp;#10017;" data-id="43617" title="Star Of David">✡</span>
    <span class="char" data-decimal="9784" data-entity="&amp;#9784;" data-id="45791" title="Wheel Of Dharma">☸</span>
    <span class="char" data-decimal="10018" data-entity="&amp;#10018;" data-id="43618" title="Four Teardrop Spoked Asterisk">✢</span>
    <span class="char" data-decimal="10019" data-entity="&amp;#10019;" data-id="43619" title="Four Balloon Spoked Asterisk">✣</span>
    <span class="char" data-decimal="10020" data-entity="&amp;#10020;" data-id="43620" title="Heavy Four Balloon Spoked Asterisk">✤</span>
    <span class="char" data-decimal="10021" data-entity="&amp;#10021;" data-id="43621" title="Four Club Spoked Asterisk">✥</span>
    <span class="char" data-decimal="10022" data-entity="&amp;#10022;" data-id="43622" title="Black Four Pointed Star">✦</span>
    <span class="char" data-decimal="10023" data-entity="&amp;#10023;" data-id="43623" title="White Four Pointed Star">✧</span>
    <span class="char" data-decimal="10025" data-entity="&amp;#10025;" data-id="43624" title="Stress Outlined White Star">✩</span>
    <span class="char" data-decimal="10026" data-entity="&amp;#10026;" data-id="43625" title="Circled White Star">✪</span>
    <span class="char" data-decimal="10027" data-entity="&amp;#10027;" data-id="43626" title="Open Centre Black Star">✫</span>
    <span class="char" data-decimal="10028" data-entity="&amp;#10028;" data-id="43627" title="Black Centre White Star">✬</span>
    <span class="char" data-decimal="10029" data-entity="&amp;#10029;" data-id="43628" title="Outlined Black Star">✭</span>
    <span class="char" data-decimal="10030" data-entity="&amp;#10030;" data-id="43629" title="Heavy Outlined Black Star">✮</span>
    <span class="char" data-decimal="10031" data-entity="&amp;#10031;" data-id="43630" title="Pinwheel Star">✯</span>
    <span class="char" data-decimal="10032" data-entity="&amp;#10032;" data-id="43631" title="Shadowed White Star">✰</span>
    <span class="char" data-decimal="10034" data-entity="&amp;#10034;" data-id="43633" title="Open Centre Asterisk">✲</span>
    <span class="char" data-decimal="10033" data-entity="&amp;#10033;" data-id="43632" title="Heavy Asterisk">✱</span>
    <span class="char" data-decimal="10035" data-entity="&amp;#10035;" data-id="43634" title="Eight Spoked Asterisk">✳</span>
    <span class="char" data-decimal="10036" data-entity="&amp;#10036;" data-id="43635" title="Eight Pointed Black Star">✴</span>
    <span class="char" data-decimal="10037" data-entity="&amp;#10037;" data-id="43636" title="Eight Pointed Pinwheel Star">✵</span>
    <span class="char" data-decimal="10038" data-entity="&amp;#10038;" data-id="43637" title="Six Pointed Black Star">✶</span>
    <span class="char" data-decimal="10039" data-entity="&amp;#10039;" data-id="43638" title="Eight Pointed Rectilinear Black Star">✷</span>
    <span class="char" data-decimal="10040" data-entity="&amp;#10040;" data-id="43639" title="Heavy Eight Pointed Rectilinear Black Star">✸</span>
    <span class="char" data-decimal="10041" data-entity="&amp;#10041;" data-id="43640" title="Twelve Pointed Black Star">✹</span>
    <span class="char" data-decimal="10042" data-entity="&amp;#10042;" data-id="43641" title="Sixteen Pointed Asterisk">✺</span>
    <span class="char" data-decimal="10043" data-entity="&amp;#10043;" data-id="43642" title="Teardrop Spoked Asterisk">✻</span>
    <span class="char" data-decimal="10044" data-entity="&amp;#10044;" data-id="43643" title="Open Centre Teardrop Spoked Asterisk">✼</span>
    <span class="char" data-decimal="10045" data-entity="&amp;#10045;" data-id="43644" title="Heavy Teardrop Spoked Asterisk">✽</span>
    <span class="char" data-decimal="10046" data-entity="&amp;#10046;" data-id="43645" title="Six Petalled Black And White Florette">✾</span>
    <span class="char" data-decimal="10048" data-entity="&amp;#10048;" data-id="43647" title="White Florette">❀</span>
    <span class="char" data-decimal="10047" data-entity="&amp;#10047;" data-id="43646" title="Black Florette">✿</span>
    <span class="char" data-decimal="10049" data-entity="&amp;#10049;" data-id="43648" title="Eight Petalled Outlined Black Florette">❁</span>
    <span class="char" data-decimal="10051" data-entity="&amp;#10051;" data-id="43650" title="Heavy Teardrop Spoked Pinwheel Asterisk">❃</span>
    <span class="char" data-decimal="10055" data-entity="&amp;#10055;" data-id="43654" title="Sparkle">❇</span>
    <span class="char" data-decimal="10056" data-entity="&amp;#10056;" data-id="43655" title="Heavy Sparkle">❈</span>
    <span class="char" data-decimal="10057" data-entity="&amp;#10057;" data-id="43656" title="Balloon Spoked Asterisk">❉</span>
    <span class="char" data-decimal="10058" data-entity="&amp;#10058;" data-id="43657" title="Eight Teardrop Spoked Propeller Asterisk">❊</span>
    <span class="char" data-decimal="10059" data-entity="&amp;#10059;" data-id="43658" title="Heavy Eight Teardrop Spoked Propeller Asterisk">❋</span>
    <span class="char" data-decimal="8277" data-entity="&amp;#8277;" data-id="46468" title="Flower Punctuation Mark">⁕</span>
    <span class="char" data-decimal="9752" data-entity="&amp;#9752;" data-id="45759" title="Shamrock">☘</span>
    <span class="char" data-decimal="10086" data-entity="&amp;#10086;" data-id="43678" title="Floral Heart">❦</span>
    <span class="char" data-decimal="10087" data-entity="&amp;#10087;" data-id="43679" title="Rotated Floral Heart Bullet">❧</span>
    <span class="char" data-decimal="9753" data-entity="&amp;#9753;" data-id="45760" title="Reversed Rotated Floral Heart Bullet">☙</span>
    <span class="char" data-decimal="10082" data-entity="&amp;#10082;" data-id="43674" title="Heavy Exclamation Mark Ornament">❢</span>
    <span class="char" data-decimal="10083" data-entity="&amp;#10083;" data-id="43675" title="Heavy Heart Exclamation Mark Ornament">❣</span>
    <span class="char" data-decimal="9792" data-entity="&amp;#9792;" data-id="45799" title="Female Sign">♀</span>
    <span class="char" data-decimal="9794" data-entity="&amp;#9794;" data-id="45801" title="Male Sign">♂</span>
    <span class="char" data-decimal="9906" data-entity="&amp;#9906;" data-id="45910" title="Neuter">⚲</span>
    <span class="char" data-decimal="9890" data-entity="&amp;#9890;" data-id="45894" title="Doubled Female Sign">⚢</span>
    <span class="char" data-decimal="9891" data-entity="&amp;#9891;" data-id="45895" title="Doubled Male Sign">⚣</span>
    <span class="char" data-decimal="9892" data-entity="&amp;#9892;" data-id="45896" title="Interlocked Female And Male Sign">⚤</span>
    <span class="char" data-decimal="9893" data-entity="&amp;#9893;" data-id="45897" title="Male And Female Sign">⚥</span>
    <span class="char" data-decimal="9894" data-entity="&amp;#9894;" data-id="45898" title="Male With Stroke Sign">⚦</span>
    <span class="char" data-decimal="9895" data-entity="&amp;#9895;" data-id="45899" title="Male With Stroke And Male And Female Sign">⚧</span>
    <span class="char" data-decimal="9896" data-entity="&amp;#9896;" data-id="45900" title="Vertical Male With Stroke Sign">⚨</span>
    <span class="char" data-decimal="9897" data-entity="&amp;#9897;" data-id="45901" title="Horizontal Male With Stroke Sign">⚩</span>
    <span class="char" data-decimal="9791" data-entity="&amp;#9791;" data-id="45798" title="Mercury">☿</span>
    <span class="char" data-decimal="9793" data-entity="&amp;#9793;" data-id="45800" title="Earth">♁</span>
    <span class="char" data-decimal="9903" data-entity="&amp;#9903;" data-id="45907" title="Unmarried Partnership Symbol">⚯</span>
    <span class="char" data-decimal="9812" data-entity="&amp;#9812;" data-id="45819" title="White Chess King">♔</span>
    <span class="char" data-decimal="9813" data-entity="&amp;#9813;" data-id="45820" title="White Chess Queen">♕</span>
    <span class="char" data-decimal="9814" data-entity="&amp;#9814;" data-id="45821" title="White Chess Rook">♖</span>
    <span class="char" data-decimal="9815" data-entity="&amp;#9815;" data-id="45822" title="White Chess Bishop">♗</span>
    <span class="char" data-decimal="9816" data-entity="&amp;#9816;" data-id="45823" title="White Chess Knight">♘</span>
    <span class="char" data-decimal="9817" data-entity="&amp;#9817;" data-id="45824" title="White Chess Pawn">♙</span>
    <span class="char" data-decimal="9818" data-entity="&amp;#9818;" data-id="45825" title="Black Chess King">♚</span>
    <span class="char" data-decimal="9819" data-entity="&amp;#9819;" data-id="45826" title="Black Chess Queen">♛</span>
    <span class="char" data-decimal="9820" data-entity="&amp;#9820;" data-id="45827" title="Black Chess Rook">♜</span>
    <span class="char" data-decimal="9821" data-entity="&amp;#9821;" data-id="45828" title="Black Chess Bishop">♝</span>
    <span class="char" data-decimal="9822" data-entity="&amp;#9822;" data-id="45829" title="Black Chess Knight">♞</span>
    <span class="char" data-decimal="9823" data-entity="&amp;#9823;" data-id="45830" title="Black Chess Pawn">♟</span>
    <span class="char" data-decimal="9750" data-entity="&amp;#9750;" data-id="45757" title="White Shogi Piece">☖</span>
    <span class="char" data-decimal="9751" data-entity="&amp;#9751;" data-id="45758" title="Black Shogi Piece">☗</span>
    <span class="char" data-decimal="9824" data-entity="&amp;spades;" data-id="45831" title="Black Spade Suit">♠</span>
    <span class="char" data-decimal="9827" data-entity="&amp;clubs;" data-id="45834" title="Black Club Suit">♣</span>
    <span class="char" data-decimal="9830" data-entity="&amp;diams;" data-id="45837" title="Black Diamond Suit">♦</span>
    <span class="char" data-decimal="9829" data-entity="&amp;hearts;" data-id="45836" title="Black Heart Suit">♥</span>
    <span class="char" data-decimal="10084" data-entity="&amp;#10084;" data-id="43676" title="Heavy Black Heart">❤</span>
    <span class="char" data-decimal="10085" data-entity="&amp;#10085;" data-id="43677" title="Rotated Heavy Black Heart Bullet">❥</span>
    <span class="char" data-decimal="9825" data-entity="&amp;#9825;" data-id="45832" title="White Heart Suit">♡</span>
    <span class="char" data-decimal="9826" data-entity="&amp;#9826;" data-id="45833" title="White Diamond Suit">♢</span>
    <span class="char" data-decimal="9828" data-entity="&amp;#9828;" data-id="45835" title="White Spade Suit">♤</span>
    <span class="char" data-decimal="9831" data-entity="&amp;#9831;" data-id="45838" title="White Club Suit">♧</span>
    <span class="char" data-decimal="9856" data-entity="&amp;#9856;" data-id="45863" title="Die Face 1">⚀</span>
    <span class="char" data-decimal="9857" data-entity="&amp;#9857;" data-id="45864" title="Die Face 2">⚁</span>
    <span class="char" data-decimal="9858" data-entity="&amp;#9858;" data-id="45865" title="Die Face 3">⚂</span>
    <span class="char" data-decimal="9859" data-entity="&amp;#9859;" data-id="45866" title="Die Face 4">⚃</span>
    <span class="char" data-decimal="9860" data-entity="&amp;#9860;" data-id="45867" title="Die Face 5">⚄</span>
    <span class="char" data-decimal="9861" data-entity="&amp;#9861;" data-id="45868" title="Die Face 6">⚅</span>
    <span class="char" data-decimal="9863" data-entity="&amp;#9863;" data-id="45870" title="White Circle With Two Dots">⚇</span>
    <span class="char" data-decimal="9862" data-entity="&amp;#9862;" data-id="45869" title="White Circle With Dot Right">⚆</span>
    <span class="char" data-decimal="9864" data-entity="&amp;#9864;" data-id="45871" title="Black Circle With White Dot Right">⚈</span>
    <span class="char" data-decimal="9865" data-entity="&amp;#9865;" data-id="45872" title="Black Circle With Two White Dots">⚉</span>
    <span class="char" data-decimal="9832" data-entity="&amp;#9832;" data-id="45839" title="Hot Springs">♨</span>
    <span class="char" data-decimal="9833" data-entity="&amp;#9833;" data-id="45840" title="Quarter Note">♩</span>
    <span class="char" data-decimal="9834" data-entity="&amp;#9834;" data-id="45841" title="Eighth Note">♪</span>
    <span class="char" data-decimal="9835" data-entity="&amp;#9835;" data-id="45842" title="Beamed Eighth Notes">♫</span>
    <span class="char" data-decimal="9836" data-entity="&amp;#9836;" data-id="45843" title="Beamed Sixteenth Notes">♬</span>
    <span class="char" data-decimal="9837" data-entity="&amp;#9837;" data-id="45844" title="Music Flat Sign">♭</span>
    <span class="char" data-decimal="9838" data-entity="&amp;#9838;" data-id="45845" title="Music Natural Sign">♮</span>
    <span class="char" data-decimal="9839" data-entity="&amp;#9839;" data-id="45846" title="Music Sharp Sign">♯</span>
    <span class="char" data-decimal="63743" data-entity="&amp;#63743;" data-id="46533" title=""></span>
    <span class="char" data-decimal="9000" data-entity="&amp;#9000;" data-id="45982" title="Keyboard">⌨</span>
    <span class="char" data-decimal="9167" data-entity="&amp;#9167;" data-id="46149" title="Eject Symbol">⏏</span>
    <span class="char" data-decimal="9111" data-entity="&amp;#9111;" data-id="46093" title="Previous Page">⎗</span>
    <span class="char" data-decimal="9112" data-entity="&amp;#9112;" data-id="46094" title="Next Page">⎘</span>
    <span class="char" data-decimal="9113" data-entity="&amp;#9113;" data-id="46095" title="Print Screen Symbol">⎙</span>
    <span class="char" data-decimal="9114" data-entity="&amp;#9114;" data-id="46096" title="Clear Screen Symbol">⎚</span>
    <span class="char" data-decimal="8997" data-entity="&amp;#8997;" data-id="45979" title="Option Key">⌥</span>
    <span class="char" data-decimal="9095" data-entity="&amp;#9095;" data-id="46077" title="Alternative Key Symbol">⎇</span>
    <span class="char" data-decimal="8984" data-entity="&amp;#8984;" data-id="45966" title="Place Of Interest Sign">⌘</span>
    <span class="char" data-decimal="8998" data-entity="&amp;#8998;" data-id="45980" title="Erase To The Right">⌦</span>
    <span class="char" data-decimal="9003" data-entity="&amp;#9003;" data-id="45985" title="Erase To The Left">⌫</span>
    <span class="char" data-decimal="8999" data-entity="&amp;#8999;" data-id="45981" title="X In A Rectangle Box">⌧</span>
    <span class="char" data-decimal="9842" data-entity="&amp;#9842;" data-id="45849" title="Universal Recycling Symbol">♲</span>
    <span class="char" data-decimal="9843" data-entity="&amp;#9843;" data-id="45850" title="Recycling Symbol For Type 1 Plastics">♳</span>
    <span class="char" data-decimal="9844" data-entity="&amp;#9844;" data-id="45851" title="Recycling Symbol For Type 2 Plastics">♴</span>
    <span class="char" data-decimal="9845" data-entity="&amp;#9845;" data-id="45852" title="Recycling Symbol For Type 3 Plastics">♵</span>
    <span class="char" data-decimal="9846" data-entity="&amp;#9846;" data-id="45853" title="Recycling Symbol For Type 4 Plastics">♶</span>
    <span class="char" data-decimal="9847" data-entity="&amp;#9847;" data-id="45854" title="Recycling Symbol For Type 5 Plastics">♷</span>
    <span class="char" data-decimal="9848" data-entity="&amp;#9848;" data-id="45855" title="Recycling Symbol For Type 6 Plastics">♸</span>
    <span class="char" data-decimal="9849" data-entity="&amp;#9849;" data-id="45856" title="Recycling Symbol For Type 7 Plastics">♹</span>
    <span class="char" data-decimal="9850" data-entity="&amp;#9850;" data-id="45857" title="Recycling Symbol For Generic Materials">♺</span>
    <span class="char" data-decimal="9851" data-entity="&amp;#9851;" data-id="45858" title="Black Universal Recycling Symbol">♻</span>
    <span class="char" data-decimal="9852" data-entity="&amp;#9852;" data-id="45859" title="Recycled Paper Symbol">♼</span>
    <span class="char" data-decimal="9853" data-entity="&amp;#9853;" data-id="45860" title="Partially Recycled Paper Symbol">♽</span>
    <span class="char" data-decimal="8268" data-entity="&amp;#8268;" data-id="46459" title="Black Leftwards Bullet">⁌</span>
    <span class="char" data-decimal="8269" data-entity="&amp;#8269;" data-id="46460" title="Black Rightwards Bullet">⁍</span>
    <span class="char" data-decimal="9100" data-entity="&amp;#9100;" data-id="46082" title="Undo Symbol">⎌</span>
    <span class="char" data-decimal="8967" data-entity="&amp;#8967;" data-id="45949" title="Wavy Line">⌇</span>
    <span class="char" data-decimal="9010" data-entity="&amp;#9010;" data-id="45992" title="Conical Taper">⌲</span>
    <span class="char" data-decimal="9053" data-entity="&amp;#9053;" data-id="46035" title="Apl Functional Symbol Up Shoe Jot">⍝</span>
    <span class="char" data-decimal="9055" data-entity="&amp;#9055;" data-id="46037" title="Apl Functional Symbol Circle Star">⍟</span>
    <span class="char" data-decimal="9059" data-entity="&amp;#9059;" data-id="46041" title="Apl Functional Symbol Star Diaeresis">⍣</span>
    <span class="char" data-decimal="9060" data-entity="&amp;#9060;" data-id="46042" title="Apl Functional Symbol Jot Diaeresis">⍤</span>
    <span class="char" data-decimal="9061" data-entity="&amp;#9061;" data-id="46043" title="Apl Functional Symbol Circle Diaeresis">⍥</span>
    <span class="char" data-decimal="9064" data-entity="&amp;#9064;" data-id="46046" title="Apl Functional Symbol Tilde Diaeresis">⍨</span>
    <span class="char" data-decimal="9065" data-entity="&amp;#9065;" data-id="46047" title="Apl Functional Symbol Greater Than Diaeresis">⍩</span>
    <span class="char" data-decimal="9099" data-entity="&amp;#9099;" data-id="46081" title="Broken Circle With Northwest Arrow">⎋</span>
    <span class="char" data-decimal="9795" data-entity="&amp;#9795;" data-id="45802" title="Jupiter">♃</span>
    <span class="char" data-decimal="9796" data-entity="&amp;#9796;" data-id="45803" title="Saturn">♄</span>
    <span class="char" data-decimal="9797" data-entity="&amp;#9797;" data-id="45804" title="Uranus">♅</span>
    <span class="char" data-decimal="9798" data-entity="&amp;#9798;" data-id="45805" title="Neptune">♆</span>
    <span class="char" data-decimal="9799" data-entity="&amp;#9799;" data-id="45806" title="Pluto">♇</span>
    <span class="char" data-decimal="9800" data-entity="&amp;#9800;" data-id="45807" title="Aries">♈</span>
    <span class="char" data-decimal="9801" data-entity="&amp;#9801;" data-id="45808" title="Taurus">♉</span>
    <span class="char" data-decimal="9802" data-entity="&amp;#9802;" data-id="45809" title="Gemini">♊</span>
    <span class="char" data-decimal="9803" data-entity="&amp;#9803;" data-id="45810" title="Cancer">♋</span>
    <span class="char" data-decimal="9804" data-entity="&amp;#9804;" data-id="45811" title="Leo">♌</span>
    <span class="char" data-decimal="9805" data-entity="&amp;#9805;" data-id="45812" title="Virgo">♍</span>
    <span class="char" data-decimal="9806" data-entity="&amp;#9806;" data-id="45813" title="Libra">♎</span>
    <span class="char" data-decimal="9807" data-entity="&amp;#9807;" data-id="45814" title="Scorpius">♏</span>
    <span class="char" data-decimal="9808" data-entity="&amp;#9808;" data-id="45815" title="Sagittarius">♐</span>
    <span class="char" data-decimal="9809" data-entity="&amp;#9809;" data-id="45816" title="Capricorn">♑</span>
    <span class="char" data-decimal="9810" data-entity="&amp;#9810;" data-id="45817" title="Aquarius">♒</span>
    <span class="char" data-decimal="9811" data-entity="&amp;#9811;" data-id="45818" title="Pisces">♓</span>
    <span class="char" data-decimal="9178" data-entity="&amp;#9178;" data-id="46160" title="Earth Ground">⏚</span>
    <span class="char" data-decimal="9179" data-entity="&amp;#9179;" data-id="46161" title="Fuse">⏛</span>
  </div>

</mat-dialog-content>

<div class="modal-footer">
  <p style="color: red; width: 29em;">Você pode copiar e colar os símbolos/caracteres especiais listados a cima no editor de texto.</p>
  <button mat-raised-button mat-dialog-close type="button" id="cancel">Cancelar</button>

</div>
