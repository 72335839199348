<h4 mat-dialog-title ngClass="modal-toolbar-title primary">
  ANEXAR
  <span (click)="onClose()">
    <mat-icon class="icon-pattern">close</mat-icon>
  </span>
</h4>
<mat-dialog-content class="content-dialog">
  <form [formGroup]="attachmentForm" #formDirective="ngForm">
    <div class="form-pattern flex">
      <mat-form-field class="half-input">
        <input matInput formControlName="file_description" type="text" placeholder="Descrição do Arquivo" required>
        <mat-error *ngIf="attachmentForm.get('file_description').errors && attachmentForm.get('file_description').touched">
          {{attachmentForm.get('file_description').errors['message']}}</mat-error>
      </mat-form-field>


      <div class="half-input-attachment-button flex">
          <button [disabled]="!attachmentForm.get('file_description').value" id="upload-fake" class="secondary primary-text attach-btn" mat-raised-button type="button"
          (click)="uploadFile.click()" color="primary" matTooltip=".doc, .docx, .pdf, .txt, .xls, .xlsx, .jpg, .jpeg, .png, .zip, .dwg, .rar, .mp4"
          matTooltipPosition="right"><mat-icon>attach_file</mat-icon></button>

        <input type="file" id="file-input" class="select_file" formControlName="selected_file"
          (change)="onUploadMedia($event)"
          accept=".doc, .docx, .pdf, .txt, .xls, .xlsx, .jpg, .jpeg, .png, .zip, .dwg, .rar, .mp4" style="display:none;"
          #uploadFile />
        <div class="attachment-filename" *ngIf="filesToAttachMeta[0]?.filename" matTooltip="{{ filesToAttachMeta[0]?.filename }}">{{ reduceToView(filesToAttachMeta[0]?.filename) }}</div>
      </div>
    </div>
  </form>
</mat-dialog-content>

<div class="modal-footer">
  <!-- <button type="button" mat-raised-button (click)="onClose()">
    Cancelar
  </button> -->

  <button mat-raised-button class="add-attachment-btn primary" color="accent" [disabled]="attachmentForm.invalid"
    (click)="attachFiles()">Incluir</button>
</div>
