import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtServiceUtil } from './jwt-service-util.';

/**
 * Settings
 */
import { environment } from '@env/environment';

/**
 * RxJs
 */
import { BehaviorSubject ,  Observable } from 'rxjs';



@Injectable()
export class AuthenticationService {
  headersToAuth: HttpHeaders;
  urlToAuth = environment.urlToOauthToken;
  urlToApi = environment.urlToApi;
  user: any;
  bkp_token: any;
  routeCount: any = 0;
  permissions = [];

  constructor(
    private http: HttpClient,
  ) {
    this.setHeaders();
  }

  setHeaders(){
    const application = sessionStorage.getItem('application');
    const accessToken = sessionStorage.getItem('access_token');
    let headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Access-Control-Allow-Origin', '*');
    if(application && JSON.parse(application) != null){
      this.headersToAuth = headers
      .set('Authorization', String(accessToken))
      .set('event', String(JSON.parse(application)?.id));
    } else if(accessToken && accessToken.length > 0){
      this.headersToAuth = headers
      .set('Authorization', String(accessToken));
    } else {
      this.headersToAuth = headers;
    }
  }

  // Observable  starts
  setUser = () => new Promise((resolve, reject) => {
    this.setHeaders();

    this.http
    .get(this.urlToApi + 'user', { 'headers': this.headersToAuth })
    .subscribe(res => {

      this.user = res;

      if (this.user.id) {
        resolve(this.user);
        sessionStorage.setItem('user_id', this.user.id);
        sessionStorage.setItem('user', JSON.stringify(this.user));
      } else {
        resolve(false);
      }
    }, error => {
      resolve(false);
    });
  })

  getUser = () => {
    return this.user;
  }
  // Observable  ends
  getUserMenu = (param) => new Promise((resolve, reject) => {
    this.setHeaders();

    this.http.post(this.urlToApi + param.route, param.data, { 'headers': this.headersToAuth })
    .subscribe(res => {
      let obj;
      obj = res;
      resolve({
        obj
      });
    }, err => {
    });
  })

  login = (params) => new Promise((resolve, reject) => {
    let temp;

    this.setHeaders();

    this.http
    .post<any>(
      this.urlToAuth,
      {
        'client_id': 2,
        // 'client_secret': '5mmLQ5HgTDWlz9EEKfZ7hd70Hhjq1SMuudzdX97i',
        'client_secret': 'lwDUCNz21khDgGzbpoucyRE6cRWLyyYGZHoWKkQG',
        'grant_type': 'password',
        'username': params.login.value,
        'password': params.password.value
      }, { 'headers': this.headersToAuth }
    ).subscribe(res => {
      if (res.access_token) {
        // temp = res;
        sessionStorage.setItem('access_token', 'Bearer ' + res.access_token);
        sessionStorage.setItem('application', JSON.stringify(res.application));
        sessionStorage.setItem('user', JSON.stringify(res.user));
        sessionStorage.setItem('accept_terms', res.accept_terms);
        sessionStorage.setItem('privileges', res.privileges);

        resolve({
          cod: 200,
          message: 'Login feito com sucesso'
        });
      }
    }, err => {
      switch (err.status) {
        case 400:
        case 401:
          resolve({
            cod: err.status,
            message: 'CPF e/ou senha incorreto(s).'
          });
          break;
        case 403:
          resolve({
            cod: err.status,
            message: 'Usuário está inativo, entre em contato com seu interlocutor.'
          });
          break;
        case 404:
          resolve({
            cod: err.status,
            message: 'Servidor de autenticação não encontrado.'
          });
          break;
        case 0:
        case 504:
          resolve({
            cod: err.status,
            message: 'Servidor não está respondendo.'
          });
          break;

        default:
          console.log('Erro retornado da API', err);
          resolve({
            cod: err.status,
            message: err.statusText ?? 'Falha no servidor.'
          });
          break;
      }
    }, () => {

    });
  })

  logout(){
    sessionStorage.clear();
  }

  simulatePermission = (params) => new Promise((resolve, reject) => {
    this.bkp_token = sessionStorage.getItem('access_token');
    sessionStorage.setItem('bkp_access_token', 'Bearer ' + this.bkp_token);

    if (!params.route) { // Verifica se pelo menos uma child foi definida
      reject({
        cod: 'c-01',
        message: 'Informar erro c-01 ao administrador'
      });
    }

    this.setHeaders();
  })

  recoverPasswordEmail = (cpf_number, birth_date) => new Promise((resolve, reject) => {
    let emailToResetPassword;
    emailToResetPassword = {
      cpf_number: cpf_number,
      birth_date: birth_date
    };
    this.http
    .post(
      this.urlToApi + 'password/reset-password',
      emailToResetPassword
    ).subscribe(res => {
      resolve({
        return: res
      });
    }, err => resolve({return: err['_body']}),
    () => console.log(205));
  })

  getPermissions(route, user) {
    this.user = user;
    this.permissions = [];
    const menu_rules = [];
    this.user.access_profiles.map( a => {
      a.menu_rules.map( mr => {
        if (mr.menu[0] && menu_rules.filter( i => i.name === mr.name).length === 0 && mr.rule[0].name !== 'Menu') {
          if (!this.hasAForcedRemovedRule(mr)) {
            menu_rules.push(mr);
          }
        }
      });
    });

    this.user.menu_rules.map(mr => {
      if (mr.menu[0] && menu_rules.filter( i => i.name === mr.name).length === 0 && mr.rule[0].name !== 'Menu' &&
      mr.pivot.modification === 'ADDED') {
        menu_rules.push(mr);
      }
    });

    menu_rules.filter( m => {
      if (m.menu[0].route === route) { this.permissions.push(m.rule[0].name ); }
    });
    return this.permissions;
  }

  hasAForcedRemovedRule(rule) {
    return this.user.menu_rules.filter(mr => mr.menu[0].route === rule.menu[0].route
      && mr.pivot.modification === 'REMOVED' && mr.name === rule.name).length > 0;
  }
}
