import { Component, OnInit, Output, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '@env/environment';

/*Services*/
import { ToastrService } from 'ngx-toastr';
import { ImportService } from '@services/laravel/import.service';

@Component({
  selector: 'ntm-import-schools',
  templateUrl: './import-schools.component.html',
  styleUrls: ['./import-schools.component.scss']
})
export class ImportSchoolsComponent implements OnInit {
  @Output()
  public importing = false;
  public importSchoolsForm: UntypedFormGroup;
  public importErrors;
  public schoolsImported;

  public url = environment.urlToApiPreviewFiles;

  constructor(
    public dialogRef: MatDialogRef<ImportSchoolsComponent>,
    private _import: ImportService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService
  ) {
    this.importSchoolsForm = new UntypedFormGroup({
      'import_file': new UntypedFormControl('', [Validators.required])
    });
  }

  ngOnInit() {
  }

  getMedia(e) {
    let file = e.target ? e.target.files[0] : e[0];
    if (['.csv'].some(x => file.name.indexOf(x) >= 0)) {
      this.importSchoolsForm.get('import_file').setValue(file);
    } else {
      this.toastr.error('Formato invalido!', 'Erro!');
    }
  }

  deleteFile() {
    setTimeout(_ => this.importSchoolsForm.get('import_file').setValue(null));
  }

  close = () => {
    this.dialogRef.close();
  }

  closeError(){
    this.importErrors = null;
    this.schoolsImported = null;
  }

  importSchools = () => {
    this.importing = true;
    this.closeError();

    let formData = new FormData();
    formData.append('import_file', this.importSchoolsForm.value.import_file);

    this._import.post('school/import', formData)
    .then((res: any) => { 
      this.schoolsImported = res.school_imported;

      if (res.status == 201) {
        this.toastr.warning('Houveram erros durante a importação, confira os detalhes na tela.', 'Atenção!');
        this.importErrors = res.errors;
      } else {
        this.toastr.success(this.schoolsImported + ' escola(s) importada(s)!', 'Sucesso!');
        this.dialogRef.close(res);
      }

      this.importing = false;
    }).catch((err: any) => {
      let error = [];
      
      for (const key in err.errors) {
        const element = err.errors[key];
        error.push(element);
      }
      this.importErrors = error.join("<br>");
      this.importing = false;
      this.toastr.error(err.message, 'Sucesso!');
    });
  }
}
