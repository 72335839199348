import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Inject,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '@env/environment';

/*Services*/
import { RegisterComponent } from '../../../../../app/components/register/register.component';
import { AuthenticationService } from '@services/laravel/authentication.service';
import { ValidateCpf } from '@shared/validators/cpf.validator';
import { ToastrService } from 'ngx-toastr';
import { ValidateRequired } from '@shared/validators/required.validator';

interface RecoverPasswordEmailResponse {
  return: RecoverPasswordEmailResponseData;
}

interface RecoverPasswordEmailResponseData {
  data: null;
  finded: boolean;
  send_email: boolean;
  email: string;
}

@Component({
  selector: 'ntm-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent implements OnInit {
  @Output()
  change: EventEmitter<string> = new EventEmitter<string>();
  // @ViewChild(ReCaptchaComponent, { static: false }) captcha: ReCaptchaComponent;

  public user_email: any;
  public new_password: any;
  public errorMsg: any;
  public mask: any;
  public generatedPassword: any = false;
  isLoading = false;
  feedback: RecoverPasswordEmailResponseData;

  forgotPasswordForm: UntypedFormGroup;
  constructor(
    private authentication: AuthenticationService,
    public dialogRef: MatDialogRef<ForgotPasswordComponent>,

    public mdsnackbar: MatSnackBar,
    private dialog: MatDialog,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.forgotPasswordForm = new UntypedFormGroup({
      cpf: new UntypedFormControl(null, [ValidateCpf, Validators.required]),
      birthday: new UntypedFormControl(null, [Validators.required]),
      captcha: new UntypedFormControl(null, ValidateRequired),
    });
  }

  ngOnInit() {
    this.mask = {
      cpf: [
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        '.',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        '.',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        '-',
        /[0-9]/,
        /[0-9]/,
      ],
      date: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
      zip: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
      phone: [
        '(',
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ],
      cell_phone: [
        '(',
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ],
      cnpj: [
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '/',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ],
    };

    this.removeCaptchaValidationWhenRunningUnderCI();
  }

  close = () => {
    this.dialogRef.close();
  };

  forgotPassword = () => {
    this.isLoading = true;

    const cpfNumber = this.forgotPasswordForm.controls['cpf'].value;
    const birthDate = this.forgotPasswordForm.controls['birthday'].value;

    this.authentication
      .recoverPasswordEmail(cpfNumber, birthDate)
      .then(this.handleResponse.bind(this), this.handleError.bind(this));

    this.authentication.recoverPasswordEmail(cpfNumber, birthDate).then(
      (res: RecoverPasswordEmailResponse) => {
        this.isLoading = false;
        this.feedback = res.return;

        if (this.feedback.finded === true) {
          this.generatedPassword = true;
          if (this.feedback.send_email === true) {
            this.generatedPassword = true;
            this.user_email = this.feedback['email'];
            this.toastr.success(
              'Dados enviados para o email ' + this.feedback['email'] + '!',
              'Sucesso!',
              { timeOut: 7000, disableTimeOut: true, closeButton: true }
            );
            this.toastr.warning(
              'Atenção: Caso não receba o e-mail de recuperação de senha nos próximos 10 minutos, verifique o SPAM ou Lixo Eletrônico.',
              'Sucesso!',
              { timeOut: 7000, disableTimeOut: true, closeButton: true }
            );
            this.dialogRef.close();
          } else {
            this.errorMsg =
              'Serviço de e-mail indisponível no momento, tente novamente mais tarde.';
          }
        } else {
          this.errorMsg =
            'Não existe usuário cadastrado para este CPF. Deseja cadastrar?';
        }
      },
      (rej) => {
        this.errorMsg = 'Falha ao buscar usuário.';
      }
    );
  };

  private handleResponse(res: any): void {
    this.isLoading = false;
    const feedback = res.return;

    if (feedback['finded']) {
      this.generatedPassword = true;
      this.processEmailSending(feedback);
    } else {
      this.errorMsg =
        'Não existe usuário cadastrado para este CPF. Deseja cadastrar?';
    }
  }

  private processEmailSending(feedback: any): void {
    if (feedback['send_email']) {
      this.user_email = feedback['email'];
      this.showSuccessMessages();
      this.dialogRef.close();
    } else {
      this.errorMsg = 'Falha ao solicitar.';
    }
  }

  private showSuccessMessages(): void {
    this.toastr.success(
      `Dados enviados para o email ${this.user_email}!`,
      'Sucesso!',
      {
        timeOut: 7000,
        disableTimeOut: true,
        closeButton: true,
      }
    );
    this.toastr.warning(
      'Atenção: Caso não receba o e-mail de recuperação de senha nos próximos 10 minutos, verifique o SPAM ou Lixo Eletrônico.',
      'Sucesso!',
      {
        timeOut: 7000,
        disableTimeOut: true,
        closeButton: true,
      }
    );
  }

  private handleError(): void {
    this.isLoading = false;
    this.errorMsg = 'Falha ao buscar usuário.';
  }

  removeCaptchaValidationWhenRunningUnderCI() {
    if (environment.ci) {
      this.forgotPasswordForm.controls['captcha'].clearValidators();
      this.forgotPasswordForm.controls['captcha'].disable();
      this.forgotPasswordForm.updateValueAndValidity();
    }
  }

  handleCorrectCaptcha(token: string) {
    this.forgotPasswordForm.get('captcha').setValue(token);
  }

  selfRegister() {
    this.dialogRef.close();

    const dialogRef = this.dialog.open(RegisterComponent, {
      width: '80%',
      height: 'auto',
      data: {},
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      const array = [];
      const string = '';
    });
  }

  convertDateToBR(date) {
    var br_date = date;
    br_date = br_date.split('-');
    var us_date = br_date.reverse().join('/');
    return us_date;
  }

  isContinuosIntegration() {
    return environment.ci;
  }

  recaptchaExpired() {
    this.forgotPasswordForm.get('captcha').setValue(null);
  }
}
