<div class="ntm-table-data">
  <mat-card>
    <mat-toolbar
      color="primary"
      *ngIf="params.toolbar && params.toolbar.title"
      class="primary"
    >
      <div id="toolbar-title">{{ params.toolbar.title }}</div>
      <div *ngIf="params.toolbar.actionButton" id="action-button-area">
        <div
          *ngIf="params.list.permissions.indexOf('Incluir') >= 0"
          class="action-button alternative"
        >
          <button
            mat-raised-button
            (click)="setOutput(params.toolbar.actionButton.value)"
            color="{{ params.toolbar.actionButton.color }}"
            class="primary-text"
          >
            {{ params.toolbar.actionButton.value }}
          </button>
        </div>
      </div>

      <div *ngIf="params.toolbar.actionButton2" id="action-button-area">
        <div
          *ngIf="params.list.permissions.indexOf('Incluir') >= 0"
          class="action-button2 alternative"
        >
          <button
            mat-raised-button
            (click)="setOutput(params.toolbar.actionButton2.value)"
            color="{{ params.toolbar.actionButton2.color }}"
            class="primary-text"
          >
            {{ params.toolbar.actionButton2.value }}
          </button>
        </div>
      </div>

      <div *ngIf="params.toolbar.actionButtonToRemove" id="action-button-area">
        <div
          *ngIf="params.list.permissions.indexOf('Remover') >= 0"
          class="action-button2 alternative"
        >
          <button
            mat-raised-button
            (click)="setOutput(params.toolbar.actionButtonToRemove.value)"
            color="{{ params.toolbar.actionButtonToRemove.color }}"
            class="primary-text"
          >
            {{ params.toolbar.actionButtonToRemove.value }}
          </button>
        </div>
      </div>

      <div id="toolbar-action">
        <a
          *ngIf="
            params.list.permissions.indexOf('Remover') >= 0 &&
            itensToDeleteIds.length > 0
          "
        >
          <mat-icon
            *ngIf="!params.toolbar.outpuDelete"
            (click)="openDialogToDelete()"
            class="vertical-align-m toolbar-icon"
            >delete</mat-icon
          >
          <mat-icon
            *ngIf="params.toolbar.outpuDelete"
            (click)="setOutput('delete', itensToDeleteIds)"
            class="vertical-align-m toolbar-icon"
            >delete</mat-icon
          >
        </a>
      </div>
    </mat-toolbar>

    <mat-progress-bar
      mode="indeterminate"
      *ngIf="isLoading"
      style="width: inherit"
    ></mat-progress-bar>

    <div class="loading" *ngIf="isLoading">
      <mat-spinner class="spinner" diameter="50"></mat-spinner>
      <p>Carregando dados...</p>
    </div>

    <div class="content" *ngIf="!isLoading">
      <div *ngIf="!disableSearch">
        <mat-accordion>
          <mat-expansion-panel [expanded]="true" disabled>
            <mat-expansion-panel-header>
              <!-- (click)="make()" -->
              <mat-panel-title style="color: rgba(0, 0, 0, 0.87)">
                <mat-icon style="margin-top: -3px; margin-right: 5px"
                  >search</mat-icon
                >
                Pesquisar
              </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-form-field
              *ngIf="!advancedSearch"
              style="width: 100%"
              [appearance]="appearance"
            >
              <mat-label>Pesquisar</mat-label>
              <input
                matInput
                (keyup)="applyFilterOnKeyUp($event)"
                field="key"
                #searchKey
              />
            </mat-form-field>

            <div *ngIf="advancedSearch && searching">
              <form
                [formGroup]="advancedSearchForm"
                style="display: flex; flex-wrap: wrap; padding: 0 2rem"
              >
                <div id="grid" *ngFor="let item of params?.toolbar?.search">
                  <mat-form-field
                    *ngIf="item.component == 'autocomplete'"
                    [appearance]="appearance"
                  >
                    <mat-label>{{ item.placeholder }}</mat-label>
                    <input
                      placeholder="{{ item.placeholder }}"
                      matInput
                      [formControl]="pesquisa[item.field]"
                      [matAutocomplete]="autoActivity"
                      (keyup)="searchAdvance()"
                      formControlName="{{ item.field }}"
                      [(ngModel)]="advancedSearchForm.value[item.field]"
                    />
                    <mat-autocomplete
                      #autoActivity="matAutocomplete"
                      (optionSelected)="
                        searchAdvance(); filterArray(item, $event)
                      "
                    >
                      <mat-option
                        *ngFor="let data of filteredOptions[item.field] | async"
                        [value]="data[item.filter]"
                      >
                        {{ data[item.filter] }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>

                  <mat-form-field
                    *ngIf="item.component == 'input'"
                    [appearance]="appearance"
                  >
                    <mat-label>{{ item.placeholder }}</mat-label>

                    <input
                      type="text"
                      matInput
                      *ngIf="item?.filter == 'cpf_number'; else notCPF"
                      placeholder="{{ item.placeholder }}"
                      formControlName="{{ item.field }}"
                      mask="000.000.000-00"
                      [dropSpecialCharacters]="false"
                      (keyup)="searchAdvance(item.field)"
                    />

                    <ng-template #notCPF>
                      <input
                        type="text"
                        matInput
                        *ngIf="item?.date"
                        placeholder="{{ item.placeholder }}"
                        formControlName="{{ item.field }}"
                        mask="00/00/0000"
                        (keyup)="searchDate(item.field)"
                      />

                      <input
                        type="text"
                        matInput
                        *ngIf="!item?.date"
                        placeholder="{{ item.placeholder }}"
                        formControlName="{{ item.field }}"
                        (keyup)="searchAdvance()"
                      />
                    </ng-template>
                  </mat-form-field>

                  <mat-form-field
                    *ngIf="item.component == 'select'"
                    [appearance]="appearance"
                  >
                    <mat-label>{{ item.placeholder }}</mat-label>
                    <mat-select
                      [formControlName]="item.field"
                      (selectionChange)="searchAdvance()"
                    >
                      <mat-option>Selecione</mat-option>
                      <mat-option
                        *ngFor="let dado of item.options"
                        [value]="dado[item.value]"
                      >
                        {{ dado[item.filter] }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field *ngIf="item.custom" [appearance]="appearance">
                    <mat-label>{{ item.placeholder }}</mat-label>
                    <mat-select
                      formControlName="{{ item.field }}"
                      (selectionChange)="searchAdvance()"
                    >
                      <mat-option>Selecione</mat-option>
                      <mat-option
                        *ngFor="let dado of item.custom"
                        [value]="dado[item.value]"
                      >
                        {{ dado[item.filter] }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </form>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <mat-progress-bar
          mode="indeterminate"
          *ngIf="isDynamicallyLoading"
          class="dynamic-progress-bar"
        ></mat-progress-bar>
      </div>

      <div>
        <p *ngIf="total === 0" class="no-data">Nenhum dado encontrado.</p>
        <mat-table
          #table
          [dataSource]="dataSource"
          matSort
          (matSortChange)="sortTable($event)"
          *ngIf="total > 0"
        >
          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef>
              <mat-checkbox
                *ngIf="
                  params.list.permissions.indexOf('Remover') >= 0 &&
                  params?.toolbar?.delete
                "
                (change)="toggleAllSelections($event)"
                [checked]="
                  itensToDeleteIds.length == arraySource.length &&
                  arraySource.length > 0
                "
              >
              </mat-checkbox>
            </mat-header-cell>

            <mat-cell mat-cell *matCellDef="let element; let i = index">
              <mat-checkbox
                (change)="toggleChecked($event, element)"
                [checked]="isOnSelectedArray(element)"
                *ngIf="
                  params.list.permissions.indexOf('Remover') >= 0 &&
                  params?.toolbar?.delete &&
                  checkException(element, 'Delete')
                "
              >
              </mat-checkbox>
            </mat-cell>
          </ng-container>

          <ng-container
            *ngFor="let column of params.list.columns; let i = index"
            [matColumnDef]="column.columnDef"
          >
            <mat-header-cell
              mat-sort-header
              [disabled]="column.noSort"
              *matHeaderCellDef
              class="padding-cell"
            >
              {{ column.header }}
              <mat-icon *ngIf="!column.noSort" style="color: #bdbdbd"
                >sort</mat-icon
              >
            </mat-header-cell>

            <mat-cell *matCellDef="let element">
              <mat-slide-toggle
                *ngIf="column.activateLever"
                [checked]="column.status(element)"
                (change)="setOutput(column.action, element)"
              ></mat-slide-toggle>
              <button
                mat-raised-button
                *ngIf="column.btnName && !column.cell(element)"
                class="associate-btn ascent"
                (click)="onAssociateApplications(element.id)"
              >
                {{ column.btnName }}
              </button>

              <div *ngIf="column.statusCoursesColumn">
                <div
                  class="course-status"
                  *ngFor="let statusCourse of element.courses_students"
                >
                  {{ statusCourse.course.description }}
                  <mat-select
                    class="courseStatus"
                    [value]="statusCourse.status_id"
                    placeholder="Status"
                    (selectionChange)="
                      updateCourseStatus(
                        element.id,
                        statusCourse.course.id,
                        $event
                      )
                    "
                  >
                    <mat-option
                      *ngFor="let status of statusArray"
                      [value]="status.id"
                    >
                      {{ status.description }}</mat-option
                    >
                  </mat-select>
                  <br /><br /><br />
                </div>
              </div>

              <div
                *ngIf="
                  !column.checkBoxColumn &&
                  !column.hexadecimalColumn &&
                  !column.statusCoursesColumn &&
                  !column.tip
                "
                [innerHtml]="column.cell(element)"
              ></div>

              <div
                *ngIf="column.tip"
                [matTooltip]="column.cell(element)"
                class="profile-tooltip"
              >
                <mat-icon *ngIf="column.cell(element)" class="info"
                  >info</mat-icon
                >
              </div>

              <div
                *ngIf="column.hexadecimalColumn"
                [ngStyle]="{
                  background: column.cell(element),
                  color: column.cell(element),
                  display: 'block',
                  width: '5rem',
                  border: '1px solid black'
                }"
                [innerHtml]="column.cell(element)"
              ></div>

              <div *ngIf="column.checkBoxColumn" class="profile-check-boxes">
                <mat-checkbox
                  class="list-item"
                  [disabled]="params.list.disableCheckBoxes"
                  [checked]="column.cell(element)"
                >
                </mat-checkbox>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="updated_at_c">
            <mat-header-cell *matHeaderCellDef> Ações</mat-header-cell>

            <mat-cell mat-cell *matCellDef="let element">
              <a
                *ngIf="params.list.view"
                (click)="onClickEdit(params.list.view.route, element)"
                matTooltip="Ver"
              >
                <mat-icon class="settings">settings</mat-icon>
              </a>

              <a
                *ngIf="
                  params.list.edit &&
                  !params.list.edit.hide &&
                  params.list.permissions.indexOf('Editar') >= 0
                "
                (click)="onClickEdit(params.list.edit.route, element)"
                matTooltip="Editar"
              >
                <mat-icon class="edit">edit</mat-icon>
              </a>
              <a
                *ngIf="params.list.resend"
                (click)="openDialogToReSend(params.list.resend.route, element)"
              >
                <mat-icon
                  class="mail"
                  *ngIf="element.invite_status_id == 1"
                  matTooltip="Re-enviar"
                  >mail</mat-icon
                >
                <mat-icon class="mail" *ngIf="element.invite_status_id != 1"
                  >mail_outline</mat-icon
                >
              </a>

              <a
                *ngIf="params.list.modalView"
                (click)="openCoverSheet(element)"
                matTooltip="Ver"
              >
                <mat-icon class="remove_red_eye">remove_red_eye</mat-icon>
              </a>
              <a
                *ngIf="params.list.modalPreview"
                (click)="openEvaluatorManual(element)"
                matTooltip="Ver"
              >
                <mat-icon class="remove_red_eye">remove_red_eye</mat-icon>
              </a>
              <a
                *ngIf="params.list.examPreview"
                (click)="openExam(element)"
                matTooltip="Ver"
              >
                <mat-icon class="remove_red_eye">remove_red_eye</mat-icon>
              </a>
              <a
                *ngIf="params.list.viewList"
                (click)="
                  openListModal(
                    params.list.viewList.route,
                    params.list.viewList.storageRoute,
                    element
                  )
                "
                matTooltip="Ver"
              >
                <mat-icon class="attach_file">attach_file</mat-icon>
              </a>
              <a
                *ngIf="params.list.viewMatrix"
                (click)="
                  onViewReferenceMatrix(
                    params.list.viewMatrix.route,
                    element.id
                  )
                "
                matTooltip="Ver"
              >
                <mat-icon class="search">search</mat-icon>
              </a>
              <a
                *ngIf="
                  params.list.clone &&
                  params.list.permissions.indexOf('Incluir') >= 0
                "
                (click)="onClickEdit(params.list.clone.route, element)"
                matTooltip="Clonar"
              >
                <mat-icon class="content_copy">content_copy</mat-icon>
              </a>
              <a
                *ngIf="params.list.statusVideo"
                (click)="setOutput(params.list.statusVideo, element)"
                matTooltip="{{
                  element?.attendance?.action == 'Completo'
                    ? 'Completo'
                    : 'Aguardando'
                }}"
              >
                <mat-icon
                  [style.color]="
                    element?.attendance?.action == 'Completo' ? 'green' : 'red'
                  "
                  >{{
                    element?.attendance?.action == 'Completo'
                      ? 'replay'
                      : 'play_arrow'
                  }}</mat-icon
                >
              </a>

              <a
                *ngIf="params.list.preparationTest"
                (click)="setOutput(params.list.preparationTest, element)"
                matTooltip="Teste de preparação"
              >
                <mat-icon>assignment</mat-icon>
              </a>

              <mat-icon
                *ngIf="params.list.expansion"
                (click)="setOutput(params.list.expansion.action, element)"
                matTooltip="{{ params.list.expansion.tooltip }}"
                >zoom_in</mat-icon
              >

              <mat-icon
                *ngIf="params.list.reply"
                (click)="setOutput(params.list.reply.action, element)"
                matTooltip="{{ params.list.reply.tooltip }}"
                >reply</mat-icon
              >

              <mat-icon
                *ngIf="params.list.preview"
                (click)="setOutput(params.list.preview.action, element)"
                matTooltip="{{ params.list.preview.tooltip }}"
                >remove_red_eye</mat-icon
              >

              <mat-icon
                *ngIf="params.list.association"
                (click)="setOutput(params.list.association.action, element)"
                matTooltip="{{ params.list.association.tooltip }}"
              >
                {{
                  params.list.association.icon
                    ? params.list.association.icon
                    : 'event_note'
                }}</mat-icon
              >

              <mat-icon
                *ngIf="
                  params.list.version &&
                  params.list.permissions.indexOf('Incluir') >= 0
                "
                (click)="setOutput(params.list.version, element)"
                class="edit"
                matTooltip="Clonar"
                >content_copy</mat-icon
              >

              <a
                *ngIf="params.list.document"
                [style.color]="
                  element?.attendance?.action == 'Completo' ? 'green' : 'red'
                "
                (click)="setOutput(params.list.document, element)"
                matTooltip="Documento"
              >
                <mat-icon>save_all</mat-icon>
              </a>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="entrar" *ngIf="params.list.actionButton">
            <mat-header-cell mat-header-cell *matHeaderCellDef>
            </mat-header-cell>

            <mat-cell mat-cell *matCellDef="let element">
              <div>
                <div class="action-button alternative">
                  <button
                    mat-raised-button
                    *ngFor="let btn of params.list.actionButton"
                    (click)="setOutput(btn.value, element)"
                    color="{{ btn.color }}"
                  >
                    {{ btn.value }}
                  </button>
                </div>
              </div>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>

        <mat-paginator
          *ngIf="!hidePagination && total > 10"
          [length]="total"
          [pageSize]="itemsPerPage"
          [pageIndex]="0"
          [pageSizeOptions]="[10, 25, 50]"
          (page)="onClickPage($event)"
        ></mat-paginator>
      </div>
    </div>
  </mat-card>
</div>
