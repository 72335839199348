import { Component, OnInit } from "@angular/core";
import { Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatrixAdapComponent } from "../matrix-adap/matrix-adap.component";
import { getLocaleFirstDayOfWeek } from "@angular/common";
import { CrudService } from '@services/laravel/crud.service';
import { environment } from '@env/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: "app-matrix-saep-view",
  templateUrl: "./matrix-saep-view.component.html",
  styleUrls: ["./matrix-saep-view.component.scss"]
})
export class MatrixSaepViewComponent implements OnInit {
  public loading = true;
  public competence_units = [];
  public numberBasics = [];
  public numberTecnic = [];
  public numberManagement = [];
  public numberPsicomotor = [];

  public viewNotes: any;
  public matrix: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public _matrix: any,
    public crud: CrudService,
    private dialogRef: MatDialogRef<MatrixAdapComponent>,
    private toastr: ToastrService,
  ) {}

  ngOnInit() {
    this.getMatrixData(this._matrix.id);
  }

  getMatrixData(matrixId) {
    this.crud.get('import-matrices/' + matrixId).then((res: any) => {
      this.matrix = res;
      this.loadMatrix(this.matrix);
      this.numberBasics = this.matrix.capacities.filter(function(item) {
        return item.category_capacity_id === 1;
      });
      this.numberTecnic = this.matrix.capacities.filter(function(item) {
        return item.category_capacity_id === 2;
      });
      this.numberManagement = this.matrix.capacities.filter(function(item) {
        return item.category_capacity_id === 3;
      });
      this.numberPsicomotor = this.matrix.capacities.filter(function(item) {
        return item.category_capacity_id === 4;
      });
    });
  }

  loadMatrix = matrix => {
    let competence_units = [];
    let pratica = [];

    matrix.competence_units.map((unit, i) => {
      unit.competences_elements.map((element, ind) => {
        if (ind == 0) {
          element["competency_unity_description"] = unit.description;
          let span = unit.competences_elements.length || 1;
          element["competency_unity_span"] = span;
        }

        let competences_elements_capacities = [];
        let evidences_knowledges = [];

        matrix.capacities.forEach(capacitie => {
          let knowledges = [];

          element.competences_elements_capacities.map(
            (element_capacitie, index) => {
              if (
                capacitie.id == element_capacitie.capacity_id &&
                element_capacitie.competence_element_id == element.id
              ) {
                knowledges.push(this.sigla(element_capacitie.knowledges.code));
              }
            }
          );

          if (knowledges.length == 0) competences_elements_capacities.push("");
          else competences_elements_capacities.push(knowledges.filter(this.onlyUnique).sort().join(","));

          evidences_knowledges[capacitie.id] = [];
        });

        element.performance_standard.map((performanceStandard, indexPS) => {
          performanceStandard.evidences.map((evidence, indexEvidences) => {
            let evidences_knowledges_all = [];
            evidence.evidences_knowledges.map((evidenceKnowledges, indexEvidencesKnowledges) => {
              evidences_knowledges[evidence.capacity.id].push(this.sigla(evidenceKnowledges.knowledge.code));
            });
          });
        });

        let evidences_knowledges_unique_values = [];
        for (let key in evidences_knowledges) {
          if (evidences_knowledges[key].length == 0) {
            evidences_knowledges_unique_values.push("");
          } else {
            evidences_knowledges_unique_values.push(evidences_knowledges[key].filter(this.onlyUnique).sort().join(","));
          }
        }

        element.competences_elements_capacities = competences_elements_capacities;

        competence_units.push(element);

        element["evidences_knowledges"] = evidences_knowledges_unique_values; //inserir funcionalidade
        pratica.push(element);
      });
    });

    this.competence_units = competence_units;
    this.loading = false;
  };

  close(): void {
    this.dialogRef.close();
  }

  sigla(param) {
    let ordem = parseInt(param);
    let cod2, cod;

    if (ordem < 27) {
      return String.fromCharCode(ordem + 64);
    } else {
      cod2 = ordem % 26;
      if (cod2 == 0) cod2 = 26;

      cod = parseInt((ordem / 26).toString().split(".")[0]);

      if (ordem % 26 == 0) cod = cod - 1;

      return (
        String.fromCharCode(cod + 64) + "" + String.fromCharCode(cod2 + 64)
      );
    }
  }

  printMatrix: any = () => {
    if (this.loading === true) {
      this.toastr.warning("A matriz ainda não foi carregada, aguarde.");
      return;
    }
    var tab_text = '<html xmlns:x="urn:schemas-microsoft-com:office:excel">';
    tab_text =
      tab_text +
      "<head><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>";
    tab_text = tab_text + "<x:Name>Matriz De Refer&#234;ncia</x:Name>";
    tab_text =
      tab_text +
      "<x:WorksheetOptions><x:Panes></x:Panes></x:WorksheetOptions></x:ExcelWorksheet>";
    tab_text =
      tab_text +
      '</x:ExcelWorksheets></x:ExcelWorkbook></xml><meta charset="utf-8"></head><body>';
    tab_text = tab_text + "<table border='1px'>";

    //get table HTML code
    tab_text = tab_text + document.getElementById("document-header").innerHTML;
    tab_text = tab_text + "</table><br><br><br><table border='1px'>";
    tab_text = tab_text + document.getElementById("table1").innerHTML;
    tab_text = tab_text + "</table><br><br><br><table border='1px'>";
    tab_text = tab_text + document.getElementById("table2").innerHTML;
    tab_text = tab_text + "</table><br><br><br><table border='1px'>";
    tab_text = tab_text + document.getElementById("OC1").innerHTML;
    tab_text = tab_text + "</table></body></html>";
    tab_text = tab_text.replace(/<mat-icon(?:.|\n)*?mat-icon>/gm, "");

    var data_type = "data:application/vnd.ms-excel";
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");
    var date = new Date(new Date());
    const options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric"
    } as const;

    //For IE
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      if (window.navigator.msSaveBlob) {
        var blob = new Blob([tab_text], {
          type: "application/csv;charset=utf-8;"
        });
        navigator.msSaveBlob(
          blob,
          "Matriz_SAEP_Sap_" +
            date.toLocaleTimeString("pt-BR", options) +
            ".xls"
        );
        this.toastr.success("Arquivo gerado com sucesso.");
      }
    }
    //for Chrome and Firefox
    else {
      document
        .getElementById("print-btn")
        .setAttribute("href", data_type + ", " + encodeURIComponent(tab_text));
      document
        .getElementById("print-btn")
        .setAttribute(
          "download",
          "Matriz_SAEP_Sap_" +
            date.toLocaleTimeString("pt-BR", options) +
            ".xls"
        );
      this.toastr.success("Arquivo gerado com sucesso.");
    }
  };

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }
}
