import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

/**
 * Components
 */
import { LoginComponent } from './components/login/login.component';

/**
 * Guards
 */
import { AuthGuard } from '@shared/guards/auth.guard';
// import { ProfileGuard } from '@shared/guards/profile.guard';

/**
 * Modules
 */

const routes: Routes = [{ path: '',
    redirectTo: '/login',
    pathMatch: 'full' }, {
  path: 'login',
  component: LoginComponent
}, {
  path: 'login/:token',
  component: LoginComponent
}, {
  path: 'main',
  loadChildren: () => import('./modules/main/main.module').then(m => m.MainModule),
  canActivate: [AuthGuard]
}, {
  path: 'register/:id',
  component: LoginComponent,
}, {
  path: 'password/refresh/:id/:email',
  component: LoginComponent,
  canActivate: [AuthGuard]
}];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
