<h4 mat-dialog-title class="modal-toolbar-title primary" [matTooltip]="data?.title" matTooltipPosition="below">
  {{ data?.title || 'VíDEOAULA' | slice:0:50}}{{ (data?.title.length > 50) ? '...' : '' }}
  <span (click)="dialogRef.close()">
    <mat-icon class="icon-pattern">close</mat-icon>
  </span>
</h4>

<mat-dialog-content class="content-dialog">
  <div class="video-container">
    <p class="warning-text">
      Você precisa visualizar o vídeo até o final para que seu progresso na
      preparação seja contabilizado corretamente.
    </p>

    <youtube-player
      id="player"
      [width]=800
      [height]=600
      [videoId]="youtube_parser(data?.link)"
      [playerVars]="{
        modestbranding: 1,
        controls: 1,
        disablekb: 1,
        rel: 0,
        showinfo: 0
      }"
      (ready)="playerReady($event)"
      (change)="onVideoChange($event)"
    >
    </youtube-player>

    <div class="mt1 text">
      <h1 class="bold">DESCRIÇÃO</h1>
      <p>{{ data?.description }}</p>
      <p><a href="{{ data?.link }}" target="_blank">O vídeo não carregou? clique aqui para acessá-lo diretamente</a></p>
    </div>
  </div>
</mat-dialog-content>
