const BASE_URL = 'https://api-hml.sap.senai.br/';

export const environment = {
  name: 'HOMOLOGAÇÃO',
  production: false,
  ci: false,
  authenticationServiceUrl: 'http://localhost:3000/api',
  crudServiceUrl: 'https://localhost:3000/api',
  urlToOauthToken: BASE_URL + 'api/oauth/token',
  urlToApi: BASE_URL + 'api/',
  urlToApiPreviewFiles: BASE_URL,
  urlToAvatar: BASE_URL,
  urlToFiles: BASE_URL + 'storage/manual-attachments',
  urlToCoverSheet: BASE_URL + 'storage/cover-sheets',
  urlToEvaluatorManual: BASE_URL + 'storage/evaluator-manual',
};
