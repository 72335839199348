import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeBr from '@angular/common/locales/br';
registerLocaleData(localeBr, 'pt-BR');

/**
 * Components
 */
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';

/**
 * Modules
 */
import { SharedModule } from '@shared/shared.module';

/**
 * Routing
 */
import { AppRoutingModule } from './app-routing.module';
import { ResetPasswordComponent } from '@shared/components/reset-password/reset-password.component';
import { RegisterComponent } from './components/register/register.component';
import { AssociationUserSocialComponent } from './modules/main/components/association-user-social/association-user-social.component';
import { ApplicationComponent } from './components/application/application.component';
import { RecaptchaModule } from 'ng-recaptcha';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ResetPasswordComponent,
    RegisterComponent,
    AssociationUserSocialComponent,
    ApplicationComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    RecaptchaModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    RegisterComponent,
    AssociationUserSocialComponent,
    DatePipe
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
