
import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';

import { saveAs } from 'file-saver';

/**
 * Services
 */
import { CrudService } from '@services/laravel/crud.service';
import { ToastrService } from 'ngx-toastr';
export interface Gender {
  value: string;
  viewValue: string;
}

export interface Detail {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss']
})

export class ListViewComponent implements OnInit {
  displayedColumns: string[] = ['description', 'attachment', 'download'];

  public coverSheetForm: UntypedFormGroup;
  public id: any;
  public coverSheetData: any;
  public coverSheetHTML: any;
  public message: String = '';

  public modalTitle: string;
  public downloadRoute: any;
  public storageRoute: any;
  public arrayList: any;
  public showDownloadOption: boolean = false;

  constructor(
    private _fb: UntypedFormBuilder,
    private _crud: CrudService,
    private _route: ActivatedRoute,
    private _router: Router,
    public _snackbar: MatSnackBar,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ListViewComponent>,
    public toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

  }

  ngOnInit() {

  }

  close = () => {
    this.dialogRef.close();
  }

  downloadFile = (file) => {
    file.downloading = true;    
    this._crud.downloadFileFromUrl(this.storageRoute + '/' + file.hashname, file.filename).then(response => {
      saveAs(response, file.filename);
      file.downloading = false;
    }).catch(err => {
      file.downloading = false;
      this.toastr.error('Erro ao baixar anexo.', 'Erro!');
    });
  }

  convertDate(date){
    var br_date = date;
    br_date = br_date.split('/');
    var us_date = br_date.reverse().join('-');
    return us_date
  }

  convertDateToBR(date){
    var br_date = date;
    br_date = br_date.split('-');
    var us_date = br_date.reverse().join('/');
    return us_date
  }

  reduceToView(textToReduce) {
    if (textToReduce.length > 20) {
      return textToReduce.substring(0, 9) + '...' + textToReduce.substring(textToReduce.length - 8, textToReduce.length);
    }
    return textToReduce;
  }
}
