import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

/**
 * Services
 */
import { AuthenticationService } from '@services/laravel/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';

/**
 * @description Permission to navigate to any route IF there is an user loggedin ELSE force navigation to login route
 *    @extends _auth.setUser() - AuthenticationService method
 *        @returns user object with its id IF there is an user loggedin ELSE false
 *    @extends _auth.router - Router
 *        @returns navigate to login IF there is no user loggedin ELSE user will be allowed to navigate to any route under auth.guard
 */

@Injectable()
export class AuthGuard  {
  constructor(
    private _auth: AuthenticationService,
    private _router: Router,
    public _snackbar: MatSnackBar
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot, state:
    RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean
  {
    // this._auth.setUser()
    // .catch(error => {
    //   this._router.navigate(['/']);
    // })
    // .then(res => {
    //   if(!res['id']) {
    //     this._router.navigate(['/']);
    //   }
    // }).catch( rej => {
    //   this._snackbar.open('Você precisa estar logado para acessar essa área','',{
    //     duration: 4000
    //   })
    // });
    if(sessionStorage.getItem("access_token")){
      return true;
    }else{
      this._router.navigate(['/']);
      return false;
    }
  }
}
