import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from "@env/environment";
import { CrudService } from './laravel/crud.service';

@Injectable({
  providedIn: 'root'
})
export class MatrixService {
  private readonly apiService = inject(CrudService);
  headersToAuth: HttpHeaders;

  constructor(private http: HttpClient) {}

  getMatrix = (matrixId) => {
    return this.apiService.get(`sisbia/get-matrix-by-id/${matrixId}`).then(res => res);
  }
  
  filterMatrices(params) {
    return this.apiService.post(`sisbia/filter-matrices`, params).then(res => res);
  }
}
