<div [@openClose]="expandCard ? 'closed' : 'open'" class="content-card" [ngClass]="expandCard ? 'open' : ''">
  <div class="header" (click)="toggle()">
    <p>
      <mat-icon class="show-modal">{{ expandCard ? 'chevron_left' : 'chevron_right' }}</mat-icon>
      Dúvidas ou inconsistências?
      <mat-icon (click)="closeModal()" class="close">clear</mat-icon>
    </p>
  </div>
  <div class="expandible-content">
    <div>
      <p class="title-content">Suporte técnico</p>
      <p class="orientation">Contate-nos em caso de dúvidas ou inconsistências no sistema</p>

      <span class="mail">
        <mat-icon>mail</mat-icon>
        <p><a href="mailto:sap@al.senai.br">sap&#64;al.senai.br</a></p>
      </span>

      <mat-divider></mat-divider>

      <span class="orientation">
        <p class="subtitle">Informações que podem nos ajudar a solucionar suas dúvidas e eventuais erros:</p>
        <ul>
          <li>Foto ou indicação da tela que possui dúvidas</li>
          <li>Mensagem de erro apresentada</li>
          <li>Nome e perfil de usuário (Elaborador, Revisor, etc.)</li>
          <li>Processos realizados e etapa em que o erro foi apresentado.</li>
        </ul>
      </span>
    </div>
  </div>
</div>
